/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  audit_list(params) {
    return axios.post(`/api/audits`, params).then((response) => {
      return response.data;
    });
  },
  audit_get(id) {
    return axios.get(`/api/audits/${id}`).then((response) => {
      return response.data.data;
    });
  },
  audit_rollback(id) {
    return axios.post(`/api/audits/${id}/rollback`).then((response) => {
      return response.data.data;
    });
  },
  audit_delete(id) {
    return axios.delete(`/api/audits/${id}`).then((response) => {
      return response.data;
    });
  },
};
