import { DEFAULT_STORAGE_KEY, STORAGE_PREFIX } from "@/config/storage";

const { localStorage } = window;

export default function createStorageClient(storagePrefix = STORAGE_PREFIX) {
  function get(fieldName = DEFAULT_STORAGE_KEY) {
    const key = storagePrefix + fieldName;
    const value = localStorage.getItem(key);
    let data;

    try {
      data = JSON.parse(value);
    } catch (error) {
      const warnMsg = `"${key}" value from LocalStorage is not parseable. Fallback to default data type: String.`;
      console.warn(warnMsg);
      data = value;
    }

    return data;
  }

  function save(value = {}, fieldName = DEFAULT_STORAGE_KEY) {
    const key = storagePrefix + fieldName;
    const data = JSON.stringify(value);

    localStorage.setItem(key, data);
  }

  function clear(fieldName, addPrefix = true) {
    let fields = [];

    if (fieldName && fieldName !== "*") {
      if (Array.isArray(fieldName)) {
        fields = Array.from(fieldName);
      } else {
        fields = [fieldName];
      }

      if (addPrefix) {
        fields = fields.map((field) => `${storagePrefix}${field}`);
      }
    } else {
      fields = Object.keys(localStorage).filter((key) =>
        key.startsWith(storagePrefix)
      );
    }

    fields.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  return { get, save, clear };
}
