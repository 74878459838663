<template>
  <span>
    <label class="text-reader">
      Загрузить Правила Объекта
      <input type="file" @change="loadTextFromFile" accept="accept" />
    </label>
    <embed :src="form_content" :style="form_css_content" />
  </span>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "PdfReader",
  props: {
    accept: String,
    input_content: String,
  },
  data() {
    return {
      content: "",
      css_content: "",
    };
  },
  methods: {
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form_content = reader.result;
        this.$emit("load", this.content);
      };
    },
  },
  computed: {
    form_css_content: {
      get() {
        if (this.css_content) {
          return this.css_content;
        }
        if (this.input_content) {
          return "width: 100%; height: 100%";
        }
        return "";
      },
    },
    form_content: {
      get() {
        if (this.content) {
          return this.content;
        }
        return this.input_content;
      },
      set(value) {
        this.css_content = "";
        this.content = value;
        this.css_content = "width: 100%; height: 100%";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.text-reader {
  position: relative;
  overflow: hidden;
  display: inline-block;

  /* Fancy button style 😎 */
  border: 2px solid black;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
}
.text-reader input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
</style>
