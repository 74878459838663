export default async function downloadZip(blobs, filenames, ahref) {
  // The elements of blobs and filenames arrays are linked by indexes, without that this function will not work correctly, this will be fixed in new journal-fe version
  var JSZip = (await import("jszip")).default;

  if (!Array.isArray(blobs) || blobs.length === 0) {
    return;
  }

  const zip = new JSZip();

  blobs.forEach((blob, index) => {
    const filename = filenames[index];
    zip.file(filename, blob);
  });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    const url = window.URL.createObjectURL(content);

    ahref.href = url;
    ahref.download = "files.zip";
    ahref.click();
  });
}
