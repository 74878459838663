<template>
  <small-layout
    ><div id="demo">
      <h1>Cover table</h1>
      <form v-if="columnFormToggler" id="columnFormToggler" @submit.prevent="addColumnForm">
        <div>
          <label for="column_name">Имя столбца</label>
          <input id="column_name" type="text" />
        </div>
        <div>
          <label for="column_key">Поле данных столбца</label>
          <select id="column_key">
            <option value="address">Адрес</option>
            <option value="district">District</option>
            <option value="name">Название</option>
            <option value="cards">Cards (All/"Their only visitors")</option>
            <option value="visits">Visits (All/"Their only visitors")</option>
            <option value="cards_exceed_amount">More then {{ exceed_visits }} visits</option>
            <option value="avg_per_card">Avg. visits per card</option>
            <option value="avg_per_visit">Avg. price per visit</option>
            <option value="total_income">Income</option>
            <option value="percent_exceed">% of cards with visits > {{ exceed_visits }}</option>
            <option value="profit">profitability</option>
            <option value="expenses">Expenses</option>
            <option value="total_expenses">Total Expenses generated by Visitors</option>
            <option value="profit_number">
              Visitors marker: 1 - total_expenses (all visits of card visitors) / total_income (all income from card
              visitors)
            </option>
          </select>
        </div>
        <button type="submit">Сохранить</button>
      </form>
      <form id="search">
        Search <input name="query" v-model="filterKey" />
        <button type="button" @click="columnFormToggler = !columnFormToggler">Форма добавления столбцов</button>
        <button type="button" @click="clearColumnsAndRefresh">Вернуть Столбцы по умолчанию</button>
        <label for="city">City</label>
        <select v-model="filterCity" id="city">
          <option value=""></option>
          <option v-for="option in citiesList" :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </select>
      </form>
      <div>
        <label for="month_at">Month</label>
        <input type="month" id="month_at" @change="setPeriod" v-model="month_at" />
        <label for="started_at">Started</label>
        <input type="date" id="started_at" v-model="started_at" />
        <label for="finished_at">Finished</label>
        <input type="date" id="finished_at" v-model="finished_at" />
        <label for="exceed_visits">More then {{ exceed_visits }}} visits in period</label>
        <input type="number" id="exceed_visits" v-model="exceed_visits" />
        <label for="exclude_companies">Exclude Companies by Ids from profitability (example: 25,86,75)</label>
        <input type="text" id="exclude_companies" v-model="exclude_companies" />
        <a ref="download">Download again</a>
        <button type="button" @click="fetchStats">Reload</button>
      </div>
      <table>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              <span v-if="item.key === 'cards_exceed'">More then {{ exceed_visits }} visits</span>
              <span v-else-if="item.key === 'percent_exceed'">% of cards with visits > {{ exceed_visits }}</span>
              <span v-else>{{ item.name | capitalize }}</span>
              <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
              <span class="remove_button" @click="removeColumn(item)">X</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <td v-for="column in columns">
              <span v-if="column.key === 'name'">
                <router-link
                  :to="{
                    name: 'search_visit_supplier',
                    params: { supplier_id: entry.supplier_id, started_at, finished_at },
                  }"
                  >{{ entry.name }}</router-link
                >
              </span>
              <span v-else-if="column.key === 'cards' && entry.ucards && entry.cards">
                <router-link
                  :to="{
                    name: 'search_card_card',
                    params: { card_id: entry.cards.join(','), started_at, finished_at },
                  }"
                  >{{ entry.cards.length }}</router-link
                >
                /
                <router-link
                  :to="{
                    name: 'search_card_card',
                    params: { card_id: entry.ucards.join(','), started_at, finished_at },
                  }"
                  >{{ entry.ucards.length }}</router-link
                >
              </span>
              <span v-else-if="column.key === 'cards' && entry.cards">
                <router-link
                  :to="{
                    name: 'search_card_card',
                    params: { card_id: entry.cards.join(','), started_at, finished_at },
                  }"
                  >{{ entry.cards.length }}</router-link
                >
              </span>
              <span v-else-if="column.key === 'visits'">
                {{ entry.visits + "/" + entry.uvisits }}
              </span>
              <span v-else-if="column.key === 'cards_exceed_amount' && entry.cards_exceed">
                <router-link
                  :to="{
                    name: 'search_card_card',
                    params: { card_id: entry.cards_exceed.join(','), started_at, finished_at },
                  }"
                  >{{ entry.cards_exceed_amount }}</router-link
                >
              </span>
              <span v-else-if="column.key === 'percent_exceed'">
                <router-link
                  v-if="entry.ucards && entry.cards_exceed"
                  :to="{
                    name: 'search_card_card',
                    params: { card_id: entry.ucards.join(','), started_at, finished_at },
                  }"
                  >{{ entry.percent_exceed }}%</router-link
                >
                <span v-else>{{ entry.percent_exceed }}%</span>
              </span>
              <span
                v-else-if="
                  ['total_income', 'total_expenses', 'expenses', 'avg_per_visit', 'profit'].indexOf(column.key) !== -1
                "
                >{{ formatCurrency(entry[column.key]) }}</span
              >
              <span v-else>{{ entry[column.key] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import SortMixin from "../../mixins/SortMixin";
import { mapActions, mapGetters } from "vuex";
import buildCurrencyFormatter from "../../middleware/buildCurrencyFormatter";
import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";

let newStartedAt = new Date();
let newFinishedAt = new Date();
newStartedAt.setMonth(newFinishedAt.getMonth() - 1);
newStartedAt.setDate(1);
newFinishedAt.setDate(0);
export default {
  mixins: [SortMixin],
  data: function () {
    return {
      source: [],
      month_at: newStartedAt.toJSON().substr(0, 7),
      started_at: newStartedAt.toJSON().substr(0, 10),
      finished_at: newFinishedAt.toJSON().substr(0, 10),
      exceed_visits: 10,
      exclude_companies: "62,86",
      columnFormToggler: false,
    };
  },
  computed: {
    ...mapGetters({
      country: "auth/country",
      citiesList: "config/citiesList",
    }),
  },
  currencyFormat() {
    const formatter = buildCurrencyFormatter(this.country);
    return formatter.format;
  },
  methods: {
    formatCurrency(value) {
      return this.currencyFormat ? this.currencyFormat(value / 100) : value;
    },
    ...mapActions({
      cover_list: "attraction/supplier_cover_list",
    }),
    createColumns() {
      const columns = this.getColumns();
      if (!columns || !columns.length) {
        return this.clearColumns();
      }
      return columns;
    },
    getColumns() {
      try {
        let data = JSON.parse(localStorage.getItem("journal_supp_cover_index_columns"));
        return data.filter((item) => {
          return item && item.key && item.name;
        });
      } catch (e) {
        return [];
      }
    },
    clearColumns() {
      this.saveColumns([
        { key: "address", name: "Адрес", order: 1 },
        { key: "district", name: "District", order: 1 },
        { key: "name", name: "Название", order: 1 },
        { key: "cards", name: 'Cards (All/"Their only visitors")', order: 1 },
        { key: "visits", name: 'Visits (All/"Their only visitors")', order: 1 },
        { key: "cards_exceed_amount", name: "More then {{exceed_visits}} visits", order: 1 },
        { key: "avg_per_card", name: "Avg. visits per card", order: 1 },
        { key: "percent_exceed", name: "% of cards with visits > {{exceed_visits}}", order: 1 },
        { key: "profit", name: "profitability", order: 1 },
        {
          key: "profit_number",
          name: "Visitors marker: 1 - total_expenses (all visits of card visitors) / total_income (all income from card visitors)",
          order: 1,
        },
        { key: "total_income", name: "Income", order: 1 },
        { key: "avg_per_visit", name: "Avg. price per visit", order: 1 },
      ]);
      return this.getColumns();
    },
    clearColumnsAndRefresh() {
      this.columns = this.clearColumns();
    },
    saveColumns(columns) {
      localStorage.setItem("journal_supp_cover_index_columns", JSON.stringify(columns));
    },
    addColumn(column) {
      let columns = this.getColumns();
      columns.push(column);
      this.saveColumns(columns);
      this.columns = columns;
    },
    addColumnForm(e) {
      let key = e.target.column_key.value;
      let name = "";
      if (e.target.column_key.selectedOptions.length) {
        name = e.target.column_key.selectedOptions[0].text;
      }
      if (e.target.column_name.value) {
        name = e.target.column_name.value;
      }
      this.addColumn({ key: key, name: name, order: 1 });
      this.columns = this.getColumns();
    },
    removeColumn(item) {
      let columns = this.getColumns();
      columns = columns.filter((column) => column.key !== item.key);
      this.saveColumns(columns);
      this.columns = columns;
    },
    async setPeriod() {
      const month = new Date(this.month_at);
      this.started_at = month.toJSON().substr(0, 10);
      month.setMonth(month.getMonth() + 1);
      month.setDate(0);
      this.finished_at = month.toJSON().substr(0, 10);
      await this.fetchStats();
    },
    getValues(row) {
      return row.slice(1);
    },
    async fetchStats() {
      const source = await this.cover_list({
        started_at: this.started_at,
        finished_at: this.finished_at,
        exceed: this.exceed_visits,
        exclude_companies: this.exclude_companies.split(","),
      });
      this.source = source.map((entry) => {
        entry.profit = 0;
        entry.profit_number = 0;
        entry.avg_per_card = 0;
        entry.avg_per_visit = 0;
        entry.cards_exceed_amount = 0;
        entry.percent_exceed = 0;
        if (entry.cards_exceed && entry.cards) {
          entry.percent_exceed = Math.round((entry.cards_exceed.length * 100) / Number(entry.cards.length));
        }
        if (entry.cards_exceed) {
          entry.cards_exceed_amount = entry.cards_exceed.length;
        }
        if (entry.expenses) {
          entry.avg_per_visit = Math.round(Number(entry.expenses) / Number(entry.visits));
        }
        if (entry.cards.length) {
          entry.avg_per_card = Math.round((Number(entry.visits) * 100) / Number(entry.cards.length)) / 100;
        }
        if (entry.total_income) {
          entry.profit = Math.round(entry.total_income - entry.total_expenses);
          entry.profit_number = Math.round(100 - (100 * entry.total_expenses) / entry.total_income) / 100;
        }
        return entry;
      });
    },
  },
  async mounted() {
    await this.$store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, [{ country: [this.$store.state.auth.country] }]);
    this.fetchStats();
  },
};
</script>

<style scoped lang="scss">
th,
td {
  min-width: 120px;
  padding: 10px 20px;
}
</style>
