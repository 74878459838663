/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import axios from "axios";
export default {
  set_header(name, value) {
    axios.defaults.headers.common[name] = value;
  },
  signin(postData) {
    return axios
      .post("/api/admin_login", postData)
      .then((response) => response.data);
  },
  signUp(postData) {
    return axios
      .post("/api/auth/signup", postData)
      .then((response) => response.data);
  },
  refreshLogin(refreshToken) {
    return axios
      .post("/api/login/refresh", undefined, {
        headers: {
          refreshtoken: refreshToken,
        },
      })
      .then((response) => response.data);
  },
  logout() {
    return axios.post("/api/logout");
  },
  forgotPassword(email) {
    return axios
      .post(
        "/api/auth/password/email",
        { email },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => response.data);
  },
  resetPassword(token, email, password, passwordConfirmation) {
    return axios
      .post(
        "/api/auth/password/reset",
        {
          token: token,
          email: email,
          password: password,
          password_confirmation: passwordConfirmation,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => response.data);
  },
};
