<template>
  <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <fieldset v-if="ticket">
      <legend>Ticket details</legend>
      <div class="col-span-6">
        <jet-label value="Ticket Value" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ ticket.value }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Ticket Taken At" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ ticket.taken_at }}</div>
          </div>
        </div>
      </div>
      <jet-section-border />
      <button @click="resetTicket">Reset</button>
    </fieldset>

    <fieldset v-if="visit">
      <legend>Visit Details</legend>
      <div class="col-span-6">
        <jet-label value="Visit Status" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ visit.status }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Visit Created At" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ visit.created_at }}</div>
          </div>
        </div>
      </div>
      <jet-section-border />
      <button @click="resetVisit">Reset</button>
    </fieldset>
    <fieldset class="app-details">
      <legend>App Details</legend>
      <div class="col-span-6">
        <jet-label value="Instance" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.instance_id || "no_installed" }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Token" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.api_token || "no_installed" }}</div>
          </div>
        </div>
        <jet-section-border />
        <button @click="resetInstanceIdAndToken">Reset</button>
      </div>
      <div class="col-span-6">
        <jet-label value="Android Build" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.build || "no_installed" }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Activation Code" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.sms_token || "no_installed" }}</div>
          </div>
        </div>
      </div>

      <div v-if="typeof card.sms_token_v2 !== 'undefined'" class="col-span-6">
        <jet-label value="Activation Code V2" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.sms_token_v2 || "no_installed" }}</div>
          </div>
        </div>
      </div>

      <div v-if="typeof card.subscriber_panel_sms_token !== 'undefined'" class="col-span-6">
        <jet-label value="Subscriber panel sms activation code" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.subscriber_panel_sms_token || "no_installed" }}</div>
          </div>
        </div>
      </div>

      <div class="col-span-6">
        <jet-label value="Activations Left" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.remaining_installs }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Attempts to enter activation code Left" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.attempts }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Last Visit At" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.visit_at || "no_visit" }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Scan radios (Meters)" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.scan_radius }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Location Status" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.location_status }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Anti Fraud Status" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.anti_fraud_mode }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Anti Fraud Result" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.anti_fraud_result }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Is Suspicious" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.is_suspicious }}</div>
          </div>
        </div>
      </div>

      <jet-section-border />
      <button @click="resetInstalls">Reset</button>

      <div class="col-span-6">
        <jet-label value="Last notification sent from the HR portal" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>{{ card.prolongating_type | prolongating_type_label }}</div>
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <jet-label value="Activation date in notification" />

        <div class="flex items-center mt-2">
          <div class="ml-4 leading-tight">
            <div>
              {{
                card.prolongating_at
                  ? new Date(card.prolongating_at).toLocaleString(undefined, {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                  : "no data"
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 grid-rows-2">
        <div><jet-label value="Request to upload/update photo in the application" /></div>
        <div><button @click="resetAntiFraudMode">Reset photo</button></div>
      </div>

      <template>
        <div class="photo-gallery">
          <div class="photo-block">
            <h2>Last Photo</h2>
            <img :src="currentPhotoUrl" alt="last photo" />
            <p>{{ currentPhotoDate }}</p>
          </div>
          <template v-for="photo in photos">
            <SubscriberHolder :photo="photo" />
          </template>
        </div>
      </template>
    </fieldset>
    <fieldset v-if="smsList">
      <legend>SMS LIST</legend>
      <table class="table-mixed">
        <thead>
          <tr>
            <th class="w-1/2 px-4 py-2 text-light-blue-600">Type</th>
            <th class="w-1/4 px-4 py-2 text-light-blue-600">Sent</th>
            <th class="w-1/4 px-4 py-2 text-light-blue-600">Text</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="sms in smsList">
            <tr>
              <td class="break-all border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">
                {{ sms.sms_type }}
              </td>
              <td class="break-all border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium">
                {{ sms.created_at }}
              </td>
              <td
                class="break-all border border-light-blue-500 px-4 py-2 text-light-blue-600 font-medium truncate"
                title="sms.text"
              >
                {{ sms.text }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
td {
  word-break: break-word;
}
</style>

<script>
import axios from "axios";
import JetSectionBorder from "../Jetstream/SectionBorder";
import JetLabel from "../Jetstream/Label";
import SubscriberHolder from "./SubscriberHolder";
import { ref } from "@vue/composition-api";
import dateToLocaleString from "@/helpers/date/dateToLocaleString";

export default {
  components: {
    JetSectionBorder,
    JetLabel,
    SubscriberHolder,
  },
  props: {
    card: Object,
    visit: Object,
    ticket: Object,
    smsList: Array,
    photos: Array,
    currentPhoto: Object,
  },
  setup(props) {
    const currentPhotoUrl = ref("data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=");
    const currentPhotoDate = ref(null);
    const currentPhoto = props.currentPhoto;
    if (currentPhoto && currentPhoto.id) {
      axios.get(`/api/helpdesk/card/${currentPhoto.holder_id}/photo/${currentPhoto.id}`).then((resource) => {
        const url = resource.data.url;
        if (undefined === url) {
          return;
        }

        currentPhotoUrl.value = url;
        currentPhotoDate.value = dateToLocaleString(currentPhoto.created_at);
      });
    }

    return { currentPhotoUrl, currentPhotoDate };
  },
  methods: {
    resetInstalls() {
      axios.post(`/api/jrnl/admin/holders/${this.card.id}/reset/installs`).then(this.load);
    },
    resetTicket() {
      axios.post(`/api/jrnl/admin/holders/${this.card.id}/reset/ticket`).then(this.load);
    },
    resetVisit() {
      axios.post(`/api/jrnl/admin/holders/${this.card.id}/reset/visit`).then(this.load);
    },
    resetInstanceIdAndToken() {
      axios.post(`/api/jrnl/admin/holders/${this.card.id}/reset/instance`).then(this.load);
    },
    resetAntiFraudMode() {
      axios.post(`/api/jrnl/admin/holders/${this.card.id}/reset/anti-fraud-mode`).then(this.load);
    },
  },
};
</script>
<style lang="scss">
.app-details {
  position: relative;
}
.photo-gallery {
  position: absolute;
  top: 0;
  right: 0;
  overflow: scroll;
  max-height: 100%;
  img {
    min-width: 60px;
    min-height: 100px;
    background-color: darkgray;
    max-height: 300px;
    margin: 10px 0;
  }
}
</style>
