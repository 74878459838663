import axios from "axios";

const AXIOS_DEFAULT_CONFIG = {
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
  },
};

export default function createAxios(config = {}) {
  return axios.create({ ...AXIOS_DEFAULT_CONFIG, config });
}
