<template>
  <ModalContent @close="close">
    <template slot="header">
      <h3>Требуется подтверждение</h3>
    </template>
    <template slot="content">
      <p>Вы уверены?</p>
    </template>
    <template slot="controls">
      <Button button-type="light" @click.prevent="close" auto-width>Отменить</Button>
      <Button :processing="state.processing" @click.prevent="onAccept" auto-width>Удалить</Button>
    </template>
  </ModalContent>
</template>

<script>
import { reactive } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import ModalContent from "./ModalContent.vue";

import store from "@/store";

export default {
  emits: ["close"],

  components: {
    Button,
    ModalContent,
  },

  setup(props, { emit }) {
    const state = reactive({
      processing: false,
    });

    async function onAccept() {
      if (state.processing) {
        return;
      }

      const { payload } = store.state.modal;

      if (payload && payload.onAccept) {
        state.processing = true;
        await payload.onAccept();
        state.processing = false;
      }

      close();
    }

    function close() {
      emit("close");
    }

    return {
      state,

      onAccept,
      close,
    };
  },
};
</script>
