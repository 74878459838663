<template>
  <label class="base-checkbox">
    <input type="checkbox" class="base-checkbox_input" :checked="props.value" @change="onChange" />
    <span class="base-checkbox_fake-input"></span>
    <span class="base-checkbox_text" :class="{ '__overflow-elipsis': props.textOverflowElipsis }" :title="titleText"
      ><slot></slot
    ></span>
  </label>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  emits: ["input"],

  props: {
    value: {
      type: Boolean,
      required: false,
    },
    textOverflowElipsis: {
      type: Boolean,
      required: false,
    },
  },

  setup(props, { emit, slots }) {
    const titleText = computed(() => (props.textOverflowElipsis ? slots.default()[0].text : ""));
    function onChange(event) {
      emit("input", event.target.checked);
      emit("change", event.target.checked);
    }

    return {
      props,
      titleText,

      onChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-checkbox {
  display: inline-flex;
  align-items: center;

  &_input {
    display: none;

    &:checked {
      & + .base-checkbox_fake-input {
        &::before {
          border-color: #346aed;
        }

        &::after {
          display: block;
        }
      }

      & ~ .base-checkbox_text {
        color: #000000;
      }
    }
  }

  &_fake-input {
    flex-shrink: 0;
    position: relative;
    margin-right: 10px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      border: 2px solid #636363;
      border-radius: 4px;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' fill='none'%3e%3cpath fill='%233649F8' d='M4.222 7.113 1.609 4.5a.667.667 0 0 0-.942.943l2.612 2.612a1.333 1.333 0 0 0 1.886 0l6.168-6.168a.667.667 0 0 0-.942-.942L4.222 7.113Z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: center;
      display: none;
    }
  }

  &_text {
    color: #444545;
    user-select: none;

    &.__overflow-elipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
