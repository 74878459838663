<template>
  <ModalContent tag="form" @close="close">
    <template slot="header">
      <h3>{{ $t("Additionally") }}</h3>
    </template>
    <template slot="content">
      <SpinnerBrand v-if="processingFetchVisit" centered />
      <ErrorBanner :message="errorMessage" />
      <template v-if="isVisitInfoLoaded">
        <div class="content-item" v-for="item in visitState" :key="item.title">
          <p class="content-item_title">{{ $t(`${item.title}`) }}</p>
          <p class="content-item_body">{{ item.value }}</p>
        </div>
      </template>
    </template>
    <template v-if="isVisitInfoLoaded" slot="controls">
      <Button button-type="light" auto-width @click.prevent="close">{{ $t("Return to the table") }}</Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, ref, reactive, onMounted } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import ModalContent from "@/components/modal/ModalContent.vue";

import store from "@/store";
import formatCurrency from "@/helpers/formatters/formatCurrency";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

const fieldsThatNeedFormatting = ["point", "price", "card_price"];
const fieldsFormatters = {
  point: prepareUserVisitCoordinates,
  price: formatCurrency,
  card_price: formatCurrency,
};

function prepareUserVisitCoordinates(array) {
  const isFake = array[2] ? "fake" : "";

  return `lat: ${array[0]}, long: ${array[1]} ${isFake}`;
}

export default {
  emits: ["close"],

  components: {
    Button,
    ErrorBanner,
    ModalContent,
    SpinnerBrand,
  },

  setup(props, { emit }) {
    const processing = ref(false);
    const processingFetchVisit = ref(false);
    const isVisitInfoLoaded = ref(false);
    const errorMessage = ref("");
    const attractionsOptions = ref([]);

    const payloadVisitElementsIndexes = reactive({});
    const visitState = reactive({
      id: {
        title: "Visit ID",
        value: "",
      },
      registered: {
        title: "Registered",
        value: "",
      },
      holder: {
        title: "Holder",
        value: "",
      },
      card_level: {
        title: "Subscription",
        value: "",
      },
      company_name: {
        title: "Cpompany",
        value: "",
      },
      sup_name: {
        title: "Supplier",
        value: "",
      },
      status: {
        title: "Status",
        value: "",
      },
      reason: {
        title: "Reason",
        value: "",
      },
      attr_name: {
        title: "Attraction",
        value: "",
      },
      city: {
        title: "Location",
        value: "",
      },
      point: {
        title: "Coordinates",
        value: "",
      },
      sup_type: {
        title: "Activities",
        value: "",
      },
      price: {
        title: "Price",
        value: "",
      },
      card_price: {
        title: "Subscription price",
        value: "",
      },
    });

    const modalPayload = computed(() => store.state.modal.payload);

    function close() {
      emit("close");
    }

    async function fetchVisit(visitId) {
      errorMessage.value = "";

      try {
        return await store.dispatch("helpdesk/view_visit", visitId);
      } catch (error) {
        const { errorMessage: text } = prepareAxiosErrors(error);

        errorMessage.value = text;
      }
    }

    onMounted(async () => {
      processingFetchVisit.value = true;
      const { visitId } = modalPayload.value;

      const visit = await fetchVisit(visitId);

      if (errorMessage.value) {
        processingFetchVisit.value = false;
        return;
      }

      const {
        id,
        registered,
        holder,
        card_level,
        company_name,
        sup_name,
        sup_type,
        status,
        attr_name,
        reason,
        city,
        point,
        price,
        card_price,
      } = visit;

      const payloadVisit = {
        id,
        registered,
        holder,
        card_level,
        company_name,
        sup_name,
        status,
        reason,
        attr_name,
        city,
        point,
        sup_type,
        price,
        card_price,
      };

      const payloadVisitKeys = Object.keys(payloadVisit);

      payloadVisitKeys.forEach((key) => {
        if (fieldsThatNeedFormatting.includes(key)) {
          visitState[key].value = fieldsFormatters[key](payloadVisit[key]);
        } else {
          visitState[key].value = payloadVisit[key];
        }
      });

      isVisitInfoLoaded.value = true;

      processingFetchVisit.value = false;
    });

    return {
      errorMessage,
      processing,
      processingFetchVisit,
      isVisitInfoLoaded,
      payloadVisitElementsIndexes,
      attractionsOptions,

      visitState,

      close,
    };
  },
};
</script>
<style lang="scss" scoped>
.content-item {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #e7e7e7;
  color: #444545;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &_title {
    max-width: 210px;
    flex: 1;
    word-wrap: break-word;
    font-size: 22px;
    font-weight: 600;
  }

  &_body {
    word-wrap: break-word;
    max-width: 172px;
  }
}
</style>
