<template>
  <fieldset>
    <validation-errors :errors="errors" v-if="errors"></validation-errors>
    <div>
      <label for="up_name">{{ $t("attraction.name_title") }}</label>
      <input ref="nameInputRef" id="up_name" type="text" v-model="changeAttraction.name" required />
    </div>
    <div>
      <label for="up_levels">{{ $t("attraction.level_title") }}</label>
      <input
        ref="levelsInputRef"
        id="up_levels"
        type="text"
        v-model="changeAttraction.levels"
        :pattern="config.supported_countries[country].subscriptions_acronyms_regex"
        required
      />
    </div>
    <div>
      <label for="up_appendix">{{ $t("attraction.appendix_title") }}</label>
      <input id="up_appendix" type="text" v-model="changeAttraction.appendix" />
    </div>
    <div>
      <label for="up_list">{{ $t("attraction.list_title") }}</label>
      <select id="up_list" multiple="multiple" v-model="changeAttraction.list_array">
        <option v-for="(value, key) in attractions" :value="key">{{ value }}</option>
      </select>
    </div>
    <div>
      <label for="up_price">{{ $t("attraction.price_title") }}</label>
      <currency-input
        id="up_price"
        v-model="changeAttraction.price"
        :model-value="changeAttraction.model_price"
        :options="{ currency: currency, currencyDisplay: 'symbol', exportValueAsInteger: true }"
        required
      />
    </div>
    <div>
      <label for="up_activated_at">{{ $t("attraction.activated_at_title") }}</label>
      <input id="up_activated_at" type="date" v-model="changeAttraction.activated_at" />
    </div>
    <div>
      <label for="up_published_at">{{ $t("attraction.published_at_title") }}</label>
      <input id="up_published_at" type="date" v-model="changeAttraction.published_at" />
    </div>
    <div>
      <label for="up_command">{{ $t("attraction.command_title") }}</label>
      <select id="up_command" v-model="changeAttraction.command">
        <option value="update">{{ $t("attraction.cmd_update") }}</option>
        <option value="create">{{ $t("attraction.cmd_create") }}</option>
        <option value="delete">{{ $t("attraction.cmd_delete") }}</option>
      </select>
    </div>
    <slot></slot>
    <div>
      <button @click="$emit('submit', changeAttraction)" :disabled="!enableSubmit">
        {{ changeAttraction.id ? $t("attraction.update_btn") : $t("attraction.create_btn") }}
      </button>
    </div>
  </fieldset>
</template>

<script>
import config from "../../config";
import CurrencyInput from "../CurrencyInput";
import buildCurrencyFormatter from "../../middleware/buildCurrencyFormatter";
import ValidationErrors from "../ValidationErrors";
import { formatToRFC3339 } from "../../middleware/functions";

export default {
  components: {
    CurrencyInput,
    ValidationErrors,
  },
  props: ["country", "attraction", "errors", "command", "published_at", "activated_at"],

  watch: {
    attraction: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val) {
          const changedValue = Object.assign({ change: true }, val);
          changedValue.list_array = changedValue.list ? changedValue.list.split(",") : [];
          changedValue.published_at = this.published_at;
          changedValue.activated_at = this.activated_at;
          changedValue.command = this.command;
          this.changeAttraction = changedValue;
        }
      },
      deep: true,
    },
    changeAttraction: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.$refs.nameInputRef && this.$refs.levelsInputRef && this.changeAttraction.price) {
            this.enableSubmit = this.checkValidity();
          }
        });
      },
    },
  },

  data() {
    const formatter = buildCurrencyFormatter(this.country);
    return {
      changeAttraction: {
        list_array: [],
        price: "",
        name: "",
        levels: "",
      },
      attractions: config.supported_attractions,
      currency: formatter.currency,
      currencyFormat: formatter.format,
      enableSubmit: false,
      config,
    };
  },
  methods: {
    checkValidity() {
      return (
        this.$refs.nameInputRef.validity.valid &&
        this.$refs.levelsInputRef.validity.valid &&
        this.changeAttraction.price
      );
    },
  },
};
</script>

<style scoped>
input:invalid {
  border: red solid 1px;
}
</style>
