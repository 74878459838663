<template>
  <ModalContent tag="form" @close="close">
    <template slot="header">
      <h3>{{ $t("Edit") }}</h3>
    </template>
    <template slot="content">
      <SpinnerBrand v-if="processingFetchVisit" centered />
      <ErrorBanner :message="errorMessage" />
      <template v-if="isVisitInfoLoaded">
        <div class="content-item">
          <p class="content-item_title">{{ $t("Visit ID") }}</p>
          <p class="content-item_body">{{ visitState.id }}</p>
        </div>
        <div class="content-item">
          <p class="content-item_title">{{ $t("Registered") }}</p>
          <p class="content-item_body">{{ visitState.registered }}</p>
        </div>
        <div class="content-item">
          <p class="content-item_title">{{ $t("Holder") }}</p>
          <p class="content-item_body">{{ visitState.holder }}</p>
        </div>
        <div class="content-item">
          <p class="content-item_title">{{ $t("Subscription") }}</p>
          <p class="content-item_body">{{ visitState.card_level }}</p>
        </div>
        <div class="content-item">
          <p class="content-item_title">{{ $t("Company") }}</p>
          <p class="content-item_body">{{ visitState.company_name }}</p>
        </div>
        <div class="content-item">
          <p class="content-item_title">{{ $t("Supplier") }}</p>
          <p class="content-item_body">{{ visitState.sup_name }}</p>
        </div>
        <NewSelectInput
          v-model="visitState.status"
          :error="visitState.errors.status"
          :label="`${$t('Status')}:`"
          placeholder="Select status"
          :options="VISIT_STATUSES_OPTIONS"
        />
        <!-- Unlock this input when backend will allow to send custom reason8 of visit rejection -->
        <!-- <BaseInput
        v-model="visitState.reason"
        type="text"
        label="Reason"
        :required="isvisitStatusReasonRequired"
        :disabled="!isvisitStatusReasonRequired"
        tooltip-text-on-the-right
      /> -->
        <NewSelectInput
          v-model="visitState.attraction_id"
          :error="visitState.errors.attraction_id"
          :label="`${$t('Attraction')}:`"
          placeholder="Select attraction"
          :options="attractionsOptions"
        />
      </template>
    </template>
    <template v-if="isVisitInfoLoaded" slot="controls">
      <Button button-type="light" auto-width @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button type="submit" :processing="processing" auto-width @click.prevent="onSubmit" :disabled="!isObjectChanged">
        {{ $t("Save") }}
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, ref, reactive, watch, onMounted } from "@vue/composition-api";
import clone from "lodash/clone";

import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import ModalContent from "@/components/modal/ModalContent.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";

import store from "@/store";
import useIsObjectChanged from "@/composables/useIsObjectChanged";
import formatCurrency from "@/helpers/formatters/formatCurrency";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

const VISIT_STATUSES_OPTIONS = [
  { text: "app_holder_wait", value: "app_holder_wait" },
  { text: "app_holder_passed", value: "app_holder_passed" },
  { text: "app_holder_reject", value: "app_holder_reject" },
  { text: "app_holder_timeout", value: "app_holder_timeout", disabled: true },
  { text: "app_holder_unknown", value: "app_holder_unknown" },

  { text: "accepted", value: "accepted" },
  { text: "unknown_barcode", value: "unknown_barcode" },
  { text: "supplier_accepting", value: "supplier_accepting" },
  { text: "supplier_reject_wrong_id", value: "supplier_reject_wrong_id" },
  { text: "aggregator_invalid_visit_status", value: "aggregator_invalid_visit_status" },
  { text: "far_from_supplier", value: "far_from_supplier" },
  { text: "offline_geolocation", value: "offline_geolocation" },
  { text: "app_other_passed", value: "app_other_passed", disabled: true },
];

const VISIT_STATUSES_MATCHES_REASONS = {
  app_holder_wait: "manually_added_broken_scan",
  app_holder_passed: "manually_added_broken_scan",
  app_holder_reject: "manually_rejected_broken_scan",
  app_holder_unknown: "manually_rejected_unknown",

  accepted: "manually_added_broken_scan",
  unknown_barcode: "manually_updated_broken_scan",
  supplier_accepting: "manually_updated_broken_scan",
  supplier_reject_wrong_id: "manually_rejected_broken_scan",
  aggregator_invalid_visit_status: "manually_updated_broken_scan",
  far_from_supplier: "manually_updated_broken_scan",
  offline_geolocation: "manually_updated_broken_scan",
  app_other_passed: "manually_updated_broken_scan",
  app_holder_timeout: "",
};

export default {
  emits: ["close"],

  components: {
    Button,
    ErrorBanner,
    ModalContent,
    NewSelectInput,
    BaseInput,
    SpinnerBrand,
  },

  setup(props, { emit }) {
    const processing = ref(false);
    const processingFetchVisit = ref(false);
    const isVisitInfoLoaded = ref(false);
    const visitInitialState = ref({});
    const errorMessage = ref("");
    const attractionsOptions = ref([]);

    const payloadVisitElementsIndexes = reactive({});
    const visitState = reactive({
      id: "",
      registered: "",
      holder: "",
      card_level: "",
      company_name: "",
      sup_name: "",
      status: "",
      attraction_id: "",

      errors: {},
    });

    const { isObjectChanged } = useIsObjectChanged(visitInitialState, visitState);

    const isvisitStatusReasonRequired = computed(() => visitState.status === "app_holder_reject");

    const modalPayload = computed(() => store.state.modal.payload);

    async function onSubmit() {
      if (processing.value) {
        return;
      }

      await updateVisit();
      if (errorMessage.value) return;

      close();
    }

    function close() {
      emit("close");
    }

    async function updateVisit() {
      errorMessage.value = "";

      processing.value = true;

      // Make Reason field be filled by manager but not hardcoded here when backend will be ready
      const payload = {
        id: visitState.id,
        status: visitState.status,
        // reason: visitState.reason,
        reason: VISIT_STATUSES_MATCHES_REASONS[visitState.status],
        attraction_id: visitState.attraction_id,
      };

      try {
        await modalPayload.value.onAccept(payload);
      } catch (error) {
        const { errors, errorMessage: text } = prepareAxiosErrors(error);

        for (let key in errors) {
          visitState.errors[key] = errors[key];
        }
        errorMessage.value = text;
      }

      processing.value = false;
    }

    async function fetchVisit(visitId) {
      errorMessage.value = "";

      try {
        return await store.dispatch("helpdesk/view_visit", visitId);
      } catch (error) {
        const { errorMessage: text } = prepareAxiosErrors(error);

        errorMessage.value = text;
      }
    }

    async function fetchAttractionsList(supplierId) {
      errorMessage.value = "";

      try {
        return await store.dispatch("supplier/load_attraction_list", supplierId);
      } catch (error) {
        const { errorMessage: text } = prepareAxiosErrors(error);

        errorMessage.value = text;
      }
    }

    watch(
      () => visitState.status,
      (newVal, oldVal) => {
        if (newVal !== oldVal) visitState.reason = visitInitialState.value.reason;
      }
    );

    onMounted(async () => {
      processingFetchVisit.value = true;
      const { visitId } = modalPayload.value;

      const visit = await fetchVisit(visitId);
      const attractionsList = await fetchAttractionsList(visit.supplier_id);

      if (errorMessage.value) {
        processingFetchVisit.value = false;
        return;
      }

      attractionsOptions.value = attractionsList.map((attraction) => {
        return { text: attraction.name + " " + formatCurrency(attraction.price), value: attraction.id };
      });

      const { id, registered, holder, card_level, company_name, sup_name, status, attr_name, reason } = visit;

      const attraction_id = attractionsList.find((attraction) => attraction.name === attr_name)?.id;

      const payloadVisit = {
        id,
        registered,
        holder,
        card_level,
        company_name,
        sup_name,
        status,
        reason,
        attraction_id,
      };

      Object.assign(visitState, payloadVisit);

      visitInitialState.value = clone(visitState);
      isVisitInfoLoaded.value = true;

      processingFetchVisit.value = false;
    });

    return {
      errorMessage,
      processing,
      processingFetchVisit,
      isVisitInfoLoaded,
      isObjectChanged,
      payloadVisitElementsIndexes,
      VISIT_STATUSES_OPTIONS,
      attractionsOptions,
      isvisitStatusReasonRequired,

      visitState,

      onSubmit,
      close,
    };
  },
};
</script>
<style lang="scss" scoped>
.content-item {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #e7e7e7;
  color: #444545;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &_title {
    max-width: 210px;
    flex: 1;
    word-wrap: break-word;
    font-size: 22px;
    font-weight: 600;
  }

  &_body {
    word-wrap: break-word;
    max-width: 172px;
  }
}
</style>
