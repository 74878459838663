<template>
  <table>
    <caption>
      Message List
    </caption>
    <thead>
      <tr>
        <th>Path</th>
      </tr>
      <tr v-for="item in data">
        <td :class="selectedPath === item.path ? 'success' : ''">{{ item.path }}</td>
        <td>
          <button @click="() => fireCheck(item.path)">View</button>
        </td>
      </tr>
    </thead>
    <tfoot v-if="text">
      <tr>
        <td>
          <pre>{{ text }}</pre>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      text: "",
      selectedPath: false,
    };
  },

  methods: {
    ...mapActions({
      download: "affiliates_update/download",
    }),
    async fireCheck(path) {
      this.selectedPath = path;
      this.text = await this.download(path);
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>

<style type="text/css" scoped>
.success {
  background-color: #e7b6b6;
}
</style>
