import PHONES_CONFIG from "@/config/phones";
import { VALIDATION_ERRORS } from "../index";

export default (phone) => {
  if (!phone || phone.length === 0) {
    return false;
  }

  if (phone.length === 1 && phone === "+") {
    return false;
  }

  const { PHONES_COMMON_REGEXP } = PHONES_CONFIG;

  if (!PHONES_COMMON_REGEXP.test(phone)) {
    return VALIDATION_ERRORS.invalidCharacters;
  }

  return false;
};
