<template>
  <div>
    <h1>Invoice {{ props.invoice }} details</h1>
    <ErrorBanner :message="errorMessage" />

    <div class="content block-issued">
      <DatePickerInput
        label="Issued at:"
        class="datepicker"
        placeholder="YYYY-MM-DD hh:mm:ss"
        type="datetime"
        :clearable="false"
        :modelValue="invoiceObject.issued_at"
        @update:modelValue="updateInvoiceIssuedAt"
      />
    </div>

    <div class="content details">
      <div class="tables">
        <SimpleTable
          show-actions-button
          :actions="TABLE_ACTIONS"
          :headers="tableHeaders"
          :cells="tableCells"
          :items="invoiceSummary.items"
          @delete="onDelete"
          @edit="openEditDetailsModal"
        >
          <template #footer>
            <template v-if="isInvoiceCorrections">
              <tr>
                <td>Summary</td>
                <td v-for="(item, index) in invoiceStats.subtotal" :key="`invoice-subtotal-${index}`">
                  {{ item }}
                </td>
              </tr>
              <tr>
                <td colspan="2">Taxes</td>
                <td v-for="(item, index) in invoiceStats.taxes" :key="`invoice-taxes-${index}`">
                  {{ item }}
                </td>
              </tr>
              <tr
                v-for="(item, index) in invoiceSummary.corrections"
                class="tr-corrections tr-corrections__underlined"
                :key="`invoice-summary-corrections-${index}`"
              >
                <td colspan="2">Corrections</td>
                <td
                  v-for="(policy, policyIndex) in invoicePricingPoliciesList"
                  class="td-price"
                  :key="`invoice-summary-corrections-${index}-${policyIndex}`"
                >
                  {{ item.policies[policy] ? formatCurrency(item.policies[policy].price) : "" }}
                </td>
                <td class="td-icon">
                  <ActionsButton :actions="TABLE_ACTIONS" @action="onCorrectionAction($event, item.id)" />
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="2">Taxes</td>
              <td v-for="(item, index) in invoiceStats.taxes" :key="`invoice-taxes-${index}`">
                {{ item }}
              </td>
            </tr>

            <tr>
              <td>Total</td>
              <td v-for="(item, index) in invoiceStats.total" :key="`invoice-total-${index}`">
                {{ item }}
              </td>
            </tr>
          </template>
        </SimpleTable>
      </div>

      <div class="controls">
        <Button auto-width wide @click="openAddDetailsModal">Add details</Button>
        <Button auto-width wide :disabled="isInvoiceCorrections" @click="openAddCorrectionModal">Add correction</Button>
        <Button
          auto-width
          wide
          button-type="light"
          :disabled="!isInvoiceSummaryChanged"
          :processing="state.processing"
          @click="saveInvoice"
          >Save</Button
        >
        <Button auto-width wide button-type="light" :disabled="!isInvoiceSummaryChanged" @click="resetInvoiceChanges">
          Cancel
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, unref } from "@vue/composition-api";
import isEqual from "lodash/isEqual";
import get from "lodash/get";

import ActionsButton from "@/components/buttons/ActionsButton.vue";
import Button from "@/components/buttons/Button.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";

import store from "@/store";
import { MODAL_MODES, MODAL_TYPES, MODAL_ACTION_OPEN } from "@/store/modules/modal";
import {
  NEW_ORDER_INVOICE_ID,
  ORDERS_ACTION_ADD_ORDER_INVOICE_SUMMARY_ITEM,
  ORDERS_ACTION_SAVE_INVOICE,
  ORDERS_ACTION_UPDATE_ORDER_INVOICE_ISSUED_AT,
  ORDERS_ACTION_UPDATE_ORDER_INVOICE_SUMMARY,
} from "@/store/modules/orders";
import formatCurrency from "@/helpers/formatters/formatCurrency";

const ACTIONS = {
  edit: "edit",
  delete: "delete",
};

const TABLE_ACTIONS = [
  {
    title: "Редактировать",
    key: ACTIONS.edit,
    icon: {
      type: "edit",
      color: "#0038FF",
    },
  },
  {
    title: "Удалить",
    key: ACTIONS.delete,
    confirmationRequired: true,
    icon: {
      type: "delete",
      color: "#E3001B",
    },
  },
];

export const INVOICE_CORRECTIONS_KEY = "corrections";

export default {
  components: {
    ActionsButton,
    Button,
    DatePickerInput,
    ErrorBanner,
    SimpleTable,
  },

  emits: ["reset", "select"],

  props: {
    invoice: {
      type: [Number, String],
      required: true,
    },
    pricingPolicies: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      errors: {},
      processing: false,

      invoiceIssuedAt: null,
    });

    const errorMessage = computed(() => {
      const { errorMessage, errors } = state.errors;

      if (!errorMessage) {
        return null;
      }

      const errorMessageDetails = Object.values(errors).join(" ");
      const errorFullMessage = `${errorMessage} (${errorMessageDetails})`;

      return errorFullMessage;
    });

    const invoiceObject = computed(() => {
      const invoicesList = store.state.orders.order.invoices;

      if (props.invoice !== NEW_ORDER_INVOICE_ID) {
        return invoicesList.find(({ id }) => id == props.invoice);
      }

      return invoicesList.at(-1);
    });
    const invoicePricingPoliciesList = computed(() => Object.keys(invoiceObject.value.pricingPolicies));

    const invoiceSummary = computed(() => {
      const invoiceSummary = invoiceObject.value.summary;
      const invoiceSummaryList = invoiceSummary.map((item, id) => ({ ...item, id }));

      const invoiceSummaryCorrectionsList = [];
      const invoiceSummaryItemsList = [];

      invoiceSummaryList.forEach((item) => {
        if (item.level === INVOICE_CORRECTIONS_KEY) {
          invoiceSummaryCorrectionsList.push(item);
        } else {
          invoiceSummaryItemsList.push(item);
        }
      });

      return {
        all: [...invoiceSummaryItemsList, ...invoiceSummaryCorrectionsList],
        corrections: invoiceSummaryCorrectionsList,
        items: invoiceSummaryItemsList,
      };
    });

    const invoiceStats = computed(() => {
      let totalAmount = 0;
      let totalPolicies = {};
      let subtotalPolicies = {};

      invoiceSummary.value.all.forEach(({ level, amount, policies }) => {
        totalAmount += amount ? Number(amount) : 0;

        Object.keys(policies).forEach((policy) => {
          const itemPrice = Number(policies[policy].price ?? 0);
          const itemAmount = amount ?? 1;

          totalPolicies[policy] = (totalPolicies[policy] ?? 0) + itemPrice * itemAmount;

          if (level !== INVOICE_CORRECTIONS_KEY) {
            subtotalPolicies[policy] = (subtotalPolicies[policy] ?? 0) + itemPrice * itemAmount;
          }
        });
      });

      const policiesList = unref(invoicePricingPoliciesList);
      const totalPoliciesList = policiesList.map((policy) => formatCurrency(totalPolicies[policy]));
      const subtotalPoliciesList = policiesList.map((policy) => formatCurrency(subtotalPolicies[policy]));
      const sellersTaxes = policiesList.map((policy) =>
        formatCurrency(invoiceObject.value.pricingPolicies[policy].tax)
      );

      return {
        subtotal: [totalAmount, ...subtotalPoliciesList],
        total: [totalAmount, ...totalPoliciesList],
        taxes: sellersTaxes,
      };
    });

    const isInvoiceCorrections = computed(() => {
      return invoiceSummary.value.corrections.length > 0;
    });

    const isInvoiceSummaryChanged = computed(() => {
      const initialInvoiceObject = store.state.orders.initialOrder?.invoices.find(({ id }) => id == props.invoice);
      return !isEqual(invoiceObject.value, initialInvoiceObject);
    });

    const tableCells = computed(() => [
      (item) => (item.level === INVOICE_CORRECTIONS_KEY ? item.title : item.level),
      "amount",
      ...invoicePricingPoliciesList.value.map(
        (policy) => (item) => formatCurrency(get(item, `policies.${policy}.price`))
      ),
    ]);
    const tableHeaders = computed(() => [
      "Subscription level",
      "Amount",
      ...invoicePricingPoliciesList.value.map((policy) => props.pricingPolicies[policy] ?? policy),
    ]);

    function openAddDetailsModal() {
      const type = MODAL_TYPES.orders.invoiceDetails;
      const mode = MODAL_MODES[type].add;
      const payload = {
        invoicePricingPoliciesList: unref(invoicePricingPoliciesList),
        pricingPolicies: props.pricingPolicies,
        onAccept: addDetails,
      };

      const modalConfig = {
        type,
        mode,
        payload,
      };

      store.dispatch(MODAL_ACTION_OPEN, modalConfig);
    }

    function openAddCorrectionModal() {
      const policiesList = unref(invoicePricingPoliciesList);
      const type = MODAL_TYPES.orders.invoiceDetails;
      const mode = MODAL_MODES[type].edit;
      const summaryItem = {
        level: INVOICE_CORRECTIONS_KEY,
        policies: {},
      };

      policiesList.forEach((policy) => {
        summaryItem.policies[policy] = {
          price: null,
        };
      });

      const payload = {
        invoicePricingPoliciesList: policiesList,
        pricingPolicies: props.pricingPolicies,
        summaryItem,
        onAccept: addDetails,
      };

      const modalConfig = {
        type,
        mode,
        payload,
      };

      store.dispatch(MODAL_ACTION_OPEN, modalConfig);
    }

    function addDetails(newSummaryDetail) {
      store.dispatch(ORDERS_ACTION_ADD_ORDER_INVOICE_SUMMARY_ITEM, {
        invoiceId: props.invoice,
        newSummaryDetail,
      });
    }

    async function saveInvoice() {
      state.processing = true;
      state.errors = {};

      try {
        const { id } = await store.dispatch(ORDERS_ACTION_SAVE_INVOICE, { invoiceId: props.invoice });
        emit("select", id);
      } catch (errors) {
        state.errors = errors;
      }

      state.processing = false;
    }

    function onDelete(summaryItemId) {
      const newSummary = invoiceSummary.value.all
        .filter(({ id }) => id != summaryItemId)
        .map(({ id, ...item }) => item);
      store.dispatch(ORDERS_ACTION_UPDATE_ORDER_INVOICE_SUMMARY, { invoiceId: props.invoice, newSummary });
    }

    function openEditDetailsModal(summaryItemId) {
      const type = MODAL_TYPES.orders.invoiceDetails;
      const mode = MODAL_MODES[type].edit;
      const summaryItem = invoiceSummary.value.all.find(({ id }) => id == summaryItemId);
      const payload = {
        invoicePricingPoliciesList: unref(invoicePricingPoliciesList),
        pricingPolicies: props.pricingPolicies,
        summaryItem,
        onAccept: updateSummaryItem,
      };

      const modalConfig = {
        type,
        mode,
        payload,
      };

      store.dispatch(MODAL_ACTION_OPEN, modalConfig);
    }

    function updateSummaryItem(updatedSummaryItem) {
      const newSummary = invoiceSummary.value.all.map(({ id, ...item }) => {
        if (id == updatedSummaryItem.id) {
          return updatedSummaryItem;
        }

        return item;
      });

      store.dispatch(ORDERS_ACTION_UPDATE_ORDER_INVOICE_SUMMARY, { invoiceId: props.invoice, newSummary });
    }

    function onCorrectionAction(eventName, itemId) {
      if (eventName === ACTIONS.edit) {
        openEditDetailsModal(itemId);
      } else {
        onDelete(itemId);
      }
    }

    function resetInvoiceChanges() {
      emit("reset");
    }

    function updateInvoiceIssuedAt(date) {
      store.dispatch(ORDERS_ACTION_UPDATE_ORDER_INVOICE_ISSUED_AT, { date, invoiceId: props.invoice });
    }

    return {
      TABLE_ACTIONS,

      props,
      state,

      errorMessage,
      invoiceObject,
      invoicePricingPoliciesList,
      invoiceSummary,
      invoiceStats,
      isInvoiceCorrections,
      isInvoiceSummaryChanged,
      tableCells,
      tableHeaders,

      formatCurrency,
      resetInvoiceChanges,
      onCorrectionAction,
      onDelete,
      openAddDetailsModal,
      openAddCorrectionModal,
      openEditDetailsModal,
      saveInvoice,
      updateInvoiceIssuedAt,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  &.block-issued {
    margin: 24px 0 36px;
    grid-template-columns: 3fr 9fr;
  }
}

tr.tr-corrections {
  td {
    padding: 40px 10px 10px;
  }

  & + & {
    td {
      padding: 10px;
    }
  }

  .td-price {
    font-weight: 400;
  }

  .td-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__underlined {
    border-bottom: 1px solid #9c9c9c;
  }
}
</style>
