<template>
  <fieldset>
    <legend>
      Create
      <button @click="toggle(false)">Back</button>
    </legend>
    <form @submit.prevent="fireCreatingCard">
      <fieldset>
        <legend>Card {{ card.id }}: {{ card.level }}</legend>
        <div>
          <label for="up_card_holder">Holder Surname/Firstname:</label>
          <input id="up_card_holder" type="text" v-model="card.holder" />
        </div>
        <div>
          <label for="up_card_level">Level:</label>
          <input id="up_card_level" type="text" v-model="card.level" />
        </div>
        <div>
          <label for="up_card_locked">Card Locked:</label>
          <select id="up_card_locked" v-model="card.locked">
            <option :value="1">Locked</option>
            <option :value="0">Unlocked</option>
          </select>
        </div>
        <div>
          <label for="up_activated">On?:</label>
          <input id="up_activated" type="checkbox" v-model="card.activated" :true-value="1" :false-value="0" />
        </div>
        <div v-if="!card.activated">
          <label for="up_deactivation">Deactivation</label>
          <input id="up_deactivation" type="text" v-model="card.deactivation_cause" />
        </div>
        <div>
          <label for="up_card_middle_name">Holder MiddleName:</label>
          <input id="up_card_middle_name" type="text" v-model="card.middle_name" />
        </div>
        <div>
          <label for="up_card_phone">Phone ID (Format numeric, like 375291294950, without leading +):</label>
          +<input id="up_card_phone" type="number" v-model="card.phone_number" />
        </div>
        <div>
          <label for="up_card_email">Email:</label>
          <input id="up_card_email" type="text" v-model="card.email" />
        </div>
        <div>
          <label for="up_card_location_status">{{ $t("Is GeoLocation Required") }}</label>
          <select id="up_card_location_status" v-model="card.location_status">
            <option v-for="status in config.location_statuses" :value="status" :key="status">{{ status }}</option>
          </select>
        </div>
        <div>
          <label for="up_card_anti_fraud_mode">Anti-fraud mode</label>
          <select id="up_card_anti_fraud_mode" v-model="card.anti_fraud_mode">
            <option v-for="value in ANTI_FRAUD_MODE" :key="`anti-fraud-mode-${value}`" :value="value">
              {{ value }}
            </option>
          </select>
        </div>
        <div>
          <label for="up_card_anti_fraud_result">Anti-fraud result</label>
          <select id="up_card_anti_fraud_result" v-model="card.anti_fraud_result">
            <option v-for="value in ANTI_FRAUD_RESULT" :key="`anti-fraud-result-${value}`" :value="value">
              {{ value }}
            </option>
          </select>
        </div>
      </fieldset>
      <div>
        <button>Confirm</button>
      </div>
    </form>
  </fieldset>
</template>

<script>
import { mapActions } from "vuex";
import config, { ANTI_FRAUD_MODE, ANTI_FRAUD_RESULT } from "../../config";

export default {
  name: "CardsCardCreate",
  data() {
    return {
      ANTI_FRAUD_MODE,
      ANTI_FRAUD_RESULT,
      config,

      card: {
        holder: "",
        level: "platinum",
        activated: 1,
        deactivated_cause: "",
        middle_name: "",
        company_id: 0,
        id: 0,
        location_status: "off",
        locked: 0,
        anti_fraud_mode: "none",
        anti_fraud_result: "none",
      },
    };
  },
  props: {
    company_id: Number,
  },
  methods: {
    getActivation(card) {
      return card.activated ? "status-on" : "status-off";
    },
    ...mapActions({
      doCreateCard: "companycard/update_card",
    }),
    async fireCreatingCard() {
      this.card.company_id = this.company_id;
      await this.doCreateCard({ card: this.card });
    },
  },
};
</script>

<style scoped></style>
