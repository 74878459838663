<template>
  <small-layout
    ><div>
      <div ref="audit_view">
        <AuditView v-if="audit" v-bind:metadata="audit.metadata" v-bind:modified="audit.modified"></AuditView>
      </div>
      <fieldset>
        <legend>filter by</legend>
        <form @submit.prevent="fireLoadingList">
          <div>
            <label for="up_user">User:</label>
            <select v-model="params.user_id" id="up_user">
              <option value="">Choose...</option>
              <option v-for="user in sorted_user_source" :value="user.id">
                {{ user.supplier_name + " [" + user.name + "]" }}
              </option>
            </select>
          </div>
          <div>
            <label for="up_type">Entity:</label>
            <select v-model="params.auditable_type" id="up_type">
              <option value="">Choose...</option>
              <option v-for="item in auditable_types" :value="item">{{ item }}</option>
            </select>
          </div>

          <div>
            <label for="up_id">Entity id:</label>
            <input v-model="params.auditable_id" id="up_id" type="number" min="1" />
          </div>
          <div>
            <label for="params_date">Date From:</label>
            <input id="params_date" type="date" v-model="params.date" />
            <label for="params_date">Date Till:</label>
            <input id="params_date_finish" type="date" v-model="params.date_finish" />
          </div>
          <div>
            <button>Confirm</button>
          </div>
        </form>
        <div>
          <table>
            <caption>
              log
            </caption>
            <TableHeader
              :additional-columns="additionalColumns"
              :columns="columns"
              :orderKey="orderKey"
              :show-remove-button="false"
              @update:orderKey="sortBy"
            />
            <tbody>
              <tr v-for="entry in filteredSource">
                <td v-for="column in columns">
                  <span v-if="!column.link">{{ _read(entry, column) }}</span>
                  <span v-if="column.link"
                    ><router-link :to="getRouterLink(entry, column)">{{ entry[column.key] }}</router-link></span
                  >
                </td>
                <td>
                  <button @click="fireGetAudit(entry.id)">Details</button>
                  <button @click="rollbackChanges(entry.id)">Rollback</button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="100%">
                  <paginate
                    :page-count="gridMeta.last_page"
                    :page-range="3"
                    :margin-pages="2"
                    :value="gridMeta.current_page"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :page-link-class="'page-link'"
                  >
                  </paginate>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </fieldset></div
  ></small-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SortMixin from "../mixins/SortMixin";

import Paginate from "vuejs-paginate";
import AuditView from "./AuditView";
import TableHeader from "@/components/tables/TableHeader";

export default {
  mixins: [SortMixin],
  props: [],
  components: {
    AuditView,
    Paginate,
    TableHeader,
  },
  data() {
    return {
      audit: undefined,
      params: {},
      auditable_types: [
        "price_model",
        "template",
        "App\\Attraction",
        "App\\Card",
        "App\\Order",
        "App\\ClaimAct",
        "App\\ClaimInvoice",
        "App\\Company",
        "App\\ElectronicMail",
        "App\\Supplier",
        "App\\Modules\\Holder\\Models\\SupplierToken",
        "App\\SupplierAct",
        "App\\User",
        "App\\Visit",
      ],
      additionalColumns: ["details"],
    };
  },
  computed: {
    ...mapGetters({
      country: "auth/country",
      gridMeta: "audit/list_meta",
      source: "audit/list",
      user_source: "user/user_list",
    }),
    sorted_user_source() {
      const users = [...this.user_source];
      users.sort((a, b) =>
        String(a.supplier_name).localeCompare(b.supplier_name, ["ru", "en"], { sensitivity: "base" })
      );
      return users;
    },
  },

  directives: {
    autofocus: {
      // определение директивы
      inserted(el) {
        el.focus();
      },
    },
  },
  methods: {
    createColumns() {
      return [
        { key: "id", name: "id", order: 1 },
        { key: "auditable_id", name: "entity_id", link: true, order: 1 },
        { key: "event", name: "event", order: 1 },
        { key: "url", name: "url", order: 1 },
        { key: "ip_address", name: "ip_address", order: 1 },
        { key: "user_agent", name: "user_agent", order: 1 },
        { key: "tags", name: "tags", order: 1 },
        { key: "created_at", name: "created_at", order: 1 },
        { key: "updated_at", name: "updated_at", order: 1 },
        { key: "user.id", name: "user_id", check: "user", order: 1 },
        { key: "user.email", name: "email", check: "user", order: 1 },
        { key: "user.name", name: "name", check: "user", order: 1 },
      ];
    },
    getRouterLink(entry) {
      let to = { name: entry.auditable_type, params: { id: entry.auditable_id } };
      switch (to.name) {
        case "App\\Attraction":
          to.name = "attraction_cover";
          break;
        case "App\\Card":
          to.name = "card_view";
          break;
        case "App\\Company":
          to.name = "company_view";
          to.params.company = to.params.id;
          to.params.country = this.country;
          break;
        case "App\\ElectronicMail":
          to.name = "mail_list";
          break;
        case "App\\Supplier":
          to.name = "supplier_view";
          to.params.supplier = to.params.id;
          break;
        case "App\\Modules\\Holder\\Models\\SupplierToken":
          to.name = "supplier_view";
          to.params.supplier = to.params.supplier_id;
          break;
        case "App\\SupplierAct":
          to.name = "supplier_act_list";
          break;
        case "App\\User":
          to.name = "user_list";
          break;
        case "App\\Visit":
          to.name = "visit_journal";
          break;
      }
      return to;
    },
    _read(entry, column) {
      if (!column.check) {
        return eval("entry." + column.key);
      }
      if (entry[column.check]) {
        return eval("entry." + column.key);
      }
      return null;
    },
    ...mapActions({
      loadList: "audit/load_list",
      rollbackAudit: "audit/rollback_audit",
      deleteAudit: "audit/delete_audit",
      fetchList: "audit/get_list",
      fetchUsers: "user/user_list",
      loadAudit: "audit/load_audit",
    }),
    async rollbackChanges(id) {
      if (confirm("Are you sure?")) {
        await this.rollbackAudit(id);
      }
    },
    async fireGetAudit(id) {
      this.audit = await this.loadAudit(id);
      if (this.$refs.audit_view) {
        this.$refs.audit_view.scrollIntoView();
      }
    },
    async fireDeleteAudit(audit) {
      if (confirm("Are you sure?")) {
        await this.deleteAudit(audit);
      }
    },
    async fireLoadingList() {
      await this.loadList(Object.assign({ page: this.gridMeta.current_page }, this.params));
    },
    async changePage(pageNum) {
      await this.fetchList(Object.assign({ page: pageNum }, this.params));
    },
  },
  async mounted() {
    await this.fetchUsers();
    await this.fetchList(Object.assign({ page: this.gridMeta.current_page }, this.params));
  },
};
</script>

<style scoped></style>
