<template>
  <ModalContent @close="close" tag="form" @submit.prevent="onSubmit">
    <template slot="header">
      <h3 v-if="isStatePortalUserInList">Редактирование сотрудника</h3>
      <h3 v-else>Добавление сотрудника</h3>
    </template>
    <template slot="content">
      <ErrorBanner :message="state.errors.errorMessage" />
      <label class="row">
        <span>{{ $t("Phone") + ":" }}</span>
        <BaseInput
          v-model="state.portalUser.phone"
          ref="refPhone"
          class="base_input"
          validator="phone"
          :error="state.errors.phone"
          autofocus
          :processing="isPortalUserByPhoneProcessing"
          :disabled="isPortalUserByPhoneProcessing || isPortalUserByEmailProcessing"
          :min-length="minimalCountryPhoneLength"
        />
      </label>
      <label class="row">
        <span>{{ $t("Name") + ":" }}</span>
        <BaseInput
          v-model="state.portalUser.name"
          class="base_input"
          :disabled="isPortalUserByPhoneProcessing || isPortalUserByEmailProcessing"
          :error="state.errors.name"
        />
      </label>
      <label class="row">
        <span>{{ $t("Role") + ":" }}</span>
        <BaseInput
          v-model="state.portalUser.position"
          class="base_input"
          maxlength="10"
          :disabled="isPortalUserByPhoneProcessing || isPortalUserByEmailProcessing"
          :error="state.errors.position"
        />
      </label>
      <label class="row">
        <span>{{ $t("E-mail") + ":" }}</span>
        <BaseInput
          v-model="state.portalUser.email"
          ref="refEmail"
          class="base_input"
          validator="email"
          :disabled="isPortalUserByPhoneProcessing || isPortalUserByEmailProcessing"
          :error="state.errors.email"
          :processing="isPortalUserByEmailProcessing"
        />
      </label>
      <label class="row">
        <span>{{ $t("Locale") + ":" }}</span>
        <NewSelectInput
          v-model="state.portalUser.locale"
          :placeholder="$t('language for SMS notifications')"
          :error="state.errors.locale"
          :options="availableLocalesOptions"
          :max-options-visible="3"
          class="base_select"
        />
      </label>
      <label class="row">
        <span>{{ $t("Timezone") + ":" }}</span>
        <SearchInput
          v-model="state.portalUser.timezone"
          :item-text-field="({ id }) => id"
          :offline-items="timezonesOptions"
          input-mode
          staticDropdown
          class="base_search-input"
          :error="state.errors.timezone"
        />
      </label>
    </template>
    <template slot="controls">
      <Button button-type="light" @click.prevent="close" auto-width>Отменить</Button>
      <Button auto-width type="submit" :disabled="!isSubmitButtonEnabled" :processing="state.processing">
        <template v-if="isStatePortalUserInList">Сохранить</template>
        <template v-else>Добавить</template>
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, reactive, ref, watch, onBeforeMount } from "@vue/composition-api";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import BaseInput from "@/components/inputs/BaseInput.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import ModalContent from "./ModalContent.vue";

import router from "@/router";
import store from "@/store";
import request from "@/helpers/request";
import { MODAL_MODES, MODAL_TYPES } from "@/store/modules/modal";
import { VALIDATION_ERRORS } from "@/helpers/validators";
import URLS from "@/config/urls";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import clearPhone from "@/helpers/converters/clearPhone";
import PHONES_CONFIG from "@/config/phones";
import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";

const TOOLTIPS = {
  locale: "The language designation codes are used in accordance with the international standard {0}ISO 639-1{1}.",
};

const SEARCH_PORTAL_USER_TIMEOUT = 2000;

const SEARCH_PORTAL_USER_STATUS = {
  initial: Symbol("initial"),
  loaded: Symbol("loaded"),
  processing: Symbol("processing"),
};

const SEARCH_PORTAL_USER_MODES = {
  phone: "phone",
  email: "email",
};

// TODO: move this message into separate instances as part of localization process
const ERROR_MESSAGES = {
  phoneAlreadyInUse: "Phone already used by another Portal User",
  emailAlreadyInUse: "Email already used by another Portal User",
};

const LOADED_PORTAL_USER_FIELDS = ["id", "email", "name", "phone", "position", "locale", "timezone"];
const INITIAL_PORTAL_USER = {
  email: "",
  id: null,
  name: "",
  phone: null,
  position: "",
  locale: "",
  timezone: "",
};

const minimalCountryPhoneLength = PHONES_CONFIG.MINIMAL_PHONE_LENGTH;

export default {
  emits: ["close"],

  components: {
    SearchInput,
    NewSelectInput,
    BaseInput,
    Button,
    ErrorBanner,
    ModalContent,
  },

  setup(props, { emit }) {
    const refEmail = ref(null);
    const refPhone = ref(null);

    const state = reactive({
      processing: false,
      errors: {},

      searchPortalUserByPhoneStatus: SEARCH_PORTAL_USER_STATUS.initial,
      searchPortalUserByEmailStatus: SEARCH_PORTAL_USER_STATUS.initial,

      portalUser: {
        ...INITIAL_PORTAL_USER,
      },

      loadedPortalUser: {
        ...INITIAL_PORTAL_USER,
      },
    });

    const country = computed(() => store.state.auth.country);

    const availableLocales = computed(() => store.state.config.portal.availableLocales);
    const availableLocalesOptions = computed(() =>
      availableLocales.value.map((v) => ({ text: v.toUpperCase(), value: v }))
    );
    const timezonesOptions = computed(() => {
      let options = [];

      if (window.Intl) {
        const timezonesList = Intl.supportedValuesOf("timeZone");
        options = timezonesList.map((v) => ({ id: v }));
      }

      return options;
    });

    const isError = computed(() => Object.keys(state.errors).length > 0);
    const isStatePortalUserInList = computed(
      () => !!state.portalUser.id && searchStatePortalUserInPortalUsersList(state.portalUser)
    );

    const isEditMode = computed(() => !!state.portalUser.id);

    const isPortalUserByPhoneProcessing = computed(
      () => state.searchPortalUserByPhoneStatus === SEARCH_PORTAL_USER_STATUS.processing
    );
    const isPortalUserByEmailProcessing = computed(
      () => state.searchPortalUserByEmailStatus === SEARCH_PORTAL_USER_STATUS.processing
    );

    const isLoadedUserDataChanged = computed(() => {
      const isDataChanged = !isEqual(state.portalUser, state.loadedPortalUser);

      return isDataChanged;
    });

    const isSubmitButtonEnabled = computed(() => {
      const validatingInputs = [refEmail, refPhone];
      const isInputsValid = validatingInputs.every((ref) => !ref?.value?.isError);

      return isLoadedUserDataChanged.value && isInputsValid;
    });

    async function onSubmit() {
      const isProcessing =
        state.processing || isPortalUserByPhoneProcessing.value || isPortalUserByEmailProcessing.value;

      if (isProcessing || !isSubmitButtonEnabled.value) {
        return;
      }

      state.errors = validateForm();

      if (isError.value) {
        return;
      }

      state.processing = true;
      await submitData();
      state.processing = false;
    }

    function onError(xhrError) {
      const { errorMessage, errors } = prepareAxiosErrors(xhrError);

      state.errors = {
        errorMessage,
        ...errors,
      };
    }

    async function submitData() {
      const { create: urlCreate, update: urlUpdate } = URLS.companies.portalUsers;

      const { companyId } = router.currentRoute.params;
      const { payload } = store.state.modal;
      const method = isEditMode.value ? "PUT" : "POST";
      const url = isEditMode.value ? urlUpdate(companyId, state.portalUser.id) : urlCreate(companyId);

      const { phone, ...portalUser } = state.portalUser;
      const data = {
        ...portalUser,
        phone: clearPhone(phone),
      };

      try {
        const result = await request({
          method,
          data,
          url,
        });

        if (payload && payload.onAccept) {
          await payload.onAccept(result);
        }

        close();
      } catch (xhrError) {
        onError(xhrError);
      }
    }

    function validateForm() {
      const VALIDATION_FIELD = ["email", "name", "phone", "position"];
      const errors = {};

      VALIDATION_FIELD.forEach((field) => {
        if (state.portalUser[field].length === 0) {
          errors[field] = VALIDATION_ERRORS.requiredField;
        } else if (field === "phone" && clearPhone(state.portalUser.phone).length < minimalCountryPhoneLength) {
          errors[field] = VALIDATION_ERRORS.minLength;
        }
      });

      const validatingInputs = [refEmail, refPhone];
      const isInputError = validatingInputs.some((input) => input.value.isError);

      if (isInputError) {
        return {
          validationError: true,
        };
      }

      return errors;
    }

    async function requestSearchPortalUser(queryParams = {}) {
      const url = URLS.companies.portalUsers.searchOne(queryParams);

      let result = null;

      try {
        result = await request(url);
      } catch (xhrError) {
        onError(xhrError);
        state.searchPortalUserByPhoneStatus = SEARCH_PORTAL_USER_STATUS.initial;
        state.searchPortalUserByEmailStatus = SEARCH_PORTAL_USER_STATUS.initial;
      }

      return result;
    }

    async function searchPortalUserBySearchMode(searchMode, oldValue) {
      const stateSearchModeStatus =
        searchMode === SEARCH_PORTAL_USER_MODES.phone
          ? "searchPortalUserByPhoneStatus"
          : "searchPortalUserByEmailStatus";
      state.errors = {};
      state[stateSearchModeStatus] = SEARCH_PORTAL_USER_STATUS.processing;

      const paramValue =
        searchMode === SEARCH_PORTAL_USER_MODES.phone ? clearPhone(state.portalUser.phone) : state.portalUser.email;
      const stateErrorMessagesParamName =
        searchMode === SEARCH_PORTAL_USER_MODES.phone ? "phoneAlreadyInUse" : "emailAlreadyInUse";

      const queryParams = {
        [searchMode]: paramValue,
      };

      const loadedUser = await requestSearchPortalUser(queryParams);

      if (loadedUser?.id) {
        const { email, id, name, locale, position, phone, timezone } = loadedUser;

        if (oldValue === loadedUser[searchMode]) {
          return;
        }

        if ((state.portalUser.id && id !== state.portalUser.id) || searchStatePortalUserInPortalUsersList(loadedUser)) {
          state.errors = {
            [searchMode]: ERROR_MESSAGES[stateErrorMessagesParamName],
          };

          state[stateSearchModeStatus] = SEARCH_PORTAL_USER_STATUS.initial;
        } else {
          setPortalUser({ email, id, name, position, locale, phone: phone.toString(), timezone });
          state[stateSearchModeStatus] = SEARCH_PORTAL_USER_STATUS.loaded;

          if (!isStatePortalUserInList.value && !oldValue && state.portalUser.id) {
            state.errors = {};
          }
        }
      } else {
        state[stateSearchModeStatus] = SEARCH_PORTAL_USER_STATUS.initial;
      }
    }

    function onPhoneChangeOriginal(newPhoneVal, oldValue) {
      if (!isLoadedUserDataChanged.value) {
        return;
      }

      const rawPhone = clearPhone(newPhoneVal);
      const isPhoneError = refPhone.value.isError;

      if (rawPhone.length && !isPhoneError) {
        state.searchPortalUserByPhoneStatus = SEARCH_PORTAL_USER_STATUS.initial;

        searchPortalUserBySearchMode("phone", oldValue);
      }
    }

    async function onEmailChangeOriginal(newEmailVal, oldValue) {
      if (!isLoadedUserDataChanged.value) {
        return;
      }

      if (newEmailVal.length > 0 && !refEmail.value.isError) {
        state.searchPortalUserByEmailStatus = SEARCH_PORTAL_USER_STATUS.initial;

        return searchPortalUserBySearchMode("email", oldValue);
      }
    }

    function setLoadedPortalUser(newUser) {
      LOADED_PORTAL_USER_FIELDS.forEach((key) => {
        state.loadedPortalUser[key] = newUser[key];
      });
    }

    function setPortalUser(newUser) {
      LOADED_PORTAL_USER_FIELDS.forEach((key) => {
        state.portalUser[key] = newUser[key];
      });
    }

    function close() {
      emit("close");
    }

    async function getConfig() {
      const configsList = ["portal", { country: [country.value] }];

      await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, configsList);
    }

    function searchStatePortalUserInPortalUsersList(searchedUser) {
      return store.state.modal.payload?.portalUsersList.find((user) => user.id === searchedUser.id);
    }

    onBeforeMount(() => {
      const modalStore = store.state.modal;
      const mode = modalStore.mode;
      const type = MODAL_TYPES.portalUserEdit;
      const isEditMode = mode === MODAL_MODES[type].edit;
      const portalUser = { ...modalStore.payload?.portalUser };

      if (isEditMode && portalUser) {
        portalUser.phone = portalUser.phone?.toString();
        setPortalUser(portalUser);
        setLoadedPortalUser(portalUser);
        state.searchPortalUserByPhoneStatus = SEARCH_PORTAL_USER_STATUS.loaded;
        state.searchPortalUserByemailStatus = SEARCH_PORTAL_USER_STATUS.loaded;
      }
      getConfig();
    });

    const onPhoneChange = debounce(onPhoneChangeOriginal, SEARCH_PORTAL_USER_TIMEOUT);
    const onEmailChange = debounce(onEmailChangeOriginal, SEARCH_PORTAL_USER_TIMEOUT);

    watch(() => state.portalUser.phone, onPhoneChange, { flush: "post" });
    watch(() => state.portalUser.email, onEmailChange, { flush: "post" });

    return {
      refEmail,
      refPhone,

      state,

      country,
      minimalCountryPhoneLength,
      isEditMode,
      isStatePortalUserInList,
      isPortalUserByEmailProcessing,
      isPortalUserByPhoneProcessing,
      isSubmitButtonEnabled,

      close,
      onSubmit,

      timezonesOptions,
      availableLocalesOptions,
      TOOLTIPS,
    };
  },
};
</script>

<style lang="scss" scoped>
.error_banner {
  margin: 0 0 24px;
}

label.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;

  span {
    display: inline-block;
    max-width: 120px;
    word-break: break-word;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.4em;
  }

  .base_input,
  .base_select,
  .base_search-input {
    width: 280px;
  }
}
</style>
