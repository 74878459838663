<template>
  <small-layout>
    <div>
      <h1>Helpdesk</h1>
      <router-link :to="{ name: 'holder_city_list' }">Phone City List</router-link>
      <router-link :to="{ name: 'holder_ticket_list' }">Ticket List</router-link>
      <router-link v-if="isAdmin" :to="{ name: 'helpdesk_panel_control' }">Control Panel</router-link>
      <router-link v-if="isAdmin" :to="{ name: 'helpdesk_affiliates_updates' }">Affiliates Updates</router-link>
      <form id="search" @submit.prevent="">
        <fieldset>
          <div>
            <label for="params_date">Date:</label>
            <input id="params_date" type="date" v-model="params.date" />
          </div>
          <button type="button" @click="fireFetchStats">Search</button>
          <router-link :to="{ name: 'helpdesk_mobile_holder_list', query: { card_id: 0, date: params.date } }"
            >Holder</router-link
          >
        </fieldset>
      </form>
      <table>
        <colgroup>
          <col span="1" width="25%" />
          <col span="1" width="25%" />
          <col span="1" width="25%" />
          <col span="1" width="25%" />
        </colgroup>
        <thead>
          <tr>
            <th v-for="item in columns" @click.prevent="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              <span class="arrow" :class="order > 0 ? 'asc' : 'dsc'"></span>
              {{ item.name | capitalize }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <template v-for="column in columns">
              <td v-if="'id' === column.key">
                <router-link :to="{ name: 'helpdesk_mobile_view', params: { visit: entry.id } }">{{
                  entry.id
                }}</router-link>
                {{ entry.status }}
              </td>
              <td v-else-if="'holder' === column.key">
                <router-link
                  target="_blank"
                  :to="{ name: 'helpdesk_mobile_holder_list', query: { card_id: entry.card_id, date: params.date } }"
                  >{{ entry.holder }}</router-link
                >
                <router-link
                  v-if="entry.changed_photo"
                  :to="{ name: 'helpdesk_mobile_holder', params: { card_id: entry.card_id } }"
                >
                  <img ref="visitMarkerRef" src="../../../images/icon_changed_photo.svg" height="19px" width="24px" style="float: right"/>
                </router-link>
              </td>
              <td v-else-if="'registered' === column.key">
                {{ timeOnly(entry[column.key]) }}
              </td>
              <td v-else>
                {{ entry[column.key] }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </small-layout>
</template>

<style scoped lang="scss">
td {
  word-break: break-word;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
const mixSupportCompanies = String(process.env.VUE_APP_SUPPORT_COMPANIES).split(",").map(parseFloat);
export default {
  data: function () {
    const defaultColumns = [
      { key: "id", name: "Visit ID", order: 1 },
      { key: "holder", name: "Holder", order: 1 },
      { key: "sup_name", name: "Gym", order: 1 },
      { key: "registered", name: "Time", order: 1 },
    ].filter((column) => {
      if (this.isSupportManager) {
        return ["card_price", "company_type", "company_size"].indexOf(column.key) === -1;
      }
      if (this.isSaleManager) {
        return ["price"].indexOf(column.key) === -1;
      }
      return true;
    });
    const d = new Date();
    return {
      mixSupportCompanies,
      params: {
        date: d.toJSON().substr(0, 10),
        holder: "",
      },
      visitUpdateForm: false,
      visitAppUpdateForm: false,
      visitUpdateHolder: false,
      visit: null,
      cards: [],
      attractions: [],
      orderKey: 0,
      order: 1,
      filterKey: "",
      columns: defaultColumns,
    };
  },
  computed: {
    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.order;
      }
      let data = this.source;
      if (this.filterKey) {
        const filterKey = this.filterKey && this.filterKey.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (orderKey) {
        this.order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    },
    ...mapGetters({
      gridMeta: "helpdesk/list_meta",
      gridSummary: "helpdesk/list_summary",
      source: "helpdesk/visit_list",
      isAdmin: "auth/isAdmin",
      isSaleManager: "auth/isSaleManager",
      isSupportManager: "auth/isSupportManager",
    }),
    tags_from_params() {
      let tags = [];
      if (this.params.date) {
        tags.push({ name: "date", value: this.params.date });
      }
      return tags;
    },
  },
  methods: {
    timeOnly(datetime) {
      const splits = datetime.split(" ");
      return splits.length ? splits[splits.length - 1] : datetime;
    },
    ...mapActions({
      fetchStats: "helpdesk/stats_list",
    }),
    async fireFetchStats(e) {
      this.$router.push({ name: "helpdesk_mobile", query: { date: this.params.date } });
      await this.fetchStats({ tags: this.tags_from_params });
    },
    sortBy: function (key) {
      this.orderKey = key;
      this.order = this.order * -1;
    },
    async extractParamsAndRun(to) {
      if (to.query.date) {
        this.params.date = to.query.date;
      }
      await this.fetchStats({ tags: this.tags_from_params });
    },
  },
  watch: {
    $route(to) {
      this.extractParamsAndRun(to);
    },
  },
  async mounted() {
    await this.extractParamsAndRun(this.$route);
  },
};
</script>
