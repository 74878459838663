import { DOMAIN_COMMON_REGEXP } from "@/config/validators";
import { VALIDATION_ERRORS } from "../index";

const domainCommonRegexp = new RegExp(DOMAIN_COMMON_REGEXP);

export default (domain) => {
  if (domain.length === 0) {
    return false;
  }

  if (!domainCommonRegexp.test(domain)) {
    return VALIDATION_ERRORS.invalidDomain;
  }

  return false;
};
