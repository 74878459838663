<template>
  <div>
    <div v-if="props.processing">
      <SpinnerBrand centered />
    </div>
    <table v-else class="table-simple" :class="{ 'table-simple__selectable': props.selectable }">
      <thead v-if="props.headers">
        <tr>
          <td v-if="props.showCheckbox">
            <BaseCheckbox @change="onAllCheckboxChange" />
          </td>
          <td v-for="(header, i) in props.headers" :key="`table_${_uid}_header_${i}`">
            <a
              v-if="props.sortableHeaders && props.sortableHeaders.includes(header)"
              @click.prevent="sortByColumn(header)"
              class="clickable-header"
            >
              {{ $t(header) }}
              <Icon :icon="getSortableHeaderIcon(header)" class="icon" height="10px" width="10px" />
            </a>
            <span v-else>{{ $t(header) }}</span>
          </td>
        </tr>
        <tr v-if="props.summarizedColumns" class="summary">
          <td v-for="(header, i) in props.headers" :key="`table_${_uid}_header_${i}`">
            <span v-if="i === 0">{{ $t("Summary:") }}</span>
            <span v-else-if="props.summarizedColumns.hasOwnProperty(header)">{{
              props.summarizedColumns[header]
            }}</span>
          </td>
        </tr>
      </thead>
      <tbody v-if="props.items">
        <slot name="controls" />
        <tr
          v-for="item in props.items"
          :key="`table_${_uid}_body_row_${item[props.primaryKey]}`"
          :class="getRowClass(item[props.primaryKey])"
          @click="onRowClick(item[props.primaryKey])"
        >
          <td v-if="props.showCheckbox">
            <BaseCheckbox
              :value="isIdIncheckedItems(item[props.primaryKey])"
              @change="onCheckboxChange($event, item[props.primaryKey])"
            />
          </td>
          <template v-for="(field, index) in props.cells">
            <td v-if="isLazyCell(field, item)" :key="`table_${_uid}_row_${item[props.primaryKey]}_cell_lazy_${index}`">
              <Button
                icon="reload"
                class="lazy_button"
                :class="getLazyButtonClass(field, item)"
                @click="lazyLoad(field, item)"
              />
            </td>
            <td
              v-else-if="isClickableCell(field, item)"
              :key="`table_${_uid}_row_${item[props.primaryKey]}_cell_clickable_${index}`"
            >
              <a
                href="#"
                @click.prevent="onClickClickableCell(field, item)"
                v-html="isTranslatableCell(field, item) ? $t(getFieldValue(item, field)) : getFieldValue(item, field)"
              ></a>
            </td>
            <td
              v-else
              :key="`table_${_uid}_row_${item[props.primaryKey]}_cell_${index}`"
              v-html="isTranslatableCell(field, item) ? $t(getFieldValue(item, field)) : getFieldValue(item, field)"
            ></td>
          </template>

          <td
            v-for="(extraCell, i) in props.extraCells"
            :key="`table_${_uid}_row_${item[props.primaryKey]}_extracell_${i}`"
          >
            <component
              v-if="extraCell.text"
              :is="extraCell.tag"
              v-bind="getExtraCellAttrs(item, extraCell.attrs)"
              v-text="extraCell.text"
            />
            <component v-else :is="extraCell.tag" v-bind="getExtraCellAttrs(item, extraCell.attrs)" />
          </td>

          <td v-if="props.processingItems && isItemProcessing(item[props.primaryKey])" class="action">
            <SpinnerRadial :width="24" />
          </td>

          <td v-else-if="props.showDeleteButton || item['__showDeleteButton']" class="action">
            <!-- To hiding delete button pass __hideDeleteButton property for item -->
            <!-- To show delete button pass __showDeleteButton property for item -->
            <a
              v-if="!item['__hideDeleteButton']"
              href="#"
              class="action_button action_button__delete"
              @click.stop.prevent="onDelete(item[props.primaryKey])"
            >
              <Icon icon="delete" class="icon" height="20px" width="24px" />
            </a>
          </td>

          <td v-else-if="props.showEditButton" class="action">
            <a href="#" class="action_button action_button__edit" @click.stop.prevent="onEdit(item[props.primaryKey])">
              <Icon icon="edit" class="icon" height="20px" width="24px" />
            </a>
          </td>

          <td v-else-if="props.showDownloadButton" class="action">
            <a
              v-if="!item['__hideDownloadButton']"
              href="#"
              class="action_button action_button__download"
              @click.stop.prevent="onDownload(item[props.primaryKey])"
            >
              <Icon icon="download" class="icon" height="20px" width="24px" />
            </a>
          </td>

          <td v-else-if="props.showActionsButton && props.actions.length > 0" class="action">
            <!-- To hiding actions button pass __hideActionsButton property for item -->
            <ActionsButton
              v-if="!item['__hideActionsButton']"
              :actions="props.actions"
              :item="item"
              @action="onAction($event, item[props.primaryKey])"
            />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <slot name="footer" />
      </tfoot>
    </table>
  </div>
</template>

<script>
import { ref, reactive } from "@vue/composition-api";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isFunction from "lodash/isFunction";
import isUndefined from "lodash/isUndefined";

import ActionsButton from "@/components/buttons/ActionsButton.vue";
import Button from "@/components/buttons/Button.vue";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import Icon from "@/components/icons/Icon.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import SpinnerRadial from "@/components/loaders/SpinnerRadial.vue";

import useVarAsArray from "@/composables/useVarAsArray";
import store from "@/store";
import { MODAL_TYPES, MODAL_ACTION_OPEN } from "@/store/modules/modal";

import { COMPANY_TYPES } from "@/config";

export default {
  emits: ["click:clickable", "delete", "edit", "input", "sort-header"],

  components: {
    ActionsButton,
    BaseCheckbox,
    Button,
    Icon,
    SpinnerBrand,
    SpinnerRadial,
  },

  props: {
    actions: {
      type: Array,
      required: false,
    },
    cells: {
      type: Array,
      required: false,
    },
    clickableCells: {
      type: [Array, String],
      required: false,
    },
    showCheckbox: {
      type: Boolean,
      required: false,
    },
    emptyCellValue: {
      type: String,
      required: false,
    },
    extraCells: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    lazyCells: {
      type: [Array, String],
      required: false,
    },
    lazyLoadFunction: {
      type: Function,
      required: false,
    },
    primaryKey: {
      type: String,
      default: "id",
    },
    processing: {
      type: Boolean,
      default: false,
    },
    processingItems: {
      type: [Array, Number, String],
      required: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    showActionsButton: {
      type: Boolean,
      required: false,
    },
    showDeleteButton: {
      type: Boolean,
      required: false,
    },
    showEditButton: {
      type: Boolean,
      required: false,
    },
    showDownloadButton: {
      type: Boolean,
      required: false,
    },
    sortableHeaders: {
      type: Array,
      required: false,
    },
    summarizedColumns: {
      type: [Object, null],
      default: null,
    },
    translatableColumns: {
      type: Array,
      required: false,
    },
    sortableState: {
      type: Object,
      required: false,
    },
    value: {
      type: [Number, String],
      required: false,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      lazyLoadingItems: [],

      sortDirection: "asc",
      sortableHeader: null,
    });

    const checkedItemsList = ref([]);

    const lazyCells = useVarAsArray("lazyCells", props);
    const clickableCells = useVarAsArray("clickableCells", props);
    const translatableColumns = useVarAsArray("translatableColumns", props);

    function getSortableHeaderIcon(header) {
      return props.sortableState && header === props.sortableState.header ? props.sortableState.direction : "asc";
    }

    function onRowClick(companyId) {
      if (props.selectable) {
        emit("input", companyId);
        emit("change", companyId, props.value);
      }
    }

    function getRowClass(id) {
      if (id === props.value) {
        return "checked";
      }

      return "";
    }

    function onDelete(id) {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.action,
        payload: {
          onAccept: () => emit("delete", id),
        },
      });
    }

    function onDownload(id) {
      emit("download", id);
    }

    function onEdit(id) {
      emit("edit", id);
    }

    function isItemProcessing(id) {
      if (props.processingItems) {
        if (isArray(props.processingItems)) {
          return props.processingItems.includes(id);
        }

        return id == props.processingItems;
      }

      return false;
    }

    function isIdIncheckedItems(itemId) {
      return checkedItemsList.value.includes(itemId);
    }

    function onAction(eventName, itemId) {
      emit(eventName, itemId);
    }

    function onCheckboxChange(newValue, itemId) {
      checkedItemsList.value = checkedItemsList.value.filter((item) => item !== itemId);
      if (newValue) {
        checkedItemsList.value.push(itemId);
      }

      emit("checkbox-change", checkedItemsList.value);
    }

    function onAllCheckboxChange(newValue) {
      checkedItemsList.value = [];
      if (newValue) {
        props.items.forEach((item) => checkedItemsList.value.push(item[props.primaryKey]));
      }

      emit("checkbox-change", checkedItemsList.value);
    }

    function getCompanyType({ registration_type, compensation_amount }) {
      const { b2b, b2c, copay } = COMPANY_TYPES.value;

      if (registration_type === 0) {
        return b2b;
      } else if (registration_type === 1 && compensation_amount > 0) {
        return copay;
      } else if (registration_type === 1 && !compensation_amount) {
        return b2c;
      }
    }

    function getFieldValue(item, field) {
      if (isFunction(field)) {
        return field(item);
      } else if (field === COMPANY_TYPES.key) {
        return getCompanyType(item);
      } else {
        return get(item, field) || props.emptyCellValue;
      }
    }

    function getExtraCellAttrs(item, attrs) {
      if (isFunction(attrs)) {
        return attrs(item);
      } else {
        return attrs;
      }
    }

    function isLazyCell(field, item) {
      return lazyCells.value.includes(field) && isUndefined(item[field]);
    }

    function isClickableCell(field, item) {
      if (isFunction(field)) {
        return clickableCells.value.includes(field(item));
      }

      return clickableCells.value.includes(field);
    }

    function isTranslatableCell(field, item) {
      if (isFunction(field)) {
        return translatableColumns.value.includes(field(item));
      }

      return translatableColumns.value.includes(field);
    }

    function getLazyButtonClass(field, item) {
      const isLoading = state.lazyLoadingItems.find(
        ({ id: loadingId, field: loadingField }) => loadingId === item[props.primaryKey] && loadingField === field
      );

      return {
        loading: isLoading,
      };
    }

    async function lazyLoad(field, item) {
      const isAlreadyLoading = state.lazyLoadingItems.find(
        ({ id: loadingId, field: loadingField }) => loadingId === item[props.primaryKey] && loadingField === field
      );

      if (props.lazyLoadFunction && !isAlreadyLoading) {
        state.lazyLoadingItems.push({ id: item[props.primaryKey], field });

        await props.lazyLoadFunction(field, item);

        state.lazyLoadingItems = state.lazyLoadingItems.filter(
          ({ id: loadingId, field: loadingField }) => loadingId !== item[props.primaryKey] && loadingField !== field
        );
      }
    }

    function onClickClickableCell(field, item) {
      if (isFunction(field)) {
        emit("click:clickable", field(item), item);
      }

      emit("click:clickable", field, item);
    }

    function sortByColumn(header) {
      emit("sort-header", header);
    }

    return {
      props,

      getSortableHeaderIcon,

      getExtraCellAttrs,
      getFieldValue,
      getLazyButtonClass,
      getRowClass,
      isClickableCell,
      isTranslatableCell,
      isItemProcessing,
      isIdIncheckedItems,
      isLazyCell,
      lazyLoad,
      onAction,
      onAllCheckboxChange,
      onCheckboxChange,
      onClickClickableCell,
      onDelete,
      onDownload,
      onEdit,
      onRowClick,
      sortByColumn,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/animations";

.table-simple {
  width: 100%;
  border-collapse: collapse;

  &__selectable {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  thead {
    font-weight: 600;
    font-size: 18px;

    td {
      padding: 0 10px;

      & > .clickable-header {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
      }
    }

    tr.summary {
      position: relative;
      border-bottom: 1px solid #9c9c9c;

      td {
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        padding: 10px;

        &:first-child {
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
  }

  tbody {
    &:before {
      content: "@";
      display: block;
      line-height: 30px;
      text-indent: -999999px;
    }

    tr {
      border-bottom: 1px solid #9c9c9c;

      &.checked {
        background-color: #e7e7e7;

        &:not(.non-selectable):hover {
          background-color: #e7e7e7;
        }
      }

      &:not(.non-selectable):hover {
        background-color: #fafafa;
      }

      td {
        font-weight: 400;
        font-size: 15px;
        padding: 10px;
        position: relative;
        word-break: break-word;

        &.action {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        a {
          font-weight: 500;
          font-size: 15px;
          color: #000;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        .lazy_button {
          &.loading {
            animation: animation-rotate 0.3s linear infinite;
          }
        }

        .action_button {
          display: inline-block;

          &__delete {
            &:hover {
              .icon {
                fill: #d63230;
              }
            }
          }

          &__edit {
            &:hover {
              .icon {
                fill: #346aed;
              }
            }
          }

          &__download {
            &:hover {
              .icon {
                fill: #79c99e;
              }
            }
          }

          .icon {
            fill: #9c9c9c;
          }
        }
      }
    }
  }

  tfoot {
    font-weight: 500;
    font-size: 18px;

    td {
      padding: 16px 10px 0;
    }
  }
}
</style>

<style lang="scss">
.table-simple {
  tbody {
    tr {
      td {
        .cell_status {
          position: absolute;
          display: inline-block;
          width: 6px;
          top: 10px;
          left: 0;
          bottom: 10px;

          &__black {
            background-color: #444545;
          }

          &__green {
            background-color: #79c99e;
          }

          &__red {
            background-color: #d63230;
          }

          &__yellow {
            background-color: #ffbe3f;
          }
        }
      }
    }
  }
}
</style>
