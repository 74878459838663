<template>
  <ModalContent tag="form" @close="close">
    <template slot="header">
      <h3 v-if="isEditMode">Edit price model</h3>
      <h3 v-else>Add price model</h3>
    </template>
    <template slot="content">
      <ErrorBanner :message="errors.errorMessage" />
      <NewSelectInput
        v-model="priceModel.seller"
        :placeholder="$t('Select seller')"
        :error="errors.seller"
        :label="$t('Seller')"
        :options="sellersOptions"
        @select="onSellerChange"
      />
      <SearchInput
        v-model="priceModel.company"
        :label="$t('Company')"
        :item-text-field="({ text }) => text"
        model-field="value"
        :offline-items="companiesOptions"
        :error="errors.company"
        input-mode
      />
      <NewSelectInput
        v-model="priceModel.currency"
        :placeholder="$t('Select currency')"
        :error="errors.currency"
        :label="$t('Currency')"
        :options="availableCurrenciesOptions"
        :max-options-visible="3"
      />
      <h4>{{ $t("Pricing policy") }}:</h4>
      <div v-if="priceModel.pricingPolicies.length > 0" class="headers">
        <div class="headers_item">{{ $t("Condition") }}:</div>
        <div class="headers_item wide">{{ $t("Subscription type") }}:</div>
        <div class="headers_item">{{ $t("Cost per item") }}:</div>
      </div>
      <div v-for="policy in priceModel.pricingPolicies" class="inputs-group" :key="`pricing-policy-${policy._id}`">
        <BaseInput v-model="policy.condition" class="inputs-group_item" />
        <BaseInput v-model="policy.subscriptionType" class="inputs-group_item__wide" />
        <BaseInput v-model="policy.cost" class="inputs-group_item" />
        <a href="#" class="action_button action_button__delete" @click.prevent="deletePricingPolicy(policy._id)">
          <Icon icon="delete" class="action_button_icon" height="28px" width="24px" />
        </a>
      </div>
      <AddInstanceButton @click="addNewPricingPolicy">{{ $t("Add pricing policy") }}</AddInstanceButton>
    </template>
    <template slot="controls">
      <Button button-type="light" auto-width @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button
        type="submit"
        :processing="processing"
        auto-width
        @click.prevent="onSubmit"
        :disabled="!isSubmitButtonEnabled"
      >
        {{ $t("Save") }}
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, ref, reactive, onBeforeMount } from "@vue/composition-api";
import cloneDeep from "lodash/cloneDeep";

import AddInstanceButton from "@/components/buttons/AddInstanceButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import Icon from "@/components/icons/Icon.vue";
import ModalContent from "@/components/modal/ModalContent.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";

import { camelCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";
import { MODAL_MODES, MODAL_TYPES } from "@/store/modules/modal";
import { useRoute } from "@/helpers/router/routeCompositionAPI";
import { VALIDATION_ERRORS } from "@/helpers/validators/index";
import store from "@/store";
import URLS from "@/config/urls";
import useIsObjectChanged from "@/composables/useIsObjectChanged";
import useRequest from "@/composables/network/useRequest";
import validateRequiredFields from "@/helpers/validators/inputs/validateRequiredFields";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";

const REQUIRED_FIELDS = ["currency", "pricingPolicies"];

export default {
  emits: ["close"],

  components: {
    AddInstanceButton,
    BaseInput,
    Button,
    ErrorBanner,
    Icon,
    ModalContent,
    SearchInput,
    NewSelectInput,
  },

  setup(props, { emit }) {
    const route = useRoute();

    const { errors, fetch, processing, setErrors } = useRequest({
      errorsFormat: "flat",
    });

    const priceModelInitialState = ref({});

    const state = reactive({
      processingCompanies: true,
      companiesList: [],
    });

    const priceModel = reactive({
      id: null,
      currency: null,
      pricingPolicies: [],
      seller: null,
      company: null,
    });

    const { isObjectChanged } = useIsObjectChanged(priceModelInitialState, priceModel);

    const configStore = computed(() => store.state.config);
    const modalStore = computed(() => store.state.modal);
    const modalPayload = computed(() => modalStore.value.payload);

    const countryId = computed(() => route.value.params.countryId || store.state.auth.country);
    const countrySubscriptionLevels = computed(() => configStore.value.portal.levels);

    const isEditMode = computed(() => priceModel.id);
    const isCopyMode = computed(() => modalStore.value.mode === MODAL_MODES[MODAL_TYPES.priceModel].copy);
    const isFullMode = computed(() => {
      const isFullMode = modalStore.value.mode === MODAL_MODES[MODAL_TYPES.priceModel].full;
      return isCopyMode.value || isFullMode;
    });

    const isSubmitButtonEnabled = computed(() => isObjectChanged || isCopyMode.value);

    const availableCurrenciesOptions = computed(() => {
      const { company, seller } = priceModel;

      let availableCurrencies;

      if (modalPayload.value.availableCurrencies) {
        if (seller) {
          availableCurrencies = modalPayload.value.availableCurrencies?.sellers[seller];
        } else {
          availableCurrencies = modalPayload.value.availableCurrencies?.country;
        }
      }

      if (company || !availableCurrencies) {
        if (!configStore.value.portal) {
          throw new Error("Portal config is not loaded!");
        }

        availableCurrencies = configStore.value.portal.availableCurrencies;
      }

      const availableCurrenciesOptions = availableCurrencies.map((value) => ({ text: value, value }));

      return availableCurrenciesOptions;
    });

    const companiesOptions = computed(() => {
      const noCompanyOption = { text: "No company", value: null };
      const companiesOptions = state.companiesList.map(({ id, name }) => ({ text: name, value: id }));
      companiesOptions.sort((a, b) => a.text?.toLowerCase().localeCompare(b.text?.toLowerCase()));
      return [noCompanyOption, ...companiesOptions];
    });

    const sellersOptions = computed(() => {
      const countrySellersList = configStore.value.country.sellers;
      const countrySellersOptions = countrySellersList.map(({ code, name }) => ({ text: name, value: code }));
      const noSellerOption = { text: "No Seller", value: null };
      const sellersOptions = [noSellerOption, ...countrySellersOptions];

      return sellersOptions;
    });

    function addNewPricingPolicy() {
      const lastPolicyId = priceModel.pricingPolicies.at(-1)?._id || 0;
      priceModel.pricingPolicies.push({
        _id: lastPolicyId + 1,
        condition: "",
        cost: "",
        subscriptionType: "",
      });
    }

    function deletePricingPolicy(policyId) {
      priceModel.pricingPolicies = priceModel.pricingPolicies.filter(({ _id }) => _id != policyId);
    }

    function prepareData() {
      const pricingPolicy = priceModel.pricingPolicies
        .flatMap(({ condition, cost, subscriptionType }) => ["", condition, subscriptionType, cost, ""].join("|"))
        .join("\n");

      const data = {
        company_id: priceModel.company,
        currency: priceModel.currency,
        pricing_policy: pricingPolicy,
        seller_code: priceModel.seller,
      };

      return data;
    }

    async function onSubmit() {
      if (processing.value) {
        return;
      }

      const errors = validateForm();

      if (errors) {
        setErrors(errors);
        return;
      }

      const url = isEditMode.value
        ? URLS.countries.priceModels.single(countryId.value, priceModel.id)
        : URLS.countries.priceModels.index(countryId.value);
      const method = isEditMode.value ? "PUT" : "POST";
      const requestData = prepareData();

      const { data, error } = await fetch({
        url,
        method,
        data: requestData,
      });

      if (!error) {
        if (modalPayload.value && modalPayload.value.onAccept) {
          await modalPayload.value.onAccept(data);
        }

        close();
      }
    }

    function validateForm() {
      let errors = validateRequiredFields(priceModel, REQUIRED_FIELDS) || {};

      REQUIRED_FIELDS.forEach((item) => {
        if (item === "pricingPolicies") {
          if (priceModel[item].length == 0) {
            errors.errorMessage = VALIDATION_ERRORS.requiredFormFields;
          } else {
            priceModel[item].forEach((param) => {
              for (let key in param) {
                if (!param[key]) {
                  errors.errorMessage = VALIDATION_ERRORS.requiredFormFields;
                } else {
                  if (key === "subscriptionType") {
                    const country = countryId.value.toLowerCase();
                    const isLevelExist = countrySubscriptionLevels.value[country].includes(param[key]);

                    if (!isLevelExist) {
                      errors.errorMessage = VALIDATION_ERRORS.invalidSubscriptionType;
                    }
                  }
                }
              }
            });
          }
        }
      });

      return Object.keys(errors).length > 0 ? errors : null;
    }

    async function getCompanies() {
      state.processingCompanies = true;

      if (!state.companiesList || state.companiesList.length === 0) {
        const { error, data } = await fetch(URLS.companies.index);

        if (!error) {
          state.companiesList = data;
        }
      }

      state.processingCompanies = false;
    }

    function onSellerChange() {
      if (!isCopyMode.value) {
        priceModel.currency = null;
      }
    }

    function close() {
      emit("close");
    }

    onBeforeMount(() => {
      const { companies, priceModel: payloadPriceModel } = modalPayload.value;

      state.companiesList = companies || [];

      if (payloadPriceModel) {
        const { company, currency, id, pricingPolicy, seller } = camelCaseObjectKeys(payloadPriceModel);
        const pricingPolicies = pricingPolicy.split("\n").map((policy, index) => {
          const [condition, subscriptionType, cost] = policy.split("|").slice(1, -1);
          return {
            _id: index + 1,
            condition,
            cost,
            subscriptionType,
          };
        });

        priceModel.company = company?.id || null;
        priceModel.currency = currency;
        priceModel.id = id;
        priceModel.pricingPolicies = pricingPolicies;
        priceModel.seller = seller?.code || null;
      }

      priceModelInitialState.value = cloneDeep(priceModel);

      getCompanies();
    });

    return {
      errors,
      processing,

      priceModel,
      state,

      availableCurrenciesOptions,
      companiesOptions,
      sellersOptions,
      isEditMode,
      isFullMode,
      isSubmitButtonEnabled,

      addNewPricingPolicy,
      deletePricingPolicy,
      onSellerChange,
      onSubmit,
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 22px;
  color: #444545;
}

.headers {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  color: #444545;
  &_item {
    width: 107px;

    &.wide {
      margin-right: 51px;
    }
  }
}

.inputs-group {
  display: flex;
  align-items: center;
  gap: 20px;

  &_item {
    width: 107px;

    &__wide {
      width: 158px;
    }
  }

  &_delete-button {
    color: #444545;
  }

  .action_button {
    display: inline-block;
    margin-bottom: 24px;

    &__delete {
      &:hover {
        .action_button_icon {
          fill: #d63230;
        }
      }
    }

    .action_button_icon {
      fill: #9c9c9c;
    }
  }
}
</style>
