import { ref, watch } from "@vue/composition-api";
import router from "@/router";

export function useRoute(root = {}) {
  const route = ref(router.currentRoute);

  if (root.$route) {
    watch(
      () => root.$route,
      () => {
        route.value = router.currentRoute;
      },
      {
        immediate: true,
      }
    );
  }

  return route;
}
