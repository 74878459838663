export function generateCalculator(priceTable) {
  const list = String(priceTable).split("\n");
  const options = {};
  let i,
    line,
    found,
    regexp = /\|(.*?)(>=|-)([^\|]+)\|([^\|]+)\|([^\|]+)\|/i,
    min,
    command,
    max,
    level,
    price,
    item;
  for (i in list) {
    item = list[i].trim();
    if (!item) {
      continue;
    }
    found = item.match(regexp);
    if (found.length !== 6) {
      throw new Error(
        "Please, use only format: |>=100|level|price in BYN| or |10-99|level|price in BYN|"
      );
    }
    min = Number.parseInt(found[1]);
    command = found[2].trim();
    if ([">=", "-"].indexOf(command) === -1) {
      throw new Error(`Please, use only command >= and -, not ${command}`);
    }
    max = Number.parseInt(found[3]);
    level = found[4].trim();
    price = Math.floor(Number.parseFloat(found[5]) * 100);
    if (!options[level]) {
      options[level] = [];
    }
    options[level].push({ min, command, max, price });
  }
  return {
    calculatePriceBy(level, count, defaultPrice) {
      const optionList = options[level];
      let maxPrice = 0;
      if (!optionList) {
        return defaultPrice;
      }
      const found = optionList.filter((option) => {
        maxPrice = Math.max(maxPrice, option.price);
        switch (option.command) {
          case "-":
            return option.min <= count && count <= option.max;
          case ">=":
            return count >= option.max;
        }
      });
      if (found.length) {
        maxPrice = found[0].price;
      }
      return maxPrice;
    },
  };
}

export function product_name(key) {
  const list = {
    bronzem1: "Бронзовый",
    silverm1: "Серебрянный",
    goldm1: "Золотой",
    silverm3: "Серебрянный M3",
    silverm6: "Серебрянный M6",
    silverm12: "Серебрянный M12",
  };
  return list[key];
}

export function isEmpty(value) {
  if (!value) {
    return true;
  }
  if (Array.isArray(value) && !value.length) {
    return true;
  }
  return false;
}

export function isEqual(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function formatToRFC3339(date, hasDay = true) {
  return (
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    (hasDay ? "-" + String(date.getDate()).padStart(2, "0") : "")
  );
}

export function createStartOfMonth(filter_at) {
  const date = new Date(filter_at);
  date.setDate(1);
  return date;
}

export function formatToym(date) {
  return (date.getYear() % 100) * 100 + (date.getMonth() + 1);
}

export function formatToRuMinskLocale(date) {
  return date.toLocaleDateString("ru-RU", {
    year: "numeric",
    month: "long",
    timezone: "Europe/Minsk",
  });
}

export function formatToEnLocale(date) {
  return date.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    timezone: "Europe/London",
  });
}

export function findFixedRectangle(obj, foundScrollLeft, foundScrollTop) {
  let curleft = 0;
  let curtop = 0;
  if (!foundScrollLeft) {
    foundScrollLeft = 0;
  }
  if (!foundScrollTop) {
    foundScrollTop = 0;
  }
  if (obj.offsetLeft) curleft += parseInt(obj.offsetLeft);
  if (obj.offsetTop) curtop += parseInt(obj.offsetTop);
  if (obj.scrollTop && obj.scrollTop > 0) {
    curtop -= parseInt(obj.scrollTop);
    foundScrollTop = true;
  }
  if (obj.scrollLeft && obj.scrollLeft > 0) {
    curleft -= parseInt(obj.scrollLeft);
    foundScrollLeft = true;
  }
  if (obj.offsetParent) {
    let pos = findFixedRectangle(
      obj.offsetParent,
      foundScrollLeft,
      foundScrollTop
    );
    curleft += pos[0];
    curtop += pos[1];
  } else if (obj.ownerDocument) {
    let thewindow = obj.ownerDocument.defaultView;
    if (!thewindow && obj.ownerDocument.parentWindow)
      thewindow = obj.ownerDocument.parentWindow;
    if (thewindow) {
      if (!foundScrollTop && thewindow.scrollY && thewindow.scrollY > 0)
        curtop -= parseInt(thewindow.scrollY);
      if (!foundScrollLeft && thewindow.scrollX && thewindow.scrollX > 0)
        curleft -= parseInt(thewindow.scrollX);
      if (thewindow.frameElement) {
        let pos = findFixedRectangle(thewindow.frameElement);
        curleft += pos[0];
        curtop += pos[1];
      }
    }
  }

  return [curleft, curtop, obj.offsetWidth, obj.offsetHeight];
}

export function statisticStatusToText(statusKey) {
  const statusKeyDictionary = {
    1: "unknown_barcode",
    3: "card_daily_limit",
    5: "card_monthly_limit",
    6: "deactivated_card",
    7: "supplier_offline",
    8: "supplier_accepting",
    9: "supplier_reject_wrong_id",
    10: "supplier_reject_refused_copayment",
    11: "supplier_reject_other",
    12: "accepted",
    13: "holder_accepting",
    14: "supplier_request_copayment",
    18: "aggregator_invalid_attraction",
    19: "aggregator_invalid_other",
    20: "aggregator_invalid_visit_status",
    21: "aggregator_other",
    22: "server_accepted",
    23: "missed_token",
    24: "card_weekly_limit",
    25: "aggregator_invalid_attraction_passed_cards",
    26: "aggregator_no_visit_found",
    27: "",
    28: "app_holder_unknown",
    29: "app_holder_passed",
    30: "app_holder_wait",
    31: "app_holder_timeout",
    32: "app_holder_reject",
    33: "app_holder_limit",
    34: "far_from_supplier",
    35: "visit_offline_geolocation",
    36: "app_other_passed",
    37: "app_faceid_required",
  };
  return statusKeyDictionary[statusKey]
    ? statusKeyDictionary[statusKey]
    : statusKey;
}
