<template>
  <ModalContent tag="form" @close="close">
    <template slot="header">
      <h3>Seller</h3>
    </template>
    <template slot="content">
      <ErrorBanner :message="errors.errorMessage" />
      <BaseInput v-model="seller.name" :label="`${$t('Name')}:`" :error="errors.name" />
      <BaseInput v-model="seller.vatNumber" :label="`${$t('Registration code')}:`" :error="errors.vat_number" />
      <NewSelectInput
        v-model="seller.taxRate"
        :label="`${$t('Tax rate')}:`"
        placeholder="Select tax rate"
        :options="taxRatesOptions"
        :max-options-visible="2"
        :error="errors.tax_rate"
      />
    </template>
    <template slot="controls">
      <Button button-type="light" auto-width @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button type="submit" :processing="processing" auto-width @click.prevent="onSubmit" :disabled="!isObjectChanged">
        {{ $t("Save") }}
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, ref, onBeforeMount, reactive, nextTick } from "@vue/composition-api";
import clone from "lodash/clone";

import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import ModalContent from "@/components/modal/ModalContent.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";

import { camelCaseObjectKeys, snakeCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";
import store from "@/store";
import URLS from "@/config/urls";
import useIsObjectChanged from "@/composables/useIsObjectChanged";
import useRequest from "@/composables/network/useRequest";
import validateRequiredFields from "@/helpers/validators/inputs/validateRequiredFields";
import { useRoute } from "@/helpers/router/routeCompositionAPI";

const REQUIRED_FIELDS = ["name", "vatNumber", "taxRate"];

export default {
  emits: ["close"],

  components: {
    Button,
    BaseInput,
    ErrorBanner,
    ModalContent,
    NewSelectInput,
  },

  setup(props, { emit, root }) {
    const route = useRoute(root);
    const { errors, fetch, processing, setErrors, resetErrors } = useRequest({
      errorsFormat: "flat",
    });

    const sellerInitialState = ref({});

    const seller = reactive({
      code: null,
      name: "",
      vatNumber: "",
      taxRate: "",
    });

    const { isObjectChanged } = useIsObjectChanged(sellerInitialState, seller);

    const isEditMode = computed(() => !!seller.code);
    const modalPayload = computed(() => store.state.modal.payload);
    const countryId = computed(() => route.value.params.countryId);

    const taxRatesOptions = computed(() =>
      store.state.config.country.taxRates.map((taxRate) => ({ text: taxRate, value: taxRate }))
    );

    async function onAccept(data) {
      if (modalPayload.value && modalPayload.value.onAccept) {
        await modalPayload.value.onAccept(data);
      }
    }

    async function onSubmit() {
      if (processing.value) {
        return;
      }

      resetErrors();

      const errors = validateForm();

      if (errors) {
        return nextTick(() => {
          setErrors(snakeCaseObjectKeys(errors));
        });
      }

      const url = isEditMode.value
        ? URLS.countries.sellers.single(countryId.value, seller.code)
        : URLS.countries.sellers.index(countryId.value);
      const method = isEditMode.value ? "PUT" : "POST";
      const requestData = snakeCaseObjectKeys(seller);

      const { error, data } = await fetch({
        url,
        method,
        data: requestData,
      });

      if (!error) {
        await onAccept(data);
        close();
      }
    }

    function validateForm() {
      let errors = validateRequiredFields(seller, REQUIRED_FIELDS);

      return errors;
    }

    function close() {
      emit("close");
    }

    onBeforeMount(() => {
      const payloadSeller = camelCaseObjectKeys(modalPayload.value.seller);

      if (payloadSeller) {
        Object.assign(seller, payloadSeller);
      }

      sellerInitialState.value = clone(seller);
    });

    return {
      errors,
      processing,
      isObjectChanged,

      seller,

      isEditMode,
      taxRatesOptions,

      onSubmit,
      close,
    };
  },
};
</script>
