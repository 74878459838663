import isNumber from "lodash/isNumber";
import isNaN from "lodash/isNaN";
import globalConfig from "@/config";
import store from "@/store";

const DEFAULT_CONFIG = {
  decimalDelimiter: ",",
  dropEmptyDecimal: false,
  precision: 2,
};

const DEFAULT_LOCALE = "ru";
const DEFAULT_COUNTRY = "by";
const DEFAULT_CURRENCY = "BYN";

export default function (value, params = {}) {
  const locale = store.state.auth.locale || DEFAULT_LOCALE;
  const country = store.state.auth.country || DEFAULT_COUNTRY;
  const currency = globalConfig.currencies[country] || DEFAULT_CURRENCY;

  const config = Object.assign({}, DEFAULT_CONFIG, params);

  let num = parseInt(value);

  if (!isNumber(num) || isNaN(num)) {
    return value;
  }

  const absNum = Math.abs(num);

  const polarity = num < 0 ? "-" : "";

  const fractional = 10 ** config.precision;
  const integer = Math.floor(absNum / fractional);
  const decimal = absNum % fractional;

  const integerPart = new Intl.NumberFormat(locale).format(integer);

  let decimalPart = "";

  if (!config.dropEmptyDecimal || decimal > 0) {
    const decimalNums = `${decimal}`.padStart(config.precision, "0");
    decimalPart = `${config.decimalDelimiter}${decimalNums}`;
  }

  return `${polarity}${integerPart}${decimalPart} ${currency}`;
}
