<template>
  <small-layout>
    <div>
      <fieldset>
        <h1>Affiliates Updates</h1>
        <button @click="toggleCreateUpdate = !toggleCreateUpdate">Toggle Create Message Form</button>
        <br />
        <StoreForm v-if="toggleCreateUpdate" class="createUpdate" @hide="toggleCreateUpdate = false"></StoreForm>
        <UpdateList v-if="toggleList" :data="list"></UpdateList>
      </fieldset>
    </div>
  </small-layout>
</template>

<style scoped lang="scss">
td {
  word-break: break-word;
}
</style>

<script>
import { mapActions } from "vuex";
import StoreForm from "./affiliates-updates/StoreForm";
import UpdateList from "./affiliates-updates/List";

export default {
  components: {
    StoreForm,
    UpdateList,
  },
  data: function () {
    return {
      list: [],
      toggleCreateUpdate: false,
      toggleList: false,
    };
  },
  methods: {
    ...mapActions({
      loadList: "affiliates_update/load_list",
      create: "affiliates_update/create",
      download: "affiliates_update/download",
    }),
  },
  async created() {
    this.list = await this.loadList();
    this.toggleList = true;
  },
};
</script>
