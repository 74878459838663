<template>
  <div class="modal_container" :is="props.tag" v-on="$listeners">
    <div class="modal_row header">
      <slot name="header"></slot>
      <a href="#" class="modal_close" @click.prevent="emit('close')">
        <Icon icon="modal-close" fill="#000" />
      </a>
    </div>

    <slot name="content"></slot>

    <div v-if="$slots.controls" class="modal_row buttons">
      <slot name="controls"></slot>
    </div>

    <slot />
  </div>
</template>

<script>
import Icon from "@/components/icons/Icon.vue";

const DEFAULT_TAG = "div";
const AVAILABLE_TAGS = ["div", "form"];

export default {
  emits: ["close"],

  components: {
    Icon,
  },

  props: {
    tag: {
      type: String,
      default: DEFAULT_TAG,
      validator: (val) => AVAILABLE_TAGS.includes(val),
    },
  },

  setup(props, { emit }) {
    return {
      props,
      emit,
    };
  },
};
</script>
