<template>
  <ModalContent tag="form" @close="close">
    <template slot="header">
      <h3>{{ $t("Add visit") }}</h3>
    </template>
    <template slot="content">
      <ErrorBanner :message="errorMessage" />
      <template>
        <DatePickerInput
          v-model="visitState.created_at"
          :error="visitState.errors.created_at"
          :label="$t('Date')"
          :placeholder="$t('yyyy-mm-dd-hh-mm-ss')"
          :clearable="false"
          type="datetime"
        />
        <SearchInput
          v-model="visitState.card_id"
          :label="$t('Holder')"
          :error="visitState.errors.card_id"
          search-field="holder"
          :search-url="getHolderSearchUrl"
          model-field="id"
          :item-text-field="({ id, holder }) => `${id} ${holder}`"
        />
        <SearchInput
          v-model="visitState.supplier_id"
          ref="refSupplierInput"
          :label="$t('Supplier')"
          :error="visitState.errors.supplier_id"
          :search-params="supplierSearchParams"
          :search-url="URLS.suppliers.search"
          search-field="matchByName"
          model-field="id"
          :item-text-field="({ id, name }) => `${id} ${name}`"
          @select="onSupplierChange"
        />
        <NewSelectInput
          v-model="visitState.status"
          :placeholder="$t('Select status')"
          :error="visitState.errors.status"
          :label="`${$t('Status')}:`"
          :options="VISIT_STATUSES_OPTIONS"
          :max-options-visible="4"
        />
        <NewSelectInput
          v-model="visitState.attraction_id"
          :placeholder="attractionSelectPlaceholder"
          :error="visitState.errors.attraction_id"
          :label="`${$t('Attraction')}:`"
          :options="attractionsOptions"
          :disabled="!isSupplierSelectedByName"
        />
      </template>
    </template>
    <template slot="controls">
      <Button button-type="light" auto-width @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button type="submit" :processing="processing" auto-width @click.prevent="onSubmit" :disabled="!isObjectChanged">
        {{ $t("Save") }}
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, ref, reactive } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import ModalContent from "@/components/modal/ModalContent.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";

import store from "@/store";
import useIsObjectChanged from "@/composables/useIsObjectChanged";
import formatCurrency from "@/helpers/formatters/formatCurrency";
import { prepareAxiosErrors } from "@/helpers/index";
import { URLS } from "@/config/index";
import isNull from "lodash/isNull";
import { t } from "@/helpers/i18n";

const VISIT_STATUSES_OPTIONS = [
  { text: "Choose", value: "supplier_accepting" },
  { text: "Client App Testing", value: "app_holder_wait" },
  { text: "Accepted (reason: Manually added broken scan)", value: "accepted" },
  { text: "Rejected (reason: Manually reject broken scan)", value: "supplier_reject_wrong_id" },
  { text: "Failed scanning (reason: Manually update broken scan)", value: "unknown_barcode" },
];

const VISIT_STATUSES_MATCHES_REASONS = {
  app_holder_wait: "manually_added_broken_scan",
  accepted: "manually_added_broken_scan",
  unknown_barcode: "manually_updated_broken_scan",
  supplier_accepting: "manually_updated_broken_scan",
  supplier_reject_wrong_id: "manually_rejected_broken_scan",
};

const VISIT_INITIAL_STATE = {
  created_at: "",
  supplier_id: null,
  card_id: "",
  timedelta: 0,
  registered: "",
  status: "",
  attraction_id: "",

  errors: {},
};

export default {
  emits: ["close"],

  components: {
    Button,
    ErrorBanner,
    ModalContent,
    NewSelectInput,
    DatePickerInput,
    SearchInput,
  },

  setup(props, { emit }) {
    const processing = ref(false);
    const errorMessage = ref("");
    const attractionsOptions = ref([]);
    const attractionSelectPlaceholder = ref("Attractions were not found");

    const visitState = reactive({
      created_at: "",
      supplier_id: null,
      card_id: "",
      timedelta: 0,
      registered: "",
      status: "",
      attraction_id: "",

      errors: {},
    });

    const { isObjectChanged } = useIsObjectChanged(VISIT_INITIAL_STATE, visitState);

    const isSupplierSelectedByName = computed(() => visitState.supplier_id);

    const modalPayload = computed(() => store.state.modal.payload);

    const supplierSearchParams = computed(() => ({
      country: store.state.auth.country,
    }));

    async function onSupplierChange(supplierId) {
      if (isNull(supplierId)) {
        return;
      }

      await updateAttractionsOptionsListBySupplierId(supplierId);
    }

    async function onSubmit() {
      if (processing.value) {
        return;
      }

      await createVisit();
      if (errorMessage.value) return;

      close();
    }

    function close() {
      emit("close");
    }

    async function createVisit() {
      errorMessage.value = "";

      processing.value = true;

      const payload = {
        created_at: visitState.created_at,
        supplier_id: visitState.supplier_id,
        card_id: visitState.card_id,
        status: visitState.status,
        reason: VISIT_STATUSES_MATCHES_REASONS[visitState.status],
        attraction_id: visitState.attraction_id,
        timedelta: visitState.timedelta,
      };

      try {
        await modalPayload.value.onAccept(payload);
      } catch (error) {
        const { errors, errorMessage: text } = prepareAxiosErrors(error);

        for (let key in errors) {
          visitState.errors[key] = errors[key];
        }
        errorMessage.value = text;
      }

      processing.value = false;
    }

    async function fetchAttractionsList(supplierId) {
      errorMessage.value = "";

      try {
        return await store.dispatch("supplier/load_attraction_list", supplierId);
      } catch (error) {
        const { errorMessage: text } = prepareAxiosErrors(error);
        errorMessage.value = text;
      }
    }

    function prepareAttractionsOptionsList(attractionsList) {
      if (attractionsList?.length) {
        attractionSelectPlaceholder.value = t("Select attraction");
        attractionsOptions.value = attractionsList.map((attraction) => {
          return { text: attraction.name + " " + formatCurrency(attraction.price), value: attraction.id };
        });
      } else {
        attractionSelectPlaceholder.value = t("Attractions were not found");
      }
    }

    async function updateAttractionsOptionsListBySupplierId(supplierId) {
      const attractionsList = await fetchAttractionsList(supplierId);
      prepareAttractionsOptionsList(attractionsList);
    }

    function getHolderSearchUrl({ holder }) {
      return `/api/helpdesk/load_holder_by?card_id=&holder=${holder}`;
    }

    return {
      URLS,
      VISIT_STATUSES_OPTIONS,

      errorMessage,
      processing,
      attractionsOptions,
      attractionSelectPlaceholder,

      visitState,

      isObjectChanged,
      isSupplierSelectedByName,
      supplierSearchParams,

      onSubmit,
      close,
      onSupplierChange,
      getHolderSearchUrl,
    };
  },
};
</script>
