// определяем объект примеси
var ColumnMixin = {
  data() {
    return {
      orderKey: 0,
      filterKey: "",
      storage_key: "",
    };
  },
  computed: {
    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.columns[index].order;
      }
      let data = this.source;
      if (this.filterKey) {
        const filterKey = this.filterKey && this.filterKey.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (orderKey && index !== -1) {
        this.columns[index].order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      if (index === -1) {
        console.log(
          `${index} cannot be -1. We are looking for ${orderKey} in our columns.`
        );
      }
      return data;
    },
  },
  methods: {
    sortBy: function (key, columns) {
      this.orderKey = key;
      const cols = columns ? columns : this.columns;
      const index = cols.findIndex((column) => column.key === key);
      if (index !== -1) {
        cols[index].order = cols[index].order * -1;
        return;
      }
      console.log(
        `${index} cannot be -1. We are looking for ${key} in our columns.`
      );
    },
    createColumns(storage_key, defaultColumns) {
      this.local_storage_key = storage_key;
      const columns = this.getColumns();
      if (!columns || !columns.length) {
        return this.clearColumns(defaultColumns);
      }
      return columns;
    },
    getColumns() {
      try {
        let data = JSON.parse(localStorage.getItem(this.local_storage_key));
        return data.filter((item) => {
          return item && item.key && item.name;
        });
      } catch (e) {
        return [];
      }
    },
    clearColumns(defaultColumns) {
      const columns = defaultColumns ? defaultColumns : this.default_columns;
      this.saveColumns(columns);
      return this.getColumns();
    },
    clearColumnsAndRefresh() {
      this.columns = this.clearColumns();
    },
    saveColumns(columns) {
      localStorage.setItem(this.local_storage_key, JSON.stringify(columns));
    },
    addColumn(column) {
      let columns = this.getColumns();
      columns.push(column);
      this.saveColumns(columns);
      this.columns = columns;
    },
    addColumnForm(e) {
      let key = e.target.column_key.value;
      let name = "";
      if (e.target.column_key.selectedOptions.length) {
        name = e.target.column_key.selectedOptions[0].text;
      }
      if (e.target.column_name.value) {
        name = e.target.column_name.value;
      }
      this.addColumn({ key: key, name: name, order: 1 });
      this.columns = this.getColumns();
    },
    removeColumn(item) {
      let columns = this.getColumns();
      columns = columns.filter((column) => column.key !== item.key);
      this.saveColumns(columns);
      this.columns = columns;
    },
  },
};

export default ColumnMixin;
