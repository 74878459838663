import isString from "lodash/isString";

import createAxios from "./createAxios";
import { axiosRequestSuccessInterceptor } from "../../middleware/axios_interceptors";
import openBlobOtherWindow from "./openBlobOtherWindow";
import createElement from "@/helpers/document/createElement";
import prepareAxiosErrors from "../prepareAxiosErrors";

const downloadClient = createAxios();

downloadClient.interceptors.request.use(axiosRequestSuccessInterceptor);

export default async (params) => {
  const requestParams = {
    responseType: "blob",
  };

  if (isString(params)) {
    requestParams.url = params;
  } else {
    Object.assign(requestParams, params);
  }

  try {
    const response = await downloadClient(requestParams);

    const downloadLink = createElement("a");
    const documentType = response.headers["content-type"];
    const contentDisposition = response.headers["content-disposition"];
    const file = new Blob([response.data], { type: documentType });

    file.name = contentDisposition.split("=")[1].replaceAll('"', "");

    openBlobOtherWindow(file, file.name, downloadLink);

    downloadLink.remove();
  } catch (xhrError) {
    if (requestParams.prepareErrors) {
      const errors = prepareAxiosErrors(xhrError, { flatResult: true });
      return Promise.reject(errors);
    }

    return Promise.reject(xhrError);
  }

  return Promise.resolve();
};
