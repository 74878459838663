<template>
  <div>
    <input
      @change="addPhone"
      type="tel"
      v-model="phone_number"
      placeholder="International Code: +XX?X?"
      required="required"
      :pattern="pattern"
      ref="phone_number"
    />
    <button @click.prevent="addPhone">+</button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String],
      default: "+37",
    },
    pattern: String,
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val) {
          this.phone_number = val;
        }
      },
      deep: true,
    },
  },
  data: function () {
    return {
      phone_number: "",
    };
  },
  methods: {
    addPhone() {
      if (this.$refs.phone_number.checkValidity()) {
        this.$emit("change", "+" + this.phone_number.replace(/[^\d]/g, ""));
      }
    },
  },
};
</script>
<style scoped>
input + span {
  padding-right: 30px;
}

input:invalid {
  border: red solid 1px;
}

input:invalid + span:after {
  position: absolute;
  content: "✖";
  padding-left: 5px;
  color: #8b0000;
}

input:valid + span:after {
  position: absolute;
  content: "✓";
  padding-left: 5px;
  color: #009000;
}
</style>
