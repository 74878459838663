<template>
  <ModalContent tag="form" @close="close">
    <template slot="header">
      <h3 v-if="isEditMode">Edit location</h3>
      <h3 v-else>Add location</h3>
    </template>
    <template slot="content">
      <ErrorBanner :message="errors.errorMessage" />
      <label>
        <span class="select-input-header">{{ $t("Type of location") }}:</span>
        <NewSelectInput
          v-model="location.type"
          :placeholder="$t('Select type')"
          :options="availableLocationTypesOptions"
          :error="errors.type"
        />
      </label>
      <BaseInput
        v-model="location.name"
        action-icon="location"
        :label="`${$t('Location name')}:`"
        :error="errors.name"
        @action="showLocationOverlay"
      />
      <BaseInput
        v-model="location.geolocation"
        action-icon="location"
        :label="`${$t('Coordinates')}:`"
        :error="errors.geolocation"
        @action="showLocationOverlay"
      />
      <BaseInput v-model="location.supportPhone" :label="`${$t('Support phone')}:`" :error="errors.support_phone" />
      <BaseInput
        validator="email"
        v-model="location.supportEmail"
        :label="`${$t('Support email')}:`"
        :error="errors.support_email"
      />
      <BaseInput v-model="location.salesPhone" :label="`${$t('Sales phone')}:`" :error="errors.sales_phone" />
      <BaseInput
        validator="email"
        v-model="location.salesEmail"
        :label="`${$t('Sales email')}:`"
        :error="errors.sales_email"
      />
    </template>
    <template slot="controls">
      <Button button-type="light" auto-width @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button type="submit" :processing="processing" auto-width @click.prevent="onSubmit" :disabled="!isObjectChanged">
        {{ $t("Save") }}
      </Button>
    </template>
    <LocationMapOverlay
      v-if="state.showLocationOverlay"
      :disabled-locations="state.disabledLocationsList"
      :location-coords="location.geolocation"
      @cancel="closeLocationOverlay"
      @save="saveLocationFromOverlay"
    />
  </ModalContent>
</template>

<script>
import { computed, ref, onBeforeMount, reactive, nextTick } from "@vue/composition-api";
import clone from "lodash/clone";

import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import LocationMapOverlay from "@/overlays/LocationMapOverlay.vue";
import ModalContent from "@/components/modal/ModalContent.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";

import { camelCaseObjectKeys, snakeCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";
import { useRoute } from "@/helpers/router/routeCompositionAPI";
import validateRequiredFields from "@/helpers/validators/inputs/validateRequiredFields";
import store from "@/store";
import URLS from "@/config/urls";
import useRequest from "@/composables/network/useRequest";
import useIsObjectChanged from "@/composables/useIsObjectChanged";

const REQUIRED_FIELDS = ["geolocation", "name", "salesEmail", "salesPhone", "supportEmail", "supportPhone", "type"];

export default {
  emits: ["close"],

  components: {
    Button,
    BaseInput,
    ErrorBanner,
    LocationMapOverlay,
    ModalContent,
    NewSelectInput,
  },

  setup(props, { emit }) {
    const route = useRoute();
    const { errors, fetch, processing, setErrors, resetErrors } = useRequest({
      errorsFormat: "flat",
    });

    const locationInitialState = ref({});

    const state = reactive({
      showLocationOverlay: false,
      disabledLocationsList: [],
    });

    const location = reactive({
      id: null,
      geolocation: "",
      name: "",
      salesEmail: "",
      salesPhone: "",
      supportEmail: "",
      supportPhone: "",
      type: null,
    });

    const { isObjectChanged } = useIsObjectChanged(locationInitialState, location);

    const countryId = computed(() => route.value.params.countryId);
    const availableLocationTypes = computed(() => store.state.config.portal.availableLocationTypes);
    const isEditMode = computed(() => !!location.id);
    const modalPayload = computed(() => store.state.modal.payload);

    const availableLocationTypesOptions = computed(() =>
      availableLocationTypes.value.map((value) => ({ text: value, value }))
    );

    async function onSubmit() {
      if (processing.value) {
        return;
      }

      resetErrors();

      const errors = validateForm();

      if (errors) {
        return nextTick(() => {
          setErrors(snakeCaseObjectKeys(errors));
        });
      }

      const method = isEditMode.value ? "PUT" : "POST";
      const url = isEditMode.value
        ? URLS.countries.locations.single(countryId.value, location.id)
        : URLS.countries.locations.index(countryId.value);

      const { data } = await fetch({
        url,
        method,
        data: snakeCaseObjectKeys(location),
      });

      if (data) {
        if (modalPayload.value && modalPayload.value.onAccept) {
          await modalPayload.value.onAccept(data);
        }

        close();
      }
    }

    function validateForm() {
      let errors = validateRequiredFields(location, REQUIRED_FIELDS);

      return errors;
    }

    function close() {
      emit("close");
    }

    function closeLocationOverlay() {
      state.showLocationOverlay = false;
    }

    function showLocationOverlay() {
      state.showLocationOverlay = true;
    }

    function saveLocationFromOverlay(data) {
      const { coords, name } = data;

      location.geolocation = coords.join(", ");
      location.name = name || location.name;
    }

    function getInitialManagersData(locations) {
      const initialManagersData = {};
      const prevLocation = camelCaseObjectKeys(locations[locations.length - 1]);
      initialManagersData.salesEmail = prevLocation.salesEmail;
      initialManagersData.salesPhone = prevLocation.salesPhone;
      initialManagersData.supportEmail = prevLocation.supportEmail;
      initialManagersData.supportPhone = prevLocation.supportPhone;

      return initialManagersData;
    }

    onBeforeMount(() => {
      const payloadLocations = modalPayload.value.locations;
      const payloadLocation = camelCaseObjectKeys(modalPayload.value.location);

      if (payloadLocation) {
        Object.assign(location, payloadLocation);
      } else if (payloadLocations && payloadLocations.length > 0) {
        const initialManagersData = getInitialManagersData(payloadLocations);

        Object.assign(location, initialManagersData);
      }

      locationInitialState.value = clone(location);

      if (payloadLocations) {
        state.disabledLocationsList = payloadLocations.filter(({ id }) => id != location.id);
      }
    });

    return {
      errors,
      processing,
      isObjectChanged,

      state,
      location,

      availableLocationTypesOptions,
      isEditMode,

      onSubmit,
      close,
      closeLocationOverlay,
      showLocationOverlay,
      saveLocationFromOverlay,
    };
  },
};
</script>

<style lang="scss" scoped>
.select-input-header {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 18px;
  color: #444545;
}
</style>
