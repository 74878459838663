<template>
  <component :is="iconComponent" :fill="props.fill" v-bind="$attrs" />
</template>

<script>
import { computed } from "@vue/composition-api";

import AddCircleIcon from "./simple/AddCircleIcon.vue";
import ArrowDownIcon from "./simple/ArrowDownIcon.vue";
import ArrowUpIcon from "./simple/ArrowUpIcon.vue";
import ChainIcon from "./simple/ChainIcon.vue";
import CopyIcon from "./simple/CopyIcon.vue";
import CouponIcon from "./simple/CouponIcon.vue";
import DangerIcon from "./simple/DangerIcon.vue";
import DeleteIcon from "./simple/DeleteIcon";
import DocumentDocIcon from "./simple/DocumentDocIcon.vue";
import DotsVerticalIcon from "./simple/DotsVerticalIcon.vue";
import DownloadIcon from "./simple/DownloadIcon.vue";
import EditIcon from "./simple/EditIcon.vue";
import EmailIcon from "./simple/EmailIcon.vue";
import ImportIcon from "./simple/ImportIcon.vue";
import InputCalendarIcon from "./simple/InputCalendarIcon.vue";
import LocationIcon from "./simple/LocationIcon.vue";
import ModalCloseIcon from "./simple/ModalCloseIcon.vue";
import PeopleIcon from "./simple/PeopleIcon.vue";
import ReloadIcon from "./simple/ReloadIcon.vue";
import SearchIcon from "./simple/SearchIcon.vue";
import SmsIcon from "./simple/SmsIcon.vue";
import TooltipIcon from "./simple/TooltipIcon.vue";
import DescriptionListIcon from "./simple/DescriptionListIcon.vue";
import SmallChainIcon from "./simple/SmallChainIcon.vue";
import DeclineIcon from "@/components/icons/simple/DeclineIcon.vue";
import AcceptIcon from "@/components/icons/simple/AcceptIcon.vue";
import VisitMarker from "./simple/VisitMarker.vue";

const ICON_TYPES = {
  "add-circle": AddCircleIcon,
  "arrow-down": ArrowDownIcon,
  desc: ArrowDownIcon,
  asc: ArrowUpIcon,
  "arrow-up": ArrowUpIcon,
  "document-doc": DocumentDocIcon,
  "dots-vertical": DotsVerticalIcon,
  "input-calendar": InputCalendarIcon,
  "modal-close": ModalCloseIcon,
  "small-chain": SmallChainIcon,
  "visit-marker": VisitMarker,
  chain: ChainIcon,
  copy: CopyIcon,
  coupon: CouponIcon,
  danger: DangerIcon,
  delete: DeleteIcon,
  download: DownloadIcon,
  edit: EditIcon,
  email: EmailIcon,
  import: ImportIcon,
  location: LocationIcon,
  people: PeopleIcon,
  reload: ReloadIcon,
  search: SearchIcon,
  sms: SmsIcon,
  decline: DeclineIcon,
  accept: AcceptIcon,
  tooltip: TooltipIcon,
  additional: DescriptionListIcon,
};

export const DEFAULT_ICON_FILL_COLOR = "#999";

export default {
  props: {
    fill: {
      type: String,
      default: "black",
    },
    icon: {
      type: String,
      required: true,
      validator: (val) => Object.keys(ICON_TYPES).includes(val),
    },
  },

  setup(props) {
    const iconComponent = computed(() => ICON_TYPES[props.icon]);

    return {
      props,
      iconComponent,
    };
  },
};
</script>
