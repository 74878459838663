<template>
  <small-layout>
    <div id="demo">
      <h1>Helpdesk</h1>
      <input
        type="text"
        v-model="params.visit_id"
        placeholder="Search by VISIT ID"
        @keyup.enter="fireFetchStatsWithVisit"
      />
      <router-link :to="getVisitsCorrectionLink()" class="link" target="_blank">
        {{ $t("Requests for changes") }} ({{ visitCorrectionRequest }})
      </router-link>
      <form v-if="columnFormToggler" id="columnFormToggler" @submit.prevent="addColumnForm">
        <div>
          <label for="column_name">Имя столбца</label>
          <input id="column_name" type="text" />
        </div>
        <div>
          <label for="column_key">Поле данных столбца</label>
          <select id="column_key">
            <option v-for="column in default_columns" :value="column.key">{{ column.name }}</option>
          </select>
        </div>
        <button type="submit">Сохранить</button>
      </form>
      <fieldset v-if="visitUpdateHolder">
        <legend>To update holder scans <button @click="visitUpdateHolder = false">Back</button></legend>
        <form @submit.prevent="fireUpdateVisitCard">
          <div>
            <label for="up_holder_text">Enter FIO and choose options bellow:</label>
            <input type="text" id="up_holder_text" v-model="params.holder" v-on:keyup="holderSearchTimeout" />
            <select v-model="visit.new_card_id" id="up_holder">
              <option value="visit.card_id">
                Chosen
                {{
                  cards
                    .filter((card) => card.id === visit.card_id)
                    .reduce((acc, card) => (card ? card.holder : "..."), "...")
                }}
              </option>
              <option v-for="card in cards" :value="card.id">{{ card.holder }}</option>
            </select>
            <label for="up_created_at"
              >{{ visit.created_at }}-<input
                type="number"
                min="0"
                max="30"
                v-model.range="visit.new_timedelta"
                id="up_created_at"
              />
              DAYS</label
            >
            <button v-if="holderTimer" @click="holderSearchCancel">stop</button>
          </div>
          <div>
            <button>Confirm</button>
          </div>
        </form>
      </fieldset>
      <fieldset v-if="visitAppUpdateForm">
        <legend>To update app_holder_wait scans <button @click="visitAppUpdateForm = false">Back</button></legend>
        <form @submit.prevent="fireUpdateVisit">
          <div>
            <label for="up_status">Status:</label>
            <select v-model="visit.status" id="up_app_status">
              <option value="app_holder_wait">Choose</option>
              <option value="app_holder_passed">Accepted (with reason: Manually added broken scan)</option>
              <option value="app_holder_reject">Rejected (with reason: Manually reject broken scan)</option>
              <option value="app_holder_unknown">Unknown (with reason: Failed sync app)</option>
            </select>
          </div>
          <div>
            <label for="up_attraction">Attraction:</label>
            <select v-model="visit.attraction_id" id="up_app_attraction">
              <option value="">Choose</option>
              <option v-for="attraction in attractions" :value="attraction.id">
                {{ attraction.name }}|{{ attraction.price }}|
              </option>
            </select>
          </div>
          <div>
            <button>Confirm</button>
          </div>
        </form>
      </fieldset>
      <fieldset v-if="visitUpdateForm">
        <legend>To update supplier_accepting scans <button @click="visitUpdateForm = false">Back</button></legend>
        <form @submit.prevent="fireUpdateVisit">
          <div>
            <label for="up_status">Status:</label>
            <select v-model="visit.status" id="up_status">
              <option value="supplier_accepting">Choose</option>
              <option value="accepted">Accepted (with reason: Manually added broken scan)</option>
              <option value="supplier_reject_wrong_id">Rejected (with reason: Manually reject broken scan)</option>
              <option value="unknown_barcode">Failed scanning (with reason: Manually update broken scan)</option>
            </select>
          </div>
          <div>
            <label for="up_attraction">Attraction:</label>
            <select v-model="visit.attraction_id" id="up_attraction">
              <option value="">Choose</option>
              <option v-for="attraction in attractions" :value="attraction.id">
                {{ attraction.name }}|{{ attraction.price }}|
              </option>
            </select>
          </div>
          <div>
            <button>Confirm</button>
          </div>
        </form>
      </fieldset>
      <form id="search" @submit.prevent="">
        <fieldset>
          <div>
            <label for="params_date">Date:</label>
            <input id="params_date" type="date" v-model="params.date" />
          </div>
          <button type="button" @click="fireFetchStats">Search</button>
          <button type="button" @click="columnFormToggler = !columnFormToggler">Форма добавления столбцов</button>
          <button type="button" @click="clearColumnsAndRefresh">Вернуть Столбцы по умолчанию</button>
          <input
            type="text"
            id="up_holder1_text"
            placeholder="Search by Holder Name"
            v-model="params.holder"
            v-on:keyup="holderSearchTimeout"
          />
          <span v-if="holderTickCount > 0 && holderTickCount != 3">Search will happen in: {{ holderTickCount }}</span>
          <div v-if="params.holder">
            <table>
              <thead>
                <th>holder</th>
                <th>phone</th>
                <th>level</th>
                <th>activated</th>
                <th>id</th>
                <th>company_id</th>
                <th>why deactivated</th>
                <th>Manager</th>
              </thead>
              <tbody>
                <tr v-for="card in cards">
                  <td>
                    <router-link :to="{ name: 'helpdesk_mobile_holder', params: { card_id: card.id } }">{{
                      card.holder
                    }}</router-link>
                  </td>
                  <td>{{ card.phone_number }}</td>
                  <td>{{ card.level }}</td>
                  <td>{{ card.activated }}</td>
                  <td>{{ card.id }}</td>
                  <td>{{ card.company_id }}</td>
                  <td>{{ card.deactivation_cause }}</td>
                  <td>{{ card.user_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </fieldset>
      </form>
      <table>
        <thead>
          <tr>
            <th v-for="(value, column) in gridSummary">
              {{ column }}<button @click="fireMonthlyByStatus(column)">load monthly</button>
            </th>
          </tr>
          <tr>
            <td v-for="(value, column) in gridSummary">{{ value }}</td>
          </tr>
        </thead>
        <thead>
          <tr>
            <th v-for="item in columns" @click.prevent="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              <span class="arrow" :class="order > 0 ? 'asc' : 'dsc'"></span>
              {{ item.name | capitalize }}
              <span class="remove_button" @click.prevent="removeColumn(item)">X</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <td v-for="column in columns">
              <span v-if="column.key === 'id'">
                <router-link :to="{ name: 'helpdesk_mobile_view', params: { visit: entry.id } }">{{
                  entry.id
                }}</router-link>
                <button v-if="isSupportManager" @click="fireUpdatingVisit(entry)">&#9998;</button>
              </span>
              <span v-else-if="column.key === 'prolongating_type'">
                {{ entry.prolongating_type | prolongating_type_label }}
              </span>
              <span v-else-if="column.key === 'holder'">
                <router-link
                  :to="{
                    name: 'helpdesk_mobile_holder_list',
                    query: { card_id: entry.card_id, date: getDate(entry.registered) },
                  }"
                  >{{ entry.holder }}</router-link
                >
                <router-link
                  v-if="entry.changed_photo"
                  :to="{ name: 'helpdesk_mobile_holder', params: { card_id: entry.card_id } }"
                >
                  <img
                    ref="visitMarkerRef"
                    src="../../images/icon_changed_photo.svg"
                    height="19px"
                    width="24px"
                    style="float: right"
                  />
                </router-link>
                <button
                  v-if="isSupportManager && mixSupportCompanies.indexOf(Number(entry.company_id)) !== -1"
                  @click="fireUpdatingVisitHolder(entry)"
                >
                  &#9998;
                </button>
              </span>
              <span v-else>{{ entry[column.key] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </small-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ColumnMixin from "../mixins/ColumnMixin";
import { formatToRFC3339 } from "../middleware/functions";
import { ROUTES_NAMES } from "@/router";
import api_visit from "@/api/visit";
const mixSupportCompanies = String(process.env.VUE_APP_SUPPORT_COMPANIES).split(",").map(parseFloat);
export default {
  mixins: [ColumnMixin],
  data: function () {
    const defaultColumns = [
      { key: "id", name: "Visit ID", order: 1 },
      { key: "prolongating_type", name: "Last Message", order: 1 },
      { key: "registered", name: "Registered", order: 1 },
      { key: "status", name: "Status", order: 1 },
      { key: "company_name", name: "Company", order: 1 },
      { key: "holder", name: "Holder", order: 1 },
      { key: "sup_name", name: "Supplier", order: 1 },
      { key: "sup_type", name: "Type", order: 1 },
      { key: "price", name: "Price Of Entrance", order: 1 },
      { key: "card_price", name: "Price Of Card", order: 1 },
      { key: "reason", name: "Reason", order: 1 },
      { key: "company_type", name: "Type", order: 1 },
      { key: "company_size", name: "Size", order: 1 },
      { key: "card_level", name: "Card Level", order: 1 },
    ].filter((column) => {
      if (this.isSupportManager) {
        return ["card_price", "company_type", "company_size", "card_level"].indexOf(column.key) === -1;
      }
      if (this.isSaleManager) {
        return ["sup_type", "price"].indexOf(column.key) === -1;
      }
      return true;
    });
    const d = new Date();
    return {
      mixSupportCompanies,
      params: {
        date: d.toJSON().substr(0, 10),
        holder: "",
        visit_id: "",
      },
      visitUpdateForm: false,
      visitAppUpdateForm: false,
      visitUpdateHolder: false,
      visit: null,
      cards: [],
      attractions: [],
      orderKey: 0,
      order: 1,
      filterKey: "",
      holderTimer: null,
      holderTickCount: 3,
      holderTick: null,
      columnFormToggler: false,
      default_columns: defaultColumns,
      columns: this.createColumns("helpdesk_index", defaultColumns),
      visitCorrectionRequest: 0,
    };
  },
  computed: {
    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.order;
      }
      let data = this.source;
      if (this.filterKey) {
        const filterKey = this.filterKey && this.filterKey.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (orderKey) {
        this.order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    },
    ...mapGetters({
      gridSummary: "helpdesk/list_summary",
      source: "helpdesk/visit_list",
      isAdmin: "auth/isAdmin",
      isSaleManager: "auth/isSaleManager",
      isSupportManager: "auth/isSupportManager",
    }),
    tags_from_params() {
      let tags = [];
      if (this.params.date) {
        tags.push({ name: "date", value: this.params.date });
      }
      return tags;
    },
    currentMonth() {
      const date = new Date();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const fullYear = date.getFullYear().toString();
      return `${fullYear}-${month}`;
    },
  },
  methods: {
    getDate(str) {
      return formatToRFC3339(new Date(str), true);
    },
    getVisitsCorrectionLink() {
      return { name: ROUTES_NAMES.visits.corrections, query: { period: this.currentMonth } };
    },
    async getVisitsCountForCorrection() {
      await api_visit
        .load_visits_corrections(this.currentMonth)
        .then((response) => {
          const waitingVisitCorrections = response.data.filter((e) => e.status === "waiting");
          this.visitCorrectionRequest = waitingVisitCorrections.length;
        })
        .catch((e) => e);
    },
    holderSearchTimeout() {
      this.holderSearchCancel();
      this.holderTick = setInterval(() => {
        --this.holderTickCount;
      }, 500);
      this.holderTimer = setTimeout(() => {
        this.loadHoldersBy({ card_id: null, holder: this.params.holder }).then((cards) => {
          this.holderSearchCancel();
          this.cards = cards;
        });
      }, 1000);
    },
    holderSearchCancel() {
      if (this.holderTimer) {
        clearTimeout(this.holderTimer);
        clearInterval(this.holderTick);
        this.holderTimer = null;
        this.holderTick = null;
        this.holderTickCount = 3;
      }
    },
    ...mapActions({
      fetchStats: "helpdesk/stats_list",
      fetchMonthlyByStatus: "helpdesk/stats_monthly_list_by_status",
      updateVisit: "helpdesk/manually_update_visit",
      updateVisitCard: "helpdesk/manually_update_visit_card",
      loadAttractionByVisit: "helpdesk/load_attraction_by_visit",
      loadHoldersBy: "helpdesk/load_holder_by",
      loadVisitsCorrections: "helpdesk/load_visits_corrections",
    }),
    async fireUpdatingVisit(visit) {
      this.visit = Object.assign({ new_card_id: visit.card_id, new_timedelta: 0 }, visit);
      if (
        visit.status === "aggregator_invalid_visit_status" ||
        visit.status === "supplier_accepting" ||
        visit.status === "server_accepted" ||
        visit.price === 0
      ) {
        this.attractions = await this.loadAttractionByVisit(visit);
        this.visitUpdateForm = !this.visitUpdateForm;
      }
      if (
        visit.status === "aggregator_invalid_visit_status" ||
        visit.status === "app_holder_wait" ||
        visit.status === "app_holder_timeout" ||
        visit.price === 0
      ) {
        this.attractions = await this.loadAttractionByVisit(visit);
        this.visitAppUpdateForm = !this.visitAppUpdateForm;
      }
    },
    async fireUpdatingVisitHolder(visit) {
      if (mixSupportCompanies.indexOf(Number(visit.company_id)) !== -1) {
        this.cards = await this.loadHoldersBy({ card_id: visit.card_id, holder: null });
        this.visit = Object.assign({ new_card_id: visit.card_id, new_timedelta: 0 }, visit);
        this.visitUpdateHolder = !this.visitUpdateHolder;
        this.params.holder = "";
      }
    },
    async fireUpdateVisitCard() {
      if (this.visit.new_card_id !== this.visit.card_id) {
        this.visit.card_id = this.visit.new_card_id;
        this.visit.timedelta = this.visit.new_timedelta;
        await this.updateVisitCard(this.visit);
        this.visitUpdateHolder = false;
        this.visit = null;
      }
    },
    async fireUpdateVisit() {
      switch (this.visit.status) {
        case "accepted":
        case "app_holder_passed":
          this.visit.reason = "manually_added_broken_scan";
          break;
        case "supplier_reject_wrong_id":
        case "app_holder_reject":
          this.visit.reason = "manually_rejected_broken_scan";
          break;
        case "app_holder_unknown":
          this.visit.reason = "manually_rejected_unknown";
          break;
        default:
          this.visit.reason = "manually_updated_broken_scan";
          break;
      }
      await this.updateVisit(this.visit);
      this.visitUpdateForm = false;
      this.visitAppUpdateForm = false;
      this.visit = null;
    },
    async fireFetchStats(e) {
      await this.fetchStats({ tags: this.tags_from_params });
    },
    async fireFetchStatsWithVisit() {
      await this.fetchStats({ tags: [{ name: "id", value: this.params.visit_id }].concat(this.tags_from_params) });
    },
    async fireMonthlyByStatus(status) {
      const tags = this.tags_from_params;
      await this.fetchMonthlyByStatus([{ name: "status", value: status }].concat(tags));
    },
    sortBy: function (key) {
      this.orderKey = key;
      this.order = this.order * -1;
    },
  },
  async mounted() {
    await this.fetchStats({ tags: this.tags_from_params });
    await this.getVisitsCountForCorrection();
  },
};
</script>

<style lang="scss" scoped>
.link {
  padding: 5px 10px;
  background-color: #444545;
  text-decoration: none;
  color: white;
  border-radius: 4px;
}
</style>
