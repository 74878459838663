<template>
  <div class="fullscreen-overlay fullscreen-overlay__location">
    <div id="map" class="fullscreen-overlay_map"></div>
    <div class="fullscreen-overlay_controls">
      <Button button-type="light" @click="close">{{ $t("Cancel") }}</Button>
      <Button :disabled="!isSaveButtonActive" :processing="state.processing" @click="onSave">{{ $t("Save") }}</Button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onMounted } from "@vue/composition-api";
import mapboxgl from "!mapbox-gl";

import Button from "@/components/buttons/Button.vue";

import { MAPBOX_MAP_STYLES } from "@/config/map";
import request from "@/helpers/request";
import URLS from "@/config/urls";

import "mapbox-gl/dist/mapbox-gl.css";

export default {
  components: {
    Button,
  },

  emits: ["cancel", "save"],

  props: {
    disabledLocations: {
      type: Array,
      default: () => [],
    },
    locationCoords: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      processing: false,

      map: null,
      locationMarker: null,
    });

    const isSaveButtonActive = computed(() => state.locationMarker && !state.processing);

    const allCoords = computed(() => {
      const allCoordsRawList = props.disabledLocations;

      if (props.locationCoords) {
        allCoordsRawList.push({ geolocation: props.locationCoords });
      }

      const allCoordsPreparedList = allCoordsRawList.map(({ geolocation }) =>
        geolocation.split(",").map((val) => val.trim())
      );

      return allCoordsPreparedList;
    });

    const boundsCoords = computed(() => {
      let minLat = undefined;
      let minLng = undefined;
      let maxLat = undefined;
      let maxLng = undefined;

      allCoords.value.forEach(([lat, lng]) => {
        if (minLat === undefined) {
          minLat = lat;
          minLng = lng;
          maxLat = lat;
          maxLng = lng;
        } else {
          minLat = Math.min(minLat, lat);
          minLng = Math.min(minLng, lng);
          maxLat = Math.max(maxLat, lat);
          maxLng = Math.max(maxLng, lng);
        }
      });

      const southWest = [minLat, minLng];
      const northEast = [maxLat, maxLng];

      return [southWest, northEast];
    });

    function initMap() {
      state.map = new mapboxgl.Map({
        accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
        center: [27.552555, 53.9028],
        container: "map",
        style: MAPBOX_MAP_STYLES.streets,
        testMode: process.env.NODE_ENV === "development",
        zoom: 1,
      });

      state.map.on("click", onMapClick);
    }

    function initLocationMarker(locationMarkerCoords) {
      const markerConfig = {
        anchor: "center",
        color: "#F00",
        draggable: true,
      };

      state.locationMarker = new mapboxgl.Marker(markerConfig).setLngLat(locationMarkerCoords).addTo(state.map);
    }

    function initDisabledLocations() {
      props.disabledLocations.forEach(({ geolocation }) => {
        const [lng, lat] = geolocation.split(",").map((val) => val.trim());

        if (lng && lat) {
          const markerConfig = {
            anchor: "center",
            color: "#999",
          };

          new mapboxgl.Marker(markerConfig).setLngLat([lng, lat]).addTo(state.map);
        }
      });
    }

    function onMapClick(e) {
      const { lat, lng } = e.lngLat;

      if (!state.locationMarker) {
        initLocationMarker([lng, lat]);
      }
    }

    async function getLocationInfo() {
      state.processing = true;

      const { lat, lng } = state.locationMarker.getLngLat();
      const url = URLS.map.coordsDetails(lng, lat);
      const locationInfo = {
        coords: [lng, lat],
      };

      try {
        const locationDetails = await request(url);
        const locationFeature = locationDetails.features.find(({ place_type }) => place_type.includes("region"));

        locationInfo.name = locationFeature?.text || null;
      } catch (xhrError) {
        locationInfo.name = null;
      }

      state.processing = false;

      return locationInfo;
    }

    function setMapSettings() {
      const MAP_BOUND_PADDING = 50;
      const MAP_MAX_ZOOM = 8;

      if (allCoords.value.length > 0) {
        state.map.fitBounds(boundsCoords.value, {
          maxZoom: MAP_MAX_ZOOM,
          padding: {
            top: MAP_BOUND_PADDING,
            left: MAP_BOUND_PADDING,
            bottom: MAP_BOUND_PADDING,
            right: MAP_BOUND_PADDING,
          },
        });
      }
    }

    async function onSave() {
      const locationData = await getLocationInfo();

      emit("save", locationData);

      close();
    }

    function close() {
      emit("cancel");
    }

    onMounted(() => {
      initMap();
      setMapSettings();
      initDisabledLocations();

      if (props.locationCoords) {
        const [lng, lat] = props.locationCoords.split(",").map((val) => val.trim());

        if (lng && lat) {
          initLocationMarker([lng, lat]);
        }
      }
    });

    return {
      state,

      isSaveButtonActive,

      close,
      onSave,
    };
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #fff;

  &_controls {
    position: absolute;
    bottom: 48px;
    right: 36px;
    display: flex;
    gap: 20px;
  }

  &_map {
    width: 100%;
    height: 100%;
  }
}
</style>
