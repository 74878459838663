<template>
  <small-layout>
    <div>
      <fieldset>
        <h1>PanelControl</h1>
        <button @click="fireGetAmountOfGyms">Show Amount Of Gyms</button>
        <button @click="fireGetMessageList">List of Messages</button>
        <button @click="toggleCreateMessage = !toggleCreateMessage">Toggle Create Message Form</button>
        <br />
        <button @click="fireCheckPhone">Check Phones (Email)</button>
        <button @click="firePrepareHolders">Prepare Subscriptions to Turn On (Email with errors)</button>
        <button @click="fireSwitchHolders">Switch New Month Subscriptions (1 of Month only - Email)</button>
        <br />
        <button @click="fireGetLockList">List Block List</button>
        <button @click="toggleCreateBlock = !toggleCreateBlock">Toggle Create Block for Holder</button>
        <table v-if="toggleAmountOfGyms">
          <caption>
            Amount of Gyms
          </caption>
          <thead>
            <tr>
              <th>Country</th>
              <th>Amount</th>
            </tr>
            <tr v-for="item in amount_of_gyms_by_country">
              <td>{{ item.name }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </thead>
        </table>
        <table v-if="toggleMessageList">
          <caption>
            Message List
          </caption>
          <thead>
            <tr>
              <th>Title</th>
              <th>Body</th>
              <th>Status</th>
              <th>Command</th>
            </tr>
            <tr v-for="item in messageList">
              <td>{{ item.title }}</td>
              <td>{{ item.body }}</td>
              <td>{{ item.message_status }}</td>
              <td v-if="false === item.autogenerated">
                <button @click="() => fireMessageToSubscribers(item.id)">
                  Send To {{ item.phone ? item.phone : 'All Holders' }} at {{ item.country }}, ID: {{ item.id }}
                </button>
              </td>
            </tr>
          </thead>
        </table>
        <StoreForm v-if="toggleCreateMessage" class="createMessage" @hide="toggleCreateMessage = false"></StoreForm>
        <LockStoreForm v-if="toggleCreateBlock" class="createBlock" @hide="toggleCreateBlock = false"></LockStoreForm>
        <LockList v-if="toggleLockList" :data="lockList"></LockList>
      </fieldset>
    </div>
  </small-layout>
</template>

<style scoped lang="scss">
td {
  word-break: break-word;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import StoreForm from "./messages/StoreForm";
import LockStoreForm from "./lock/StoreForm";
import LockList from "./lock/List";

export default {
  components: {
    StoreForm,
    LockStoreForm,
    LockList,
  },
  data: function () {
    return {
      amount_of_gyms_by_country: [],
      toggleAmountOfGyms: false,
      toggleMessageList: false,
      toggleCreateMessage: false,
      toggleCreateBlock: false,
      toggleLockList: false,
    };
  },
  computed: {
    ...mapGetters({
      messageList: "message/get_message_list",
      lockList: "lock_list/get_lock_list",
    }),
  },
  methods: {
    ...mapActions({
      show_amount_of_gyms: "helpdesk/show_amount_of_gyms",
      get_message_list: "message/get_message_list",
      queue_message_for_all_subsribers: "message/queue_message_for_all_subsribers",
      switch_holders: "control_new_month/switch_holders",
      check_phone: "control_new_month/check_phone",
      prepare_holders: "control_new_month/prepare_holders",
      get_lock_list: "lock_list/get_lock_list",
    }),
    async fireGetAmountOfGyms() {
      this.toggleAmountOfGyms = false;
      this.amount_of_gyms_by_country = await this.show_amount_of_gyms();
      this.toggleAmountOfGyms = true;
    },
    async fireGetMessageList() {
      this.toggleMessageList = false;
      this.messages = await this.get_message_list();
      this.toggleMessageList = true;
    },
    async fireGetLockList() {
      this.toggleLockList = false;
      this.blocks = await this.get_lock_list();
      this.toggleLockList = true;
    },
    async fireMessageToSubscribers(id) {
      this.toggleMessageList = false;
      await this.queue_message_for_all_subsribers(id);
    },
    async fireSwitchHolders() {
      if (confirm("Are you sure? Is it 1 of New Month?")) {
        await this.switch_holders();
      }
    },
    async firePrepareHolders() {
      if (confirm("Are you sure? Did you have changes in invoices?")) {
        await this.prepare_holders();
      }
    },
    async fireCheckPhone() {
      if (confirm("Do you need to check broken phone numbers?")) {
        await this.check_phone();
      }
    },
  },
};
</script>
