<template>
  <ModalContainer v-if="isOpen" @close="close">
    <component :class="modalClassList" :is="currentComponent" @close="close"><slot></slot></component>
  </ModalContainer>
</template>

<script>
import { computed } from "@vue/composition-api";

import ActionModal from "./ActionModal.vue";
import CountryLocationModal from "./modals/CountryLocationModal.vue";
import CreateCouponsGroupModal from "./coupons/CreateCouponsGroupModal.vue";
import MessageModal from "./MessageModal.vue";
import ModalContainer from "./ModalContainer.vue";
import OrderInvoiceDetailsEditModal from "@/views/orders/components/modals/OrderInvoiceDetailsEditModal.vue";
import PortalUserEditModal from "./PortalUserEditModal.vue";
import PriceModelModal from "./modals/PriceModelModal.vue";
import SellerModal from "./modals/SellerModal.vue";
import CancelOderModal from "@/components/modal/modals/CancelOderModal.vue";
import UpdateVisitModal from "@/components/modal/visits/UpdateVisitModal.vue";
import VisitInfoModal from "@/components/modal/visits/VisitInfoModal.vue";
import AddVisitModal from "@/components/modal/visits/AddVisitModal.vue";

import store from "@/store";
import { MODAL_TYPES, MODAL_ACTION_CLOSE, MODAL_GETTER_IS_OPEN } from "@/store/modules/modal";

const MODAL_COMPONENTS = {
  [MODAL_TYPES.action]: ActionModal,
  [MODAL_TYPES.countryLocation]: CountryLocationModal,
  [MODAL_TYPES.coupons.createCouponsGroup]: CreateCouponsGroupModal,
  [MODAL_TYPES.message]: MessageModal,
  [MODAL_TYPES.orders.invoiceDetails]: OrderInvoiceDetailsEditModal,
  [MODAL_TYPES.portalUserEdit]: PortalUserEditModal,
  [MODAL_TYPES.priceModel]: PriceModelModal,
  [MODAL_TYPES.seller]: SellerModal,
  [MODAL_TYPES.orders.cancelOrder]: CancelOderModal,
  [MODAL_TYPES.updateVisit]: UpdateVisitModal,
  [MODAL_TYPES.visitInfo]: VisitInfoModal,
  [MODAL_TYPES.addVisit]: AddVisitModal,
};

const MODAL_CLASS_MODIFIERS = {
  [MODAL_TYPES.priceModel]: ["wide"],
};

export default {
  components: {
    ModalContainer,
  },

  setup() {
    const isOpen = computed(() => store.getters[MODAL_GETTER_IS_OPEN]);
    const modalType = computed(() => store.state.modal.type);
    const modalClassList = computed(() => MODAL_CLASS_MODIFIERS[modalType.value] || "");
    const currentComponent = computed(() => {
      return MODAL_COMPONENTS[modalType.value];
    });

    function close() {
      store.dispatch(MODAL_ACTION_CLOSE);
    }

    return {
      currentComponent,
      isOpen,
      modalClassList,

      close,
    };
  },
};
</script>

<style lang="scss">
div.modal_container,
form.modal_container {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 480px;
  max-height: 100vh;
  overflow: auto;

  &.wide {
    width: 540px;
  }

  .modal_row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.header {
      padding-right: 20px;
      margin-bottom: 30px;
      position: relative;
    }

    &.buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }

    &_import-info {
      display: flex;
      justify-content: space-between;
    }

    .col-flex {
      display: flex;
      align-items: center;
    }

    .col-40 {
      width: 40%;
    }

    .col-50 {
      width: 50%;
    }

    &.file_upload {
      width: 100%;
      height: 146px;
      border: 1px dashed rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      justify-content: center;
      flex-direction: column;
      position: relative;
      cursor: pointer;
      margin-top: 24px;

      &:hover {
        label {
          a {
            text-decoration: none;
          }
        }
      }

      p,
      label {
        margin-top: 1em;
        font-size: 13px;
        font-weight: 400;

        a {
          font-weight: 50;
          color: #0038ff;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      span {
        color: #888;
      }

      p {
        color: #000;
        font-weight: 500;

        a {
          color: #0038ff;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
    }

    p {
      color: #000;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.5em;

      a {
        color: #0038ff;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .btn + .btn {
      margin-left: 20px;
    }

    .modal_close {
      line-height: 0;
      opacity: 0.6;
      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        opacity: 1;
      }
    }
  }

  p {
    display: inline-block;
  }

  a {
    display: inline-block;
    text-decoration: underline;
    margin-left: 8px;
    color: #0038ff;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
