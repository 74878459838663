/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import { isEmpty, isEqual } from "../../middleware/functions";
import api_audit from "../../api/audit";

// initial state
// shape: [{ supplier }]
const state = {
  audit: undefined,
  params: {},
  audit_list: [],
  list_meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 100,
    to: 15,
    total: 0,
  },
};

// getters
const getters = {
  list_meta(state) {
    return state.list_meta;
  },
  list(state) {
    return state.audit_list;
  },
};

// actions
const actions = {
  async get_list({ commit, state }, params) {
    if (isEqual(state.params, params)) {
      return state.audit_list;
    }
    commit("audit_list", {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 100,
        to: 15,
        total: 0,
      },
    });
    if (isEmpty(state.audit_list)) {
      commit("audit_list_cache", params);
    }
    if (isEmpty(state.audit_list)) {
      await actions.load_list({ commit }, params);
    }
  },
  async load_list({ commit }, params) {
    try {
      commit("error/clear", undefined, { root: true });
      const audit_list = await api_audit.audit_list(params);
      commit("audit_list", audit_list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async rollback_audit({ commit }, id) {
    try {
      commit("error/clear", undefined, { root: true });
      const audit = await api_audit.audit_rollback(id);
      commit("audit_add", audit);
      return audit;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_audit({ commit }, id) {
    try {
      commit("error/clear", undefined, { root: true });
      const audit = await api_audit.audit_get(id);
      commit("audit", audit);
      return audit;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async delete_audit({ commit }, audit) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_audit.audit_delete(audit.id);
      commit("audit_delete", audit);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  audit_list_cache(state, params) {
    state.params = params;
    const item = localStorage.getItem("audit_list");
    if (item) {
      const object = JSON.parse(item);
      if (isEqual(object.params, params)) {
        state.audit_list = object.audit_list;
      }
    }
  },
  audit_list(state, list) {
    state.audit_list = list.data;
    state.list_meta = list.meta;
    localStorage.setItem(
      "audit_list",
      JSON.stringify({ audit_list: state.audit_list, params: state.params })
    );
  },
  audit_add(state, item) {
    state.audit_list.push(item);
  },
  audit_delete(state, data) {
    state.audit_list.splice(state.audit_list.indexOf(data), 1);
  },
  audit(state, list) {
    state.audit = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
