import { ref, watch } from "@vue/composition-api";
import isArray from "lodash/isArray";

export default function (key, props) {
  const state = ref([]);

  function varWatcher(newVal) {
    if (newVal) {
      if (isArray(newVal)) {
        state.value = newVal;
      } else {
        state.value = [newVal];
      }
    }
  }

  watch(() => props[key], varWatcher, {
    immediate: true,
  });

  return state;
}
