/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  load_list() {
    return axios
      .get(`/api/helpdesk/affiliates_update/list`)
      .then((response) => {
        return response.data.data;
      });
  },
  create(item) {
    return axios
      .post(`/api/helpdesk/affiliates_update/create`, item)
      .then((response) => {
        return response.data.data;
      });
  },
  download(path) {
    return axios.get(`/api/helpdesk/affiliates_update/download?path=${path}`, {
      responseType: "blob", //Force to receive data in a Blob Format
    });
  },
};
