<template>
  <small-layout
    ><div>
      <h1>Electronic Mail</h1>
      <div>To {{ $route.params.to }}</div>
      <textarea ref="mail"></textarea>
      <button @click="fireSendingMessage($refs.mail)">Send mail</button>
      <form id="search">Search <input name="query" v-model="filterKey" /></form>
      <table>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.name | capitalize }}
              <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <td>{{ entry.id }}</td>
            <td>
              <code>{{ entry.headers }}</code>
            </td>
            <td><div v-html="entry.body"></div></td>
            <td>{{ entry.updated_at }}</td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SortMixin from "../mixins/SortMixin";
export default {
  mixins: [SortMixin],
  computed: {
    ...mapGetters({
      source: "mail/list",
    }),
  },
  methods: {
    createColumns() {
      return [
        { key: "id", name: "ID", order: 1 },
        { key: "headers", name: "headers", order: 1 },
        { key: "body", name: "body", order: 1 },
        { key: "updated_at", name: "updated_at", order: 1 },
      ];
    },
    ...mapActions({
      fetchElectronicMails: "mail/index",
      sendMessage: "mail/send",
    }),
    async fireSendingMessage(domMessage) {
      await this.sendMessage({ message: domMessage.value, to: this.$route.params.to });
      domMessage.value = "";
    },
  },
  mounted() {
    this.fetchElectronicMails(this.$route.params.to);
  },
};
</script>

<style scoped></style>
