<template>
  <small-layout>
    <div>
      <fieldset v-if="isSuperAdmin">
        <legend>card stats</legend>
        <button :disabled="summary_stats_disabled" v-if="isSuperAdmin" @click="fireDownloadSummaryStatistics">
          Summary Statistics by Month
        </button>
        <a ref="download_ref">__</a>
      </fieldset>
      <ul>
        <li>
          <button @click.prevent="copyToClipboard">Copy to Clipboard (to paste in any table)</button>
        </li>
      </ul>
      <select aria-label="Command" :value="params.command" @change="setNewCommand">
        <option value=""></option>
        <option value="new">Create Cards</option>
        <option value="phones">Registration of Phones</option>
        <option value="activate" :disabled="params.todayMonth < params.filter_at">Activate/Deactivate</option>
        <option value="continue_plus_card" :disabled="params.todayMonth < params.filter_at">
          New Cards for Exist Holders
        </option>
      </select>
      <button title="Edit" :disabled="params.command === ''" @click="fireUpdateCards">Upgrade</button>
      <label for="change_company_id">Filter by Company</label>
      <input
        aria-label="find company"
        id="change_company_id"
        type="text"
        :value="params.company"
        v-on:keyup.enter="setParamsCompany"
      />
      <select aria-label="Company" :value="params.company_id" @input="setParamsCompanyId">
        <option value=""></option>
        <option v-for="company in filterCompanies" :value="company.id">{{ company.name }}</option>
      </select>
      <card-update :input="card"></card-update>
      <div v-if="source.length">
        <div>{{ employees_count }} all</div>
        <table ref="claimcards">
          <thead>
            <tr>
              <th v-for="column in columns" @click="sortBy(column.key)" :class="{ active: orderKey === column.key }">
                {{ column.name | capitalize }}
                <span class="arrow" :class="column.order > 0 ? 'asc' : 'dsc'"> </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in filteredSource">
              <td v-for="column in columns">
                <span v-if="column.key === 'holder'">
                  <router-link
                    :to="{ name: 'card_view', params: { company: entry.company_id, card: entry.card_id } }"
                    >{{ entry.holder }}</router-link
                  >
                </span>
                <span v-else-if="column.key === 'card_id'">
                  {{ entry.card_id
                  }}<button
                    v-if="entry.card_id"
                    title="Edit"
                    @click="updatingCard({ id: entry.card_id, company_id: entry.company_id })"
                  >
                    &#9998;
                  </button>
                </span>
                <span v-else>{{ entry[column.key] }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </small-layout>
</template>

<script>
import SortMixin from "../../mixins/SortMixin";
import { mapActions, mapGetters } from "vuex";
import { formatToRFC3339 } from "../../middleware/functions";
import JsonCSV from "vue-json-csv";
import CardUpdate from "../cards/CardUpdate";
import { DataTable, DataColumn } from "vue-teible";

export default {
  name: "ClaimCardIndex",
  components: {
    CardUpdate,
    DataTable,
    DataColumn,
  },
  mixins: [SortMixin],
  watch: {
    $route(to) {
      this.extractParamsAndRun(to);
    },
  },
  async mounted() {
    await this.extractParamsAndRun(this.$route);
  },
  data() {
    const date = new Date();
    return {
      statuses: ["", "new", "continue", "reject"],
      params: {
        company_id: 0,
        company: "",
        filter: "",
        todayMonth: formatToRFC3339(date, false),
        filter_at: formatToRFC3339(date, false),
        command: "",
      },
      card: {},
      summary_stats_disabled: false,
    };
  },
  computed: {
    filterCompanies() {
      let companies = {};
      this.source.map((item) => {
        companies[item.company_id] = item.company;
      });
      companies = Object.getOwnPropertyNames(companies).map((id) => {
        return { id, name: companies[id], search: companies[id].toLowerCase() };
      });
      if (this.params.company) {
        const filterCompany = this.params.company && this.params.company.toLowerCase();
        companies = companies.filter((company) => {
          return company.search.indexOf(filterCompany) > -1;
        });
        if (companies.length === 1) {
          this.params.company_id = companies[0].id;
        } else {
          this.params.company_id = "";
        }
      }
      return companies;
    },
    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.columns[index].order;
      }
      let data = this.source;
      const card_mode_compare = this.card_mode_compare;
      data = data.map((item) => {
        item.changed = card_mode_compare(item);
        return item;
      });
      if (this.params.company_id) {
        const company_id = Number(this.params.company_id);
        data = data.filter(function (row) {
          return row.company_id === company_id;
        });
      }
      if (this.params.command) {
        switch (this.params.command) {
          case "new":
            data = data.filter(function (row) {
              return row.changed[0];
            });
            break;
          case "activate":
            data = data.filter(function (row) {
              return row.changed[1];
            });
            break;
          case "continue_plus_card":
            data = data.filter(function (row) {
              return row.changed[2];
            });
            break;
        }
      }
      if (orderKey && index !== -1) {
        this.columns[index].order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      if (index === -1) {
        console.log(`${index} cannot be -1. We are looking for ${orderKey} in our columns.`);
      }
      return data;
    },
    tags_from_params() {
      let tags = [];
      if (this.params.filter_at) {
        tags.push({ name: "filter_at", value: this.params.filter_at });
      }
      if (this.params.limit) {
        tags.push({ name: "limit", value: this.params.limit });
      }
      if (this.params.offset) {
        tags.push({ name: "offset", value: this.params.offset });
      }
      return tags;
    },
    ...mapGetters({
      isSuperAdmin: "auth/isSuperAdmin",
      source: "companycard/claim_list",
    }),
    employees_count() {
      return this.source.length;
    },
  },
  filters: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  methods: {
    setNewCommand($event) {
      const command = $event.target.value;
      this.params.command = command;
    },
    copyToClipboard() {
      var urlField = this.$refs.claimcards;
      var range = document.createRange();
      range.selectNode(urlField);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      alert("Copied the text: " + window.getSelection().toString().substr(0, 100) + "...");
    },
    setParamsCompany($event) {
      this.params.company = $event.target.value;
      this.$router.push({
        name: "claim_card_list",
        params: { filter_at: this.params.filter_at },
        query: { company: this.params.company },
      });
    },
    setParamsCompanyId($event) {
      this.params.company_id = $event.target.value;
    },
    async fireDownloadSummaryStatistics() {
      const params = { finished_at: this.params.filter_at, ref: this.$refs.download_ref };
      this.summary_stats_disabled = true;
      try {
        await this.downloadSummaryStatistics(params);
      } catch (e) {}
      this.summary_stats_disabled = false;
    },
    card_mode_compare(entity) {
      switch (entity.status) {
        case "reject":
        case "":
          return [0, entity.activated === 1, 0];
        case "continue":
          return [0, entity.activated === 0, 0];
        case "continue_plus_card":
          return [0, entity.activated === 0, 1];
        case "new":
          return [1, entity.activated === 0, 0];
        default:
          return [0, 0, 0];
      }
    },
    async fireUpdateCards() {
      if (this.params.command === "activate" && !this.params.company_id) {
        //return alert("Please, choose company ID first.")
      }
      await this.updateCards({
        command: this.params.command,
        filter_at: this.params.filter_at,
        data: this.filteredSource,
      });
    },
    async extractParamsAndRun(to) {
      if (to.params.filter_at) {
        this.params.filter_at = to.params.filter_at;
      }
      if (to.query.company) {
        this.params.company = to.query.company;
      }
      if (to.query.company_id) {
        this.params.company_id = to.query.company_id;
      }
      if (to.query.limit) {
        this.params.limit = to.query.limit;
      }
      if (to.query.offset) {
        this.params.offset = to.query.offset;
      }
      await this.loadCards(this.tags_from_params);
    },
    createColumns() {
      return [
        { key: "id", name: "id", order: 1 },
        { key: "company", name: "company", order: 1 },
        { key: "holder", name: "holder", order: 1 },
        { key: "status", name: "status", order: 1 },
        { key: "level", name: "level", order: 1 },
        { key: "phone", name: "phone", order: 1 },
        { key: "phone_number", name: "PhoneID", order: 1 },
        { key: "info", name: "info", order: 1 },
        { key: "card_id", name: "card_id", order: 1 },
        { key: "created_at", name: "Created", order: 1 },
        { key: "updated_at", name: "Updated", order: 1 },
      ];
    },
    updatingCard(card) {
      this.card = card;
    },
    ...mapActions({
      loadCards: "companycard/load_claim_list_by_filter",
      updateCards: "companycard/update_claim_list_by_filter",
      downloadSummaryStatistics: "card/download_summary_stats",
    }),
  },
  created() {
    window.name = "_claim_card_list";
  },
};
</script>

<style scoped>
.btn {
  background-color: grey; /* grey */
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
</style>
