<template>
  <fieldset v-if="visitUpdateHolder">
    <legend>To update holder scans <button @click="$emit('toggleForm', false)">Back</button></legend>
    <form @submit.prevent="fireUpdateVisitCard">
      <div>
        <label for="up_holder_text">Enter FIO and choose options bellow:</label>
        <input type="text" id="up_holder_text" v-model="params.holder" v-on:input="holderSearchTimeout" />
        <select v-model="visit.card_id" id="up_holder">
          <option :value="visit.card_id">
            Chosen
            {{
              cards
                .filter((card) => card.id === visit.card_id)
                .reduce((acc, card) => (card ? card.holder : "..."), "...")
            }}
          </option>
          <option v-for="card in cards" :value="card.id">{{ card.holder }}</option>
        </select>
        <input v-model="visit.created_at" type="datetime-local" required />
        <button v-if="holderTimer" @click="holderSearchCancel">stop</button>
      </div>
      <div>
        <label for="up_status">Status:</label>
        <select v-model="visit.status" id="up_status">
          <option value="supplier_accepting">Choose</option>
          <option value="app_holder_wait">Client App Testing</option>
          <option value="accepted">Accepted (with reason: Manually added broken scan)</option>
          <option value="supplier_reject_wrong_id">Rejected (with reason: Manually reject broken scan)</option>
          <option value="unknown_barcode">Failed scanning (with reason: Manually update broken scan)</option>
        </select>
      </div>
      <div>
        <label for="up_attraction">Attraction:</label>
        <select v-model="visit.attraction_id" id="up_attraction">
          <option value="">Choose</option>
          <option v-for="attraction in attractions" :value="attraction.id">
            {{ attraction.name }}|{{ attraction.price }}|
          </option>
        </select>
      </div>
      <div>
        <button>Confirm</button>
      </div>
    </form>
  </fieldset>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["supplier_id", "attractions", "visitUpdateHolder"],
  data() {
    return {
      visit: {
        supplier_id: this.$props.supplier_id,
        status: "supplier_accepting",
        card_id: 0,
        created_at: "",
        attraction_id: 0,
        aggregator: "allsports_super",
        timedelta: 0,
      },
      cards: [],
      params: {
        holder: "",
      },
      holderTimer: null,
      holderTickCount: 3,
      holderTick: null,
    };
  },
  computed: {
    ...mapGetters({
      gridMeta: "comment/list_meta",
      source: "comment/list",
    }),
    sorted_user_source() {
      const users = [...this.user_source];
      users.sort((a, b) =>
        String(a.supplier_name).localeCompare(b.supplier_name, ["ru", "en"], { sensitivity: "base" })
      );
      return users;
    },
  },
  methods: {
    holderSearchTimeout() {
      this.holderSearchCancel();
      this.holderTick = setInterval(() => {
        --this.holderTickCount;
      }, 1000);
      this.holderTimer = setTimeout(() => {
        this.loadHoldersBy({ card_id: null, holder: this.params.holder }).then((cards) => {
          this.holderSearchCancel();
          this.cards = cards;
        });
      }, 1000);
    },
    holderSearchCancel() {
      if (this.holderTimer) {
        clearTimeout(this.holderTimer);
        clearInterval(this.holderTick);
        this.holderTimer = null;
        this.holderTick = null;
        this.holderTickCount = 3;
      }
    },
    async fireUpdatingVisitHolder(visit) {
      this.cards = [];
      this.visit = Object.assign({ new_timedelta: 0 }, visit);
      this.$emit("toggleForm", !this.visitUpdateHolder);
    },
    async fireUpdateVisitCard() {
      switch (this.visit.status) {
        case "accepted":
          this.visit.reason = "manually_added_broken_scan";
          break;
        case "supplier_reject_wrong_id":
          this.visit.reason = "manually_rejected_broken_scan";
          break;
        default:
          this.visit.reason = "manually_updated_broken_scan";
          break;
      }
      await this.updateVisitCard(this.visit);
      this.$emit("toggleForm", false);
      this.visit = {
        supplier_id: this.$props.supplier_id,
        status: "supplier_accepting",
        card_id: 0,
        created_at: 0,
        attraction_id: 0,
        aggregator: "allsports_super",
      };
      this.params.holder = "";
    },
    ...mapActions({
      updateVisitCard: "helpdesk/manually_create_visit_card",
      loadHoldersBy: "helpdesk/load_holder_by",
    }),
  },
};
</script>

<style scoped>
.full-row {
  width: 100%;
}
.crossed {
  text-decoration: line-through;
}
.approved {
  background-color: #42b983;
  color: rgba(255, 255, 255, 0.66);
}
</style>
