import isObject from "lodash/isObject";
import { validateRequiredProps } from "@/helpers/validators";

const STORE_NAMESPACE = "modal";

const ACTION_CLOSE = "actionCloseModal";
const ACTION_OPEN = "actionOpenModal";

const MUTATION_UPDATE_STATE = "mutationUpdateState";

const GETTER_IS_OPEN = "isOpen";

export const MODAL_ACTION_CLOSE = `${STORE_NAMESPACE}/${ACTION_CLOSE}`;
export const MODAL_ACTION_OPEN = `${STORE_NAMESPACE}/${ACTION_OPEN}`;

export const MODAL_GETTER_IS_OPEN = `${STORE_NAMESPACE}/${GETTER_IS_OPEN}`;

export const MODAL_TYPES = {
  action: Symbol("action"),
  countryLocation: Symbol("countryLocation"),
  coupons: {
    createCouponsGroup: Symbol("createCouponGroup"),
  },
  updateVisit: Symbol("updateVisit"),
  visitInfo: Symbol("visitInfo"),
  addVisit: Symbol("addVisit"),
  message: Symbol("message"),
  orders: {
    invoiceDetails: Symbol("orders:invoiceDetails"),
    cancelOrder: Symbol("orders:cancelOrder"),
  },
  portalUserEdit: Symbol("portalUserEdit"),
  priceModel: Symbol("priceModel"),
  seller: Symbol("seller"),
};

export const MODAL_MODES = {
  [MODAL_TYPES.portalUserEdit]: {
    add: Symbol("add"),
    edit: Symbol("edit"),
  },
  [MODAL_TYPES.orders.invoiceDetails]: {
    add: Symbol("add"),
    edit: Symbol("edit"),
  },
  [MODAL_TYPES.priceModel]: {
    copy: Symbol("copy"),
    full: Symbol("full"),
    short: Symbol("short"),
  },
};

const MODAL_STATUSES = {
  closed: Symbol("closed"),
  opened: Symbol("opened"),
};

const MODAL_TYPES_LIST = Object.values(MODAL_TYPES).flatMap((type) => {
  if (isObject(type)) {
    return Object.values(type);
  }

  return type;
});

export default {
  namespaced: true,

  state: {
    status: MODAL_STATUSES.closed,
    type: null,
    mode: null,
    payload: null,
  },

  getters: {
    [GETTER_IS_OPEN](state) {
      return state.status === MODAL_STATUSES.opened;
    },
  },

  mutations: {
    [MUTATION_UPDATE_STATE](state, payload = {}) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
  },

  actions: {
    [ACTION_CLOSE]({ commit }) {
      commit(MUTATION_UPDATE_STATE, {
        status: MODAL_STATUSES.closed,
        type: null,
        payload: null,
      });
    },
    [ACTION_OPEN]({ commit }, { type, mode, payload } = {}) {
      const allPropsPassed = validateRequiredProps(ACTION_OPEN, type);
      const isModalTypeDefined = MODAL_TYPES_LIST.includes(type);
      const isModalModeDefined =
        !mode || Object.values(MODAL_MODES[type]).includes(mode);

      if (!isModalTypeDefined) {
        throw `Modal type "${type.toString()}" is not defined`;
      }

      if (!isModalModeDefined) {
        throw `Modal mode "${mode.toString()}" is not defined`;
      }

      if (allPropsPassed) {
        commit(MUTATION_UPDATE_STATE, {
          status: MODAL_STATUSES.opened,
          type,
          mode: mode || null,
          payload: payload || null,
        });
      }
    },
  },
};
