export { default as validateRequiredProps } from "./validateRequiredProps";

import { email, number, phone, byUnnNumber, domain } from "./inputs";

export const VALIDATION_ERRORS = {
  invalidCharacters: "invalid_characters",
  invalidFileExtension: "invalid_file_extension",
  invalidFormat: "invalid_format",
  invalidLocaleValue: "invalid_locale_value",
  invalidSubscriptionType: "invalid_subscription_type",
  maxLength: "max_length",
  minLength: "min_length",
  requiredField: "required_field",
  requiredFormFields: "required_form_fields",
  invalidByUnnNumber: "invalid_by_unn_number",
  invalidDomain: "invalid_domain",
};

export const ERRORS_MESSAGES = {
  [VALIDATION_ERRORS.invalidCharacters]:
    "The field contains forbidden characters",
  [VALIDATION_ERRORS.invalidFormat]: "The data format is incorrect",
  [VALIDATION_ERRORS.invalidLocaleValue]:
    "The selected locale value is invalid",
  [VALIDATION_ERRORS.invalidSubscriptionType]: "Invalid subscription type",
  [VALIDATION_ERRORS.maxLength]: "Exceeded the maximum field length",
  [VALIDATION_ERRORS.minLength]: "Minimum number of characters is {n}",
  [VALIDATION_ERRORS.requiredField]: "Required field",
  [VALIDATION_ERRORS.requiredFormFields]:
    "Fill in all required fields of the form",
  [VALIDATION_ERRORS.invalidByUnnNumber]:
    "VAT must contain only numbers and be nine characters long",
  [VALIDATION_ERRORS.invalidDomain]:
    "The domain name must begin and end with an alphanumeric character and contain only letters, numbers, hyphens and periods. Hyphens can only appear within a domain name, but cannot be the first or last characters. The last part of the domain name (top-level domain) must consist of only letters and be longer than two characters.",
};

export const validators = {
  email,
  number,
  phone,
  byUnnNumber,
  domain,
};

export default {
  VALIDATION_ERRORS,
  validators,
};
