<template>
  <small-layout
    ><div>
      <h1>Create</h1>
      <form @submit.prevent="fireCreateCompany">
        <fieldset>
          <div>
            <label for="company_name">Имя:</label>
            <input id="company_name" type="text" v-model="company.name" />
          </div>
          <div>
            <label for="company_size">Размер:</label>
            <input id="company_size" type="number" v-model="company.size" />
          </div>
          <div>
            <label for="company_industry">Тип:</label>
            <input id="company_industry" type="text" v-model="company.industry_type" />
          </div>
          <div>
            <label for="operator_email">Operator Email</label>
            <input id="operator_email" size="20" type="text" v-model="company.operator_email" />
          </div>
          <div>
            <label for="params_city">City</label>
            <select id="params_city" v-model="company.city">
              <option v-for="city in cities" :value="city">{{ city }}</option>
            </select>
          </div>
          <div v-if="manager_list && manager_list.length > 1">
            <label for="params_manager_id">manager</label>
            <select id="params_manager_id" v-model="company.manager_id">
              <option v-for="manager in manager_list" :value="manager.id">{{ manager.name }}</option>
            </select>
          </div>
          <div v-if="timezones && timezones.length > 1">
            <label for="params_timezone">Timezone</label>
            <select id="params_timezone" v-model="company.timezone">
              <option v-for="timezone in timezones" :value="timezone">{{ timezone }}</option>
            </select>
          </div>
          <div v-if="locales && locales.length > 1">
            <label for="params_locale">locale</label>
            <select id="params_locale" v-model="company.locale">
              <option v-for="locale in locales" :value="locale">{{ locale }}</option>
            </select>
          </div>
          <div v-if="pricing_policies && pricing_policies.length > 1">
            <label for="params_pricing">{{ $t("pricing_id in Orders") }}</label>
            <select id="params_pricing" v-model="company.pricing_id">
              <option v-for="pricing in pricing_policies" :value="pricing" :key="`company-option-pricing-${pricing}`">
                {{ pricing }}
              </option>
            </select>
          </div>
          <div>
            <label for="params_invoice_mode">{{ $t("Invoice Mode") }}</label>
            <select id="params_invoice_mode" v-model="company.invoice_mode">
              <option v-for="invoice_mode in invoice_modes" :value="invoice_mode" :key="`company-option-invoice_mode-${invoice_mode}`">
                {{ invoice_mode }}
              </option>
            </select>
          </div>
          <div>
            <label for="contact_info">Контакты (Каждая строка: Позиция, ФИО, контакты)</label>
            <textarea rows="4" cols="50" v-model="company.contact_info" id="contact_info"></textarea>
          </div>
          <fieldset>
            <legend>детали для Инвойса и Акта выполненных работ</legend>
            <div>
              <label for="unp">UNN</label>
              <input type="number" v-model="company.unp" id="unp" @blur="findRegisteredName()" />
              <button @click.prevent="findRegisteredName">Lookup Name</button>
            </div>
            <div>
              <label for="registered_name">Legal Name</label>
              <input type="text" v-model="company.registered_name" style="width: 100%" id="registered_name" />
            </div>
            <div>
              <label for="allow_update_period">{{ $t("columns.allow_update_period.title") }}</label>
              <input
                type="number"
                min="0000"
                max="3131"
                v-model="company.allow_update_period"
                id="allow_update_period"
              />
            </div>
            <div>
              <label for="hr_portal_only">{{ $t("columns.hr_portal_only.title") }}</label>
              <CheckboxBoolean v-model="company.hr_portal_only" id="hr_portal_only" />
            </div>
            <div>
              <label for="contract_started_at">Заключен:</label>
              <input id="contract_started_at" type="date" v-model="company.contract_started_at" />
            </div>
            <div>
              <label for="contract_finished_at">Истекает:</label>
              <input id="contract_finished_at" type="date" v-model="company.contract_finished_at" />
            </div>
            <div v-if="isDefaultLoaded">
              <label for="number_agreement_it">Номер и дата соглашения IT</label>
              <input
                id="number_agreement_it"
                style="width: 100%"
                type="text"
                maxlength="191"
                v-model="company.number_agreement"
              />
            </div>
            <div v-if="isDefaultLoaded">
              <label for="number_agreement_service">Номер и дата соглашения Service</label>
              <input
                id="number_agreement_service"
                style="width: 100%"
                type="text"
                maxlength="191"
                v-model="company.number_agreement_service"
              />
            </div>
            <div>
              <label for="legal_address">Benefiiary Legal Address</label>
              <input
                id="legal_address"
                style="width: 100%"
                type="text"
                maxlength="191"
                v-model="company.legal_address"
              />
            </div>
            <div>
              <label for="legal_contact_phone">Benefiiary Legal Contact Phone</label>
              <input
                id="legal_contact_phone"
                style="width: 100%"
                type="tel"
                maxlength="30"
                v-model="company.legal_contact_phone"
              />
            </div>
            <div>
              <label for="bank_name">Benefiiary Bank</label>
              <input id="bank_name" style="width: 100%" type="text" maxlength="75" v-model="company.bank_name" />
            </div>
            <div>
              <label for="bik">Benefiiary BIK</label>
              <input id="bik" style="width: 100%" type="text" maxlength="11" v-model="company.bik" />
            </div>
            <div>
              <label for="accnum">Benefiiary Account</label>
              <input id="accnum" style="width: 100%" type="text" maxlength="75" v-model="company.accnum" />
            </div>
            <div>
              <label for="appendix">Дата приложения 1/2/3</label>
              <input id="appendix" style="width: 100%" type="text" maxlength="191" v-model="company.appendix" />
            </div>
            <div>
              <label for="official_representative">Offisial representative (optional)</label>
              <input
                id="official_representative"
                style="width: 100%"
                type="text"
                maxlength="191"
                v-model="company.official_representative"
              />
            </div>
            <div>
              <label for="power_of_attorney_representative">Power of Attorney representative (optional)</label>
              <input
                id="power_of_attorney_representative"
                style="width: 100%"
                type="text"
                maxlength="191"
                v-model="company.power_of_attorney_representative"
              />
            </div>
            <div>
              <label for="aggregator">signed with Aggregator</label>
              <select id="aggregator" v-model="company.aggregator">
                <option value="allsports">ALLSPORTS (20% VAT)</option>
                <option value="allsports_super">SUPER (NO VAT)</option>
              </select>
            </div>
            <div>
              <label for="region_card_price">Region price</label>
              <input id="region_card_price" style="width: 100%" type="number" v-model="company.region_card_price" />
            </div>
            <div>
              <label for="silver_card_price">Silver price</label>
              <input id="silver_card_price" style="width: 100%" type="number" v-model="company.silver_card_price" />
            </div>
            <div>
              <label for="gold_card_price">Gold price</label>
              <input id="gold_card_price" style="width: 100%" type="number" v-model="company.gold_card_price" />
            </div>
            <div>
              <label for="platinum_card_price">Platinum price</label>
              <input id="platinum_card_price" style="width: 100%" type="number" v-model="company.platinum_card_price" />
            </div>
            <div>
              <label for="google_sheet_id">Google Sheet</label>
              <input id="google_sheet_id" style="width: 100%" type="text" v-model="company.google_sheet_id" />
            </div>
          </fieldset>
          <div>
            <label for="additional">Additional</label>
            <textarea rows="4" cols="50" v-model="company.additional" id="additional"></textarea>
          </div>
          <div>
            <label for="address">Post Address</label>
            <textarea rows="4" cols="50" v-model="company.address" id="address"></textarea>
          </div>
          <div>
            <button>Add</button>
          </div>
        </fieldset>
      </form>
    </div></small-layout
  >
</template>

<script>
import { mapActions } from "vuex";
import CheckboxBoolean from "@/components/Jetstream/CheckboxBoolean";

import config from "../../config";
import { ROUTES_NAMES } from "@/router";

export default {
  components: { CheckboxBoolean },
  data() {
    const country = this.$route.params.country;
    const localConfig = config.supported_countries[country];
    return {
      cities: localConfig.cities,
      timezones: localConfig.timezones,
      locales: localConfig.locales,
      pricing_policies: localConfig.pricing_policies,
      invoice_modes: config.invoice_modes,
      company: {
        aggregator: "allsports",
        city: localConfig.cities[0],
        timezone: localConfig.timezones[0],
        locale: localConfig.locales[0],
        country: country,
        pricing_id: localConfig.pricing_policies[0],
        invoice_mode: config.invoice_modes[0],
      },
      manager_list: [],
      isDefaultLoaded: false,
    };
  },
  methods: {
    ...mapActions({
      createCompany: "company/add_company",
      companyDefault: "company/load_company_default",
      unnSearch: "company/unn_search",
      managers: "user/managers",
    }),
    async findRegisteredName() {
      this.company.registered_name = await this.unnSearch(this.company);
    },
    async fireCreateCompany() {
      await this.createCompany(this.company);
      this.$router.push({ name: ROUTES_NAMES.companies.list });
    },
  },
  async created() {
    window.name = "_new_company";
    this.manager_list = await this.managers();
  },
  async mounted() {
    const defaultCompany = await this.companyDefault();
    this.company.number_agreement = defaultCompany.number_agreement;
    this.company.number_agreement_service = defaultCompany.number_agreement_service;
    this.company.allow_update_period = defaultCompany.allow_update_period;
    this.company.hr_portal_only = defaultCompany.hr_portal_only;
    this.isDefaultLoaded = true;
  },
};
</script>

<style scoped></style>
