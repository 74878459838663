<template>
  <div v-if="text" class="tooltip-component">
    <Icon :fill="color" :icon="icon" />
    <p v-html="text" class="tooltip-component_text" :class="{ 'tooltip-component_text_right': right }"></p>
  </div>
</template>

<script>
import Icon from "@/components/icons/Icon.vue";

export default {
  components: { Icon },

  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
      default: "#346AED",
    },
    icon: {
      type: String,
      default: "tooltip",
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.tooltip-component {
  position: relative;
  display: inline;

  & &_text {
    display: none;
    position: absolute;
    right: 0;
    width: 178px;
    padding: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.25em;
    color: #000000;
    border: 1px solid #9c9c9c;
    border-radius: 8px;
    background-color: #fafafa;
    z-index: 1;
    overflow-wrap: break-word;

    a {
      color: #346aed;

      &:hover {
        text-decoration: underline;
      }
    }
    &_right {
      left: 0;
    }
  }

  &:hover {
    .tooltip-component_text {
      display: block;
    }
  }
}
</style>
