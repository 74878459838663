/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_attraction from "../../api/attractions";

// initial state
// shape: [{ supplier }]
const state = {};

// getters
const getters = {};

// actions
const actions = {
  async supplier_cover_list(
    { commit },
    { started_at, finished_at, exceed, exclude_companies }
  ) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_attraction.supplier_cover_list(
        started_at,
        finished_at,
        exceed,
        exclude_companies
      );
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async cover_list({ commit }, { started_at, finished_at }) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_attraction.cover_list(started_at, finished_at);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async cover_pivot({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_attraction.cover_pivot();
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
