<template>
  <fieldset>
    <legend>Acts <button @click="fireLoadingActs">Reload From Server</button></legend>
    <a ref="act_download_ref">__</a>

    <div v-if="source.length">
      <form id="search">Local Search <input name="query" v-model="filterKey" /></form>
      <table>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.name | capitalize }}
              <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
            </th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="act in filteredSource">
            <td>{{ act.number }}</td>
            <td>{{ act.aggregator }}</td>
            <td>{{ act.started_at | parseDate }}</td>
            <td>{{ act.finished_at | parseDate }}</td>
            <td>{{ act.name }}</td>
            <td>{{ act.status }}</td>
            <td>
              <button @click="download(act)">Download</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </fieldset>
</template>

<script>
import { parseDate } from "../../helpers";
import { mapActions, mapGetters } from "vuex";
import SortMixin from "../../mixins/SortMixin";
const today = new Date();
function addZ(n) {
  return (n < 10 ? "0" : "") + n;
}
const date = today.getFullYear() + "-" + addZ(today.getMonth() + 1) + "-" + addZ(today.getDate());
export default {
  mixins: [SortMixin],
  props: ["route_supplier"],
  computed: {
    ...mapGetters({
      source: "supplieract/act_list",
    }),
  },
  filters: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    parseDate,
  },
  methods: {
    createColumns() {
      return [
        { key: "number", name: "Act N", order: 1 },
        { key: "aggregator", name: "Компания", order: 1 },
        { key: "started_at", name: "From", order: 1 },
        { key: "finished_at", name: "Till", order: 1 },
        { key: "name", name: "Name", order: 1 },
        { key: "status", name: "Status", order: 1 },
      ];
    },
    ...mapActions({
      downloadAct: "supplieract/download_act",
      fetchActs: "supplieract/get_act_list_by_supplier",
      loadActs: "supplieract/load_act_list_by_supplier",
    }),
    async download(act) {
      await this.downloadAct({ act, ref: this.$refs.act_download_ref });
    },
    async fireLoadingActs() {
      await this.loadActs(this.route_supplier);
    },
    async fireFetchingActs() {
      await this.fetchActs(this.route_supplier);
    },
  },
  mounted() {
    this.fireFetchingActs();
  },
};
</script>

<style scoped>
.existing-event .time {
  right: 50px;
}
</style>
