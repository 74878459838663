export function buildQueryFromObject(params) {
  let list = [];
  if (params) {
    Object.getOwnPropertyNames(params).forEach((name) => {
      list.push(name + "=" + params[name]);
    });
  }
  return list.length > 0 ? "?" + list.join("&") : "";
}

export function buildFormDataFromObject(params) {
  const formData = new FormData();
  if (params) {
    Object.getOwnPropertyNames(params).forEach((name) => {
      formData.append(name, params[name]);
    });
  }
  return formData;
}
