<template>
  <jet-form-section @click_overlay="hide">
    <template #title> Search Suppliers </template>

    <template #description> Find suppliers by name, activated status and date of last update. </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="id" value="Supplier ID" />
        <jet-input id="id" type="text" class="mt-1 block w-full" v-model="form.id" autofocus />
        <jet-input-error :message="form.errors.id" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="matchByName" value="Supplier MatchByName" />
        <jet-input id="matchByName" type="text" class="mt-1 block w-full" v-model="form.matchByName" autofocus />
        <jet-input-error :message="form.errors.matchByName" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="activated" value="Supplier Activated" />
        <jet-checkbox
          id="activated"
          class="mt-1 block w-full"
          @checked="&quot;1&quot;;"
          v-model="form.activated"
          autofocus
        />
        <jet-input-error :message="form.errors.activated" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="updated_at" value="Supplier Updated_at" />
        <jet-input id="updated_at" type="date" class="mt-1 block w-full" v-model="form.updated_at" autofocus />
        <jet-input-error :message="form.errors.updated_at" class="mt-2" />
      </div>

      <div class="col-span-6 sm:col-span-4">
        <jet-label for="city" value="City" />
        <select id="city" class="mt-1 block w-full" v-model="form.city" autofocus>
          <option v-for="city in cities" :value="city">{{ city }}</option>
        </select>
        <jet-input-error :message="form.errors.city" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <jet-button
        @click="applyFilter"
        type="button"
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        Find
      </jet-button>
      <jet-button
        @click="firePrintSuppliers"
        type="button"
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        Printing Page A5
      </jet-button>
      <jet-button
        v-if="['cy', 'lt'].indexOf(country) !== -1"
        @click="fireDownloadPdfListOfA7Stickers"
        type="button"
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        Printing Page A7
      </jet-button>
      <jet-button
        v-if="['by'].indexOf(country) !== -1"
        @click="downloadA5QrCode"
        type="button"
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        download A5 QRCode only
      </jet-button>
      <a ref="download_image_ref">Repeat &#8595; </a>
      <span v-if="downloadCounter">{{ downloadMessage }}</span>
    </template>
  </jet-form-section>
</template>

<script>
import JetButton from "../Jetstream/Button";
import JetFormSection from "../Jetstream/FormSection";
import JetInput from "../Jetstream/Input";
import JetCheckbox from "../Jetstream/Checkbox";
import JetInputError from "../Jetstream/InputError";
import JetLabel from "../Jetstream/Label";
import config from "../../config";
import { mapActions, mapGetters } from "vuex";
import openBlobOtherWindow from "@/middleware/openBlobOtherWindow";
import { NUM_RANGE_REG, ENUMERATION_REG } from "@/config/validators";
import downloadZip from "@/helpers/request/downloadZip";
import api_supplier from "@/api/supplier";

export default {
  components: {
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetCheckbox,
  },

  data() {
    return {
      countries: config.countries,
      form: {
        id: "",
        matchByName: "",
        activated: 0,
        updated_at: "2019-01-01",
        city: "",
        errors: [],
      },
      downloadCounter: 0,
    };
  },

  computed: {
    ...mapGetters({
      country: "auth/country",
    }),
    cities() {
      return config.supported_countries[this.country].cities;
    },
    downloadMessage() {
      return this.downloadCounter ? `Successfully downloaded: ${this.downloadCounter}` : "";
    },
  },

  methods: {
    ...mapActions({
      getQrBlobSupplier: "suppliers/response_qrcode_supplier",
      fetchSuppliers: "suppliers/load_supplier_list",
    }),
    async applyFilter() {
      const form = Object.assign(
        {
          country: this.country,
        },
        this.form
      );
      const output = await this.fetchSuppliers(form);
      if (output && output.errors) {
        this.form.errors = output.errors;
      }
    },
    firePrintSuppliers() {
      this.downloadCounter = 0;
      const form = Object.assign(
        {
          country: this.country,
          addToken: true,
          format: "A5",
        },
        this.form
      );
      this.$router.push({ name: "qrcode_supplier_list", query: form });
    },
    async fireDownloadPdfListOfA7Stickers() {
      this.downloadCounter = 0;
      const form = Object.assign(
        {
          contry: this.country,
          addToken: true,
          format: "A7",
        },
        this.form
      );
      this.$router.push({ name: "qrcode_supplier_list", query: form });
    },
    async downloadA5QrCode() {
      this.downloadCounter = 0;
      var id = this.form.id;
      var format = "A5_transparent";
      var form = { ...this.form, id: "" };
      var source = await api_supplier.supplier_list({ ...form, country: this.country });

      if (NUM_RANGE_REG.test(id)) {
        const parsedRange = id.split("-");
        const startId = parseInt(parsedRange[0]);
        const endId = parseInt(parsedRange[1]);

        const filteredSource = source.filter((item) => item.id >= startId && item.id <= endId);
        const { responses, filenames } = await this.getMultipleA5QrCode(filteredSource, format);

        downloadZip(responses, filenames, this.$refs.download_image_ref);
      } else if (ENUMERATION_REG.test(id)) {
        const parsedEnum = id.split(",");

        const filteredSource = source.filter((item) => parsedEnum.includes(`${item.id}`));
        const { responses, filenames } = await this.getMultipleA5QrCode(filteredSource, format);

        downloadZip(responses, filenames, this.$refs.download_image_ref);
      } else {
        const qrCode = await this.getQrCodeInstance(id, format);
        if (!qrCode) {
          return alert("No Photo generated!");
        }
        openBlobOtherWindow(qrCode.file, qrCode.name, this.$refs.download_image_ref);
      }
    },
    async getMultipleA5QrCode(source, format) {
      var responses = [];
      var filenames = [];
      for (let i = 0; i < source.length; i++) {
        if (i && i % 10 === 0) {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          const qrCode = await this.getQrCodeInstance(source[i].id, format);

          if (qrCode) {
            responses.push(qrCode.file);
            filenames.push(qrCode.name);
          }
        } else {
          const qrCode = await this.getQrCodeInstance(source[i].id, format);

          if (qrCode) {
            responses.push(qrCode.file);
            filenames.push(qrCode.name);
          }
        }
      }

      return {
        responses,
        filenames,
      };
    },

    async getQrCodeInstance(id, format) {
      var response = await this.getQrBlobSupplier({ id, format });

      if (response && response.status === 200 && response.data.size !== 0) {
        var file = response.data;
        var contentDisposition = response.headers["content-disposition"];
        var fileNameMatches = contentDisposition.match(/filename=(.+);/);
        var name = "sticker.svg";
        if (fileNameMatches && fileNameMatches[1]) {
          name = fileNameMatches[1];
        }
        if (name.indexOf(".") === -1) {
          name = name + ".svg"; // TODO: only while we support previous SVG stickers. To remove.
        }
        this.downloadCounter++;
        return { name, file };
      }
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
