var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalContent',{attrs:{"tag":"form"},on:{"close":_vm.close}},[_c('template',{slot:"header"},[_c('h3',[_vm._v(_vm._s(_vm.$t("Add visit")))])]),_c('template',{slot:"content"},[_c('ErrorBanner',{attrs:{"message":_vm.errorMessage}}),[_c('DatePickerInput',{attrs:{"error":_vm.visitState.errors.created_at,"label":_vm.$t('Date'),"placeholder":_vm.$t('yyyy-mm-dd-hh-mm-ss'),"clearable":false,"type":"datetime"},model:{value:(_vm.visitState.created_at),callback:function ($$v) {_vm.$set(_vm.visitState, "created_at", $$v)},expression:"visitState.created_at"}}),_c('SearchInput',{attrs:{"label":_vm.$t('Holder'),"error":_vm.visitState.errors.card_id,"search-field":"holder","search-url":_vm.getHolderSearchUrl,"model-field":"id","item-text-field":function (ref) {
	var id = ref.id;
	var holder = ref.holder;

	return (id + " " + holder);
}},model:{value:(_vm.visitState.card_id),callback:function ($$v) {_vm.$set(_vm.visitState, "card_id", $$v)},expression:"visitState.card_id"}}),_c('SearchInput',{ref:"refSupplierInput",attrs:{"label":_vm.$t('Supplier'),"error":_vm.visitState.errors.supplier_id,"search-params":_vm.supplierSearchParams,"search-url":_vm.URLS.suppliers.search,"search-field":"matchByName","model-field":"id","item-text-field":function (ref) {
	var id = ref.id;
	var name = ref.name;

	return (id + " " + name);
}},on:{"select":_vm.onSupplierChange},model:{value:(_vm.visitState.supplier_id),callback:function ($$v) {_vm.$set(_vm.visitState, "supplier_id", $$v)},expression:"visitState.supplier_id"}}),_c('NewSelectInput',{attrs:{"placeholder":_vm.$t('Select status'),"error":_vm.visitState.errors.status,"label":((_vm.$t('Status')) + ":"),"options":_vm.VISIT_STATUSES_OPTIONS,"max-options-visible":4},model:{value:(_vm.visitState.status),callback:function ($$v) {_vm.$set(_vm.visitState, "status", $$v)},expression:"visitState.status"}}),_c('NewSelectInput',{attrs:{"placeholder":_vm.attractionSelectPlaceholder,"error":_vm.visitState.errors.attraction_id,"label":((_vm.$t('Attraction')) + ":"),"options":_vm.attractionsOptions,"disabled":!_vm.isSupplierSelectedByName},model:{value:(_vm.visitState.attraction_id),callback:function ($$v) {_vm.$set(_vm.visitState, "attraction_id", $$v)},expression:"visitState.attraction_id"}})]],2),_c('template',{slot:"controls"},[_c('Button',{attrs:{"button-type":"light","auto-width":""},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c('Button',{attrs:{"type":"submit","processing":_vm.processing,"auto-width":"","disabled":!_vm.isObjectChanged},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }