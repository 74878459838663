<template>
  <small-layout>
    <div>
      <h1>Holder</h1>
      <form id="search" @submit.prevent="">
        <fieldset>
          <div>
            <label for="query_date">Date:</label>
            <input id="query_date" type="date" v-model="query.date" />

            <input
              type="text"
              id="up_holder1_text"
              placeholder="Search by Holder Name"
              v-model="query.holder"
              v-on:keyup="holderSearchTimeout"
            />
            <input
              type="tel"
              inputmode="tel"
              id="up_holder1_phone"
              placeholder="Search by Phone Number"
              v-model="phone"
              v-on:keyup="holderSearchTimeout"
            />
            <span v-if="holderTickCount > 0 && holderTickCount !== 3"
              >Search will happen in: {{ holderTickCount }}</span
            >
            <div v-if="query.holder || phone">
              <table>
                <colgroup>
                  <col span="1" width="25%" />
                  <col span="1" width="25%" />
                  <col span="1" width="25%" />
                  <col span="1" width="25%" />
                </colgroup>
                <thead>
                  <th>holder</th>
                  <th>phone</th>
                  <th>level</th>
                  <th>activated</th>
                </thead>
                <tbody>
                  <tr v-for="card in cards">
                    <td>
                      <router-link :to="{ name: 'helpdesk_mobile_holder', params: { card_id: card.id } }">{{
                        card.holder
                      }}</router-link>
                    </td>
                    <td>{{ card.phone_number }}</td>
                    <td>{{ card.level }}</td>
                    <td>{{ card.activated }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button type="button" @click="fireFetchStats">Search</button>
        </fieldset>
      </form>
      <table>
        <colgroup>
          <col span="1" width="25%" />
          <col span="1" width="25%" />
          <col span="1" width="25%" />
          <col span="1" width="25%" />
        </colgroup>
        <thead>
          <tr>
            <th v-for="item in columns" @click.prevent="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              <span class="arrow" :class="order > 0 ? 'asc' : 'dsc'"></span>
              {{ item.name | capitalize }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <template v-for="column in columns">
              <td v-if="'id' === column.key">
                <router-link :to="{ name: 'helpdesk_mobile_view', params: { visit: entry.id } }">{{
                  entry.id
                }}</router-link>
                {{ entry.status }}
              </td>
              <td v-else-if="'registered' === column.key">
                {{ timeOnly(entry[column.key]) }}
              </td>
              <td v-else-if="'holder' === column.key">
                <router-link :to="{ name: 'helpdesk_mobile_holder', params: { card_id: entry.card_id } }">{{
                  entry.holder
                }}</router-link>
              </td>
              <td v-else>
                {{ entry[column.key] }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>

      <button @click="loadMore">More</button>
      <div v-if="visitHistory">
        <table>
          <colgroup>
            <col span="1" width="25%" />
            <col span="1" width="25%" />
            <col span="1" width="25%" />
            <col span="1" width="25%" />
          </colgroup>
          <thead>
            <th>status</th>
            <th>CreatedAt</th>
            <th>Supplier</th>
            <th>Attraction</th>
          </thead>
          <tbody>
            <tr v-for="visit in visitHistory">
              <td>
                {{ statStatusToText(visit.status) }}
              </td>
              <td>{{ visit.created_at }}</td>
              <td>{{ visit.supplier.name }}</td>
              <td>{{ visit.attraction.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </small-layout>
</template>

<style scoped lang="scss">
td {
  word-break: break-word;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatToRFC3339, statisticStatusToText } from "../../middleware/functions";
export default {
  data: function () {
    const defaultColumns = [
      { key: "id", name: "Visit ID", order: 1 },
      { key: "holder", name: "Holder", order: 1 },
      { key: "sup_name", name: "Gym", order: 1 },
      { key: "registered", name: "Time", order: 1 },
    ].filter((column) => {
      if (this.isSupportManager) {
        return ["card_price", "company_type", "company_size"].indexOf(column.key) === -1;
      }
      if (this.isSaleManager) {
        return ["price"].indexOf(column.key) === -1;
      }
      return true;
    });
    const d = new Date();
    return {
      query: {
        date: d.toJSON().substr(0, 10),
        holder: "",
      },
      phone: "",
      visitUpdateForm: false,
      visitAppUpdateForm: false,
      visitUpdateHolder: false,
      visit: null,
      cards: [],
      attractions: [],
      orderKey: 0,
      order: 1,
      holderTimer: null,
      holderTickCount: 3,
      holderTick: null,
      filterKey: "",
      columns: defaultColumns,
      visitHistory: [],
    };
  },
  computed: {
    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.order;
      }
      let data = this.source;
      if (this.filterKey) {
        const filterKey = this.filterKey && this.filterKey.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (orderKey) {
        this.order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    },
    ...mapGetters({
      gridSummary: "helpdesk/list_summary",
      source: "helpdesk/visit_list",
      isAdmin: "auth/isAdmin",
      isSaleManager: "auth/isSaleManager",
      isSupportManager: "auth/isSupportManager",
    }),
    tags_from_query() {
      let tags = [];
      if (this.query.date) {
        tags.push({ name: "date", value: this.query.date });
      }
      return tags;
    },
  },
  methods: {
    statStatusToText(statusKey) {
      return statisticStatusToText(statusKey);
    },
    async loadMore() {
      let tags = [];
      if (this.query.date) {
        tags.push({ name: "finished_at", value: this.query.date });
      }
      const date = new Date(this.query.date);
      date.setMonth(date.getMonth() - 1);
      if (date) {
        this.query.date = formatToRFC3339(date, true);
        tags.push({ name: "started_at", value: this.query.date });
      }
      if (this.query.card_id) {
        tags.push({ name: "card_id", value: this.query.card_id });
      }
      this.visitHistory = await this.fetchVisits({ page: 1, tags });
    },
    timeOnly(datetime) {
      const splits = datetime.split(" ");
      return splits.length ? splits[splits.length - 1] : datetime;
    },
    holderSearchTimeout() {
      this.holderSearchCancel();
      this.holderTick = setInterval(() => {
        --this.holderTickCount;
      }, 500);
      this.holderTimer = setTimeout(() => {
        const number = this.phone ? this.phone : this.query.holder;
        this.loadHoldersBy({ card_id: null, holder: number }).then((cards) => {
          this.holderSearchCancel();
          this.cards = cards;
        });
      }, 1000);
    },
    holderSearchCancel() {
      if (this.holderTimer) {
        clearTimeout(this.holderTimer);
        clearInterval(this.holderTick);
        this.holderTimer = null;
        this.holderTick = null;
        this.holderTickCount = 3;
      }
    },
    ...mapActions({
      fetchHelpdeskHolder: "helpdesk/helpdesk_holder",
      viewHolderVisits: "helpdesk/stats_list",
      loadHoldersBy: "helpdesk/load_holder_by",
      fetchVisits: "visit/visit_search",
    }),
    async fireUpdatingVisit(visit) {
      if (visit.status === "supplier_accepting" || visit.status === "server_accepted" || visit.price === 0) {
        this.attractions = await this.loadAttractionByVisit(visit);
        this.visit = Object.assign({ new_card_id: visit.card_id, new_timedelta: 0 }, visit);
        this.visitUpdateForm = !this.visitUpdateForm;
      }
      if (
        visit.status === "aggregator_invalid_visit_status" ||
        visit.status === "app_holder_wait" ||
        visit.status === "app_holder_timeout" ||
        visit.price === 0
      ) {
        this.attractions = await this.loadAttractionByVisit(visit);
        this.visit = Object.assign({ new_card_id: visit.card_id, new_timedelta: 0 }, visit);
        this.visitAppUpdateForm = !this.visitAppUpdateForm;
      }
    },
    async fireUpdatingVisitHolder(visit) {
      if (mixSupportCompanies.indexOf(Number(visit.company_id)) !== -1) {
        this.cards = await this.loadHoldersBy({ card_id: visit.card_id, holder: null });
        this.visit = Object.assign({ new_card_id: visit.card_id, new_timedelta: 0 }, visit);
        this.visitUpdateHolder = !this.visitUpdateHolder;
        this.query.holder = "";
      }
    },
    async fireUpdateVisitCard() {
      if (this.visit.new_card_id !== this.visit.card_id) {
        this.visit.card_id = this.visit.new_card_id;
        this.visit.timedelta = this.visit.new_timedelta;
        await this.updateVisitCard(this.visit);
        this.visitUpdateHolder = false;
        this.visit = null;
      }
    },
    async fireUpdateVisit() {
      switch (this.visit.status) {
        case "accepted":
        case "app_holder_passed":
          this.visit.reason = "manually_added_broken_scan";
          break;
        case "supplier_reject_wrong_id":
        case "app_holder_reject":
          this.visit.reason = "manually_rejected_broken_scan";
          break;
        case "app_holder_unknown":
          this.visit.reason = "manually_rejected_unknown";
          break;
        default:
          this.visit.reason = "manually_updated_broken_scan";
          break;
      }
      await this.updateVisit(this.visit);
      this.visitUpdateForm = false;
      this.visitAppUpdateForm = false;
      this.visit = null;
    },
    async fireFetchStats(e) {
      this.$router.push({ name: "helpdesk_mobile", query: { date: this.query.date } });
      await this.fetchStats({ tags: this.tags_from_query });
    },
    async fireMonthlyByStatus(status) {
      const tags = this.tags_from_query;
      await this.fetchMonthlyByStatus([{ name: "status", value: status }].concat(tags));
    },
    sortBy: function (key) {
      this.orderKey = key;
      this.order = this.order * -1;
    },
    async extractQueryAndRun(to) {
      if (to.query.date) {
        this.query.date = to.query.date;
      }
      await this.fetchStats({ tags: this.tags_from_query });
    },
  },
  watch: {
    $route(to) {
      this.query = to.query;
      this.fetchHelpdeskHolder(to.query).then((visits) => {
        this.visits = visits;
      });
    },
  },
  async mounted() {
    this.query = this.$route.query;
    this.visits = await this.fetchHelpdeskHolder(this.$route.query);
  },
};
</script>
