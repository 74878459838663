<template>
  <small-layout>
    <div>
      <h1>Holder</h1>
      <div class="buttons">
        <button @click="toggleVisitForm = !toggleVisitForm">Show/Hide Add Visit Form</button>
        <router-link
          :to="{ name: ROUTES_NAMES.subscribers.audit, params: { subscriberId: $route.params.card_id } }"
          tag="button"
        >
          Show subscriber audit
        </router-link>
      </div>
      <div v-if="toggleVisitForm">
        <input
          type="text"
          id="up_supplier_text"
          placeholder="Search by Supplier Name"
          v-model="supplierName"
          v-on:keyup="supplierSearchTimeout"
        />
        <span v-if="supplierTickCount > 0 && supplierTickCount !== 3"
          >Search will happen in: {{ supplierTickCount }}</span
        >
        <select v-if="suppliers" @change="getAttractionBySupplier">
          <option value=""></option>
          <option v-for="item in suppliers" :value="item.id">{{ item.name }}</option>
        </select>
        <select v-if="attractions" @change="attraction_id = $event.target.value">
          <option value=""></option>
          <option v-for="item in attractions" :value="item.id">
            {{ item.name }}|{{ item.levels }}|{{ item.list }}
          </option>
        </select>
        <button v-if="attraction_id && supplier_id" @click="addVisit">Add Visit</button>
      </div>
      <fieldset v-if="holder">
        <legend>Holder</legend>
        <router-link :to="{ name: 'helpdesk_mobile_holder_list', query: { card_id: holder.id, date: today } }"
          >Last Visits</router-link
        >
        <div v-for="column in columns">
          <label :for="'view_' + column.key">{{ column.name }}:</label>
          <span v-if="'activated' === column.key" :id="'view_' + column.key">{{
            holder[column.key] ? "ON" : "off"
          }}</span>
          <span v-else :id="'view_' + column.key">{{ holder[column.key] }}</span>
          <hr />
        </div>
        <HelpDeskHolderDetails :card="card" :visit="visit" :ticket="ticket" :smsList="smsList" :photos="photos" :currentPhoto="currentPhoto"
          >Open App Details</HelpDeskHolderDetails
        >
      </fieldset>
      <button @click="changingHolder = changingHolder.id ? {} : holder">Show/Hide Holder</button>
      <card-update :input="changingHolder"></card-update>
    </div>
  </small-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { formatToRFC3339 } from "../../middleware/functions";
import CardUpdate from "../cards/CardUpdate";
import HelpDeskHolderDetails from "../holder/HelpDeskHolderDetails";
import { ROUTES_NAMES } from "@/router";

const mixSupportCompanies = String(process.env.VUE_APP_SUPPORT_COMPANIES).split(",").map(parseFloat);

export default {
  components: {
    CardUpdate,
    HelpDeskHolderDetails,
  },

  data: function () {
    const defaultColumns = [
      { key: "id", name: "Holder ID", order: 1 },
      { key: "holder", name: "Holder", order: 1 },
      { key: "phone_number", name: "Phone Number", order: 1 },
      { key: "activated", name: "Activated", order: 1 },
      { key: "company_name", name: "Company", order: 1 },
      { key: "level", name: "Level", order: 1 },
      { key: "deactivation_cause", name: "Reason if Deactivated", order: 1 },
      { key: "company_on", name: this.$t("columns.company_on.title"), order: 1 },
    ];
    return {
      ROUTES_NAMES,

      mixSupportCompanies,
      params: {
        holder: "",
      },
      iframe: {
        src: "",
      },
      holder: null,
      orderKey: 0,
      order: 1,
      filterKey: "",
      columns: defaultColumns,
      today: formatToRFC3339(new Date(), true),

      supplierTimer: null,
      supplierTickCount: 3,
      supplierTick: null,
      suppliers: [],
      attractions: [],
      supplier_id: null,
      supplierName: "",
      attraction_id: null,
      toggleVisitForm: false,
      changingHolder: {},
      card: {},
      visit: {},
      ticket: {},
      smsList: {},
      photos: {},
      currentPhoto: null,
    };
  },

  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
      isSaleManager: "auth/isSaleManager",
      isSupportManager: "auth/isSupportManager",
    }),
  },

  methods: {
    async addVisit() {
      await this.updateVisitCard({
        supplier_id: this.supplier_id,
        attraction_id: this.attraction_id,
        timedelta: 0,
        created_at: new Date().toLocaleString(),
        card_id: this.holder.id,
        reason: "manually_added_broken_scan",
        status: "accepted",
      });
      this.$router.push({ name: "helpdesk_mobile_holder_list", query: { card_id: this.holder.id, date: this.today } });
    },
    async getAttractionBySupplier($event) {
      this.supplier_id = $event.target.value;
      this.attractions = await this.getAttractionList($event.target.value);
    },
    supplierSearchTimeout() {
      this.supplierSearchCancel();
      this.supplierTick = setInterval(() => {
        --this.supplierTickCount;
      }, 500);
      this.supplierTimer = setTimeout(() => {
        const name = this.supplierName;
        this.loadSuppliersBy({ matchByName: name }).then((suppliers) => {
          this.supplierSearchCancel();
          this.suppliers = suppliers;
        });
      }, 1000);
    },
    supplierSearchCancel() {
      if (this.supplierTimer) {
        clearTimeout(this.supplierTimer);
        clearInterval(this.supplierTick);
        this.supplierTimer = null;
        this.supplierTick = null;
        this.supplierTickCount = 3;
      }
    },
    getActivePhoto() {
      const id = this.card.photo_id;
      const photo = this.photos.find(photo => photo.id === id);
      return photo;
    },
    ...mapActions({
      viewHolder: "helpdesk/view_holder",
      loadSuppliersBy: "suppliers/load_supplier_list",
      getAttractionList: "supplier/load_attraction_list",
      updateVisitCard: "helpdesk/manually_create_visit_card",
    }),
  },

  watch: {
    $route(to) {
      this.viewHolder(this.$route.params.card_id).then((data) => {
        this.card = data.card;
        this.holder = data.holder;
        this.visit = data.visit;
        this.ticket = data.ticket;
        this.smsList = data.smsList;
        this.photos = data.photos;
        this.currentPhoto = this.getActivePhoto();
      });
    },
  },

  mounted() {
    this.viewHolder(this.$route.params.card_id).then((data) => {
      this.card = data.card;
      this.holder = data.holder;
      this.visit = data.visit;
      this.ticket = data.ticket;
      this.smsList = data.smsList;
      this.photos = data.photos;
      this.currentPhoto = this.getActivePhoto();
    });
  },
};
</script>

<style lang="scss" scoped>
select {
  width: 400px;
  max-width: 100%;
}

.buttons {
  button {
    padding: 0 0.5em;

    & + button {
      margin-left: 16px;
    }
  }
}
</style>
