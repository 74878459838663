<template>
  <div class="md:grid md:grid-cols-3 md:gap-6" tabindex="0" @keydown.esc="$emit('click_overlay')">
    <jet-section-title>
      <template #title><slot name="title"></slot></template>
      <template #description><slot name="description"></slot></template>
    </jet-section-title>

    <div class="mt-5 md:mt-0 md:col-span-2">
      <form @submit.prevent="$emit('submitted')">
        <div
          class="px-4 py-5 bg-white sm:p-6 shadow"
          :class="hasActions ? 'sm:rounded-tl-md sm:rounded-tr-md' : 'sm:rounded-md'"
        >
          <div class="grid grid-cols-6 gap-6">
            <slot name="form"></slot>
          </div>
        </div>

        <div
          class="flex items-center justify-end px-4 py-3 bg-gray-50 text-right sm:px-6 shadow sm:rounded-bl-md sm:rounded-br-md"
          v-if="hasActions"
        >
          <slot name="actions"></slot>
        </div>
      </form>
    </div>
    <a href="#" class="closeoverlay" @click="$emit('click_overlay')"></a>
  </div>
</template>

<style scoped>
.closeoverlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: -1;
  position: fixed;
  cursor: pointer;
  background-color: rgba(245, 245, 245, 0.5);
}
.grid {
  display: block;
  pointer-events: auto;
}
</style>

<script>
import JetSectionTitle from "./SectionTitle";

export default {
  emits: ["submitted"],

  components: {
    JetSectionTitle,
  },

  computed: {
    hasActions() {
      return !!this.$slots.actions;
    },
  },
};
</script>
