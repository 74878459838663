<template>
  <fieldset v-if="updateVisible">
    <legend>
      Update
      <button @click="toggle(false)">Back</button>
    </legend>
    <form @submit.prevent="fireUpdatingCard">
      <fieldset>
        <legend>Card {{ card.id }}</legend>
        <div>
          <label for="up_card_holder">Holder Surname/Firstname:</label>
          <input id="up_card_holder" type="text" v-model="card.holder" />
        </div>
        <div v-if="card.possible_internal_parents.length">
          <label for="up_card_holder_parent">Parent holder:</label>
          <select id="up_card_holder_parent" v-model="card.internal_parent">
            <option v-for="parent in card.possible_internal_parents" :key="parent.id" :value="parent.id">
              {{ parent.holder }}
            </option>
            <option :value="null">Null</option>
          </select>
        </div>
        <div>
          <label for="up_card_level">Level:</label>
          <input id="up_card_level" type="text" v-model="card.level" />
        </div>
        <div>
          <label for="up_card_locked">Card Locked:</label>
          <select id="up_card_locked" v-model="card.locked">
            <option :value="1">Locked</option>
            <option :value="0">Unlocked</option>
          </select>
        </div>
        <div>
          <label for="up_activated">On?:</label>
          <input id="up_activated" type="checkbox" v-model="card.activated" :true-value="1" :false-value="0" />
        </div>
        <div v-if="!card.activated">
          <label for="up_deactivation">Deactivation</label>
          <input id="up_deactivation" type="text" v-model="card.deactivation_cause" />
        </div>
        <div>
          <label for="up_card_middle_name">Holder MiddleName:</label>
          <input id="up_card_middle_name" type="text" v-model="card.middle_name" />
        </div>
        <div>
          <label for="up_card_phone">Phone ID (Format numeric, like 375291294950, without leading +):</label>
          +<input id="up_card_phone" type="number" v-model="card.phone_number" />
        </div>
        <div>
          <label for="up_card_email">Email:</label>
          <input id="up_card_email" type="text" v-model="card.email" />
        </div>
        <div>
          <label for="up_card_location_status">{{ $t("Is GeoLocation Required") }}</label>
          <select id="up_card_location_status" v-model="card.location_status">
            <option v-for="status in config.location_statuses" :value="status" :key="status">{{ status }}</option>
          </select>
        </div>
        <div>
          <label for="up_card_anti_fraud_mode">Anti-fraud mode</label>
          <select id="up_card_anti_fraud_mode" v-model="card.anti_fraud_mode">
            <option v-for="value in ANTI_FRAUD_MODE" :key="`anti-fraud-mode-${value}`" :value="value">
              {{ value }}
            </option>
          </select>
        </div>
        <div>
          <label for="up_card_anti_fraud_result">Anti-fraud result</label>
          <select id="up_card_anti_fraud_result" v-model="card.anti_fraud_result">
            <option v-for="value in ANTI_FRAUD_RESULT" :key="`anti-fraud-result-${value}`" :value="value">
              {{ value }}
            </option>
          </select>
        </div>
        <div>
          <label for="up_is_suspicious">Is Suspicious?:</label>
          <select id="up_is_suspicious" v-model="card.is_suspicious">
            <option :value="true">To Track activity</option>
            <option :value="false">none</option>
          </select>
        </div>
      </fieldset>
      <div>
        <button>Confirm</button>
      </div>
      <footer>
        <span>{{ card.new_data_at }}</span>
        <dl>
          <template v-for="(value, name) in card.new_data">
            <dd>{{ name }}</dd>
            <dt>{{ value }}</dt>
          </template>
        </dl>
      </footer>
    </form>
  </fieldset>
</template>

<script>
import { mapActions } from "vuex";
import config, { ANTI_FRAUD_MODE, ANTI_FRAUD_RESULT } from "../../config";

export default {
  name: "CardsCardUpdate",
  data() {
    return {
      ANTI_FRAUD_MODE,
      ANTI_FRAUD_RESULT,
      config,

      card: null,

      updateVisible: false,
    };
  },
  props: {
    input: {
      type: Object,
    },
  },
  watch: {
    input: {
      handler(val) {
        if (val.id) {
          this.viewCard({ card: val }).then(this.parseCard);
        } else {
          this.toggle(false);
        }
      },
      deep: true,
    },
  },
  methods: {
    parseCard(card) {
      this.card = card;
      this.toggle(true);
    },
    getActivation(card) {
      return card.activated ? "status-on" : "status-off";
    },
    ...mapActions({
      viewCard: "companycard/view_card",
      doUpdateCard: "companycard/update_card",
    }),
    toggle(value) {
      this.updateVisible = value;
    },
    async fireUpdatingCard() {
      await this.doUpdateCard({ card: this.card });
      this.toggle(false);
    },
  },
};
</script>

<style scoped></style>
