/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  list(supplier_id) {
    return axios
      .get(`/api/suppliers/${supplier_id}/attractions`)
      .then((response) => {
        return response.data.data;
      });
  },
  scheduling(attraction) {
    return axios.post(`/api/shdl_attractions`, attraction).then((response) => {
      return response.data.data;
    });
  },
  destroy(attraction) {
    return axios.delete(`/api/shdl_attractions/${attraction.sid}`);
  },
  scheduling_list(offset, limit) {
    return axios
      .get(`/api/shdl_attractions?offset=${offset}&limit=${limit}`)
      .then((response) => {
        return response.data.data;
      });
  },
  supplier_cover_list(started_at, finished_at, exceed, exclude_companies) {
    let list = [];
    exclude_companies.map((id) => list.push("exclude_companies[]=" + id));
    const param = list.length ? "&" + list.join("&") : "";
    return axios
      .get(
        `/api/stats/supplier_cover?started_at=${started_at}&finished_at=${finished_at}&exceed=${exceed}` +
          param
      )
      .then((response) => {
        return response.data.data;
      });
  },
  cover_list(started_at, finished_at) {
    return axios
      .get(
        `/api/stats/attraction_cover?started_at=${started_at}&finished_at=${finished_at}`
      )
      .then((response) => {
        return response.data.data;
      });
  },
  cover_pivot() {
    return axios
      .get(`/api/stats/attraction_pivot${location.search}`)
      .then((response) => {
        return response.data;
      });
  },
};
