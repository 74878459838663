import { computed, unref } from "@vue/composition-api";
import isEqualWith from "lodash/isEqualWith";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";

function customizer(objValue, othValue) {
  if (isNumber(objValue) || isNumber(othValue)) {
    return isEqual(parseFloat(objValue), parseFloat(othValue));
  }
}

export default function useIsObjectChanged(initialObjState, currentObjState) {
  const isObjectChanged = computed(() => {
    const initialValue = unref(initialObjState);
    const currentValue = unref(currentObjState);

    return !isEqualWith(initialValue, currentValue, customizer);
  });

  return { isObjectChanged };
}
