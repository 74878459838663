<template>
  <div v-if="errors">
    <ul>
      <li v-for="(value, index) in flatErrors" :key="`flat_error_${index}`">@ {{ value }}</li>
    </ul>
  </div>
</template>

<script>
import isObject from "lodash/isObject";

export default {
  props: ["errors"],
  computed: {
    flatErrors() {
      let errors = [];
      if (this.errors) {
        if (isObject(this.errors)) {
          errors = Object.values(this.errors).flat();
        } else {
          errors = [this.errors];
        }
      }
      return errors;
    },
  },
};
</script>

<style scoped></style>
