import config from "../config";

export default function buildCurrencyFormatter(country) {
  const currency = config.currencies[country];
  const intl = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currency,
  });
  intl.currency = currency;
  return intl;
}
