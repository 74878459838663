/**
 * Created by Alexander Golovnya on 2019-01-05.
 */
import axios from "axios";

export default {
  visit_search(page, tags) {
    let params = [];
    if (page) {
      params.push("page=" + page);
    }
    if (tags) {
      tags.forEach((tag) => {
        params.push(tag.name + "=" + tag.value);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios.get(`/api/visits/search${query}`).then((response) => {
      return response.data;
    });
  },
  visit_list(page, row_count, tags) {
    let params = [];
    if (page) {
      params.push("page=" + page);
    }
    if (row_count) {
      params.push("row_count=" + row_count);
    }
    if (tags) {
      tags.forEach((tag) => {
        params.push(tag.name + "=" + tag.value);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios.get(`/api/journal/${query}`).then((response) => {
      return response.data;
    });
  },
  manually_update_visit(visit) {
    return axios
      .patch(
        `/api/journal/manually_update_visit?id=${visit.id}&status=${visit.status}&reason=${visit.reason}&attraction_id=${visit.attraction_id}`
      )
      .then((response) => {
        return response.data;
      });
  },
  manually_patching_visit(visit) {
    return axios
      .get(`/api/journal/manually_patching_visit?id=${visit.id}`)
      .then((response) => {
        return response.data.data;
      });
  },
  visit_download(tags) {
    let params = [];
    if (tags) {
      tags.forEach((tag) => {
        params.push(tag.name + "=" + tag.value);
      });
    }
    let query = params.length > 0 ? "?" + params.join("&") : "";

    return axios({
      url: `/api/journal/csv/${query}`,
      method: "GET",
      responseType: "blob", // important
    });
  },
  load_visits_corrections(period) {
    return axios
      .get(`/api/support/1.0.0/visits/corrections?period=${period}`)
      .then((response) => {
        return response.data;
      });
  },
  accept_visits_correction(correctionId) {
    return axios
      .post(`/api/support/1.0.0/visits/corrections/${correctionId}/accept`)
      .then((response) => {
        return response;
      });
  },
  decline_visits_correction(correctionId) {
    return axios
      .post(`/api/support/1.0.0/visits/corrections/${correctionId}/decline`)
      .then((response) => {
        return response;
      });
  },
};
