<template>
  <small-layout
    ><div>
      <router-link
        v-for="to in String(supplier.aemail).split(',')"
        :to="{ name: 'mail_list', params: { to: to.trim() } }"
        :key="to"
      >
        Mails to {{ to }}
      </router-link>
      <h1>View</h1>
      <button @click="toggleView">Просмотр всех полей</button>
      <div v-if="supplier" v-bind:class="'flex-container flex-2cols ' + class_full_view">
        <template v-for="(value, index) in supplier">
          <div class="flex-cell">{{ index }}:</div>
          <div class="flex-cell">{{ value }}</div>
        </template>
      </div>
      <SupplierViewAttractionCrud
        v-bind:country="country"
        v-bind:supplier_id="this.$route.params.supplier"
        v-bind:supplier_appendix="this.supplier.appendix"
      ></SupplierViewAttractionCrud>
      <SupplierViewDocumentCrud v-bind:route_supplier="this.$route.params.supplier"></SupplierViewDocumentCrud>
      <SupplierViewFileCrud v-bind:route_supplier="this.$route.params.supplier"></SupplierViewFileCrud></div
  ></small-layout>
</template>

<script>
import SupplierViewFileCrud from "./SupplierViewFileCrud";
import SupplierViewDocumentCrud from "./SupplierViewDocumentCrud";
import SupplierViewAttractionCrud from "./SupplierViewAttractionCrud";
import { mapActions } from "vuex";
export default {
  components: {
    SupplierViewDocumentCrud,
    SupplierViewAttractionCrud,
    SupplierViewFileCrud,
  },
  data() {
    return {
      supplier: {},
      class_full_view: "hide_full_view",
    };
  },
  methods: {
    ...mapActions({
      getSupplier: "supplier/load_item",
    }),
    toggleView() {
      this.class_full_view = this.class_full_view === "" ? "hide_full_view" : "";
    },
  },
  async created() {
    this.supplier = await this.getSupplier(this.$route.params.supplier);
  },
};
</script>

<style lang="scss" scoped>
.hide_full_view {
  height: 110px;
}

.flex-container {
  .flex-cell {
    width: 10%;
    word-wrap: break-word;

    &:nth-child(2n) {
      width: 90%;
    }
  }
}
</style>
