import createAxios from "./createAxios";
import { axiosRequestSuccessInterceptor } from "../../middleware/axios_interceptors";

const uploadClient = createAxios({
  headers: { "Content-Type": "multipart/form-data" },
});

uploadClient.interceptors.request.use(axiosRequestSuccessInterceptor);

export default uploadClient;
