<template>
  <button
    v-bind="$attrs"
    class="btn"
    :class="elementClass"
    :disabled="props.disabled"
    :type="props.type"
    v-on="$listeners"
  >
    <span class="spinner_container">
      <Spinner />
    </span>
    <span class="text_container">
      <Icon v-if="props.icon" :icon="props.icon" class="icon" />
      <slot />
    </span>
  </button>
</template>

<script>
import { computed } from "@vue/composition-api";

import Icon from "@/components/icons/Icon.vue";
import Spinner from "@/components/loaders/Spinner.vue";

export const BUTTON_TYPES = {
  light: "light",
  primary: "primary",
};

export default {
  components: {
    Icon,
    Spinner,
  },

  props: {
    autoWidth: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: BUTTON_TYPES.primary,
      validator: (val) => Object.values(BUTTON_TYPES).includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { slots }) {
    const elementClass = computed(() => {
      const { autoWidth, disabled, processing, wide } = props;

      const classList = {
        [`btn-${props.buttonType}`]: true,
        "btn__auto-width": autoWidth,
        btn__disabled: disabled || processing,
        btn__empty: !slots.default,
        btn__icon: !!props.icon,
        btn__processing: processing,
        btn__wide: wide,
      };

      return classList;
    });

    return {
      props,
      elementClass,
    };
  },
};
</script>

<style lang="scss" scoped>
@use "sass:map";

$btn_primary: (
  "bg_color": #444545,
  "bg_color_hover": #888888,
  "bg_color_disabled": #9c9c9c,
  "font_color": #fff,
);

$btn_light: (
  "bg_color": #e7e7e7,
  "bg_color_hover": #f5f5f5,
  "bg_color_disabled": #fdfbfb,
  "font_color": #444545,
  "font_color_disabled": #e7e7e7,
);

$buttons: (
  "primary": $btn_primary,
  "light": $btn_light,
);

@function get-from-map($target, $key, $default-value) {
  @if map.get($target, $key) {
    @return map.get($target, $key);
  }

  @return $default-value;
}

.btn {
  border-radius: 8px;
  cursor: pointer;
  min-height: 38px;
  padding: 5px 24px;
  position: relative;
  min-width: 178px;

  .text_container {
    font-weight: 500;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .spinner_container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
  }

  @each $type, $btn in $buttons {
    &-#{$type} {
      $color: map.get($btn, "font_color");

      background-color: map.get($btn, "bg_color");
      color: $color;

      &:hover {
        background-color: map.get($btn, "bg_color_hover");
      }

      .icon {
        fill: $color;
      }

      &.btn__disabled {
        $color: get-from-map($btn, "font_color_disabled", map.get($btn, "font_color"));

        background-color: map.get($btn, "bg_color_disabled");
        color: $color;

        .icon {
          fill: $color;
        }
      }
    }
  }

  &-simple {
    padding: 0 12px;

    .text_container {
      img {
        margin: 0;
      }
    }
  }

  &__disabled {
    cursor: auto;
  }

  &__processing {
    .text_container {
      visibility: hidden;
    }

    .spinner_container {
      display: flex;
    }
  }

  &__wide {
    width: 100%;
  }

  &__auto-width {
    min-width: 0;
  }

  &__icon:not(#{&}__empty) {
    .text_container {
      .icon {
        margin-right: 8px;
      }
    }
  }

  &__empty {
    padding: 0;
    background: none;
    min-width: 0;
    min-height: 0;
    line-height: 0;

    &:hover {
      background: none;
    }
  }
}
</style>
