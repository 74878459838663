import PHONES_CONFIG from "./phones";

const EMAIL_COMMON_REGEXP = /^[\w][\w-\.]*@([\w-]+\.)+[\w-]{2,}$/;
const DOMAIN_COMMON_REGEXP =
  /^(?:[\wа-яё](?:[\wа-яё-]{0,61}[\wа-яё])?\.)+(?:[a-zа-яё]{2,})$/;
const NUM_RANGE_REG = /^\d+-\d+$/;
const ENUMERATION_REG = /^\d+(,\d+)+$/;

export {
  EMAIL_COMMON_REGEXP,
  DOMAIN_COMMON_REGEXP,
  PHONES_CONFIG,
  NUM_RANGE_REG,
  ENUMERATION_REG,
};
