<template>
  <div class="info-banner" :class="elementClass" v-bind="bannerAttributes">
    <Icon icon="danger" fill="#d63230" class="info-banner_icon" />
    <p class="info-banner_message">
      <template v-if="isNonTranslatedError">{{ $t(errorMsg) }}</template>
      <template v-else>{{ errorMsg }}</template>
      <a
        v-for="(action, index) in props.inlineActions"
        class="info-banner_message_inline-action"
        href="#"
        :key="`${_uid}-inline-action-${index}`"
        @click.prevent="emit(action.type)"
      >
        {{ action.text }}
      </a>
    </p>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";

import Icon from "@/components/icons/Icon.vue";

import { ERRORS_MESSAGES } from "@/helpers/validators";

const ERROR_BANNER_CLASS = "info-banner";
const ERROR_BANNER_MODIFIERS = ["small"];

export default {
  components: {
    Icon,
  },

  props: {
    inlineActions: {
      type: Array,
      default: () => [],
    },
    message: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      required: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const isNonTranslatedError = computed(() => !!ERRORS_MESSAGES[props.message]);
    const errorMsg = computed(() => (isNonTranslatedError.value ? ERRORS_MESSAGES[props.message] : props.message));

    const isVisible = computed(() => props.visible && !!props.message?.length);

    const elementClass = computed(() => {
      const activeModifiers = ERROR_BANNER_MODIFIERS.filter((modifier) => props[modifier]);

      if (isVisible.value) {
        activeModifiers.push("visible");
      }

      const elementClassList = activeModifiers.map((modifier) => `${ERROR_BANNER_CLASS}__${modifier}`);

      return elementClassList;
    });

    const bannerAttributes = computed(() => {
      const attrs = {};

      if (isVisible.value) {
        attrs["data-error-type"] = "banner";
      }

      return attrs;
    });

    return {
      props,

      isNonTranslatedError,
      errorMsg,
      elementClass,
      bannerAttributes,

      emit,
    };
  },
};
</script>

<style lang="scss" scoped>
$main_class: ".info-banner";

#{$main_class} {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff6f5;
  border-radius: 8px;
  padding: 10px 12px;
  visibility: hidden;
  margin: 12px 0;

  &_message {
    color: #d63230;
    font-weight: 500;
    font-size: 16px;
    margin-left: 7px;
    word-break: break-word;

    &_inline-action {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &_icon {
    flex-shrink: 0;
  }

  &__visible {
    visibility: visible;
  }

  &__small {
    padding: 5px 12px;
    margin: 8px 0;

    #{$main_class}_icon {
      height: 13px;
    }

    #{$main_class}_message {
      font-size: 13px;
    }
  }
}
</style>
