import URLS from "@/config/urls";
import request from "@/helpers/request";
import router from "@/router";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import { validateRequiredProps } from "@/helpers/validators";
import cloneDeep from "lodash/cloneDeep";

const STORE_NAMESPACE = "orders";

const ACTION_GET_ORDER = "actionGetOrder";
const ACTION_RESET_ORDER = "actionResetOrder";

const ACTION_ADD_NEW_INVOICE = "actionAddInvoice";
const ACTION_DELETE_INVOICE = "actionDeleteInvoice";
const ACTION_SAVE_INVOICE = "actionSaveInvoice";

const ACTION_CREATE_ACT = "actionCreateAct";
const ACTION_SAVE_ACT = "actionSaveAct";

const ACTION_ADD_ORDER_INVOICE_SUMMARY_ITEM =
  "actionAddOrderInvoiceSummaryItem";
const ACTION_UPDATE_ORDER_INVOICE_SUMMARY = "actionUpdateOrderInvoiceSummary";
const ACTION_UPDATE_ORDER_INVOICE_ISSUED_AT =
  "actionUpdateOrderInvoiceIssuedAt";

const ACTION_ADD_ORDER_ACT_SUMMARY_ITEM = "actionAddOrderActSummaryItem";
const ACTION_UPDATE_ORDER_ACT_SUMMARY = "actionUpdateOrderActSummary";

const MUTATION_SET_ERRORS = "mutationSetErrors";
const MUTATION_SET_ORDER = "mutationSetOrder";
const MUTATION_SET_ORDER_ACTS = "mutationSetOrderActs";
const MUTATION_SET_ORDER_INVOICES = "mutationSetOrderInvoices";
const MUTATION_SET_PRICING_GROUP = "mutationSetPricingGroup";
const MUTATION_SET_PROCESSING = "mutationSetProcessing";
const MUTATION_UPDATE_ORDER_ACT_SUMMARY = "mutationUpdateOrderActSummary";
const MUTATION_UPDATE_ORDER_INVOICE_SUMMARY =
  "mutationUpdateOrderInvoiceSummary";
const MUTATION_UPDATE_ORDER_INVOICE_ISSUED_AT =
  "mutationUpdateOrderInvoiceIssuedAt";

export const ORDERS_ACTION_GET_ORDER = `${STORE_NAMESPACE}/${ACTION_GET_ORDER}`;
export const ORDERS_ACTION_RESET_ORDER = `${STORE_NAMESPACE}/${ACTION_RESET_ORDER}`;

export const ORDERS_ACTION_ADD_NEW_INVOICE = `${STORE_NAMESPACE}/${ACTION_ADD_NEW_INVOICE}`;
export const ORDERS_ACTION_DELETE_INVOICE = `${STORE_NAMESPACE}/${ACTION_DELETE_INVOICE}`;
export const ORDERS_ACTION_SAVE_INVOICE = `${STORE_NAMESPACE}/${ACTION_SAVE_INVOICE}`;

export const ORDERS_ACTION_CREATE_ACT = `${STORE_NAMESPACE}/${ACTION_CREATE_ACT}`;
export const ORDERS_ACTION_SAVE_ACT = `${STORE_NAMESPACE}/${ACTION_SAVE_ACT}`;

export const ORDERS_ACTION_ADD_ORDER_ACT_SUMMARY_ITEM = `${STORE_NAMESPACE}/${ACTION_ADD_ORDER_ACT_SUMMARY_ITEM}`;
export const ORDERS_ACTION_ADD_ORDER_INVOICE_SUMMARY_ITEM = `${STORE_NAMESPACE}/${ACTION_ADD_ORDER_INVOICE_SUMMARY_ITEM}`;
export const ORDERS_ACTION_UPDATE_ORDER_ACT_SUMMARY = `${STORE_NAMESPACE}/${ACTION_UPDATE_ORDER_ACT_SUMMARY}`;
export const ORDERS_ACTION_UPDATE_ORDER_INVOICE_SUMMARY = `${STORE_NAMESPACE}/${ACTION_UPDATE_ORDER_INVOICE_SUMMARY}`;
export const ORDERS_ACTION_UPDATE_ORDER_INVOICE_ISSUED_AT = `${STORE_NAMESPACE}/${ACTION_UPDATE_ORDER_INVOICE_ISSUED_AT}`;

export const NEW_ORDER_INVOICE_ID = "-";

const ORDER_DOCUMENT_TYPES = {
  act: "act",
  invoice: "invoice",
};

const getOrderId = () => router.currentRoute.params.orderId;

function onRequestStart(commit, ...mutationsList) {
  commit(MUTATION_SET_ERRORS);
  commit(MUTATION_SET_PROCESSING);

  mutationsList.forEach((mutation) => {
    commit(mutation);
  });
}

function onError(xhrError, commit) {
  const errors = prepareAxiosErrors(xhrError);
  commit(MUTATION_SET_ERRORS, errors);
  commit(MUTATION_SET_PROCESSING, false);
  return Promise.reject(errors);
}

export default {
  namespaced: true,

  state: {
    processing: false,
    errors: {},

    order: null,
    initialOrder: null,
    pricingGroup: null,
  },

  actions: {
    async [ACTION_GET_ORDER](
      { commit },
      documentType = ORDER_DOCUMENT_TYPES.invoice
    ) {
      const orderId = getOrderId();

      onRequestStart(commit, MUTATION_SET_ORDER);

      try {
        let url;

        if (documentType == ORDER_DOCUMENT_TYPES.invoice) {
          url = URLS.orders.invoices.list(orderId);
        } else {
          url = URLS.orders.acts.index(orderId);
        }

        const result = await request(url);
        const { sell_strategy: sellStrategies, ...order } = result;

        commit(MUTATION_SET_ORDER, order);
        commit(MUTATION_SET_PRICING_GROUP, sellStrategies);
        commit(MUTATION_SET_PROCESSING, false);

        return Promise.resolve(result);
      } catch (xhrError) {
        return onError(xhrError, commit);
      }
    },
    async [ACTION_ADD_NEW_INVOICE]({ commit, state }, orderId = getOrderId()) {
      onRequestStart(commit);

      try {
        const url = URLS.orders.invoices.create(orderId);
        const newInvoice = await request(url);
        const newInvoicesList = [
          ...state.order.invoices,
          { ...newInvoice, id: NEW_ORDER_INVOICE_ID },
        ];

        commit(MUTATION_SET_ORDER_INVOICES, newInvoicesList);
        commit(MUTATION_SET_PROCESSING, false);

        return Promise.resolve(newInvoice);
      } catch (xhrError) {
        return onError(xhrError, commit);
      }
    },
    async [ACTION_DELETE_INVOICE](
      { commit, state },
      { invoiceId, orderId = getOrderId() }
    ) {
      const allPropsPassed = validateRequiredProps(
        ORDERS_ACTION_DELETE_INVOICE,
        orderId,
        invoiceId
      );

      if (allPropsPassed) {
        onRequestStart(commit);

        try {
          if (invoiceId != NEW_ORDER_INVOICE_ID) {
            const url = URLS.orders.invoices.delete(orderId, invoiceId);
            await request({
              url,
              method: "DELETE",
            });
          }

          const newInvoicesList = state.order.invoices.filter(
            ({ id }) => id !== invoiceId
          );

          commit(MUTATION_SET_PROCESSING, false);
          commit(MUTATION_SET_ORDER_INVOICES, newInvoicesList);
          commit(MUTATION_SET_ORDER);

          return Promise.resolve(newInvoicesList);
        } catch (xhrError) {
          return onError(xhrError, commit);
        }
      }
    },
    [ACTION_ADD_ORDER_INVOICE_SUMMARY_ITEM](
      { commit, state },
      { invoiceId, newSummaryDetail }
    ) {
      const allPropsPassed = validateRequiredProps(
        ORDERS_ACTION_ADD_ORDER_INVOICE_SUMMARY_ITEM,
        invoiceId,
        newSummaryDetail
      );

      if (allPropsPassed) {
        const invoice = state.order.invoices.find(({ id }) => id == invoiceId);
        const newSummary = [...invoice.summary, newSummaryDetail];

        if (invoice) {
          commit(MUTATION_UPDATE_ORDER_INVOICE_SUMMARY, {
            invoice,
            newSummary,
          });
        }
      }
    },
    [ACTION_ADD_ORDER_ACT_SUMMARY_ITEM](
      { commit, state },
      { actId, newSummaryDetail }
    ) {
      const allPropsPassed = validateRequiredProps(
        ORDERS_ACTION_ADD_ORDER_ACT_SUMMARY_ITEM,
        actId,
        newSummaryDetail
      );

      if (allPropsPassed) {
        const act = state.order.acts.find(({ id }) => id == actId);
        const newSummary = [...act.summary, newSummaryDetail];

        if (act) {
          commit(MUTATION_UPDATE_ORDER_ACT_SUMMARY, {
            act,
            newSummary,
          });
        }
      }
    },
    [ACTION_UPDATE_ORDER_INVOICE_SUMMARY](
      { commit, state },
      { invoiceId, newSummary }
    ) {
      const allPropsPassed = validateRequiredProps(
        ORDERS_ACTION_UPDATE_ORDER_INVOICE_SUMMARY,
        invoiceId,
        newSummary
      );

      if (allPropsPassed) {
        const invoice = state.order.invoices.find(({ id }) => id == invoiceId);

        if (invoice) {
          commit(MUTATION_UPDATE_ORDER_INVOICE_SUMMARY, {
            invoice,
            newSummary,
          });
        }
      }
    },
    [ACTION_UPDATE_ORDER_ACT_SUMMARY](
      { commit, state },
      { actId, newSummary }
    ) {
      const allPropsPassed = validateRequiredProps(
        ORDERS_ACTION_UPDATE_ORDER_INVOICE_SUMMARY,
        actId,
        newSummary
      );

      if (allPropsPassed) {
        const act = state.order.acts.find(({ id }) => id == actId);

        if (act) {
          commit(MUTATION_UPDATE_ORDER_ACT_SUMMARY, {
            act,
            newSummary,
          });
        }
      }
    },
    [ACTION_UPDATE_ORDER_INVOICE_ISSUED_AT](
      { commit, state },
      { invoiceId, date } = {}
    ) {
      const allPropsPassed = validateRequiredProps(
        ACTION_UPDATE_ORDER_INVOICE_ISSUED_AT,
        date
      );

      if (allPropsPassed) {
        const invoice = state.order.invoices.find(({ id }) => id == invoiceId);
        commit(MUTATION_UPDATE_ORDER_INVOICE_ISSUED_AT, { invoice, date });
      }
    },
    async [ACTION_SAVE_INVOICE](
      { commit, state },
      { invoiceId, orderId = getOrderId() }
    ) {
      onRequestStart(commit);

      try {
        const newInvoicesList = cloneDeep(state.order.invoices);
        const invoice = newInvoicesList.find(({ id }) => id == invoiceId);

        let url;
        let method;

        if (invoiceId === NEW_ORDER_INVOICE_ID) {
          method = "POST";
          url = URLS.orders.invoices.store(orderId);
        } else {
          method = "PUT";
          url = URLS.orders.invoices.update(orderId, invoiceId);
        }

        const newSavedInvoice = await request({
          url,
          method,
          data: invoice,
        });

        Object.assign(invoice, newSavedInvoice);

        commit(MUTATION_SET_PROCESSING, false);
        commit(MUTATION_SET_ORDER_INVOICES, newInvoicesList);
        commit(MUTATION_SET_ORDER);

        return Promise.resolve(newSavedInvoice);
      } catch (xhrError) {
        return onError(xhrError, commit);
      }
    },
    async [ACTION_CREATE_ACT]({ commit }) {
      const orderId = getOrderId();

      try {
        const newOrderAct = await request({
          url: URLS.orders.acts.index(orderId),
          method: "POST",
        });

        return Promise.resolve(newOrderAct);
      } catch (xhrError) {
        return onError(xhrError, commit);
      }
    },
    async [ACTION_SAVE_ACT](
      { commit, state },
      { actId, orderId = getOrderId() }
    ) {
      onRequestStart(commit);

      try {
        const newActsList = cloneDeep(state.order.acts);
        const act = newActsList.find(({ id }) => id == actId);

        let url;
        let method;

        if (actId === NEW_ORDER_INVOICE_ID) {
          method = "POST";
          url = URLS.orders.acts.index(orderId);
        } else {
          method = "PUT";
          url = URLS.orders.acts.single(orderId, actId);
        }

        const newSavedAct = await request({
          url,
          method,
          data: act,
        });

        Object.assign(act, newSavedAct);

        commit(MUTATION_SET_PROCESSING, false);
        commit(MUTATION_SET_ORDER_ACTS, newActsList);
        commit(MUTATION_SET_ORDER);

        return Promise.resolve(newSavedAct);
      } catch (xhrError) {
        return onError(xhrError, commit);
      }
    },
    [ACTION_RESET_ORDER]({ commit, state }) {
      commit(MUTATION_SET_ORDER, state.initialOrder);
    },
  },

  mutations: {
    [MUTATION_SET_ERRORS](state, newState = {}) {
      state.errors = newState;
    },
    [MUTATION_SET_PROCESSING](state, newState = true) {
      state.processing = newState;
    },
    [MUTATION_SET_ORDER](state, newOrder) {
      newOrder = newOrder ?? state.order;
      state.order = newOrder;
      state.initialOrder = cloneDeep(newOrder);
    },
    [MUTATION_SET_PRICING_GROUP](state, newState = null) {
      state.pricingGroup = newState;
    },
    [MUTATION_SET_ORDER_INVOICES](state, newInvoices = []) {
      state.order.invoices = newInvoices;
    },
    [MUTATION_SET_ORDER_ACTS](state, newActs = []) {
      state.order.acts = newActs;
    },
    [MUTATION_UPDATE_ORDER_INVOICE_SUMMARY](state, { invoice, newSummary }) {
      invoice.summary = newSummary;
    },
    [MUTATION_UPDATE_ORDER_ACT_SUMMARY](state, { act, newSummary }) {
      act.summary = newSummary;
    },
    [MUTATION_UPDATE_ORDER_INVOICE_ISSUED_AT](state, { invoice, date }) {
      invoice.issued_at = date;
    },
  },
};
