<style scoped>
.action-link {
  cursor: pointer;
}
</style>

<template>
  <small-layout>
    <form @submit.prevent="signinUser">
      <fieldset>
        <legend>Signin</legend>
        <div>
          <label for="email">Email</label>
          <input id="email" type="email"
name="email" autocomplete="email" required autofocus />
        </div>
        <div>
          <label for="password">Password</label>
          <input autocomplete="current-password" id="password" type="password" name="password" required />
        </div>
        <div>
          <label for="remember">Remember me</label>
          <input id="remember" type="checkbox" name="remember" />
        </div>
        <div>
          <button>Signin</button>
          <router-link :to="{ name: 'passport_forgot_password' }">Forgot Password</router-link>
          <router-link :to="{ name: 'signup' }">Sign Up</router-link>
        </div>
      </fieldset>
    </form>
  </small-layout>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions({
      signin: "auth/signin",
    }),
    async signinUser(e) {
      let postData = {
        email: e.target.email.value,
        password: e.target.password.value,
      };
      const routeName = await this.signin(postData);
      this.$router.push({ name: routeName, params: { filter_at: this.filter_at } });
    },
  },
};
</script>
