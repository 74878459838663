import packageJson from "../../package.json";

import URLS from "./urls";
import STORAGE_CONFIG from "./storage";
import AUDITABLE_TYPES from "./auditableTypes";

const DEFAULT_BRAND_NAME = "Allsports";

export const BRAND_NAME = process.env.VUE_APP_BRAND || DEFAULT_BRAND_NAME;
export const BRAND = BRAND_NAME.toLocaleLowerCase();

export const BUILD_NUMBER =
  process.env.VUE_APP_BITBUCKET_BUILD_NUMBER || "local";

export const DEPLOYMENT_ENVIRONMENT =
  process.env.VUE_APP_BITBUCKET_DEPLOYMENT_ENVIRONMENT || "local";

export const DEPLOYMENT_RELEASE = `${packageJson.name}@${packageJson.version}+${BUILD_NUMBER}`;

export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;

export { URLS, AUDITABLE_TYPES, STORAGE_CONFIG };

export default {
  AUDITABLE_TYPES,
  BRAND_NAME,
  BRAND,
  STORAGE_CONFIG,
  URLS,
};
