<template>
  <small-layout>
    <div id="demo">
      <h1>Journal of Visits</h1>
      <form id="search">
        <fieldset>
          <div>
            <label for="query">Local Search here</label>
            <input id="query" name="query" v-model="filterKey" />
          </div>
          <div>
            <label for="rowsPerPage">Page</label>
            <input type="number" id="pagePerPage" v-model="pageNum" @change="fireFetchVisits" />
          </div>
          <div>
            <label for="params_id">ID:</label>
            <input id="params_id" type="number" v-model="params.id" />
            <label for="params_status">Status:</label>
            <input id="params_status" type="text" v-model="params.status" />
            <label for="params_reason">Reason:</label>
            <input id="params_reason" type="text" v-model="params.reason" />
          </div>
          <div>
            <label for="params_supplier">Supplier:</label>
            <input id="params_supplier" type="text" v-model="params.supplier_search" />
            <label for="params_city">City</label>
            <select id="params_city" v-model="params.city">
              <option value=""></option>
              <option v-for="option in citiesList" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
          <div v-if="isSaleManager">
            <label for="params_client">Company:</label>
            <input id="params_client" type="text" v-model="params.client_search" />
          </div>
          <div>
            <label for="params_card">Holder/Card:</label>
            <input id="params_card" type="text" v-model="params.card_search" />
            <label for="params_card_level">Level</label>
            <select id="params_card_level" v-model="params.level">
              <option value=""></option>
              <option value="region">region</option>
              <option value="silver">silver</option>
              <option value="gold">gold</option>
              <option value="platinum">platinum</option>
            </select>
          </div>
          <div>
            <label for="month_at">Month</label>
            <input type="month" id="month_at" @change="setPeriod" v-model="params.month_at" />
            <label for="started_at">Started</label>
            <input type="date" id="started_at" v-model="params.started_at" />
            <label for="finished_at">Finished</label>
            <input type="date" id="finished_at" v-model="params.finished_at" />
          </div>
          <button type="button" @click="fireFetchVisits" v-if="!fetching">Search</button>
        </fieldset>
        <div>
          <a ref="download">Download again</a>
          <button type="button" @click="exportExcel" v-if="!fetching">export Excel</button>
        </div>
      </form>
      <table>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.title | capitalize }}
              <span class="arrow" :class="order > 0 ? 'asc' : 'dsc'"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <td v-for="item in columns">
              <router-link
                v-if="item.name === 'company_id' && entry[item.key]"
                :to="{
                  name: 'search_card_company',
                  params: {
                    company_id: entry[item.key],
                    started_at: params.started_at,
                    finished_at: params.finished_at,
                  },
                }"
                >{{ entry[company_name_key] }}</router-link
              >
              <router-link
                v-if="item.name === 'holder' && entry[card_id_key]"
                :to="{
                  name: 'search_card_card',
                  params: {
                    card_id: entry[card_id_key],
                    started_at: params.started_at,
                    finished_at: params.finished_at,
                  },
                }"
                >{{ entry[item.key] }}</router-link
              >
              <span v-else-if="['card_price', 'price'].indexOf(item.name) !== -1">{{
                formatCurrency(entry[item.key])
              }}</span>
              <span v-else-if="'lat' === item.name">
                {{ entry[item.key] }}
                <router-link v-if="entry[item.key]" :to="{ name: 'show_bounds', params: { visit_id: entry[0] } }">
                  {{ $t("map.titleShowVisit") }}
                </router-link>
                <span v-if="getIsGeoMocked(entry)" class="fake_geo" title="Fake geo location"><b>×</b> fake</span>
              </span>
              <router-link
                v-else-if="item.name === 'id'"
                :to="{ name: 'helpdesk_mobile_view', params: { visit: entry[item.key] } }"
              >
                {{ entry[item.key] }}
              </router-link>
              <span v-else>{{ entry[item.key] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </small-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import buildCurrencyFormatter from "../middleware/buildCurrencyFormatter";
import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";

export default {
  components: {
    Paginate,
  },
  data: function () {
    let newStartedAt = new Date();
    let newFinishedAt = new Date();
    newFinishedAt.setMonth(newFinishedAt.getMonth() + 1);
    newStartedAt.setDate(1);
    newFinishedAt.setDate(0);
    return {
      fetching: false,
      pageNum: 1,
      params: {
        id: "",
        status: "",
        reason: "",
        level: "",
        supplier_search: "",
        client_search: "",
        card_search: "",
        month_at: newStartedAt.toJSON().substr(0, 7),
        started_at: newStartedAt.toJSON().substr(0, 10),
        finished_at: newFinishedAt.toJSON().substr(0, 10),
      },
      orderKey: 0,
      order: 1,
      filterKey: "",
      company_name_key: 0,
      card_id_key: 0,
    };
  },
  computed: {
    columns() {
      return this.createColumns();
    },
    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.order;
      }
      let data = this.source;
      if (this.filterKey) {
        const filterKey = this.filterKey && this.filterKey.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (orderKey) {
        this.order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    },
    ...mapGetters({
      header_to_ids: "visit/header_to_ids",
      source: "visit/list_source",
      gridMeta: "visit/list_meta",
      isAdmin: "auth/isAdmin",
      isSaleManager: "auth/isSaleManager",
      isSupportManager: "auth/isSupportManager",
      country: "auth/country",
      citiesList: "config/citiesList",
    }),
    currencyFormat() {
      const formatter = buildCurrencyFormatter(this.country);
      return formatter.format;
    },
    tags_from_params() {
      let tags = [];
      if (this.params.id) {
        tags.push({ name: "id", value: this.params.id });
      }
      if (this.params.status) {
        tags.push({ name: "status", value: this.params.status });
      }
      if (this.params.reason) {
        tags.push({ name: "reason", value: this.params.reason });
      }
      if (this.params.supplier_search) {
        tags.push({ name: "supplier_search", value: this.params.supplier_search });
      }
      if (this.params.city) {
        tags.push({ name: "city", value: this.params.city });
      }
      if (this.params.card_search) {
        tags.push({ name: "card_search", value: this.params.card_search });
      }
      if (this.params.level) {
        tags.push({ name: "level", value: this.params.level });
      }
      if (this.params.client_search) {
        tags.push({ name: "client_search", value: this.params.client_search });
      }
      if (this.params.started_at) {
        tags.push({ name: "date", value: this.params.started_at });
      }
      if (this.params.finished_at) {
        tags.push({ name: "date_finish", value: this.params.finished_at });
      }
      return tags;
    },
  },
  methods: {
    getIsGeoMocked(entry) {
      const geoMockHeaderIndex = this.$store.state.visit.header_to_ids.geo_mocked;
      return entry[geoMockHeaderIndex];
    },
    formatCurrency(value) {
      return this.currencyFormat ? this.currencyFormat(value / 100) : value;
    },
    async setPeriod() {
      const month = new Date(this.params.month_at);
      this.params.started_at = month.toJSON().substr(0, 10);
      this.$route.query.start_at = this.params.started_at;
      month.setMonth(month.getMonth() + 1);
      month.setDate(0);
      this.params.finished_at = month.toJSON().substr(0, 10);
      await this.fireFetchVisits();
    },
    createColumns() {
      let headers = [
        { key: "id", name: "Visit ID" },
        { key: "registered", name: "Registered" },
        { key: "status", name: "Status" },
        { key: "company_id", name: "Company" },
        { key: "holder", name: "Holder" },
        { key: "sup_name", name: "Supplier" },
        { key: "lat", name: "CheckLocation" },
      ];
      if (this.isSupportManager) {
        headers.push({ key: "sup_type", name: "Type" });
        headers.push({ key: "price", name: "Price Of Entrance" });
      }
      if (this.isSaleManager) {
        headers.push({ key: "card_price", name: "Price Of Card" });
      }
      headers.push({ key: "reason", name: "Reason" });
      if (this.isSaleManager) {
        headers.push({ key: "company_type", name: "Type" });
        headers.push({ key: "company_size", name: "Size" });
        headers.push({ key: "card_level", name: "Card Level" });
      }
      headers.push({ key: "city", name: "City" });
      const header_to_ids = this.header_to_ids;
      headers = headers.map((header) => {
        header.title = header.name;
        header.name = header.key;
        header.key = header_to_ids[header.key];
        return header;
      });
      if (header_to_ids["company_name"]) {
        this.company_name_key = header_to_ids["company_name"];
      }
      if (header_to_ids["card_id"]) {
        this.card_id_key = header_to_ids["card_id"];
      }
      return headers;
    },
    ...mapActions({
      fetchVisits: "visit/visit_list",
      excelVisits: "visit/visit_print_download",
    }),
    async fireFetchVisits() {
      try {
        this.fetching = true;
        await this.fetchVisits({ page: this.pageNum, tags: this.tags_from_params });
      } catch (e) {
        this.fetching = false;
        throw e;
      }
      this.fetching = false;
    },
    async exportExcel() {
      try {
        this.fetching = true;
        await this.excelVisits({ tags: this.tags_from_params, ahref: this.$refs.download });
      } catch (e) {
        this.fetching = false;
        throw e;
      }
      this.fetching = false;
    },
    sortBy: function (key) {
      this.orderKey = key;
      this.order = this.order * -1;
    },
    async extractParamsAndRun(to) {
      if (to.query.started_at) {
        this.params.started_at = to.query.started_at;
        this.params.month_at = this.params.started_at.substr(0, 7);
      }
      if (to.query.finished_at) {
        this.params.finished_at = to.query.finished_at;
      }
      if (to.params.started_at) {
        this.params.started_at = to.params.started_at;
        this.params.month_at = this.params.started_at.substr(0, 7);
      }
      if (to.params.finished_at) {
        this.params.finished_at = to.params.finished_at;
      }
      await this.fireFetchVisits();
    },
  },
  watch: {
    $route(to, from) {
      this.extractParamsAndRun(to);
    },
  },
  async mounted() {
    await this.$store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, [{ country: [this.$store.state.auth.country] }]);
    await this.extractParamsAndRun(this.$route);
  },
};
</script>

<style scoped lang="sass">
#demo
  font-family: Helvetica Neue, Arial, sans-serif
  font-size: 14px
  color: #444

th, td
  min-width: 120px
  padding: 10px 20px

.fake_geo
  color: #f00
</style>
<style lang="sass">
.pagination
  display: -ms-flexbox
  display: flex
  padding-left: 0
  list-style: none
  border-radius: .25rem

  li.disabled
    background-color: gray
    .page-link
      cursor: default
      background-color: gray
      color: white

  .page-link
    cursor: pointer
    position: relative
    display: block
    padding: .5rem .75rem
    margin-left: -1px
    line-height: 1.25
    color: #007bff
    background-color: #fff
    border: 1px solid #dee2e6
  .page-item.active
    .page-link
      background-color: #007bff
      color: #fff
  .page-item:first-child > .page-link
    margin-left: 0
    border-top-left-radius: .25rem
    border-bottom-left-radius: .25rem
</style>
