<template>
  <small-layout>
    <div id="demo">
      <h1>Journal of Visits</h1>
      <form id="search">
        <fieldset>
          <label for="month_at">Month</label>
          <input type="month" id="month_at" @change="setPeriod" v-model="params.month_at" />
          <label for="started_at">Started</label>
          <input type="date" id="started_at" v-model="params.started_at" />
          <label for="finished_at">Finished</label>
          <input type="date" id="finished_at" v-model="params.finished_at" />
          <div>
            <label for="query">Search here</label>
            <input id="query" name="query" v-model="filterKey" />
          </div>
          <div>
            <label for="params_id">ID:</label>
            <input id="params_id" type="number" v-model="params.id" />
          </div>
          <div>
            <label for="params_supplier">SupplierID:</label>
            <input id="params_supplier" type="text" v-model="params.supplier_id" />
          </div>
          <div v-if="isSaleManager">
            <label for="params_client">CompanyID:</label>
            <input id="params_client" type="text" v-model="params.company_id" />
          </div>
          <div>
            <label for="params_card">CardID:</label>
            <input id="params_card" type="text" v-model="params.card_id" />
          </div>
          <button type="button" @click="fireFetchVisits">Search</button>
        </fieldset>
      </form>
      <table>
        <thead>
          <tr>
            <td colspan="100%">
              <paginate
                :page-count="gridMeta.last_page"
                :page-range="3"
                :margin-pages="2"
                :value="gridMeta.current_page"
                :click-handler="changePage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :prev-link-class="'page-link'"
                :next-link-class="'page-link'"
                :page-link-class="'page-link'"
              >
              </paginate>
            </td>
          </tr>
        </thead>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.name | capitalize }}
              <span class="arrow" :class="order > 0 ? 'asc' : 'dsc'"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <td>{{ entry.id }}</td>
            <td>{{ entry.activated }}</td>
            <td>{{ entry.attraction ? entry.attraction.name : "..." }}</td>
            <td>
              <router-link
                :to="{
                  name: 'search_visit_card',
                  params: { card_id: entry.card_id, started_at: params.started_at, finished_at: params.finished_at },
                }"
                >{{ entry.holder }}</router-link
              >
            </td>
            <td v-if="isSupportManager">{{ entry.price }}</td>
            <td>
              <router-link
                v-if="entry.company.id"
                :to="{
                  name: 'search_visit_company',
                  params: {
                    company_id: entry.company.id,
                    started_at: params.started_at,
                    finished_at: params.finished_at,
                  },
                }"
                >{{ entry.company.name }}</router-link
              >
            </td>
            <td>
              <router-link
                v-if="entry.supplier.id"
                :to="{
                  name: 'search_visit_supplier',
                  params: {
                    supplier_id: entry.supplier.id,
                    started_at: params.started_at,
                    finished_at: params.finished_at,
                  },
                }"
                >{{ entry.supplier.name }}</router-link
              >
            </td>
            <td>{{ entry.created_at }}</td>
            <td v-if="isSaleManager">{{ entry.status }}</td>
            <td v-if="isSaleManager">{{ entry.aggregator }}</td>
            <td>{{ entry.reason_text }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </small-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
export default {
  components: {
    Paginate,
  },
  data: function () {
    let newStartedAt = new Date();
    let newFinishedAt = new Date();
    newStartedAt.setMonth(newFinishedAt.getMonth() - 1);
    newStartedAt.setDate(1);
    newFinishedAt.setDate(0);
    return {
      source: [],
      pageNum: 1,
      params: {
        id: "",
        supplier_id: "",
        company_id: "",
        card_id: "",
        month_at: newStartedAt.toJSON().substr(0, 7),
        started_at: newStartedAt.toJSON().substr(0, 10),
        finished_at: newFinishedAt.toJSON().substr(0, 10),
      },
      orderKey: 0,
      order: 1,
      filterKey: "",
    };
  },
  computed: {
    columns() {
      return this.createColumns();
    },
    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.order;
      }
      let data = this.source;
      if (this.filterKey) {
        const filterKey = this.filterKey && this.filterKey.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (orderKey) {
        this.order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    },
    ...mapGetters({
      gridMeta: "visit/search_meta",
      isSaleManager: "auth/isSaleManager",
      isSupportManager: "auth/isSupportManager",
    }),
    tags_from_params() {
      let tags = [];
      if (this.params.id) {
        tags.push({ name: "id", value: this.params.id });
      }
      if (this.params.supplier_id) {
        tags.push({ name: "supplier_id", value: this.params.supplier_id });
      }
      if (this.params.started_at) {
        tags.push({ name: "started_at", value: this.params.started_at });
      }
      if (this.params.finished_at) {
        tags.push({ name: "finished_at", value: this.params.finished_at });
      }
      if (this.params.card_id) {
        tags.push({ name: "card_id", value: this.params.card_id });
      }
      if (this.params.company_id) {
        tags.push({ name: "company_id", value: this.params.company_id });
      }
      return tags;
    },
  },
  methods: {
    setPeriod() {
      const month = new Date(this.params.month_at);
      this.params.started_at = month.toJSON().substr(0, 10);
      month.setMonth(month.getMonth() + 1);
      month.setDate(0);
      this.params.finished_at = month.toJSON().substr(0, 10);
    },
    createColumns() {
      let headers = [
        { key: "id", name: "Visit ID" },
        { key: "activated", name: "Activated" },
        { key: "attraction", name: "Attraction" },
        { key: "holder", name: "Holder" },
      ];
      if (this.isSupportManager) {
        headers.push({ key: "price", name: "Price" });
      }
      headers.push({ key: "company", name: "Company" });
      headers.push({ key: "supplier", name: "Supplier" });
      headers.push({ key: "created_at", name: "CreatedAt" });
      if (this.isSaleManager) {
        headers.push({ key: "status", name: "Status" });
        headers.push({ key: "aggregator", name: "Aggregator" });
      }
      headers.push({ key: "reason_text", name: "Reason" });
      return headers;
    },
    ...mapActions({
      fetchVisits: "visit/visit_search",
    }),
    async fireFetchVisits(e) {
      this.source = await this.fetchVisits({ page: this.gridMeta.current_page, tags: this.tags_from_params });
    },
    async changePage(pageNum) {
      this.source = await this.fetchVisits({ page: pageNum, tags: this.tags_from_params });
    },
    sortBy: function (key) {
      this.orderKey = key;
      this.order = this.order * -1;
    },
    async extractParamsAndRun(to) {
      this.params.card_id = "";
      this.params.supplier_id = "";
      this.params.company_id = "";
      if (to.params.supplier_id) {
        this.params.supplier_id = to.params.supplier_id;
      }
      if (to.params.card_id) {
        this.params.card_id = to.params.card_id;
      }
      if (to.params.company_id) {
        this.params.company_id = to.params.company_id;
      }
      if (to.params.started_at) {
        this.params.started_at = to.params.started_at;
      }
      if (to.params.finished_at) {
        this.params.finished_at = to.params.finished_at;
      }
      this.source = await this.fetchVisits({ page: this.gridMeta.current_page, tags: this.tags_from_params });
    },
  },
  watch: {
    $route(to, from) {
      this.extractParamsAndRun(to);
    },
  },
  async mounted() {
    this.extractParamsAndRun(this.$route);
  },
};
</script>

<style scoped lang="sass">
#demo
  font-family: Helvetica Neue, Arial, sans-serif
  font-size: 14px
  color: #444

th, td
  min-width: 120px
  padding: 10px 20px
</style>
<style lang="sass">
.pagination
  display: -ms-flexbox
  display: flex
  padding-left: 0
  list-style: none
  border-radius: .25rem

  li.disabled
    background-color: gray
    .page-link
      cursor: default
      background-color: gray
      color: white

  .page-link
    cursor: pointer
    position: relative
    display: block
    padding: .5rem .75rem
    margin-left: -1px
    line-height: 1.25
    color: #007bff
    background-color: #fff
    border: 1px solid #dee2e6
  .page-item.active
    .page-link
      background-color: #007bff
      color: #fff
  .page-item:first-child > .page-link
    margin-left: 0
    border-top-left-radius: .25rem
    border-bottom-left-radius: .25rem
</style>
