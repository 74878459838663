import { ref, onBeforeMount, unref, reactive } from "@vue/composition-api";
import cloneDeep from "lodash/cloneDeep";
import isString from "lodash/isString";
import merge from "lodash/merge";

import { camelCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";
import { prepareAxiosErrors } from "@/helpers/index";
import request from "@/helpers/request";

const AVAILABLE_HOOKS = {
  onBeforeMount,
};
const AVAILABLE_HOOKS_LIST = Object.keys(AVAILABLE_HOOKS);

const ERRORS_FORMATS = {
  default: "default",
  flat: "flat",
};

const REQUEST_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
};

const DEFAULT_PROCESSING_METHODS = {
  [REQUEST_METHODS.GET]: false,
  [REQUEST_METHODS.POST]: false,
  [REQUEST_METHODS.PUT]: false,
  CUSTOM: false,
  DOWNLOAD: false,
};

const DEFAULT_CONFIG = {
  data: null,
  defaultValue: null,
  errorsFormat: ERRORS_FORMATS.default,
  hook: null,
  metaFields: false,
  method: REQUEST_METHODS.GET,
  omitData: false,
  url: null,
};

export default function useRequest(params = {}) {
  const config = cloneDeep(merge({}, DEFAULT_CONFIG, params));

  const data = ref(config.defaultValue);
  const errors = ref({});
  const errorMessage = ref(null);
  const processing = ref(false);
  const processingMethods = reactive(cloneDeep(DEFAULT_PROCESSING_METHODS));

  let requestsQueue = [];

  if (config.hook && AVAILABLE_HOOKS_LIST.includes(config.hook)) {
    AVAILABLE_HOOKS[config.hook](() => {
      fetch();
    });
  }

  function resetData() {
    data.value = ref(config.defaultValue);
  }

  function setData(newData) {
    data.value = newData;
  }

  function resetErrors() {
    errors.value = {};
  }

  function setErrors(newErrors) {
    errors.value = newErrors;
  }

  function clearRequestsQueue() {
    requestsQueue = requestsQueue.filter(({ done }) => !done);

    Object.assign(processingMethods, DEFAULT_PROCESSING_METHODS);

    if (requestsQueue.length === 0) {
      processing.value = false;
    } else {
      const activeProcessingMethodsList = requestsQueue.map(
        ({ method }) => method
      );
      const activeProcessingMethodsSet = new Set(activeProcessingMethodsList);

      activeProcessingMethodsSet.forEach((method) => {
        processingMethods[method] = true;
      });
    }
  }

  async function fetch(fetchParams = {}) {
    const userFetchConfig = isString(fetchParams)
      ? { url: fetchParams }
      : fetchParams;
    const fetchConfig = merge({}, config, userFetchConfig);

    const requestTraceKey =
      fetchConfig.traceKey || fetchConfig.method.toUpperCase();

    errorMessage.value = null;
    errors.value = {};
    processing.value = true;
    processingMethods[requestTraceKey] = true;
    data.value = fetchConfig.omitData ? data.value : fetchConfig.defaultValue;

    const url = fetchConfig.url;

    let error = false;
    let responseErrorMessage = null;
    let responseErrors = {};
    let responseErrorsExtra = {};
    let meta = {};

    if (!url) {
      throw `Need to pass 'url' param into useRequest composable or fetch function!`;
    }

    const requestConfig = {
      url,
      method: fetchConfig.method,
      data: fetchConfig.data,
      originalResponse: fetchConfig.metaFields,
    };

    const requestsQueueItem = {
      url,
      method: requestTraceKey,
      done: false,
    };

    requestsQueue.push(requestsQueueItem);

    let responseData = null;

    try {
      responseData = await request(requestConfig);

      if (requestConfig.originalResponse) {
        meta = camelCaseObjectKeys(responseData.meta);
        responseData = responseData.data;
      }

      if (!fetchConfig.omitData) {
        data.value = responseData;
      }
    } catch (xhrError) {
      const {
        errors: preparedErrors,
        errorMessage: preparedErrorMessage,
        extra: preparedErrorsExtra,
      } = prepareAxiosErrors(xhrError);

      error = true;

      if (fetchConfig.errorsFormat === ERRORS_FORMATS.default) {
        responseErrors = preparedErrors;
        responseErrorMessage = preparedErrorMessage;
      } else {
        responseErrors = {
          ...preparedErrors,
          errorMessage: preparedErrorMessage,
        };
      }

      responseErrorsExtra = preparedErrorsExtra;
      errorMessage.value = responseErrorMessage;
      errors.value = {
        ...errors.value,
        ...responseErrors,
      };
    }

    requestsQueueItem.done = true;

    clearRequestsQueue();

    return {
      data: unref(data),
      error,
      errorMessage: responseErrorMessage,
      errors: responseErrors,
      errorsExtra: responseErrorsExtra,
      meta,
      responseData,
    };
  }

  return {
    data,

    errors,
    errorMessage,

    fetch,

    processing,
    processingMethods,

    resetData,
    setData,

    resetErrors,
    setErrors,
  };
}
