<template>
  <small-layout>
    <div>
      <h1>Карты</h1>
      <form v-if="columnFormToggler" id="columnFormToggler" @submit.prevent="addColumnForm">
        <div>
          <label for="column_name">Column Name</label>
          <input id="column_name" type="text" />
        </div>
        <div>
          <label for="column_key">Columns</label>
          <select id="column_key">
            <optgroup label="Card">
              <option value="holder">holder</option>
              <option value="level">level</option>
              <option value="activated">On?</option>
              <option value="phone_number">PhoneID</option>
              <option value="updated_at">Updated</option>
            </optgroup>
          </select>
        </div>
        <button type="submit">Add</button>
      </form>
      <div>
        <button type="button" @click="columnFormToggler = !columnFormToggler">Форма добавления столбцов</button>
        <button type="button" @click="clearColumnsAndRefresh">Вернуть Столбцы по умолчанию</button>
      </div>
      <div></div>
      <label for="rowsPerPage">Rows Per Page</label>
      <form id="search">
        Local Search <input name="query" v-model="filterKey" />
        <button @click.prevent="doServerSearch">Do Server Search</button>
        <download-csv :data="getHoldersJson" class="btn btn-default"> Download Holders CSV </download-csv>
        <div v-if="filterFixedKey">{{ filterFixedKey }}<button @click="filterFixedKey = ''">X</button></div>
      </form>
      <table>
        <TableHeader
          :additional-columns="additionalColumns"
          :columns="columns"
          :insert-additional-cols-at-start="true"
          :orderKey="orderKey"
          @update:orderKey="sortBy"
        />
        <tbody>
          <tr v-for="entry in filteredSource">
            <td>
              <router-link :to="{ name: 'search_visit_card', params: { card_id: entry.id } }">{{
                entry.id
              }}</router-link>
            </td>
            <td>
              <router-link
                v-if="entry.company_id"
                :to="{ name: 'company_view', params: { company: entry.company_id, country } }"
                >{{ entry.company_name }}</router-link
              >
            </td>
            <td v-for="column in columns">
              <span v-if="column.key === 'holder'">
                <router-link
                  :to="{
                    name: 'search_visit_card',
                    params: { card_id: entry.id, started_at: params.started_at, finished_at: params.finished_at },
                  }"
                  >{{ entry.holder }}</router-link
                >
              </span>
              <span v-else-if="column.key === 'level'">
                <span :class="entry.activated ? 'card_on' : 'card_off'">
                  {{ entry[column.key] }}
                </span>
              </span>
              <span v-else-if="entry[column.key]">{{ entry[column.key] }}</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="100%">
              <paginate
                :page-count="gridMeta.last_page"
                :page-range="3"
                :margin-pages="2"
                :value="gridMeta.current_page"
                :click-handler="changePage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :prev-link-class="'page-link'"
                :next-link-class="'page-link'"
                :page-link-class="'page-link'"
              >
              </paginate>
            </td>
          </tr>
        </tfoot>
      </table></div
  ></small-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import SortMixin from "../mixins/SortMixin";
import JsonCSV from "vue-json-csv";
import { formatToRFC3339 } from "../middleware/functions";

import TableHeader from "@/components/tables/TableHeader";

export default {
  components: {
    DownloadCsv: JsonCSV,
    Paginate,
    TableHeader,
  },
  mixins: [SortMixin],
  data() {
    const date = new Date();
    date.setDate(1);
    const date_finish = new Date();
    date_finish.setMonth(date.getMonth() + 1);
    date_finish.setDate(0);
    return {
      finished_at: new Date(date.getFullYear(), date.getMonth() + 1, 0, 10, 10, 10).toJSON().substr(0, 7),
      summary_stats_disabled: false,
      extended_statuses: ["", "new", "continue", "reject"],
      source: [],
      columnFormToggler: false,
      filterFixedKey: "",
      params: {
        started_at: formatToRFC3339(date, true),
        finished_at: formatToRFC3339(date_finish, true),
      },
      additionalColumns: ["ID", "Клиент (компания)"],
    };
  },
  computed: {
    ...mapGetters({
      country: "auth/country",
      gridMeta: "card/card_list_meta",
      isSuperAdmin: "auth/isSuperAdmin",
    }),
    getHoldersJson() {
      return this.filteredSource.map((card) => {
        return {
          holder: card.holder,
          extended_status: card.extended_status,
          level: card.level,
          activated: card.activated,
          company_name: card.company_name,
          fi: this.extractNameSurname(card.holder),
          phone_number: card.phone_number,
        };
      });
    },
  },
  methods: {
    async doServerSearch() {
      this.filterFixedKey = this.filterKey;
      await this.changePage(1);
    },
    extractNameSurname(holder) {
      if (!holder) {
        return holder;
      }
      let parts = holder.replace(/ +/g, " ").split(" ");
      if (parts.length > 2) {
        return parts[0] + " " + parts[1];
      }
      return holder;
    },
    createColumns() {
      const columns = this.getColumns();
      if (!columns || !columns.length) {
        return this.clearColumns();
      }
      return columns;
    },
    getColumns() {
      try {
        let data = JSON.parse(localStorage.getItem("journal_card_index_columns"));
        return data.filter((item) => {
          return item && item.key && item.name && item.order;
        });
      } catch (e) {
        return [];
      }
    },
    clearColumns() {
      this.saveColumns([
        { key: "holder", name: "holder", order: 1 },
        { key: "level", name: "level", order: 1 },
        { key: "phone_number", name: "Phone", order: 1 },
        { key: "money_prev_month", name: "money_prev_month", order: 1 },
        { key: "visits_prev_month", name: "visits_prev_month", order: 1 },
        { key: "money_this_month", name: "money_this_month", order: 1 },
        { key: "visits_this_month", name: "visits_this_month", order: 1 },
        { key: "updated_at", name: "updated_at", order: 1 },
        { key: "created_at", name: "created_at", order: 1 },
      ]);
      return this.getColumns();
    },
    clearColumnsAndRefresh() {
      this.columns = this.clearColumns();
    },
    saveColumns(columns) {
      localStorage.setItem("journal_card_index_columns", JSON.stringify(columns));
    },
    addColumn(column) {
      let columns = this.getColumns();
      columns.push(column);
      this.saveColumns(columns);
      this.columns = columns;
    },
    addColumnForm(e) {
      let key = e.target.column_key.value;
      let name = "";
      if (e.target.column_key.selectedOptions.length) {
        name = e.target.column_key.selectedOptions[0].text;
      }
      if (e.target.column_name.value) {
        name = e.target.column_name.value;
      }
      this.addColumn({ key: key, name: name, order: 1 });
      this.columns = this.getColumns();
    },
    removeColumn(item) {
      let columns = this.getColumns();
      columns = columns.filter((column) => column.key != item.key);
      this.saveColumns(columns);
      this.columns = columns;
    },
    ...mapActions({
      fetchCards: "card/card_list",
      downloadSummaryStatistics: "card/download_summary_stats",
    }),
    async changePage(pageNum) {
      this.source = await this.fetchCards({ page: pageNum, search: this.filterFixedKey });
    },
    async fireDownloadSummaryStatistics(params) {
      this.summary_stats_disabled = true;
      try {
        await this.downloadSummaryStatistics(params);
      } catch (e) {}
      this.summary_stats_disabled = false;
    },
  },
  async mounted() {
    this.source = await this.fetchCards({ page: this.gridMeta.current_page, search: this.filterFixedKey });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

@include table-hoverable;

.card_on {
  background: #d2fced;
}
.card_off {
  background: #e7b6b6;
}
.btn {
  background-color: grey; /* grey */
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
</style>
