const AUDITABLE_TYPES = {
  attraction: "App\\Attraction",
  card: "App\\Card",
  claimact: "App\\ClaimAct",
  claimcompany: "App\\ClaimCompany",
  claiminvoice: "App\\ClaimInvoice",
  company: "App\\Company",
  electronicmail: "App\\ElectronicMail",
  supplier: "App\\Supplier",
  supplieract: "App\\SupplierAct",
  suppliertoken: "App\\Modules\\Holder\\Models\\SupplierToken",
  user: "App\\User",
  visit: "App\\Visit",
};

export default AUDITABLE_TYPES;
