<template>
  <a href="#" class="add-instance-button" @click.prevent="onClick">
    <Icon icon="add-circle" class="add-instance-button_icon" />
    <slot></slot>
  </a>
</template>

<script>
import Icon from "@/components/icons/Icon.vue";

export default {
  components: {
    Icon,
  },

  emits: ["click"],

  setup(props, { emit }) {
    function onClick() {
      emit("click");
    }

    return {
      onClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-instance-button {
  color: #444545;
  font-weight: 500;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  line-height: 1.4em;

  &:hover {
    text-decoration: underline;
  }

  &_icon {
    margin-right: 10px;
  }
}
</style>
