import axios from "axios";
import { buildQueryFromObject } from "../middleware/api_functions";

/**
 * Created by Alexander Golovnya on 2019-06-17.
 */
export default {
  supplier_list(params) {
    const query = buildQueryFromObject(params);
    return axios.get(`/api/suppliers${query}`).then((response) => {
      return response.data.data;
    });
  },
  supplier_get(id) {
    return axios.get(`/api/suppliers/${id}`).then((response) => {
      return response.data.data;
    });
  },
  supplier_update(id, data) {
    return axios.put(`/api/suppliers/${id}`, data).then((response) => {
      return response.data.data;
    });
  },
  supplier_delete(id) {
    return axios.delete(`/api/suppliers/${id}`).then((response) => {
      return response.data;
    });
  },
  supplier_clone(data) {
    return axios.post(`/api/suppliers`, data).then((response) => {
      return response.data.data;
    });
  },
  supplier_image(id, format) {
    return axios.get(`/api/jrnl/admin/suppliers/print/${id}?format=${format}`, {
      responseType: "blob", //Force to receive data in a Blob Format
    });
  },
};
