<template>
  <div class="photo-block">
    <img :src="photoUrl" :alt="photo.id" />
    <div class="photo-block__wrapper">
      <p>{{ formattedDate }}</p>
      <button @click="downloadPhoto">Load/Reload</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, computed } from "@vue/composition-api";
import dateToLocaleString from "@/helpers/date/dateToLocaleString";

export default {
  props: {
    photo: Object,
  },
  setup(props) {
    const photoUrl = ref("data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=");
    const photo = props.photo;
    const downloadPhoto = () => {
      axios.get(`/api/helpdesk/card/${photo.holder_id}/photo/${photo.id}`).then((resource) => {
        const url = resource.data.url;
        if (undefined === url) {
          return;
        }
        photoUrl.value = url;
      });
    };

    const formattedDate = computed(() => {
      return dateToLocaleString(props.photo.created_at);
    });

    return { downloadPhoto, photoUrl, formattedDate };
  },
};
</script>

<style lang="scss" scoped>
.photo-block {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}
</style>
