<template>
  <ModalContent @close="close" tag="form" @submit.prevent="onSubmit">
    <template slot="header">
      <h3 v-if="state.isCorrection">Invoice correction</h3>
      <h3 v-else-if="isEditMode">Edit Order Invoice Details</h3>
      <h3 v-else>Add Order Invoice Details</h3>
    </template>
    <template slot="content">
      <template v-if="!state.isCorrection">
        <label class="row">
          <span>Subscription level</span>
          <NewSelectInput v-model="state.details.level" :options="subscriptionLevels" placeholder="Select an option" />
        </label>
        <label class="row">
          <span>Amount</span>
          <BaseInput ref="refAmount" v-model="state.details.amount" validator="number" />
        </label>
      </template>
      <label v-for="policy in modalPayload.invoicePricingPoliciesList" class="row" :key="policy">
        <span>{{ getPolicyName(policy) }}</span>
        <CurrencyInput ref="refPrices" v-model="state.details.prices[policy]" />
      </label>
    </template>
    <template slot="controls">
      <Button button-type="light" @click.prevent="close" auto-width>Cancel</Button>
      <Button auto-width type="submit" :disabled="!isSubmitButtonActive">
        <template v-if="state.isCorrection">
          <template v-if="modalPayload.summaryItem.id">Change correction</template>
          <template v-else>Add correction</template>
        </template>
        <template v-else>
          <template v-if="isEditMode">Change details</template>
          <template v-else>Add details</template>
        </template>
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, reactive, ref, unref, onBeforeMount } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import CurrencyInput from "@/components/inputs/CurrencyInput.vue";
import ModalContent from "@/components/modal/ModalContent.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";

import store from "@/store";
import { MODAL_MODES } from "@/store/modules/modal";
import { INVOICE_CORRECTIONS_KEY } from "../OrderInvoiceDetails.vue";

export default {
  components: {
    Button,
    CurrencyInput,
    ModalContent,
    NewSelectInput,
    BaseInput,
  },

  setup(props, { emit }) {
    const refAmount = ref([]);
    const refPrices = ref([]);

    const state = reactive({
      isCorrection: false,

      details: {
        id: null,
        level: null,
        amount: "",
        prices: {},
      },
    });

    const modalType = computed(() => store.state.modal.type);
    const modalMode = computed(() => store.state.modal.mode);
    const modalPayload = computed(() => store.state.modal.payload);

    const country = computed(() => store.state.auth.country);
    const isEditMode = computed(() => modalType.value && modalMode.value === MODAL_MODES[modalType.value].edit);

    const isSubmitButtonActive = computed(() => {
      const { level, amount } = state.details;
      const isLevelDefined = Boolean(level);
      const isAmountDefined = amount.toString().length > 0 && !refAmount.value.isError;
      const isPricesDefined = refPrices.value.every((item) => item.value !== null && !item.isError);

      const isSubmitButtonActive = isLevelDefined && isAmountDefined && isPricesDefined;

      return isSubmitButtonActive;
    });

    const subscriptionLevels = computed(() => {
      const levelsList = store.state.config.portal.levels[country.value];
      const subscriptionLevels = levelsList.map((level) => ({ text: level, value: level }));
      return subscriptionLevels;
    });

    function getPolicyName(policy) {
      return modalPayload.value.pricingPolicies[policy] ?? policy;
    }

    function close() {
      emit("close");
    }

    function onSubmit() {
      const { invoicePricingPoliciesList } = unref(modalPayload);
      const { id, level, amount, prices } = state.details;

      let preparedDetails = {
        id,
        level,
        policies: {},
      };

      if (!state.isCorrection) {
        preparedDetails.amount = amount;
      }

      invoicePricingPoliciesList.forEach((policy) => {
        preparedDetails.policies[policy] = {
          price: prices[policy],
        };
      });

      if (modalPayload.value.onAccept) {
        modalPayload.value.onAccept(preparedDetails);
      }

      close();
    }

    onBeforeMount(() => {
      if (isEditMode.value) {
        const { id, level, amount, policies } = modalPayload.value.summaryItem;
        const prices = {};

        state.isCorrection = level === INVOICE_CORRECTIONS_KEY;

        Object.entries(policies).forEach(([policy, { price }]) => {
          prices[policy] = price;
        });

        state.details = {
          id,
          level,
          amount: state.isCorrection ? 1 : amount,
          prices,
        };
      }
    });

    return {
      refAmount,
      refPrices,

      state,

      isEditMode,
      isSubmitButtonActive,
      subscriptionLevels,
      modalPayload,

      getPolicyName,
      close,
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
.error_banner {
  margin: 0 0 24px;
}

label.row {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 24px;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 18px;
    padding-bottom: 16px;
    line-height: 1.2em;
  }
}
</style>
