export const STORAGE_PREFIX = "journal_";
export const DEFAULT_STORAGE_KEY = "config";
export const STORAGE_KEYS = {
  auth: {
    details: "auth_details",
  },
};

export default {
  DEFAULT_STORAGE_KEY,
  STORAGE_PREFIX,
  STORAGE_KEYS,
};
