function dateToLocaleString(dateString) {
  if (!dateString) {
    return "–";
  }

  const date = new Date(dateString);
  return date.toLocaleString();
}

export default dateToLocaleString;
