<template>
  <div v-click-outside="closeMenu" class="button_container" :class="{ button_container__opened: state.isOpened }">
    <button type="button" @click="toggleState">
      <Icon icon="dots-vertical" fill="#000" />
    </button>
    <ul class="actions-menu" :class="{ 'actions-menu--top': state.positionTop }" ref="actionsMenuRef">
      <template v-for="action in props.actions">
        <li v-if="isActionAvailable(action)" :key="`action_${action.key}`">
          <RouterLink
            v-if="isLink(action)"
            v-bind="getLinkAttrs(action)"
            @mouseenter="onMouseEnter(action.key)"
            @mouseleave="onMouseLeave(action.key)"
          >
            {{ $t(action.title) }}
            <Icon v-if="action.icon" class="icon" :icon="action.icon.type" :fill="iconFillStyle(action)" />
          </RouterLink>
          <a
            v-else
            href="#"
            @mouseenter="onMouseEnter(action.key)"
            @mouseleave="onMouseLeave(action.key)"
            @click.prevent="triggerEvent(action)"
          >
            {{ $t(action.title) }}
            <Icon v-if="action.icon" class="icon" :icon="action.icon.type" :fill="iconFillStyle(action)" />
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { nextTick, reactive, ref, unref } from "@vue/composition-api";
import isFunction from "lodash/isFunction";

import Icon, { DEFAULT_ICON_FILL_COLOR } from "@/components/icons/Icon.vue";

import store from "@/store";
import { MODAL_TYPES, MODAL_ACTION_OPEN } from "@/store/modules/modal";

const ACTION_EVENT = "action";

export default {
  components: {
    Icon,
  },

  props: {
    actions: {
      type: Array,
      required: true,
    },
    item: {
      type: [Object, Array],
      default: null,
    },
  },

  setup(props, { emit }) {
    const actionsKeysList = props.actions.map((action) => action.key);
    const defaultHoverState = actionsKeysList.reduce((hover, key) => Object.assign({}, hover, { [key]: false }), {});

    const actionsMenuRef = ref(null);

    const state = reactive({
      isOpened: false,
      positionTop: false,

      hover: defaultHoverState,
    });

    function closeMenu() {
      toggleState("close");
    }

    function getLinkAttrs(action) {
      if (isFunction(action.attrs)) {
        return action.attrs(props.item);
      }

      return action.attrs;
    }

    function iconFillStyle(action) {
      if (state.hover[action.key]) {
        return action.icon.color;
      }
      return DEFAULT_ICON_FILL_COLOR;
    }

    function isLink(action) {
      return action.type === "link";
    }

    function onMouseEnter(key) {
      state.hover[key] = true;
    }

    function onMouseLeave(key) {
      state.hover[key] = false;
    }

    function toggleState(newState) {
      state.isOpened = newState === "close" ? false : !state.isOpened;

      nextTick(updateMenuPosition);
    }

    function updateMenuPosition() {
      if (state.isOpened) {
        const menu = unref(actionsMenuRef);
        const menuRect = menu.getBoundingClientRect();

        const menuBottomPosition = menuRect.top + menuRect.height;

        if (menuBottomPosition > window.innerHeight) {
          state.positionTop = true;
        } else {
          state.positionTop = false;
        }
      }
    }

    function isActionAvailable(action) {
      if (isFunction(action.checkIsAvailable)) {
        return action.checkIsAvailable(props.item);
      }

      return true;
    }

    function triggerEvent(action) {
      if (action.confirmationRequired) {
        const modalConfig = {
          type: MODAL_TYPES.action,
          payload: {
            onAccept: emit.bind(null, ACTION_EVENT, action.key),
          },
        };

        store.dispatch(MODAL_ACTION_OPEN, modalConfig);
      } else {
        emit(ACTION_EVENT, action.key);
      }

      toggleState("close");
    }

    return {
      props,

      actionsMenuRef,

      state,

      closeMenu,
      getLinkAttrs,
      iconFillStyle,
      isLink,
      onMouseEnter,
      onMouseLeave,
      toggleState,
      triggerEvent,
      isActionAvailable,
    };
  },
};
</script>

<style lang="scss" scoped>
.button_container {
  width: 24px;
  height: 24px;
  position: relative;

  button {
    background: none;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .actions-menu {
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    list-style-type: none;
    background: #fff;
    padding: 0 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    display: none;
    z-index: 1;

    &--top {
      top: inherit;
      bottom: calc(100% + 12px);
    }

    li {
      padding: 8px 0;

      a {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          color: #000;
        }

        .icon {
          margin-left: 24px;
        }
      }
    }
  }

  &__opened {
    button {
      opacity: 1;
    }

    .actions-menu {
      display: block;
    }
  }
}
</style>
