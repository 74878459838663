<template>
  <div>
    <div id="map"></div>
    <label>
      Lat
      <input type="text" v-model="changed_lat" />
    </label>
    <label>
      Lng
      <input type="text" v-model="changed_lng" />
    </label>
    <button @click="sendManualLatLng">Send Lat Lng manually</button>
  </div>
</template>

<script>
class EventEmitter {
  constructor() {
    this.events = {};
    this.ymapReady = false;
    this.scriptIsNotAttached = true;
  }

  $on(eventName, fn) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(fn);

    return () => {
      this.events[eventName] = this.events[eventName].filter((eventFn) => fn !== eventFn);
    };
  }

  $emit(eventName, data) {
    const event = this.events[eventName];
    if (event) {
      event.forEach((fn) => fn(data));
    }
  }
}

export const emitter = new EventEmitter();
export default {
  data: () => {
    return {
      map: {},
      location: {},
      local_lat: "",
      local_lng: "",
    };
  },
  name: "YandexMap",
  props: {
    link: String,
    lat: String,
    lng: String,
  },
  computed: {
    changed_lat: {
      get() {
        if (this.local_lat) {
          return this.local_lat;
        }
        return this.lat;
      },
      set(value) {
        this.local_lat = value;
      },
    },
    changed_lng: {
      get() {
        if (this.local_lng) {
          return this.local_lng;
        }
        return this.lng;
      },
      set(value) {
        this.local_lng = value;
      },
    },
  },
  methods: {
    init() {
      if (!window.ymaps || !ymaps.GeoObjectCollection) return;

      this.map = new ymaps.Map(
        "map",
        {
          center: [this.lat, this.lng],
          zoom: 10,
        },
        {
          searchControlProvider: "yandex#search",
        }
      );
      this.location = new ymaps.GeoObject(
        {
          // The geometry description.
          geometry: {
            type: "Point",
            coordinates: [parseFloat(this.lat), parseFloat(this.lng)],
          },
          // Properties.
          properties: {
            // The placemark content.
            iconContent: "Move me to your object",
            hintContent: "You can change location if current location is wrong",
          },
        },
        {
          /**
           * Options.
           * The placemark's icon will stretch to fit its contents.
           */
          preset: "islands#greenDotIcon",
          // The placemark can be dragged.
          draggable: true,
        }
      );

      this.location.events.add("dragend", this.onDraggerEnd);

      this.map.geoObjects.add(this.location);
    },
    onDraggerEnd(event) {
      const location = this.location.geometry.getCoordinates();
      this.local_lat = location[0];
      this.local_lng = location[1];
      this.$emit("update_coordinates", location);
    },
    sendManualLatLng() {
      this.$emit("update_coordinates", [this.local_lat, this.local_lng]);
    },
  },
  mounted() {
    this.observer = new MutationObserver(
      function (mutations) {
        this.map.destroy && this.map.destroy();
        this.init();
      }.bind(this)
    );

    if (emitter.scriptIsNotAttached) {
      const yandexMapScript = document.createElement("SCRIPT");
      const mapLink = this.link || "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
      yandexMapScript.setAttribute("src", mapLink);
      yandexMapScript.setAttribute("async", "");
      yandexMapScript.setAttribute("defer", "");
      document.body.appendChild(yandexMapScript);
      emitter.scriptIsNotAttached = false;
      yandexMapScript.onload = () => {
        emitter.ymapReady = true;
        emitter.$emit("scriptIsLoaded");
      };
    }
    if (emitter.ymapReady) {
      ymaps.ready(this.init);
    } else {
      emitter.$on("scriptIsLoaded", () => {
        ymaps.ready(this.init);
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#map {
  width: 100%;
  min-height: 300px;
  height: 100%;
}
#marker {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDEyIDE4Ij48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiNDNTE0MUMiIGQ9Ik0xMS44NiA3LjI5Yy0uNDQ1IDIuMjAzLTEuODgyIDQuNDQtMy4xODMgNi41ODdMNiAxNy45NjZWMTJhNiA2IDAgMSAxIDUuODYtNC43MXoiLz48Y2lyY2xlIGN4PSI2IiBjeT0iNiIgcj0iNiIgZmlsbD0iI0YzMyIvPjxjaXJjbGUgY3g9IjYiIGN5PSI2IiByPSIyLjI1IiBmaWxsPSIjRkZGIi8+PC9nPjwvc3ZnPg==");
  width: 12px;
  height: 18px;
  position: absolute;
}
.header {
  padding: 5px;
}
</style>
