<template>
  <thead>
    <tr>
      <template v-if="insertAdditionalColsAtStart">
        <th v-for="(col, i) in additionalColumns" :key="`add_header_${i}`">{{ col }}</th>
      </template>
      <th
        v-for="item in columns"
        :key="item.key"
        @click="updateOrderKey(item.key)"
        :class="{ active: orderKey === item.key }"
      >
        {{ item.name | capitalize }}
        <span v-if="orderKey === item.key" class="arrow_sort">
          <template v-if="item.order > 0">↑</template>
          <template v-else>↓</template>
        </span>
        <span v-if="showRemoveButton" class="remove_button" @click="removeHeaderItem(item.key)">×</span>
      </th>
      <template v-if="!insertAdditionalColsAtStart">
        <th v-for="(col, i) in additionalColumns" :key="`add_header_${i}`">{{ col }}</th>
      </template>
    </tr>
  </thead>
</template>

<script>
export default {
  props: {
    additionalColumns: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    insertAdditionalColsAtStart: {
      type: Boolean,
      default: false,
    },
    orderKey: {
      required: true,
    },
    showRemoveButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["remove:headerItem", "update:orderKey"],
  setup(props, ctx) {
    function removeHeaderItem(key) {
      ctx.emit("remove:headerItem", key);
    }

    function updateOrderKey(key) {
      ctx.emit("update:orderKey", key);
    }

    return {
      removeHeaderItem,
      updateOrderKey,
    };
  },
};
</script>

<style lang="scss" scoped>
.remove_button {
  border: 1px solid #aaa;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  line-height: 16px;
  font-size: 12px;

  &:hover {
    background-color: #ddd;
  }
}
</style>
