import isUndefined from "lodash/isUndefined";

export default function validateRequiredProps(calleeName, ...args) {
  const isSomeArgUndefined = args.some(isUndefined);

  if (isSomeArgUndefined) {
    const warnMsg = `You need to pass all required props into "${calleeName}"`;
    console.warn(warnMsg);
  }

  return !isSomeArgUndefined;
}
