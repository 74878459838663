<template>
  <label @mousedown="onInputMouseDown" class="datepicker-input_label">
    <p v-if="props.label" class="datepicker-input_title">
      {{ props.label }}
      <Tooltip :text="props.tooltipText" />
      <Tooltip v-if="props.required" text="Required field" color="#e3001b" />
    </p>
    <div class="datepicker-input_container">
      <DatePicker v-model="value" v-bind="datePickerAttrs" />
      <span v-if="errorMsg" class="datepicker-input_error" :title="errorMsg" data-error-type="message">
        {{ $t(errorMsg) }}
      </span>
    </div>
  </label>
</template>

<script>
import { computed, ref, watch } from "@vue/composition-api";

import DatePicker from "vue2-datepicker";
import Tooltip from "@/components/tooltips/Tooltip.vue";

import "@/assets/styles/datepicker.scss";

const DATEPICKER_LANG_OPTIONS = {
  formatLocale: {
    firstDayOfWeek: 1,
  },
  monthBeforeYear: true,
};

export default {
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },

  components: {
    DatePicker,
    Tooltip,
  },

  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    disabledDate: {
      type: Function,
      required: false,
    },
    isUnlocked: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      required: false,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "date",
    },
    error: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const changed = ref(false);
    const isUnlocked = computed(() => {
      if (props.locked) {
        return props.isUnlocked ? undefined : false;
      }

      return undefined;
    });

    const errorMsg = computed(() => (changed.value ? "" : props.error));

    const datePickerAttrs = computed(() => ({
      clearable: props.clearable,
      editable: false,
      "disabled-date": props.disabledDate,
      lang: DATEPICKER_LANG_OPTIONS,
      placeholder: props.placeholder,
      prefixClass: "custom-datepicker",
      type: props.type,
      valueType: "format",
      open: isUnlocked.value,
      "input-class": errorMsg.value ? "custom-datepicker-input error" : "custom-datepicker-input",
    }));

    const value = computed({
      get() {
        return props.modelValue;
      },
      set(newVal) {
        changed.value = true;

        emit("update:modelValue", newVal);
      },
    });

    function onInputMouseDown() {
      if (props.locked) {
        if (!props.isUnlocked) {
          emit("unlock");
        }
      }
    }

    watch(
      () => props.error,
      () => {
        changed.value = false;
      }
    );

    return {
      props,
      datePickerAttrs,
      value,
      errorMsg,
      onInputMouseDown,
    };
  },
};
</script>

<style lang="scss">
.datepicker-input_label {
  display: block;
  width: 100%;
  position: relative;

  .datepicker-input_container {
    width: 100%;
    position: relative;
    padding-bottom: 24px;
  }

  .datepicker-input_title {
    display: flex;
    gap: 6px;
    font-size: 18px;
    font-weight: 500;
    color: #444545;
    margin-bottom: 6px;
  }

  .datepicker-input_error {
    font-size: 13px;
    color: #e3001b;
    line-height: 24px;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}

input.custom-datepicker-input.error {
  border: 1px solid #e3001b;
}
</style>
