<template>
  <fieldset>
    <legend>
      Files
      <button @click.prevent="fireLoadingFiles">Reload From Server</button>
    </legend>
    <label for="force_delete">Force Delete Operation</label>
    <input type="checkbox" v-model="force_delete" id="force_delete" />
    <a ref="act_download_ref">__</a>

    <img src="" :src="show_file.url" :alt="show_file.name" />
    <button @click.prevent="fileCreateForm = !fileCreateForm">{{ fileCreateForm ? "Back" : "Add" }}</button>
    <fieldset v-if="fileCreateForm">
      <div>
        <p>
          <select name="image_group" v-model="imageGroup">
            <option value="">No Image</option>
            <option value="icon">Icon (size 132/132)</option>
            <option value="gallery">Gallery (size 320/132)</option>
            <option value="mobile">Mobile (size 800/533)</option>
            <option value="rules">Rules in pdf format</option>
            <option value="other">Random picture</option>
          </select>
        </p>
        <div>
          <label>
            <input type="file" ref="file" name="file" @change="addFile()" />
            <span>
              &
              <span> Upload new file </span>
            </span>
            <span class="file-name" v-if="attachment.name" v-html="attachment.name"></span>
          </label>
        </div>
        <p>
          <button @click.prevent="fireCreatingFile">Add new file</button>
        </p>
      </div>
    </fieldset>
    <form id="search">Local Search <input name="query" v-model="filterKey" /></form>
    <div class="flex-container">
      <div v-if="source.length === 0">
        &#128194;
        <figure>
          <figcaption>
            <p>This folder is empty!</p>
          </figcaption>
        </figure>
      </div>
      <div v-if="filteredSource.length" class="flex-row">
        <div v-for="file in filteredSource">
          <p v-if="file !== editingFile" @dblclick="fireEditingFile(file)" :title="'Double click for editing filename'">
            {{ file.name + "." + file.extension }}
          </p>
          <input
            v-if="editingFile && file.id === editingFile.id"
            v-autofocus
            @keyup.enter="endEditing(editingFile)"
            @blur="endEditing(editingFile)"
            type="text"
            :placeholder="editingFile.name"
            v-model="editingFile.name"
          />
          <time datetime="2016-1-1">{{ file.created_at }}</time>
          <button title="Delete" @click.prevent="fireDeletingFile(file)">Delete</button>
          <figure v-if="file.type == 'image'" @click.prevent="show_file = file">
            &#128444;
            <img :src="file.url" :alt="file.name" />
          </figure>
          <figure v-if="file.type == 'icon'" @click.prevent="show_file = file">
            &#128444;
            <img width="132" height="132" :src="file.url" :alt="file.name" />
          </figure>
          <figure v-if="file.type == 'image_gallery'" @click.prevent="show_file = file">
            &#128444;
            <img width="320" height="132" :src="file.url" :alt="file.name" />
          </figure>
          <figure v-if="file.type == 'mobile'" @click.prevent="show_file = file">
            &#128444;
            <img width="800" height="533" :src="file.url" :alt="file.name" />
          </figure>

          <div v-if="file.type == 'audio'">
            &#9833;
            <audio controls>
              <source src="" :src="file.url" :type="'audio/' + file.extension" />
              Your browser does not support the audio tag.
            </audio>
          </div>

          <div v-if="file.type == 'video'">
            &#127909;
            <video controls>
              <source :src="file.url" :type="'video/' + file.extension" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div v-if="file.type == 'document'">
            &#128196;
            <a href="" :href="file.url" target="_blank"> &#x21e7;&nbsp;Download </a>
          </div>
        </div>
      </div>
    </div>

    <paginate
      :page-count="gridMeta.last_page"
      :page-range="3"
      :margin-pages="1"
      :value="gridMeta.current_page"
      :click-handler="changePage"
      :prev-text="'Prev'"
      :next-text="'Next'"
      :container-class="'pagination'"
      :page-class="'page-item'"
      :prev-link-class="'page-link'"
      :next-link-class="'page-link'"
      :page-link-class="'page-link'"
    >
    </paginate>
  </fieldset>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SortMixin from "../../mixins/SortMixin";
import Paginate from "vuejs-paginate";

export default {
  mixins: [SortMixin],
  props: ["route_supplier"],
  components: { Paginate },
  data() {
    return {
      show_file: {},

      formData: {},
      fileName: "",
      imageGroup: "",
      attachment: "",

      editingFile: null,
      deletingFile: {},
      fileCreateForm: false,
      force_delete: false,
    };
  },
  computed: {
    ...mapGetters({
      gridMeta: "supplierfile/file_list_meta",
      source: "supplierfile/file_list",
    }),
  },
  directives: {
    autofocus: {
      // определение директивы
      inserted(el) {
        el.focus();
      },
    },
  },
  methods: {
    createColumns() {
      return [
        { key: "number", name: "Act N", order: 1 },
        { key: "aggregator", name: "Компания", order: 1 },
        { key: "started_at", name: "From", order: 1 },
        { key: "finished_at", name: "Till", order: 1 },
        { key: "name", name: "Name", order: 1 },
        { key: "status", name: "Status", order: 1 },
      ];
    },
    ...mapActions({
      loadFiles: "supplierfile/load_file_list_by_supplier",
      fetchFiles: "supplierfile/get_file_list_by_supplier",
      editFile: "supplierfile/update_file",
      createFile: "supplierfile/create_file",
      deleteFile: "supplierfile/delete_file",
    }),
    async fireLoadingFiles() {
      await this.loadFiles({ supplier_id: this.route_supplier, page: this.gridMeta.current_page });
    },
    async fireCreatingFile() {
      try {
        this.formData = new FormData();
        this.formData.append("name", this.fileName);
        this.formData.append("image_group", this.imageGroup);
        this.formData.append("file", this.attachment);

        await this.createFile({ supplier_id: this.route_supplier, formData: this.formData });
      } catch (error) {
        console.error(error);
      }
    },
    addFile() {
      this.attachment = this.$refs.file.files[0];
    },
    async fireDeletingFile(file) {
      if (confirm(`Delete file ${file.name}?`)) {
        await this.deleteFile({ file, force_delete: this.force_delete });
      }
    },
    fireEditingFile(file) {
      this.editingFile = Object.assign({}, file);
    },
    async endEditing(file) {
      this.editingFile = null;

      await this.editFile(file);
    },
    resetForm() {
      this.formData = {};
      this.fileName = "";
      this.attachment = "";
    },
    async changePage(pageNum) {
      await this.fetchFiles({ supplier_id: this.route_supplier, page: pageNum });
    },
  },
  async mounted() {
    await this.fetchFiles({ supplier_id: this.route_supplier, page: this.gridMeta.current_page });
  },
};
</script>

<style scoped type="text/css" lang="scss">
$device-xs: 540px;
$device-sm: 768px;
$device-md: 992px;
$device-lg: 1200px;

.flex-container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 1em;

  @media (min-width: $device-xs) {
    max-width: $device-xs;
  }

  @media (min-width: $device-sm) {
    max-width: $device-sm;
  }

  @media (min-width: $device-md) {
    max-width: $device-md;
  }

  @media (min-width: $device-lg) {
    max-width: $device-lg;
  }
}

.flex-row {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.flex-row--align-v-center {
  align-items: center;
}

.flex-row--align-v-bottom {
  align-items: flex-end;
}

.flex-row--align-h-center {
  justify-content: center;
}

.flex-row--align-h-right {
  justify-content: flex-end;
}

.flex-row--reverse {
  flex-direction: row-reverse;
}

@for $i from 1 through 12 {
  .flex-col-xs-#{$i},
  .flex-col-sm-#{$i},
  .flex-col-md-#{$i},
  .flex-col-lg-#{$i} {
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
  }

  .flex-col-xs-#{$i} {
    @media (min-width: $device-xs) {
      width: calc(100% * (#{$i} / 12));
    }
  }

  .flex-col-sm-#{$i} {
    @media (min-width: $device-sm) {
      width: calc(100% * (#{$i} / 12));
    }
  }

  .flex-col-md-#{$i} {
    @media (min-width: $device-md) {
      width: calc(100% * (#{$i} / 12));
    }
  }

  .flex-col-lg-#{$i} {
    @media (min-width: $device-lg) {
      width: calc(100% * (#{$i} / 12));
    }
  }
}
</style>
