<template>
  <input ref="inputRef" type="text" />
</template>

<script>
import { watch } from "@vue/composition-api";
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    value: Number,
    modelValue: Number, // Vue 2: value
    options: Object,
  },
  setup(props) {
    const { inputRef, setOptions, setValue } = useCurrencyInput(props.options);

    watch(
      () => props.modelValue,
      (value) => {
        // Vue 2: props.value
        setValue(value);
      }
    );

    watch(
      () => props.options,
      (options) => {
        setOptions(options);
      }
    );

    return { inputRef };
  },
};
</script>
