<template>
  <input type="checkbox" :value="value" v-model="proxyChecked" :true-value="true" :false-value="false" />
</template>

<script>
export default {
  emits: ["input"],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    proxyChecked: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
