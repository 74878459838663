import { isObject } from "lodash";
import { VALIDATION_ERRORS } from "../index";

export default (object, requiredFields = []) => {
  let errors = {};

  const requiredFieldsList = requiredFields.length
    ? requiredFields
    : Object.keys(object);

  requiredFieldsList.forEach((item) => {
    if (isObject(object[item])) {
      for (let key in object[item]) {
        if (!object[item][key]) {
          errors[key] = VALIDATION_ERRORS.requiredField;
        }
      }
    } else if (!object[item]) {
      errors[item] = VALIDATION_ERRORS.requiredField;
    }
  });

  return Object.keys(errors).length > 0 ? errors : null;
};
