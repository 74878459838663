<template>
  <label class="block font-medium text-sm text-gray-700">
    <span v-if="value">{{ $t(value) }}</span>
    <span v-else><slot></slot></span>
  </label>
</template>

<script>
export default {
  props: ["value"],
};
</script>
