import createAxios from "./createAxios";
import {
  AxiosInterceptorOnRejected,
  axiosResponseSuccessInterceptor,
  axiosRequestSuccessInterceptor,
} from "../../middleware/axios_interceptors";

const axiosClient = createAxios();

axiosClient.interceptors.response.use(
  axiosResponseSuccessInterceptor,
  AxiosInterceptorOnRejected
);

axiosClient.interceptors.request.use(axiosRequestSuccessInterceptor);

export default axiosClient;
export { default as upload } from "./upload";
export { default as download } from "./download";

window.axiosClient = axiosClient;
