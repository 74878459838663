<template>
  <small-layout
    ><div>
      <fieldset ref="assign_document_form">
        <legend>Find Document</legend>
        <div v-if="viewEntry">
          <button @click="viewEntry = null">Hide</button>
          <table>
            <tr v-for="(value, index) in viewEntry">
              <th>{{ index }}</th>
              <td>{{ value }}</td>
            </tr>
            <tr v-for="(value, index) in viewEntry.document">
              <th>document.{{ index }}</th>
              <td>{{ value }}</td>
            </tr>
          </table>
        </div>
        <div v-if="payment">
          <form @submit.prevent="fireAssignToClaim">
            <label for="claim_document">Claim</label>
            <input id="claim_document" type="number" v-model="params.claim_id" />
            <button>Assign</button>
          </form>
        </div>
      </fieldset>
      <fieldset>
        <legend>filter by</legend>
        <form v-if="!payment" @submit.prevent="fireLoadingList">
          <div>
            <label for="params_aggregator">Aggregator</label>
            <select id="params_aggregator" v-model="params.aggregator">
              <option value=""></option>
              <option value="allsports">AllSports</option>
              <option value="allsports_super">Super</option>
            </select>
            <label for="params_unn">UNN:</label>
            <input id="params_unn" type="number" v-model="params.unn" />
            <label for="params_claim_id">Claim ID:</label>
            <input id="params_claim_id" type="number" v-model="params.claim_id" />
            <label for="params_started_at">Since:</label>
            <input id="params_started_at" type="date" v-model="params.started_at" />
            <label for="params_finished_at">Till:</label>
            <input id="params_finished_at" type="date" v-model="params.finished_at" />
          </div>
          <div>
            <button>Confirm</button>
          </div>
        </form>
        <div>
          <form id="search">Local search <input name="query" v-model="filterKey" /></form>
          <table>
            <caption>
              payments
            </caption>
            <thead>
              <tr>
                <th>actions</th>
                <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey == item.key }">
                  {{ item.name | capitalize }}
                  <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
                  <span class="remove_button" @click="removeColumn(item)">X</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in filteredSource" :class="getRowColorByCompare(entry)">
                <td>
                  <button @click="fireOpenLookup(entry)">Assign document</button>
                  <button @click="viewDetails(entry)">Details</button>
                </td>
                <td v-for="column in columns">
                  {{ _read(entry, column) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="100%">
                  <paginate
                    :page-count="gridMeta.last_page"
                    :page-range="3"
                    :margin-pages="2"
                    :value="gridMeta.current_page"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :page-link-class="'page-link'"
                  >
                  </paginate>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </fieldset></div
  ></small-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SortMixin from "../mixins/SortMixin";
import Paginate from "vuejs-paginate";
export default {
  mixins: [SortMixin],
  props: [],
  components: {
    Paginate,
  },
  data() {
    return {
      viewEntry: undefined,
      payment: undefined,
      params: {
        claim_id: null,
        unn: null,
        aggregator: null,
      },
      paymentable_types: ["App\\SupplierAct"],
    };
  },
  computed: {
    ...mapGetters({
      gridMeta: "payment/list_meta",
      source: "payment/list",
    }),
  },

  directives: {
    autofocus: {
      // определение директивы
      inserted(el) {
        el.focus();
      },
    },
  },
  methods: {
    async fireAssignToClaim() {
      this.payment.claim_id = this.params.claim_id;
      await this.updatePayment(this.payment);
      this.payment = null;
    },
    getBynWithKopeks(kopeks) {
      const byn = isNaN(kopeks) ? NaN : Math.floor(kopeks / 100);
      return byn + "." + String(kopeks % 100).padStart(2, "0");
    },
    getRowColorByCompare(entry) {
      if (entry.claim) {
        switch (entry.aggregator) {
          case "allsports":
            return entry.claim.it_credit <= entry.debet1 - entry.credit1 ? "matched" : "no-matched";
          case "allsports_super":
            return entry.claim.service_credit <= entry.debet1 - entry.credit1 ? "matched" : "no-matched";
        }
      }
      if (entry.document) {
        if (entry.document.direction === "debet") {
          return this.getBynWithKopeks(entry.document.price) === entry.debet1 ? "matched" : "no-matched";
        }
        if (entry.document.direction === "credit") {
          return this.getBynWithKopeks(entry.document.price) === entry.credit1 ? "matched" : "no-matched";
        }
      }
      return "";
    },
    createColumns() {
      return [
        { key: "id", name: "id", order: 1 },
        { key: "document.id", name: "doc_id", check: "document", order: 1 },
        { key: "document.direction", name: "direction", check: "document", order: 1 },
        { key: "document.name", name: "name", check: "document", order: 1 },
        { key: "document.price", name: "price", check: "document", order: 1 },
        { key: "doc_date_string", name: "doc_date_string", order: 1 },
        { key: "doc_number", name: "doc_number", order: 1 },
        { key: "debet1", name: "debet1", order: 1 },
        { key: "credit1", name: "credit1", order: 1 },
        { key: "correspondent", name: "correspondent", order: 1 },
        { key: "correspondent_name", name: "correspondent_name", order: 1 },
        { key: "correspondent_unn", name: "correspondent_unn", order: 1 },
        { key: "doc_id", name: "doc_id", order: 1 },
        { key: "doc_date", name: "doc_date", order: 1 },
        { key: "operation_number", name: "operation_number", order: 1 },
        { key: "description", name: "description", order: 1 },
        { key: "currency", name: "currency", order: 1 },
        { key: "debet", name: "debet", order: 1 },
        { key: "credit", name: "credit", order: 1 },
        { key: "code", name: "code", order: 1 },
        { key: "print_form", name: "print_form", order: 1 },
        { key: "pay_c", name: "pay_c", order: 1 },
      ];
    },
    _read(entry, column) {
      if (!column.check) {
        return eval("entry." + column.key);
      }
      if (entry[column.check]) {
        return eval("entry." + column.key);
      }
      return null;
    },
    ...mapActions({
      loadList: "payment/load_list",
      updatePayment: "payment/update_payment",
    }),
    viewDetails(entry) {
      this.viewEntry = entry;
      if (this.$refs.assign_document_form) {
        this.$refs.assign_document_form.scrollIntoView();
      }
    },
    async fireOpenLookup(entry) {
      this.payment = Object.assign({ transactable: "on" }, entry);
      if (this.$refs.assign_document_form) {
        this.$refs.assign_document_form.scrollIntoView();
      }
    },
    async fireLoadingList() {
      await this.loadList(Object.assign({ page: this.gridMeta.current_page }, this.params));
    },
    async changePage(pageNum) {
      await this.loadList(Object.assign({ page: pageNum }, this.params));
    },
    async extractParamsAndRun(to) {
      this.params.claim_id = "";
      this.params.unn = "";
      this.params.aggregator = "";
      if (to.params.aggregator) {
        this.params.aggregator = to.params.aggregator;
      }
      if (to.params.unn) {
        this.params.unn = to.params.unn;
      }
      if (to.params.claim_id) {
        this.params.claim_id = to.params.claim_id;
      }
      await this.loadList(Object.assign({ page: this.gridMeta.current_page }, this.params));
    },
  },
  watch: {
    $route(to, from) {
      this.extractParamsAndRun(to);
    },
  },
  async mounted() {
    this.extractParamsAndRun(this.$route);
  },
};
</script>

<style scoped>
.no-matched > td {
  background-color: rgba(227, 52, 47, 0.16);
}
.matched > td {
  background-color: rgba(42, 178, 123, 0.16);
}
</style>
