<template>
  <small-layout
    ><div id="demo">
      <h1>Coverage</h1>
      <button @click="toggleSearch">
        Show stats {{ searchMode === "attractions" ? "By Groups" : "By Attractions" }}
      </button>
      <table>
        <thead>
          <tr>
            <th v-for="column in pivotColumns">{{ column }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in pivot">
            <th>{{ row[0] }}</th>
            <td v-for="value in getValues(row)">{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: function () {
    return {
      pivot: [],
      pivotColumns: [],
      searchMode: "groups",
    };
  },
  methods: {
    ...mapActions({
      cover_pivot: "attraction/cover_pivot",
    }),
    getValues(row) {
      return row.slice(1);
    },
    async fetchStats() {
      const pivot = await this.cover_pivot();
      if (pivot) {
        this.pivotColumns = pivot.shift();
        this.pivot = pivot;
      }
    },
    toggleSearch() {
      if (this.searchMode === "groups") {
        location.search = "?no_summary=1";
      } else {
        location.search = "";
      }
    },
  },
  mounted() {
    this.fetchStats();
    if (location) {
      if (location.search.indexOf("no_summary") !== -1) {
        this.searchMode = "attractions";
      } else {
        this.searchMode = "groups";
      }
    }
  },
};
</script>

<style scoped lang="scss">
th,
td {
  min-width: 120px;
  padding: 10px 20px;
}
</style>
