import store from "../store";
import { SET_IF_UPDATE_FOUND } from "../mutation-types";
import { captureSentryException } from "@/helpers/dev/sentry";

/**
 * Created by Alexander Golovnya on 2019-05-04.
 */
export function AxiosInterceptorOnFullFilled(response) {
  store.commit("auth/" + SET_IF_UPDATE_FOUND, response);
  return response;
}

export function AxiosInterceptorOnRejected(error) {
  captureSentryException(error);

  if (error && error.message === "Network Error") {
    error = {
      message: "Проверьте интернет соединение",
    };
  }
  store.commit("auth/" + SET_IF_UPDATE_FOUND, error.response);
  let res = error.response;
  if (
    store.getters["auth/getRefreshToken"] &&
    res &&
    res.status === 401 &&
    res.config &&
    !res.config.__isRetryRequest &&
    res.config.url === "/api/admin_login"
  ) {
    return store.dispatch("auth/refreshLogin", error);
  }
  store.commit("error/set_error", error);
  throw error;
}

export function axiosRequestSuccessInterceptor(config) {
  const authStore = store?.state?.auth;

  if (authStore) {
    const token = authStore.auth?.access_token;
    const { country, locale } = authStore;

    const patchedHeaders = {
      "X-country": country,
      "X-localization": locale,
      Authorization: `Bearer ${token}`,
    };

    config.headers = {
      ...patchedHeaders,
      ...config.headers,
    };
  }

  return config;
}

export function axiosResponseSuccessInterceptor(response) {
  // save backward compatibility with api requests in @/api
  store.commit("auth/" + SET_IF_UPDATE_FOUND, response);

  if (response.config.originalResponse) {
    return response.data;
  }

  return response?.data?.data || response.data;
}
