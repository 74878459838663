/**
 * Created by Alexander Golovnya on 2019-03-24.
 */
export function priceByn(kopeks) {
  return isNaN(kopeks) ? NaN : kopeks / 100;
}
export function parseDate(e) {
  if (e) {
    let str = e;
    if (str.date) {
      str = str.date;
    }
    if (str[10] === " ") {
      str = str.replace(" ", "T");
    }
    try {
      return new Date(str);
    } catch (e) {
      console.warn(str + e);
    }
  }
  return undefined;
}

export function parseWeek(e) {
  const date = parseDate(e);
  if (!date) {
    return undefined;
  }
  const onejan = new Date(date.getFullYear(), 0, 1);
  const millisecsInDay = 86400000;
  return Math.ceil(
    ((date - onejan) / millisecsInDay + onejan.getDay() + 1) / 7
  );
}

export function binaryToHex(binaryString) {
  let output = "";

  // For every 4 bits in the binary string
  for (let i = 0; i < binaryString.length; i++) {
    // Convert to decimal then hexadecimal
    const decimal = binaryString.charCodeAt(i);
    const hex = decimal.toString(16);

    // Uppercase all the letters and append to output
    output += hex.toUpperCase();
  }

  return output;
}

export function hexToBinary(hexString) {
  let output = "";

  if (!hexString || hexString.length % 2 !== 0) {
    throw "Invalid HEX. Should be length 2/4/6/8/10/12/14/16/....";
  }

  // For each hexadecimal character
  for (let i = 0; i < hexString.length; i += 2) {
    // Convert to decimal
    let decimal = parseInt(hexString.substr(i, 2), 16);

    // Convert to binary and add 0s onto the left as necessary to make up to 4 bits
    let binary = String.fromCharCode(decimal);

    // Append to string
    output += binary;
  }

  return output;
}

export function hexToBase64(hexString) {
  if (hexString) {
    const binary = hexToBinary(hexString);
    return binary ? btoa(binary) : binary;
  }
  return "";
}

export function base64ToHex(base64String) {
  if (base64String) {
    const binaryString = atob(base64String);
    return binaryToHex(binaryString);
  }
  return base64String;
}
