<template>
  <input type="checkbox" :value="value" v-model="proxyChecked" :true-value="1" :false-value="0" />
</template>

<script>
export default {
  emits: ["input"],

  props: {
    value: {
      type: [Array, Boolean, Number],
      default: false,
    },
    checked: {
      default: null,
    },
  },

  computed: {
    proxyChecked: {
      get() {
        return this.checked;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
