<template>
  <small-layout
    ><div>
      <h1>Tokens</h1>
      <form id="search" v-if="!$route.params.token">
        <label for="revoked">Revoked?</label>
        <input
          id="revoked"
          type="checkbox"
          v-model="params.revoked"
          :false-value="0"
          :true-value="1"
          @change="refresh"
        />
        <br />
        <label for="expired">Expired?</label>
        <input
          id="expired"
          type="checkbox"
          v-model="params.expired"
          :false-value="0"
          :true-value="1"
          @change="refresh"
        />
        <br />
        <label for="user_id">User_id?</label>
        <input id="user_id" type="number" v-model="params.user_id" @change="refresh" />
        Search <input name="query" v-model="filterKey" />
      </form>
      <table>
        <caption>
          Devices
        </caption>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.name | capitalize }}
              <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in devices">
            <td v-for="column in columns">
              <span v-if="column.key === 'id'" :class="getBackground(entry.id.indexOf(active_token) !== -1)">
                {{ entry[column.key] }}<span v-if="entry.id.indexOf(active_token) !== -1">(Current)</span>
              </span>
              <span v-else-if="column.key === 'proper_address'">
                {{ entry.proper_address | int2ip }}
              </span>
              <span v-else-if="column.key === 'revoked'">
                {{ entry[column.key] }}<button v-if="!entry.revoked" @click.prevent="fireRevoke(entry)">Revoke</button>
              </span>
              <span v-else>{{ entry[column.key] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <caption>
          Tokens
        </caption>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.name | capitalize }}
              <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <td v-for="column in columns">
              <span v-if="column.key === 'id'" :class="getBackground(entry.id.indexOf(active_token) !== -1)">
                {{ entry[column.key] }}<span v-if="entry.id.indexOf(active_token) !== -1">(Current)</span>
              </span>
              <span v-else-if="column.key === 'proper_address'">
                {{ entry.proper_address | int2ip }}
              </span>
              <span v-else-if="column.key === 'revoked'">
                {{ entry[column.key] }}<button v-if="!entry.revoked" @click.prevent="fireRevoke(entry)">Revoke</button>
              </span>
              <span v-else>{{ entry[column.key] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SortMixin from "../mixins/SortMixin";
export default {
  mixins: [SortMixin],
  data: function () {
    return {
      params: {
        token: this.$route.params.token,
        revoked: 0,
        expired: 0,
        user_id: this.$route.params.user_id || 2,
      },
      orderKey: "id",
      order: 1,
      filterKey: "",
    };
  },
  computed: {
    ...mapGetters({
      source: "token/token_history_list",
      devices: "token/device_history_list",
      token: "auth/getAccessToken",
    }),
    active_token() {
      if (!this.token && !this.$route.params.token) {
        return "";
      }
      if (this.$route.params.token) {
        return this.$route.params.token;
      }
      const token = this.parseJwt(this.token);
      if (token && token.jti) {
        return token.jti;
      }
      return "";
    },
  },
  filters: {
    int2ip(ipInt) {
      if (!ipInt) {
        return "";
      }
      return (ipInt >>> 24) + "." + ((ipInt >> 16) & 255) + "." + ((ipInt >> 8) & 255) + "." + (ipInt & 255);
    },
  },
  methods: {
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    getBackground(isCurrent) {
      return isCurrent ? "current" : "no-current";
    },
    async refresh() {
      await this.fetchTokens(this.params);
    },
    createColumns() {
      return [
        { key: "id", name: "id", order: 1 },
        { key: "user_id", name: "user_id", order: 1 },
        { key: "client_id", name: "client_id", order: 1 },
        { key: "name", name: "name", order: 1 },
        { key: "scopes", name: "scopes", order: 1 },
        { key: "revoked", name: "revoked", order: 1 },
        { key: "created_at", name: "created_at", order: 1 },
        { key: "updated_at", name: "updated_at", order: 1 },
        { key: "expires_at", name: "expires_at", order: 1 },
        { key: "proper_address", name: "proper_address", order: 1 },
        { key: "user_agent", name: "user_agent", order: 1 },
      ];
    },
    ...mapActions({
      fetchTokens: "token/token_history_list",
      fireRevoke: "token/revoke_token",
    }),
  },
  async mounted() {
    await this.refresh();
  },
};
</script>

<style scoped>
.current {
  background-color: lightgreen;
}
.no-current {
  background-color: lightgoldenrodyellow;
}
</style>
