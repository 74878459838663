<template>
  <table>
    <caption>
      Message List
    </caption>
    <thead>
      <tr>
        <th>Card ID</th>
        <th>Supplier ID</th>
        <th>Message</th>
      </tr>
      <tr v-for="item in data">
        <td>{{ item.card_id }}</td>
        <td>{{ item.supplier_id }}</td>
        <td>{{ item.message }}</td>
        <td>
          <button @click="() => fireDelete(item.id)">DELETE</button>
        </td>
      </tr>
    </thead>
  </table>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      form: {
        card_id: "",
        supplier_id: "",
        errors: [],
      },
    };
  },

  methods: {
    ...mapActions({
      delete: "lock_list/delete",
    }),
    async fireDelete(id) {
      if (confirm("Are you sure?")) {
        await this.delete(id);
      }
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
