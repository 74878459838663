import isArray from "lodash/isArray";
import isFunction from "lodash/isFunction";
import isString from "lodash/isString";
import setValue from "lodash/set";

import request from "@/helpers/request";
import URLS from "@/config/urls";
import { camelCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

const STORE_NAMESPACE = "config";

const ACTION_FETCH_CONFIGS = "actionFetchConfigs";
const ACTION_UPDATE_CONFIG = "actionUpdateConfig";

const MUTATION_SET_CONFIGS = "mutationSetConfigs";
const MUTATION_UPDATE_CONFIG = "mutationUpdateConfig";

export const CONFIG_ACTION_FETCH_CONFIGS = `${STORE_NAMESPACE}/${ACTION_FETCH_CONFIGS}`;
export const CONFIG_ACTION_UPDATE_CONFIG = `${STORE_NAMESPACE}/${ACTION_UPDATE_CONFIG}`;

export const COMPENSATION_TYPES = {
  FULL: 0,
  FULL_WITH_EXTENSION: 1,
  X_AMOUNT: 2,
  X_AMOUNT_WITH_EXTENSION: 3,
};

const CONFIG_URLS = {
  country: URLS.config.country,
  managers: URLS.config.managers,
  portal: URLS.config.portal,
};

const CONFIGS_LIST = Object.keys(CONFIG_URLS);

export default {
  namespaced: true,

  state: {
    processing: false,
    errors: {},

    companyDefault: {},

    country: {
      locations: [],
      phone: {},
      prices: {},
      sellers: [],
      taxRates: [],
      templates: {},
      registrationType: [],
      supportedSubscriptions: [],
      compensationType: [],
      internalCompanies: [],
    },

    managers: [],

    portal: {
      availableCurrencies: [],
      availableLocales: [],
      availableLocationTypes: [],
      levels: {},
      orderRecalculateTimeout: 0,
    },
  },

  getters: {
    country(state) {
      return state.country;
    },
    citiesList(state) {
      return state.country.locations.map((location) => ({
        text: location,
        value: location,
      }));
    },
  },

  actions: {
    async [ACTION_FETCH_CONFIGS]({ commit }, userConfigsList = []) {
      const configsParams = {};
      const configsList = [];

      userConfigsList.forEach((config) => {
        let configName = config;

        if (!isString(config)) {
          Object.keys(config).forEach((configWithParams) => {
            configName = configWithParams;
            configsParams[configWithParams] = config[configWithParams];
          });
        }

        if (CONFIGS_LIST.includes(configName)) {
          configsList.push(configName);
        }
      });

      const urls = configsList.map((configName) => {
        let url = CONFIG_URLS[configName];

        if (isFunction(url)) {
          url = url(...configsParams[configName]);
        }

        return url;
      });

      const requestsList = urls.map((url) => request(url));

      try {
        const responseConfigs = await Promise.allSettled(requestsList);

        const configsValues = [];
        responseConfigs.forEach(({ status, value, reason }) => {
          if (status === "fulfilled") {
            configsValues.push(value);
          } else {
            throw reason;
          }
        });

        commit(MUTATION_SET_CONFIGS, { configsList, configsValues });
        return true;
      } catch (xhrError) {
        const { errorMessage } = prepareAxiosErrors(xhrError);

        return Promise.reject(errorMessage);
      }
    },
    [ACTION_UPDATE_CONFIG]({ commit }, { key, value } = {}) {
      if (key && value) {
        commit(MUTATION_UPDATE_CONFIG, { key, value });
      }
    },
  },

  mutations: {
    [MUTATION_SET_CONFIGS](state, { configsList, configsValues }) {
      for (let i = 0; i < configsList.length; i++) {
        const configKey = configsList[i];
        const configValue = configsValues[i];

        if (configValue) {
          if (isArray(configValue)) {
            state[configKey] = configValue;
          } else {
            state[configKey] = camelCaseObjectKeys(configValue);
          }
        }
      }
    },
    [MUTATION_UPDATE_CONFIG](state, { key, value }) {
      setValue(state, key, value);
    },
  },
};
