import Vue from "vue";
import VueI18n from "vue-i18n";
import i18nEn from "./locales/en.json";
import i18nRu from "./locales/ru.json";
import config from "./config";

Vue.use(VueI18n);

const dateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
  ru: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    },
  },
};

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "BYN",
    },
  },
  ru: {
    currency: {
      style: "currency",
      currency: "BYN",
      currencyDisplay: "symbol",
    },
  },
};

let locale = window.localStorage.language || navigator.language || "ru";
let fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru";
if (config.supported_languages.indexOf(locale) === -1) {
  locale = "ru";
}
if (config.supported_languages.indexOf(fallbackLocale) === -1) {
  fallbackLocale = "ru";
}

export default new VueI18n({
  dateTimeFormats,
  numberFormats,
  locale: locale,
  fallbackLocale: fallbackLocale,
  messages: {
    en: i18nEn,
    ru: i18nRu,
  },
});
