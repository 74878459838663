<template>
  <div>
    <div v-for="supplier in suppliers" :id="'s' + supplier.id" class="sheet printing-block">
      <img alt="stiker" :src="supplier.image" />
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      suppliers: [],
    };
  },
  methods: {
    ...mapActions({
      qrCodeSupplier: "suppliers/response_qrcode_supplier",
      fetchSuppliers: "suppliers/load_supplier_list_printable",
    }),
    toDataURL (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }
  },
  async mounted() {
    const suppliers = await this.fetchSuppliers(this.$route.query);
    const minLength = Math.min(suppliers.length, 4);
    for (let i = 0; i < minLength; i++) {
      const response = await this.qrCodeSupplier({ id: suppliers[i].id, format: this.$route.query.format });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      suppliers[i].image = await this.toDataURL(blob);
    }
    this.suppliers = suppliers;
  },
};
</script>
