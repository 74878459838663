<template>
  <fieldset v-if="act">
    <a ref="claim_act_download_ref">__</a>
    <button @click="back">Back</button>
    <form @submit.prevent>
      <button @click="newAct(claim, filter_at, dictionaries, monthly, info_price)">New</button>
      <button @click="actAdvanced = !actAdvanced">Advanced</button>
      <div>
        <label for="cra_cards">Act N{{ act.id }}:</label>
        <table id="cra_cards">
          <thead>
            <tr>
              <th v-if="actAdvanced">it</th>
              <th v-if="actAdvanced">service</th>
              <th>Level</th>
              <th>Price</th>
              <th v-if="actAdvanced">Percent</th>
              <th>count</th>
              <th>Actions <button @click.prevent="addLine">&#10010;</button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in act.summary">
              <td v-if="actAdvanced"><textarea aria-label="license" rows="5" cols="20" v-model="item.it" /></td>
              <td v-if="actAdvanced"><textarea aria-label="service" rows="5" cols="20" v-model="item.service" /></td>
              <td>
                <select aria-label="card level" @change="changeLine(index, 'level')" v-model="item.level">
                  <option value="region">region</option>
                  <option value="silver">silver</option>
                  <option value="gold">gold</option>
                  <option value="platinum">platinum</option>
                  <option value="vip">vip</option>
                </select>
              </td>
              <td @input="changeLine(index, 'price')">
                <input aria-label="price per card" type="number" v-model="item.price" />
              </td>
              <td v-if="actAdvanced" @input="changeLine(index, 'percent')">
                <input aria-label="percent on it" type="number" v-model="item.percent" />
              </td>
              <td @input="changeLine(index, 'count')">
                <input aria-label="count of cards" type="number" v-model="item.count" />
              </td>
              <td><button @click="deleteLine(index)">&#128465;</button></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4">{{ total }}</td>
            </tr>
            <tr>
              <td colspan="4"><button @click.prevent="recalculatePrices">Update prices</button></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-if="actAdvanced">
        <div>
          <label for="cra_created_at">Created At:</label>
          <input id="cra_created_at" type="date" v-model="act.created_at" />
        </div>
        <div>
          <label for="cra_started_at">Since:</label>
          <input id="cra_started_at" :min="act.min_at" :max="act.max_at" type="date" v-model="act.started_at" />
        </div>
        <div>
          <label for="cra_finished_at">Till:</label>
          <input id="cra_finished_at" :min="act.min_at" :max="act.max_at" type="date" v-model="act.finished_at" />
        </div>
        <div>
          <label for="cra_act_template">Add customer act template:</label>
          <select id="cra_act_template" v-model="act.template_path">
            <option v-for="template in templates.companies.acts" :value="template">{{ template }}</option>
          </select>
        </div>
        <div>
          <label for="cra_act_comments">comments in Act:</label>
          <input type="text" v-model="act.comments" id="cra_act_comments" />
        </div>
      </div>
      <div>
        <button v-if="!act.deleted_at" @click.prevent="fireUpdateAct(act)">Save</button>
      </div>
    </form>
    <table v-if="acts">
      <caption>
        Acts
      </caption>
      <thead>
        <tr>
          <th v-for="column in actColumns">{{ column }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in acts">
          <td v-for="column in actColumns">
            <span v-if="column === 'path'">
              <button @click="downloadAct({ act: item, ref: $refs.claim_act_download_ref })">download</button>
            </span>
            <span v-else-if="column === 'comments'">
              {{ calculateFromSummary(item.summary) }}:{{ item.comments }}
            </span>
            <span v-else>{{ item[column] }}</span>
          </td>
          <td>
            <button v-if="!item.deleted_at" @click.prevent="fireConfigureAct(item)">&#9998;</button>
            <button v-if="!item.deleted_at" @click.prevent="fireDelete(item)">&#128465;</button>
          </td>
        </tr>
      </tbody>
    </table>
  </fieldset>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatToRFC3339, formatToRuMinskLocale, generateCalculator } from "../../middleware/functions";
export default {
  name: "ActIndex",
  props: {
    claim: {
      type: Object,
    },
    monthly: {
      type: Object,
    },
    filter_at: {
      type: String,
    },
    dictionaries: {
      type: Object,
    },
    info_price: {
      type: String,
    },
    toggleDeleteButtons: {
      type: Boolean,
      default: false,
    },
    templates: {
      type: Object,
    },
  },
  watch: {
    claim: {
      handler(entity, oldVal) {
        if (!entity) {
          this.act = null;
          return;
        }
        const that = this;
        if (!oldVal || entity.company_id !== oldVal.company_id || !this.acts.length) {
          this.actList(entity).then((acts) => {
            if (acts.length) {
              that.fireConfigureAct(acts[0]);
            }
          });
        }

        if (!this.act) {
          this.newAct(entity, this.filter_at, this.dictionaries, this.monthly, this.info_price);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      actAdvanced: false,
      act: null,
      actColumns: ["id", "comments", "path"],
      priceCalculator: { calculatePriceBy: (level, total, price) => price },
    };
  },
  computed: {
    total() {
      return this.calculateFromSummary(this.act.summary);
    },
    ...mapGetters({
      acts: "company/act_list",
    }),
  },
  methods: {
    newAct(entity, filterAt, info, monthly, infoPrice) {
      const act = {};
      const priceCalculator = (this.priceCalculator = generateCalculator(infoPrice));
      const started_at = new Date(filterAt);
      const finished_at = new Date(filterAt);
      finished_at.setMonth(finished_at.getMonth() + 1);
      finished_at.setDate(0);
      act.started_at_211001 = started_at >= new Date("2021-10-01");
      act.min_at = formatToRFC3339(new Date(started_at.getFullYear(), started_at.getMonth() - 1, 1, 10, 10, 10));
      act.max_at = formatToRFC3339(finished_at);
      act.started_at = formatToRFC3339(started_at);
      act.finished_at = act.max_at;
      const now = new Date();
      act.created_at = formatToRFC3339(now);
      act.template_path = "company_act.1.html";
      const percent = info.percent;
      if (!act.comments) {
        act.comments = "за " + formatToRuMinskLocale(started_at);
      }
      const summary = {
        plus_card: {
          it: "",
          service: "Плата за замену карты",
          level: "plus_card",
          price: 500,
          percent: 0,
          count: 0,
        },
      };
      Object.getOwnPropertyNames(monthly).map((key) => {
        const item = monthly[key];
        if (["reject", ""].indexOf(item.status) !== -1) {
          return;
        }
        if (typeof item.status === "undefined") {
          return;
        }
        const level = item.level;

        if (!summary[level]) {
          summary[level] = {
            it: info.it.replace("{level}", level),
            service: info.service.replace("{level}", level),
            level,
            price: 0,
            percent,
            count: 0,
          };
        }
        if (item.status === "continue_plus_card") {
          ++summary["plus_card"]["count"];
        }
        ++summary[level]["count"];
      });

      let totalCount = 0;
      Object.getOwnPropertyNames(summary).map((key) => {
        const item = summary[key];
        totalCount += item.count;
      });
      act.summary = Object.getOwnPropertyNames(summary)
        .map((key) => {
          const item = summary[key];
          item.price = priceCalculator.calculatePriceBy(item.level, totalCount, item.price);
          return item;
        })
        .filter((item) => item.count);
      this.act = act;
    },
    fireConfigureAct(activated) {
      const act = JSON.parse(JSON.stringify(activated));
      const started_at = new Date(act.started_at);
      const finished_at = new Date(act.finished_at);
      act.min_at = formatToRFC3339(new Date(started_at.getFullYear(), started_at.getMonth() - 1, 1, 10, 10, 10));
      act.max_at = formatToRFC3339(finished_at);
      act.started_at = formatToRFC3339(started_at);
      act.finished_at = act.max_at;
      act.created_at = act.created_at.substr(0, 10);
      this.act = act;
    },
    fireDelete(item) {
      const text = 'Type "DELETE" to remove act ' + item.id;
      if (prompt(text, "D") !== "DELETE") {
        return;
      }
      this.actDelete(item);
    },
    calculateFromSummary(summary) {
      const result = { it: 0, service: 0 };
      let i, item;
      for (i in summary) {
        item = summary[i];
        if (!item.price) {
          continue;
        }
        result.it += Math.round((item.percent * item.price * item.count) / 100);
        result.service += Math.round(((100 - item.percent) * item.price * item.count) / 100);
      }
      result.vat = 0;
      if (new Date(this.filter_at) >= new Date("2021-10-01")) {
        result.vat = result.service * 0.2;
      }
      return `AllSports ${Math.floor(result.it / 100)}.${result.it % 100}, Super ${Math.floor(result.service / 100)}.${
        result.service % 100
      }, Super VAT ${Math.floor(result.vat / 100)}.${result.vat % 100}`;
    },
    back() {
      this.$emit("close");
      this.act = null;
    },
    addLine() {
      this.act.summary.push({
        it: "",
        service: "",
        level: "",
        percent: Number(this.dictionaries.percent),
        price: 0,
        count: 0,
      });
    },
    deleteLine(index) {
      const result = this.act.summary.splice(index, 1);
      if (!result.length) {
        this.$set(this.act, "summary", this.act.summary);
      }
    },
    changeLine(index, key) {
      switch (key) {
        case "level":
          let totalCount = 0;
          const summary = this.act.summary;
          summary.map((item) => {
            totalCount += Number(item.count);
          });
          const line1 = this.act.summary[index];
          line1.price = this.priceCalculator.calculatePriceBy(line1.level, totalCount, line1.price);
          this.$set(this.act, "summary", this.act.summary);
          break;
      }
    },
    recalculatePrices() {
      let totalCount = 0;
      const summary = this.act.summary;
      summary.map((item) => {
        totalCount += Number(item.count);
      });
      summary.map((item) => {
        if (!item.level) {
          return;
        }
        item.price = this.priceCalculator.calculatePriceBy(item.level, totalCount, item.price);
      });
    },
    async fireUpdateAct(act) {
      if (act.id) {
        await this.actUpdate({ claim: this.claim, act });
        await this.downloadAct({ act, ref: this.$refs.claim_act_download_ref });
      } else {
        const newAct = await this.actCreate({ claim: this.claim, act });
        await this.downloadAct({ act: newAct, ref: this.$refs.claim_act_download_ref });
      }
      await this.claimLoad(this.claim.id);
      this.act = act;
    },
    ...mapActions({
      actList: "company/act_list",
      actCreate: "company/act_create",
      actUpdate: "company/act_update",
      actDelete: "company/act_delete",
      downloadAct: "company/act_download",
      claimLoad: "company/load_claim_by_id",
    }),
  },
};
</script>

<style scoped></style>
