<template>
  <small-layout
    ><div id="demo">
      <h1>Act Table</h1>
      <a ref="act_download_ref">__</a>
      <label for="month_at">Month</label>
      <input type="month" id="month_at" @change="setPeriod" v-model="params.month_at" />
      <label for="started_at">Started</label>
      <input type="date" id="started_at" v-model="params.started_at" />
      <label for="finished_at">Finished</label>
      <input type="date" id="finished_at" v-model="params.finished_at" />
      <div>
        <button v-if="sleep_total" @click="sleep_total = 0">Cancel process</button>
        <label for="stat_sleep_seconds"
          >Sleep{{ sleep_total ? "left " + sleep_total : "" }} between actions for each Act (in seconds):</label
        >
        <input id="stat_sleep_seconds" type="text" inputmode="numeric" v-model="sleep_seconds" />
        <select id="stat_sleep_seconds_list" v-model="sleep_command">
          <option value="pdf">To generate pdf</option>
          <option value="order">To generate orders</option>
          <option value="mail">To send mails</option>
        </select>
      </div>
      <button @click="fireFetchingActs">Get payment table</button>
      <button v-if="country === 'by'" @click="fireCsvActs">CSV payment table (only with bank_status paid)</button>
      <button v-if="country === 'by'" @click="fireSupActs1cExport">Suppliers by month</button>
      <button v-if="country === 'am'" @click="fireBankOrder">{{ $t("supplier_act.download_bank_order") }}</button>
      <button v-if="country === 'cy'" @click="fireBankOrderXml">
        {{ $t("supplier_act.download_bank_order") }} XML
      </button>

      <button @click="fireFetchSummary">Get summary</button>

      <label for="city">City</label>
      <select v-model="params.city" id="city">
        <option value=""></option>
        <option v-for="option in citiesList" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>

      <button @click="fireFetchAttractions">Compare attractions</button>
      <br />
      <table>
        <thead>
          <tr>
            <th>
              <button type="button" @click="fireCreatingActs">Commit Acts.</button>
            </th>
            <th>
              <button type="button" @click="fireGeneratingActs">Generate Pdf.</button>
            </th>
            <th>
              <button type="button" @click="fireOrder">Generate Order in {{ bankByCountry(country) }}.</button>
            </th>
            <th>
              <button type="button" @click="fireExport1C">Export to 1C</button>
            </th>
            <th>
              <button type="button" @click="fireDeletingActs">Delete.</button>
            </th>
            <th>
              <button type="button" @click="fireValidateActs">Validate</button>
            </th>
            <th>
              <button type="button" @click="fireUpgradeActs">Upgrade acts.</button>
            </th>
            <th>
              <button type="button" @click="actPatchForm = !actPatchForm">Open Patching</button>
            </th>
            <th>
              <button type="button" @click="fireSendActs">Send Mail</button>
            </th>
            <th>
              <button type="button" @click="fireDownloadPdf">group by 40 pages</button>
            </th>
          </tr>
          <tr>
            <td>Total acts commit {{ new_act_amount }}</td>
            <td>Pdf to generate {{ act_created_amount }}</td>
            <td>Orders to generate {{ act_created_amount }}</td>
            <td>Can be deleted {{ delete_act_amount }}</td>
            <td>Acts {{ act_created_amount }}</td>
            <td>Acts to upgrade {{ act_created_amount }}</td>
            <td>Acts to patch {{ act_generated_amount }}</td>
            <td>Acts to mail {{ act_generated_amount }}</td>
            <td>Acts to download {{ act_created_amount }}</td>
          </tr>
        </thead>
      </table>
      <label for="toggleAllActNumbers">Select/Delect All (On/Off)</label>
      <input type="checkbox" id="toggleAllActNumbers" @change="fireToggleActs" />
      <form id="search">
        <label for="query">Local search </label><input id="query" name="query" v-model="filterKey" />
      </form>
      <fieldset v-if="actPatchForm">
        <legend>Patch <button @click="actPatchForm = false">Abort</button></legend>
        <form @submit.prevent="firePatchingActs">
          <div>
            <label for="patch_act_status">Status:</label>
            <select id="patch_act_status" v-model="patchAct.status">
              <option value="generating">Генерация (пересоздание акта)</option>
              <option value="generated">Сгенерирован</option>
              <option value="stamped">подписан</option>
              <option value="signing">На визировании (отправка email пользователям с статусом "finance")</option>
              <option value="cancelled">Отменен</option>
              <option value="stamping">отправлен на подписание</option>
              <option value="stamped_received">подписан и получен</option>
            </select>
          </div>
          <div>
            <label for="patch_notify_template">Notify Now:</label>
            <select id="patch_notify_template" v-model="patchAct.notify_template">
              <option value="">Do nothing</option>
              <option value="send_act">Send Current Act</option>
            </select>
          </div>
          <div>
            <button>Patch</button>
          </div>
        </form>
      </fieldset>
      <table>
        <thead>
          <tr>
            <th colspan="2" v-for="(value, column) in summary[0]">{{ column }}</th>
          </tr>
          <tr v-for="(item, key) in summary">
            <td colspan="2" v-for="(value, column) in item">{{ value }}</td>
          </tr>
        </thead>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.name }}
              <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
              <span class="remove_button" @click="removeColumn(item)">X</span>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in filteredSource">
            <th>
              <label :for="'act_export' + row.payment_key"
                >{{ row.payment_key
                }}<input type="checkbox" :id="'act_export' + row.payment_key" :value="row" v-model="selectedData" />
              </label>
            </th>
            <th>
              <router-link
                v-if="row.supplier_id"
                :to="{ name: 'supplier_view', params: { supplier: row.supplier_id } }"
                >{{ row.supplier_name }}</router-link
              >
            </th>
            <td>{{ row.aggregator }}</td>
            <td>{{ row.visits }}</td>
            <td>{{ formatCurrency(row.money) }}</td>
            <td>{{ row.vat_rate }}</td>
            <td>{{ row.unp }}</td>
            <td>{{ row.bik }}</td>
            <td>{{ row.bank_name }}</td>
            <td>{{ row.accnum }}</td>
            <td>{{ row.legal_name }}</td>
            <td>{{ row.aemail }}</td>
            <td>
              <a target="_blank" @click="fireDownloadingAct(row)">{{ "&#8679;" + row.name }}</a>
            </td>
            <td>{{ row.status }}</td>
            <td>{{ row.bank_status }}</td>
            <td>{{ row.notify_template }}</td>
            <td>{{ row.purpose_of_document }}</td>
            <td>
              <button v-if="row.id" @click="fireDeletingAct(row)">Delete Act</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SortMixin from "../../mixins/SortMixin";
import buildCurrencyFormatter from "../../middleware/buildCurrencyFormatter";

import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";

const DEFAULT_SLEEP_SECONDS = 1;

export default {
  mixins: [SortMixin],
  data: function () {
    let newStartedAt = new Date();
    let newFinishedAt = new Date();
    newStartedAt.setMonth(newFinishedAt.getMonth() - 1);
    newStartedAt.setDate(1);
    newFinishedAt.setDate(0);
    return {
      no_download_act: true,
      params: {
        city: null,
        month_at: newStartedAt.toJSON().substr(0, 7),
        started_at: newStartedAt.toJSON().substr(0, 10),
        finished_at: newFinishedAt.toJSON().substr(0, 10),
      },
      sleep_command: "",
      sleep_seconds: DEFAULT_SLEEP_SECONDS,
      sleep_total: 0,
      selectedData: [],
      actPatchForm: false,
      patchAct: {
        mode: "",
        status: "stamping",
      },
    };
  },
  watch: {
    sleep_command: function (val, oldVal) {
      if (val === oldVal) {
        return;
      }
      switch (val) {
        case "pdf":
          this.sleep_seconds = 1;
          break;
        case "order":
          this.sleep_seconds = 13;
          break;
        case "mail":
          this.sleep_seconds = 47;
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      source: "supplieract/all_act_list",
      summary: "supplieract/summary",
      country: "auth/country",
      citiesList: "config/citiesList",
    }),
    currencyFormat() {
      const formatter = buildCurrencyFormatter(this.country);
      return formatter.format;
    },
    new_act_amount() {
      return this.selectedData.filter((act) => act.status === "to_create").length;
    },
    delete_act_amount() {
      return this.selectedData.filter((act) => ["no_pdf", "generated"].indexOf(act.status) !== -1).length;
    },
    act_created_amount() {
      return this.selectedData.filter((act) => act.status !== "to_create").length;
    },
    act_generated_amount() {
      return this.selectedData.filter((act) => ["to_create", "no_pdf"].indexOf(act.status) === -1).length;
    },
  },
  methods: {
    formatCurrency(value) {
      return this.currencyFormat ? this.currencyFormat(value / 100) : value;
    },
    setPeriod() {
      const month = new Date(this.params.month_at);
      this.params.started_at = month.toJSON().substr(0, 10);
      month.setMonth(month.getMonth() + 1);
      month.setDate(0);
      this.params.finished_at = month.toJSON().substr(0, 10);
    },
    createColumns() {
      return [
        { key: "payment_key", name: "UID", order: 1 },
        { key: "supplier_name", name: "Recipient Name", order: 1 },
        { key: "aggregator", name: "Aggregator", order: 1 },
        { key: "visits", name: "Visits", order: 1 },
        { key: "money", name: "Money", order: 1 },
        { key: "vat_rate", name: "Vat Rate", order: 1 },
        { key: "unp", name: "UNP", order: 1 },
        { key: "bik", name: "BIK", order: 1 },
        { key: "bank_name", name: "Bank Name", order: 1 },
        { key: "accnum", name: "Account Number", order: 1 },
        { key: "legal_name", name: "Legal Name", order: 1 },
        { key: "aemail", name: "Accountant Email", order: 1 },
        { key: "name", name: "Act", order: 1 },
        { key: "status", name: "Act Status", order: 1 },
        { key: "notify_template", name: "Notified?", order: 1 },
        { key: "purpose_of_document", name: "Purpose of Document", order: 1 },
      ];
    },
    ...mapActions({
      load_act_list: "supplieract/load_act_list_by_range",
      load_act_summary: "supplieract/load_act_summary_by_range",
      load_attractions_compare: "supplieract/load_attractions_compare",
      createAct: "supplieract/create_act",
      deleteAct: "supplieract/delete_act",
      validateAct: "supplieract/validate_act",
      upgradeAct: "supplieract/upgrade_act",
      doPatchAct: "supplieract/patch_act",
      generateAct: "supplieract/generate_act",
      downloadAct: "supplieract/download_act",
      downloadActs: "supplieract/download_acts",
      generateOrderBy: "supplieract/generate_order_by",
      generateOrderRu: "supplieract/generate_order_ru",
      downloadOrderArmenia: "supplieract/download_bank_order",
      downloadOrderCyprusXml: "supplieract/download_bank_order_xml",
      exportTo1C: "supplieract/export_to_1c",
      sup_act_list_to1c: "supplieract/sup_act_list_to1c",
    }),
    async fireFetchingActs() {
      await this.load_act_list({ started_at: this.params.started_at, finished_at: this.params.finished_at });
    },
    async fireCsvActs() {
      await this.csv_act_list({
        started_at: this.params.started_at,
        finished_at: this.params.finished_at,
        bank_status: "paid",
      });
    },
    async fireSupActs1cExport() {
      const params = { month: this.params.month_at, ref: this.$refs.act_download_ref };
      await this.sup_act_list_to1c(params);
    },
    async fireBankOrder() {
      const params = this.params;
      await this.downloadOrderArmenia({
        started_at: params.started_at,
        finished_at: params.finished_at,
        ref: this.$refs.act_download_ref,
      });
    },
    async fireBankOrderXml() {
      const params = this.params;
      await this.downloadOrderCyprusXml({
        started_at: params.started_at,
        finished_at: params.finished_at,
        ref: this.$refs.act_download_ref,
      });
    },
    async fireFetchSummary() {
      await this.load_act_summary({
        started_at: this.params.started_at,
        finished_at: this.params.finished_at,
        city: this.params.city,
      });
    },
    async fireFetchAttractions() {
      await this.load_attractions_compare({ started_at: this.params.started_at, finished_at: this.params.finished_at });
    },
    fireToggleActs(e) {
      const is_on = e.target.checked;
      if (is_on) {
        this.selectedData = this.filteredSource;
      } else {
        this.selectedData = this.selectedData.filter((item) => !this.filteredSource.includes(item));
      }
    },
    async fireCreatingActs() {
      const data = this.selectedData.filter((act) => act.status === "to_create");
      if (data.length) {
        await this.createAct(data.map((item) => item.payment_key));
        this.selectedData = [];
      }
    },
    async fireGeneratingActs() {
      this.selectedData = this.selectedData.filter((act) => act.status !== "to_create");
      this.sleep_command = "pdf";
      this.sleep_total = this.sleep_seconds * this.selectedData.length;
      for (let id in this.selectedData) {
        if (this.sleep_total <= 0) {
          break;
        }
        let act = this.selectedData[id];
        await this.generateAct({ act, ref: this.$refs.act_download_ref, no_download: this.no_download_act });
        delete this.selectedData[id];
        await this.sleep(this.sleep_seconds);
        this.sleep_total -= this.sleep_seconds;
      }
    },
    async sleep(seconds) {
      return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
    },
    bankByCountry(country) {
      switch (country) {
        case "ru":
          return "Tochka Bank";
        case "by":
          return "BSB Bank";
      }
      return "~";
    },
    async fireOrder() {
      this.selectedData = this.selectedData.filter((act) => act.status !== "to_create");
      this.sleep_command = "order";
      this.sleep_total = this.sleep_seconds * this.selectedData.length;
      const country = this.country;
      const generateOrderCallable = (act) => {
        switch (country) {
          case "by":
            return this.generateOrderBy(act);
          case "ru":
            return this.generateOrderRu(act);
        }
      };
      for (let id in this.selectedData) {
        if (this.sleep_total <= 0) {
          break;
        }
        let act = this.selectedData[id];
        await generateOrderCallable(act);
        delete this.selectedData[id];
        await this.sleep(this.sleep_seconds);
        this.sleep_total -= this.sleep_seconds;
      }
    },
    async fireExport1C() {
      if (this.selectedData.length) {
        let ids = this.selectedData.map((act) => act.id);
        await this.exportTo1C({
          ids: ids,
          ref: this.$refs.act_download_ref,
        });
      }
    },
    async fireDeletingAct(act) {
      if (confirm(`Are you sure deletting ${act.name}?`)) {
        await this.deleteAct(act);
      }
    },
    async fireDownloadingAct(act) {
      await this.downloadAct({ act, ref: this.$refs.act_download_ref });
    },
    async fireDeletingActs() {
      this.selectedData = this.selectedData.filter((act) => ["no_pdf", "generated"].indexOf(act.status) !== -1);
      this.sleep_command = "pdf";
      this.sleep_total = this.sleep_seconds * this.selectedData.length;
      for (let id in this.selectedData) {
        if (this.sleep_total <= 0) {
          break;
        }
        let act = this.selectedData[id];
        await this.deleteAct(act);
        delete this.selectedData[id];
        await this.sleep(this.sleep_seconds);
        this.sleep_total -= this.sleep_seconds;
      }
    },
    async fireValidateActs() {
      this.selectedData = this.selectedData.filter((act) => act.status !== "to_create");
      this.sleep_command = "pdf";
      this.sleep_total = this.sleep_seconds * this.selectedData.length;
      for (let id in this.selectedData) {
        if (this.sleep_total <= 0) {
          break;
        }
        let act = this.selectedData[id];
        await this.validateAct(act);
        delete this.selectedData[id];
        await this.sleep(this.sleep_seconds);
        this.sleep_total -= this.sleep_seconds;
      }
      alert("ALL GOOD!");
    },
    async fireUpgradeActs() {
      if (confirm(`Are you sure upgrade chosen acts?`)) {
        this.selectedData = this.selectedData.filter((act) => act.status !== "to_create");
        this.sleep_command = "pdf";
        this.sleep_total = this.sleep_seconds * this.selectedData.length;
        for (let id in this.selectedData) {
          if (this.sleep_total <= 0) {
            break;
          }
          let act = this.selectedData[id];
          await this.upgradeAct(act);
          delete this.selectedData[id];
          await this.sleep(this.sleep_seconds);
          this.sleep_total -= this.sleep_seconds;
        }
      }
    },
    async firePatchingActs() {
      this.selectedData = this.selectedData.filter((act) => ["to_create", "no_pdf"].indexOf(act.status) === -1);
      this.patchAct.mode = "without_email";
      this.patchAct.ids = this.selectedData.map((act) => act.id);
      await this.doPatchAct(this.patchAct);
      this.selectedData = [];
      this.actPatchForm = false;
    },
    async fireDownloadPdf() {
      this.selectedData = this.selectedData.filter((act) => ["to_create", "no_pdf"].indexOf(act.status) === -1);
      this.sleep_command = "pdf";
      this.sleep_total = this.sleep_seconds * this.selectedData.length;
      const data = this.selectedData.slice();
      const chunks = [];
      let i,
        j,
        chunk = 10,
        name;
      for (i = 0, j = data.length; i < j; i += chunk) {
        chunks.push(data.slice(i, i + chunk));
      }
      for (let id in chunks) {
        if (this.sleep_total <= 0) {
          break;
        }
        chunk = chunks[id];
        name = chunk.length ? chunk[0].supplier_id + "_" + chunk[chunk.length - 1].supplier_id : "act";
        await this.downloadActs({ name, ids: chunk.map((i) => i.id), ref: this.$refs.act_download_ref });
        for (let i in chunk) {
          j = chunk[i];
          delete this.selectedData[j];
        }
        await this.sleep(this.sleep_seconds);
        this.sleep_total -= this.sleep_seconds;
      }
    },
    async fireSendActs() {
      this.selectedData = this.selectedData.filter((act) => ["to_create", "no_pdf"].indexOf(act.status) === -1);
      this.patchAct.mode = "";
      this.patchAct.status = "";
      this.patchAct.bank_status = "paying";
      this.patchAct.notify_template = "send_act";
      this.sleep_command = "mail";
      this.sleep_total = this.sleep_seconds * this.selectedData.length;
      for (let id in this.selectedData) {
        if (this.sleep_total <= 0) {
          break;
        }
        let act = this.selectedData[id];
        this.patchAct.ids = [act.id];
        await this.doPatchAct(this.patchAct);
        delete this.selectedData[id];
        await this.sleep(this.sleep_seconds);
        this.sleep_total -= this.sleep_seconds;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, [{ country: [this.$store.state.auth.country] }]);
    await this.fireFetchingActs();
  },
};
</script>

<style scoped lang="scss"></style>
