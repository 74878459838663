<template>
  <div>
    <button @click="toggleCreatingForm = !toggleCreatingForm">
      {{ toggleCreatingForm ? "Hide form" : "Add card" }}
    </button>
    <card-create v-if="toggleCreatingForm && input && input.company_id" :company_id="input.company_id"></card-create>
    <card-update :input="card"></card-update>
    <div v-if="source.length">
      <table>
        <caption>
          {{
            employees_card_count
          }}[PHONES:
          {{
            employees_phone_count
          }}] online/{{
            employees_count
          }}
          all
        </caption>
        <thead>
          <tr>
            <th></th>
            <th v-for="column in columns" @click="sortBy(column.key)" :class="{ active: orderKey === column.key }">
              {{ column.name | capitalize }}
              <span class="arrow" :class="column.order > 0 ? 'asc' : 'dsc'"> </span>
            </th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <td>
              <Tooltip v-if="entry.is_accept_all_legal_rules" text="Accepted legal documents" icon="accept" right />
            </td>
            <td v-for="column in columns">
              <span v-if="column.key === 'id'">
                <router-link :to="{ name: 'card_view', params: { company: input.company_id, card: entry.id } }">{{
                  entry.id
                }}</router-link>
              </span>
              <span v-else-if="column.key === 'holder'">
                <span :class="getActivation(entry)">{{ entry.holder }}</span>
              </span>
              <span v-else-if="column.key === 'prolongating_type'">
                {{ entry.prolongating_type | prolongating_type_label }}
              </span>
              <span v-else>{{ entry[column.key] }}</span>
            </td>
            <td>
              <button title="Edit" @click="updatingCard(entry)">&#9998;</button>
              <span v-if="input.allow_deleted">
                <button title="Restore" v-if="entry.deleted_at" @click="fireRestoringCard(entry)">&#9733;</button>
                <button title="Delete" v-else @click="fireDeletingCard(entry)">&#10005;</button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <download-csv
        :data="source"
        :name="input.filtered_at + '_company' + input.company_id + '_cards'"
        class="btn btn-default"
      >
        Download Holders CSV
      </download-csv>
    </div>
  </div>
</template>

<script>
import SortMixin from "../../mixins/SortMixin";
import { mapActions, mapGetters } from "vuex";
import JsonCSV from "vue-json-csv";
import CardUpdate from "./CardUpdate";
import CardCreate from "./CardCreate";
import Tooltip from "@/components/tooltips/Tooltip.vue";

export default {
  name: "CardsCardIndex",
  mixins: [SortMixin],
  components: {
    Tooltip,
    DownloadCsv: JsonCSV,
    CardUpdate,
    CardCreate,
  },
  props: {
    input: Object,
  },
  watch: {
    input: {
      handler(val) {
        if (
          val.company_id !== this.params.company_id ||
          val.with_deleted !== this.params.with_deleted ||
          val.filtered_at !== this.params.filtered_at
        ) {
          this.params = Object.assign({ changed: true }, val);
          this.loadCards({
            company_id: val.company_id,
            date: val.filtered_at,
            with_deleted: val.with_deleted,
          }).then(() => {});
        }
        this.filterKey = val.filter;
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadCards({
      company_id: this.input.company_id,
      date: this.input.filtered_at,
      with_deleted: this.input.with_deleted,
    });
  },
  data() {
    return {
      statuses: ["", "new", "continue", "reject"],
      toggleCreatingForm: false,
      toggleDeleteButtons: false,
      filterKey: "",
      activatedFilter: false,
      params: {
        company_id: 0,
        filter: "",
        filtered_at: "",
        with_deleted: false,
      },
      card: {},
    };
  },
  computed: {
    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.columns[index].order;
      }
      let data = this.source;
      if (this.filterKey) {
        const filterKey = this.filterKey && this.filterKey.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (this.input.only_activated) {
        data = data.filter((item) => item.activated);
      }
      if (orderKey && index !== -1) {
        this.columns[index].order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      if (index === -1) {
        console.log(`${index} cannot be -1. We are looking for ${orderKey} in our columns.`);
      }
      return data;
    },
    ...mapGetters({
      source: "companycard/card_list",
    }),
    employees_card_count() {
      const holders = {};
      this.source.map((item) => {
        if (item.activated) {
          holders[item.holder] = 1;
        }
      });
      return Object.getOwnPropertyNames(holders).length;
    },
    employees_phone_count() {
      const holders = {};
      this.source.map((item) => {
        if (item.activated && item.phone_number) {
          holders[item.holder] = 1;
        }
      });
      return Object.getOwnPropertyNames(holders).length;
    },
    employees_count() {
      return this.source.length;
    },
  },
  methods: {
    getActivation(card) {
      return card.activated ? "status-on" : "status-off";
    },
    createColumns() {
      return [
        { key: "id", name: "id", order: 1 },
        { key: "holder", name: "holder", order: 1 },
        { key: "phone_number", name: "phone_number", order: 1 },
        { key: "email", name: "email", order: 1 },
        { key: "level", name: "level", order: 1 },
        { key: "prolongating_type", name: "last message", order: 1 },
        { key: "updated_at", name: "updated_at", order: 1 },
      ];
    },
    ...mapActions({
      loadCards: "companycard/load_card_list_by_company",
      restoreCard: "companycard/restore_card",
      deleteCard: "companycard/delete_card",
    }),
    async fireDeletingCard(card) {
      if (confirm("Are you sure?")) {
        await this.deleteCard({ company_id: this.input.company_id, card });
      }
    },
    async fireRestoringCard(card) {
      if (confirm("Restore?")) {
        await this.restoreCard(card);
      }
    },
    updatingCard(card) {
      this.card = card;
    },
  },
};
</script>

<style scoped>
.status-off {
  background: #e7b6b6;
}
.status-on {
  background: #d2fced;
}
</style>
