<template>
  <small-layout
    ><div>
      <router-link to="/history_tokens/list">History</router-link>
      <fieldset v-if="error">
        <title>Обновить</title>
        <form @submit.prevent="fireSentryLink">
          <div>
            <label for="cr_url">Url:</label>
            <input id="cr_url" type="url" v-model="error.url" />
          </div>
          <div>
            <button>Update</button>
          </div>
        </form>
      </fieldset>
      <h1>Tokens</h1>
      <form id="search">
        <div>
          <label for="params_date">Date:</label>
          <input id="params_date" type="date" v-model="params.date" @change="refresh" />
        </div>
        <label for="ip">Ip?</label>
        <input id="ip" type="number" v-model="params.ip" @change="refresh" />
        Search <input name="query" v-model="filterKey" />
      </form>
      <table>
        <caption>
          Tokens
        </caption>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.name | capitalize }}
              <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <td v-for="column in columns">
              <span v-if="column.key === 'id'" :class="getBackground(entry.id === tokenData.jti)">
                {{ entry[column.key] }}<span v-if="entry.id === tokenData.jti">(Current)</span>
              </span>
              <span v-else-if="column.key === 'user_id'">
                <router-link :to="{ name: 'user_list', params: { user_id: entry.user_id } }">{{
                  entry.user_id
                }}</router-link>
              </span>
              <span v-else-if="column.key === 'token'">
                <router-link
                  :to="{ name: 'token_history_list', params: { user_id: entry.user_id, token: entry.token } }"
                  >{{ entry.token }}</router-link
                >
              </span>
              <span v-else-if="column.key === 'url'">
                <a v-if="entry.url" target="_blank" :href="entry.url">See error</a>
                <button @click.prevent="error = entry">&#9998;</button>
              </span>
              <span v-else-if="column.key === 'ip'">
                {{ entry.ip | int2ip }}
              </span>
              <span v-else>{{ entry[column.key] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SortMixin from "../mixins/SortMixin";
export default {
  mixins: [SortMixin],
  data: function () {
    return {
      params: {
        ip: "",
      },
      error: null,
      orderKey: "id",
      order: 1,
      filterKey: "",
    };
  },
  computed: {
    ...mapGetters({
      source: "token/token_list",
      token: "auth/getAccessToken",
    }),
    tokenData() {
      if (!this.token) {
        return { jti: "" };
      }
      return this.parseJwt(this.token);
    },
  },
  filters: {
    int2ip(ipInt) {
      if (!ipInt) {
        return "";
      }
      return (ipInt >>> 24) + "." + ((ipInt >> 16) & 255) + "." + ((ipInt >> 8) & 255) + "." + (ipInt & 255);
    },
  },
  methods: {
    fireSentryLink() {
      this.update_error(this.error);
    },
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    getBackground(isCurrent) {
      return isCurrent ? "current" : "no-current";
    },
    async refresh() {
      await this.fetchTokens(this.params);
    },
    createColumns() {
      return [
        { key: "id", name: "id", order: 1 },
        { key: "ip", name: "ip", order: 1 },
        { key: "user_id", name: "user_id", order: 1 },
        { key: "url", name: "url", order: 1 },
        { key: "token", name: "token", order: 1 },
        { key: "created_at", name: "created_at", order: 1 },
        { key: "updated_at", name: "updated_at", order: 1 },
        { key: "date_updated_at", name: "date_updated_at", order: 1 },
      ];
    },
    ...mapActions({
      update_error: "token/update_error",
      fetchTokens: "token/token_list",
    }),
  },
  async mounted() {
    await this.refresh();
  },
};
</script>

<style scoped>
.current {
  background-color: lightgreen;
}
.no-current {
  background-color: lightgoldenrodyellow;
}
</style>
