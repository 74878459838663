<template>
  <jet-form-section @click_overlay="hide">
    <template #title> New </template>

    <template #description>
      After you register Subscription by putting CARD_ID with SUPPLIER_ID, the Subscription will not be able to visit
      the Supplier.
    </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="card_id" value="CARD ID (subscription)" />
        <jet-input id="card_id" type="text" class="mt-1 block w-full" v-model="form.card_id" autofocus />
        <jet-input-error :message="form.errors.card_id" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="supplier_id" value="SUPPLIER ID" />
        <jet-input id="supplier_id" type="text" class="mt-1 block w-full" v-model="form.supplier_id" autofocus />
        <jet-input-error :message="form.errors.supplier_id" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="message" value="Message" />
        <textarea id="message" rows="6" class="mt-1 block w-full" v-model="form.message" autofocus />
        <jet-input-error :message="form.errors.message" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <jet-button
        @click="createMessage"
        class="ml-4"
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        Confirm
      </jet-button>
      <jet-action-message :on="form.recentlySuccessful" class="mr-3"> Saved. </jet-action-message>
    </template>
  </jet-form-section>
</template>

<script>
import JetButton from "../../Jetstream/Button";
import JetFormSection from "../../Jetstream/FormSection";
import JetInput from "../../Jetstream/Input";
import JetInputError from "../../Jetstream/InputError";
import JetLabel from "../../Jetstream/Label";
import JetActionMessage from "../../Jetstream/ActionMessage";
import { mapActions } from "vuex";

export default {
  components: {
    JetActionMessage,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
  },

  data() {
    return {
      form: {
        card_id: "",
        supplier_id: "",
        errors: [],
      },
    };
  },

  methods: {
    ...mapActions({
      store: "lock_list/store",
    }),
    async createMessage() {
      await this.store(this.form);
      this.hide();
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
