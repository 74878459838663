<template>
  <div class="input_container">
    <input ref="inputRef" type="text" v-bind="$attrs" />
  </div>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import isNumber from "lodash/isNumber";
import merge from "lodash/merge";

const DEFAULT_CURRENCY_CONFIG = {
  currency: "BYN",
  currencyDisplay: "hidden",
  exportValueAsInteger: true,
  precision: 2,
};

function getCurrencyInputConfig(props) {
  const { positive, precision } = props;
  const propsConfig = {};

  if (positive) {
    propsConfig.valueRange = {
      min: 0,
    };
  }

  if (isNumber(precision)) {
    propsConfig.precision = precision;
  }

  const config = merge({}, DEFAULT_CURRENCY_CONFIG, props.options, propsConfig);

  return config;
}

export default {
  emits: ["input"],

  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    positive: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: Number,
      required: false,
    },
    value: {
      type: [Number],
      required: false,
    },
  },

  setup(props) {
    const currencyInputConfig = getCurrencyInputConfig(props);

    const { inputRef } = useCurrencyInput(currencyInputConfig);

    return {
      inputRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.input_container {
  width: 100%;
  position: relative;
  padding-bottom: 24px;

  input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: 34px;
    padding: 0 16px;
    font-weight: 400;
    font-size: 13px;
    color: #000;
    background-color: #fff;

    &[disabled] {
      background-color: rgba(231, 231, 231, 0.5);
      border-color: rgba(0, 0, 0, 0.3);
    }

    &:focus {
      border-color: #000;

      & + .input_container_icon {
        stroke: #ccc;
      }
    }
  }
}
</style>
