/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api_company from "../../api/company";
import { isEmpty } from "../../middleware/functions";
import openBlobOtherWindow from "../../middleware/openBlobOtherWindow";

const state = {
  company_list: [],
  claim_list: [],
  monthly_list: { length: 0 },
  card_list: [],
  invoice_list: [],
  act_list: [],
  match_holder_list: [],
  templates: {
    companies: [],
    suppliers: [],
  },
};

// getters
const getters = {
  templates(state) {
    return state.templates;
  },
  match_holder_list(state) {
    return state.match_holder_list;
  },
  company_list(state) {
    return state.company_list;
  },
  invoice_list(state) {
    return state.invoice_list;
  },
  act_list(state) {
    return state.act_list;
  },
  card_list(state) {
    return state.card_list;
  },
  claim_list(state) {
    return state.claim_list;
  },
  monthly_list(state) {
    return state.monthly_list;
  },
};

// actions
const actions = {
  async testGenerateInvoiceAndSendToHr({ commit }, { company, email }) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_company.testGenerateInvoiceAndSendToHr(company.id, email);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async sendCompanyOffToHolders({ commit }, { id, date }) {
    try {
      commit("error/clear", undefined, { root: true });
      const templates = await api_company.send_company_off(id, date);
      commit("templates", templates);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_templates({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      const templates = await api_company.load_templates();
      commit("templates", templates);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_summary_levels_stats({ commit }, { finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_company.summary_levels_stats_download(finished_at);
      const filename = "card_types_stats" + finished_at + "_AllSports.xls";
      openBlobOtherWindow(file, filename, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_summary_stats({ commit }, { finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_company.summary_stats_download(finished_at);
      const filename = "invoice_stats" + finished_at + "_AllSports.xls";
      openBlobOtherWindow(file, filename, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_b2b_stats({ commit }, { finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_company.b2b_stats_download(finished_at);
      const filename = "b2b_stats" + finished_at + ".xls";
      openBlobOtherWindow(file, filename, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_b2c_stats({ commit }, { finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_company.b2c_stats_download(finished_at);
      const filename = "b2c_stats" + finished_at + ".xls";
      openBlobOtherWindow(file, filename, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_copay_stats({ commit }, { finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_company.copay_stats_download(finished_at);
      const filename = "copay_stats" + finished_at + ".xls";
      openBlobOtherWindow(file, filename, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_visit_stats({ commit }, { finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_company.visit_stats_download(finished_at);
      const filename = "visit_stats" + finished_at + ".xls";
      openBlobOtherWindow(file, filename, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download_internal_visit_stats({ commit }, { finished_at, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const file = await api_company.internal_visit_stats_download(finished_at);
      const filename = "internal_visit_stats" + finished_at + ".xls";
      openBlobOtherWindow(file, filename, ref);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async choose_company({ commit, state }, id) {
    const company = await api_company.company_view(id);
    return Object.assign({}, company);
  },
  async get_company_list({ commit }) {
    if (isEmpty(state.company_list)) {
      await actions.load_company_list({ commit });
    }
  },
  async load_company_list({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      const company_list = await api_company.company_list();
      commit("company_list", company_list);
      return company_list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async add_company({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const company = await api_company.company_create(data);
      commit("company_add", company);
      return company;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_company_default({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      const company = await api_company.company_default();
      commit("company_add", company);
      return company;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async unn_search({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const found = await api_company.unn_search(data.unp);
      return found.registered_name;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async update_company({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const company = await api_company.company_update(data.id, data);
      commit("company_update", company);
      return company;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async delete_company({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const company = await api_company.company_delete(data.id);
      commit("company_delete", data);
      return company;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_claim_by_id({ commit }, id) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await api_company.load_claim_by_id(id);
      commit("claim_update", data);
      return data
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_claim_list_by_range({ commit }, tags) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await api_company.load_claim_list_by_range(tags);
      commit("claim_list", data);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async create_claim({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const item = await api_company.claim_create(data);
      commit("claim_add", item);
      return item;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async update_claim({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const item = await api_company.claim_update(data);
      commit("claim_update", item);
      return item;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async update_order_manager({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const item = await api_company.claim_update_on_manager(data);
      commit("claim_update", item);
      return item;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async view_claim_data_by_path({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_company.claim_patch(data, "path");
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async view_claim_data_by_path_changes({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const data = await api_company.claim_patch(data, "changes_path");
      return data;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      return ",,,";
    }
  },
  async view_claim_dict_by_path({ commit }, { company_id, filter_at }) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_company.claim_patch(
        { company_id, filter_at },
        "dictionary_path"
      );
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async view_claim_data_import({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_company.claim_patch(data, "google_sheet_filter");
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async view_claim_data_force_import({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_company.claim_patch(data, "google_sheet_id_to_path");
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async card_list({ commit }, company_id) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_company.card_list(company_id);
      commit("card_list", list);
      return list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async claim_monthly_list({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_company.claim_monthly_list(data);
      commit("monthly_list", list);
      return list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async claim_monthly_list_upsert({ commit }, { id, items }) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_company.claim_monthly_upsert(id, items);
      commit("monthly_list", list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async delete_claim({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_company.claim_remove(data);
      commit("claim_remove", data);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async invoice_list({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_company.invoice_list(data);
      commit("invoice_list", list);
      return list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async invoice_create({ commit }, { claim, invoice }) {
    try {
      commit("error/clear", undefined, { root: true });
      const added = await api_company.invoice_create(claim, invoice);
      commit("invoice_add", added);
      return added;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async invoice_update({ commit }, { claim, invoice }) {
    try {
      commit("error/clear", undefined, { root: true });
      const updated = await api_company.invoice_update(claim, invoice);
      commit("invoice_replace", updated);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async invoice_delete({ commit }, invoice) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_company.invoice_delete(invoice);
      commit("invoice_delete", invoice);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async invoice_download({ commit }, { invoice, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await api_company.invoice_download(invoice);
      const file = new Blob([response.data], { type: response.type });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition.match(/\"([^\"]+)\"/)[1];
      openBlobOtherWindow(file, fileName + ".pdf", ref);
    } catch (error) {
      if (error.response && error.response.data instanceof Blob) {
        const text = await error.response.data.text();
        const parsedError = JSON.parse(text);
        commit("error/set_error", parsedError, { root: true });
      }
      throw error;
    }
  },
  async act_list({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_company.act_list(data);
      commit("act_list", list);
      return list;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async act_create({ commit }, { claim, act }) {
    try {
      commit("error/clear", undefined, { root: true });
      const added = await api_company.act_create(claim, act);
      commit("act_add", added);
      return added;
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async act_update({ commit }, { claim, act }) {
    try {
      commit("error/clear", undefined, { root: true });
      const updated = await api_company.act_update(claim, act);
      commit("act_replace", updated);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async act_delete({ commit }, act) {
    try {
      commit("error/clear", undefined, { root: true });
      await api_company.act_delete(act);
      commit("act_delete", act);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async act_download({ commit }, { act, ref }) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await api_company.act_download(act);
      const file = new Blob([response.data], { type: response.type });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition.match(/\"([^\"]+)\"/)[1];
      openBlobOtherWindow(file, fileName + ".pdf", ref);
    } catch (error) {
      if (error.response.data instanceof Blob) {
        const text = await error.response.data.text();
        const parsedError = JSON.parse(text);
        commit("error/set_error", parsedError, { root: true });
        throw error;
      }
    }
  },
  async match_holder({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      const list = await api_company.match_holder(data);
      commit("match_holder_list", list);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async v2_import_server_parsing({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_company.v2_parse_server_csv(data);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async v2_import_google_parsing({ commit }, data) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_company.v2_parse_google_sheet(data);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async v2_update_list_parsing(
    { commit },
    { id, filterAt, columns, values, changes }
  ) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_company.v2_parse_update_list(
        id,
        filterAt,
        columns,
        values,
        changes
      );
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async v2_export_google_sheet(
    { commit },
    {
      next_month,
      arrayCsvObjects,
      arrayChanges,
      company_id,
      cleanup_off,
      validation_off,
      condition_off,
    }
  ) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api_company.v2_export_google_sheet(
        next_month,
        arrayCsvObjects,
        arrayChanges,
        company_id,
        cleanup_off,
        validation_off,
        condition_off
      );
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {
  templates(state, value) {
    state.templates = value;
  },
  match_holder_list(state, list) {
    state.match_holder_list = list;
  },
  company_list(state, list) {
    state.company_list = list;
  },
  company_add(state, company) {
    state.company_list.push(company);
  },
  company_update(state, newCompany) {
    state.company_list.splice(
      state.company_list.findIndex((company) => company.id === newCompany.id),
      1,
      newCompany
    );
  },
  company_delete(state, company) {
    state.company_list.splice(state.company_list.indexOf(company), 1);
  },
  claim_list(state, list) {
    state.claim_list = list;
  },
  claim_add(state, newItem) {
    state.claim_list.push(newItem);
  },
  claim_remove(state, removeItem) {
    state.claim_list.splice(
      state.claim_list.findIndex((item) => item.id === removeItem.id),
      1
    );
  },
  claim_update(state, newItem) {
    state.claim_list.splice(
      state.claim_list.findIndex((item) => item.id === newItem.id),
      1,
      newItem
    );
  },
  monthly_list(state, list) {
    state.monthly_list = list;
  },
  card_list(state, list) {
    state.card_list = list;
  },
  invoice_list(state, list) {
    state.invoice_list = list;
  },
  invoice_add(state, newItem) {
    state.invoice_list.push(newItem);
  },
  invoice_replace(state, newItem) {
    state.invoice_list.splice(
      state.invoice_list.findIndex((item) => item.id === newItem.id),
      1,
      newItem
    );
  },
  invoice_delete(state, oldItem) {
    state.invoice_list.splice(
      state.invoice_list.findIndex((item) => item.id === oldItem.id),
      1
    );
  },
  act_list(state, list) {
    state.act_list = list;
  },
  act_add(state, newItem) {
    state.act_list.push(newItem);
  },
  act_replace(state, newItem) {
    state.act_list.splice(
      state.act_list.findIndex((item) => item.id === newItem.id),
      1,
      newItem
    );
  },
  act_delete(state, oldItem) {
    state.act_list.splice(
      state.act_list.findIndex((item) => item.id === oldItem.id),
      1
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
