<template>
  <small-layout
    ><div id="demo">
      <h1>Cover table</h1>
      <div>
        <label for="started_at">Started</label>
        <input type="date" id="started_at" v-model="started_at" />
        <label for="finished_at">Finished</label>
        <input type="date" id="finished_at" v-model="finished_at" />
        <button type="button" @click="fetchStats">Reload</button>
        <download-csv :data="source" class="btn btn-default"> Download Holders CSV </download-csv>
      </div>
      <table>
        <thead>
          <tr>
            <th>Адрес</th>
            <th>City</th>
            <th>District</th>
            <th>Название</th>
            <th>Вид услуги</th>
            <th
              title="Ориентировочная для услуги, пока нет прямой привязки между списком услуг на карте и в списки выбираемых услуг"
            >
              Вознаграждение
            </th>
            <th>Доплата</th>
            <th>Имя услуги</th>
            <th>Начало с</th>
            <th>Виды карт</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in source">
            <td>{{ entry.address }}</td>
            <td>{{ entry.city }}</td>
            <td>{{ entry.district }}</td>
            <td>{{ entry.name }}</td>
            <td>{{ entry.attraction_types_ru }}</td>
            <td>{{ formatCurrency(entry.price) }}</td>
            <td>{{ formatCurrency(entry.copayment) }}</td>
            <td>{{ entry.attraction_name }}</td>
            <td>{{ entry.started_at }}</td>
            <td>{{ entry.levels }}</td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import JsonCSV from "vue-json-csv";
import buildCurrencyFormatter from "../middleware/buildCurrencyFormatter";
let newStartedAt = new Date();
let newFinishedAt = new Date();
newFinishedAt.setMonth(newFinishedAt.getMonth() + 1);
newFinishedAt.setDate(newFinishedAt.getDate() - 1);
export default {
  components: {
    DownloadCsv: JsonCSV,
  },
  data: function () {
    return {
      source: [],
      started_at: newStartedAt.toJSON().substr(0, 10),
      finished_at: newFinishedAt.toJSON().substr(0, 10),
    };
  },
  computed: {
    ...mapGetters({
      country: "auth/country",
    }),
    currencyFormat() {
      const formatter = buildCurrencyFormatter(this.country);
      return formatter.format;
    },
  },
  methods: {
    formatCurrency(value) {
      return this.currencyFormat ? this.currencyFormat(value / 100) : value;
    },
    ...mapActions({
      cover_list: "attraction/cover_list",
    }),
    getValues(row) {
      return row.slice(1);
    },
    async fetchStats() {
      this.source = await this.cover_list({ started_at: this.started_at, finished_at: this.finished_at });
    },
  },
  mounted() {
    this.fetchStats();
  },
};
</script>

<style scoped lang="scss">
th,
td {
  min-width: 120px;
  padding: 10px 20px;
}
.btn.btn-default {
  cursor: pointer;
  background-color: lightgray;
}
</style>
