/**
 * Created by Alexander Golovnya on 2019-01-02.
 */
import api from "../../api/affiliates_update";

const state = {};
// getters
const getters = {};

// actions
const actions = {
  async create({ commit }, item) {
    try {
      commit("error/clear", undefined, { root: true });
      await api.create(item);
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async load_list({ commit }) {
    try {
      commit("error/clear", undefined, { root: true });
      return await api.load_list();
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
  async download({ commit }, path) {
    try {
      commit("error/clear", undefined, { root: true });
      const response = await api.download(path);
      const file = new Blob([response.data], { type: response.type });
      return file.text();
    } catch (error) {
      commit("error/set_error", error, { root: true });
      throw error;
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
