<template>
  <small-layout
    ><div>
      <h1>View</h1>
      <table>
        <thead>
          <th>id</th>
          <th>holder</th>
          <th>level</th>
          <th>activated</th>
          <th>company_name</th>
          <th>deactivation_cause</th>
        </thead>
        <tbody>
          <tr>
            <td>{{ card.id }}</td>
            <td>{{ card.holder }}</td>
            <td>{{ card.level }}</td>
            <td>{{ card.activated }}</td>
            <td>{{ card.company_name }}</td>
            <td>{{ card.deactivation_cause }}</td>
          </tr>
        </tbody>
      </table>
      <button @click="toggleView">Просмотр всех полей</button>
      <div v-bind:class="'flex-container flex-2cols ' + class_full_view">
        <template v-for="(value, index) in card">
          <div class="flex-cell">{{ index }}:</div>
          <div class="flex-cell">{{ value }}</div>
        </template>
      </div>
    </div></small-layout
  >
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      card: {},
      class_full_view: "hide_full_view",
    };
  },
  methods: {
    toggleView() {
      this.class_full_view = this.class_full_view === "" ? "hide_full_view" : "";
    },
  },
  created() {
    axios.get(`/api/companies/${this.$route.params.company}/any_cards/${this.$route.params.card}`).then((response) => {
      this.card = response.data.data;
    });
  },
};
</script>
