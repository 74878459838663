import { VALIDATION_ERRORS } from "../index";

const NUMBER_REGEXP = /^\d{9}$/;

export default (number) => {
  const numberString = number.toString();

  if (numberString.length === 0) {
    return false;
  }

  if (!NUMBER_REGEXP.test(numberString)) {
    return VALIDATION_ERRORS.invalidByUnnNumber;
  }

  return false;
};
