<template>
  <fieldset v-if="invoice">
    <a ref="claim_invoice_download_ref">__</a>
    <button @click="back">Back</button>
    <form @submit.prevent>
      <button @click="newInvoice(claim, filter_at, dictionaries, monthly, info_price)">New</button>
      <button @click="invoiceAdvanced = !invoiceAdvanced">Advanced</button>
      <div>
        <label for="cri_cards">Invoice N{{ invoice.id }}:</label>
        <table id="cri_cards">
          <thead>
            <tr>
              <th v-if="invoiceAdvanced">it</th>
              <th v-if="invoiceAdvanced">service</th>
              <th>Level</th>
              <th>Price</th>
              <th v-if="invoiceAdvanced">Percent</th>
              <th>count</th>
              <th>Actions <button @click.prevent="addLine">&#10010;</button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in invoice.summary">
              <td v-if="invoiceAdvanced"><textarea aria-label="license" rows="5" cols="20" v-model="item.it" /></td>
              <td v-if="invoiceAdvanced">
                <textarea aria-label="service" rows="5" cols="20" v-model="item.service" />
              </td>
              <td>
                <select aria-label="card level" @change="changeLine(index, 'level')" v-model="item.level">
                  <option value="region">region</option>
                  <option value="silver">silver</option>
                  <option value="gold">gold</option>
                  <option value="platinum">platinum</option>
                  <option value="vip">vip</option>
                </select>
              </td>
              <td @input="changeLine(index, 'price')">
                <input aria-label="price per card" type="number" v-model="item.price" />
              </td>
              <td v-if="invoiceAdvanced" @input="changeLine(index, 'percent')">
                <input aria-label="percent on it" type="number" v-model="item.percent" />
              </td>
              <td @input="changeLine(index, 'count')">
                <input aria-label="count of cards" type="number" v-model="item.count" />
              </td>
              <td><button @click="deleteLine(index)">&#128465;</button></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4">{{ total }}</td>
            </tr>
            <tr>
              <td colspan="4"><button @click.prevent="recalculatePrices">Update prices</button></td>
            </tr>
            <tr>
              <td colspan="4">
                <label for="act_manually">Do not link the invoice and act</label
                ><input
                  id="act_manually"
                  type="checkbox"
                  v-model="invoice.act_manually"
                  :true-value="1"
                  :false-value="0"
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-if="invoiceAdvanced">
        <div>
          <label for="cri_created_at">Created At:</label>
          <input id="cri_created_at" type="date" v-model="invoice.created_at" />
        </div>
        <div>
          <label for="cri_started_at">Since:</label>
          <input
            id="cri_started_at"
            :min="invoice.min_at"
            :max="invoice.max_at"
            type="date"
            v-model="invoice.started_at"
          />
        </div>
        <div>
          <label for="cri_finished_at">Till:</label>
          <input id="cri_finished_at" :min="invoice.min_at" type="date" v-model="invoice.finished_at" />
        </div>
        <div>
          <label for="cri_invoice_template">Add customer invoice template {{ invoice.started_at_211001 }}:</label>
          <select id="cri_invoice_template" v-model="invoice.template_path">
            <option v-for="template in templates.companies.invoices" :value="template">{{ template }}</option>
          </select>
        </div>
        <div>
          <label for="cri_invoice_currency">currency in Invoice:</label>
          <select id="cri_invoice_currency" v-model="invoice.currency">
            <option value="byn">byn</option>
            <option value="usd">usd</option>
            <option value="eur">eur</option>
          </select>
        </div>
        <div>
          <label for="cri_invoice_comments">comments in Invoice:</label>
          <input type="text" v-model="invoice.comments" id="cri_invoice_comments" />
        </div>
      </div>
      <div>
        <button @click.prevent="fireUpdateInvoice(invoice)">Save</button>
      </div>
    </form>
    <table v-if="invoices">
      <caption>
        Invoices
      </caption>
      <thead>
        <tr>
          <th v-for="column in invoiceColumns">{{ column }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in invoices">
          <td v-for="column in invoiceColumns">
            <span v-if="column === 'path'">
              <button @click="downloadInvoice({ invoice: item, ref: $refs.claim_invoice_download_ref })">
                download
              </button>
            </span>
            <span v-else-if="column === 'comments'">
              {{ calculateFromSummary(item.summary, item.currency) }}:{{ item.comments }}
            </span>
            <span v-else>{{ item[column] }}</span>
          </td>
          <td>
            <button @click.prevent="fireConfigureInvoice(item)">&#9998;</button>
            <button v-if="!item.deleted_at" @click.prevent="fireDelete(item)">&#128465;</button>
          </td>
        </tr>
      </tbody>
    </table>
  </fieldset>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatToRFC3339, formatToRuMinskLocale, generateCalculator } from "../../middleware/functions";
export default {
  name: "InvoiceIndex",
  props: {
    claim: {
      type: Object,
    },
    monthly: {
      type: Object,
    },
    filter_at: {
      type: String,
    },
    dictionaries: {
      type: Object,
    },
    info_price: {
      type: String,
    },
    toggleDeleteButtons: {
      type: Boolean,
      default: false,
    },
    templates: {
      type: Object,
    },
  },
  watch: {
    claim: {
      handler(entity, oldVal) {
        if (!entity) {
          this.invoice = null;
          return;
        }
        const that = this;
        if (!oldVal || entity.company_id !== oldVal.company_id || !this.invoices.length) {
          this.invoiceList(entity).then((invoices) => {
            if (invoices.length) {
              that.fireConfigureInvoice(invoices[0]);
            }
          });
        }

        if (!this.invoice) {
          this.newInvoice(entity, this.filter_at, this.dictionaries, this.monthly, this.info_price);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      invoiceAdvanced: false,
      invoice: null,
      invoiceColumns: ["id", "comments", "path"],
      priceCalculator: { calculatePriceBy: (level, total, price) => price },
    };
  },
  computed: {
    total() {
      return this.calculateFromSummary(this.invoice.summary, this.invoice.currency);
    },
    ...mapGetters({
      invoices: "company/invoice_list",
    }),
  },
  methods: {
    newInvoice(entity, filterAt, info, monthly, infoPrice) {
      const invoice = {};
      const priceCalculator = (this.priceCalculator = generateCalculator(infoPrice));
      const started_at = new Date(filterAt);
      const finished_at = new Date(filterAt);
      finished_at.setMonth(finished_at.getMonth() + 1);
      finished_at.setDate(0);
      invoice.started_at_211001 = started_at >= new Date("2021-10-01 00:00:00");
      invoice.currency = info.currency;
      invoice.min_at = formatToRFC3339(new Date(started_at.getFullYear(), started_at.getMonth() - 1, 1, 10, 10, 10));
      invoice.max_at = formatToRFC3339(finished_at);
      invoice.started_at = formatToRFC3339(started_at);
      invoice.finished_at = invoice.max_at;
      const now = new Date();
      invoice.created_at = formatToRFC3339(now);
      invoice.template_path = "company_invoice.1.html";
      const percent = info.percent;
      if (!invoice.comments) {
        invoice.comments = "за " + formatToRuMinskLocale(started_at);
      }
      const summary = {
        plus_card: {
          it: "",
          service: "Плата за замену карты",
          level: "plus_card",
          price: 500,
          percent: 0,
          count: 0,
        },
      };
      Object.getOwnPropertyNames(monthly).map((key) => {
        const item = monthly[key];
        if (["reject", ""].indexOf(item.status) !== -1) {
          return;
        }
        if (typeof item.status === "undefined") {
          return;
        }
        const level = item.level;

        if (!summary[level]) {
          summary[level] = {
            it: info.it.replace("{level}", level),
            service: info.service.replace("{level}", level),
            level,
            price: 0,
            percent,
            count: 0,
          };
        }
        if (item.status === "continue_plus_card") {
          ++summary["plus_card"]["count"];
        }
        ++summary[level]["count"];
      });

      let totalCount = 0;
      Object.getOwnPropertyNames(summary).map((key) => {
        const item = summary[key];
        totalCount += item.count;
      });
      if (entity) {
        if (entity.it_balance !== 0) {
          summary["it_balance"] = {
            it:
              entity.it_balance > 0
                ? "переплата за предыдущий период составила"
                : "задолженность за предыдущий период составила",
            service: "",
            level: "",
            price: -1 * entity.it_balance,
            percent: 100,
            count: 1,
          };
        }
        if (entity.service_balance !== 0) {
          summary["service_balance"] = {
            it: "",
            service:
              entity.service_balance > 0
                ? "переплата за предыдущий период составила"
                : "задолженность за предыдущий период составила",
            level: "",
            price: -1 * entity.service_balance,
            percent: 0,
            count: 1,
          };
        }
      }

      invoice.summary = Object.getOwnPropertyNames(summary)
        .map((key) => {
          const item = summary[key];
          item.price = priceCalculator.calculatePriceBy(item.level, totalCount, item.price);
          return item;
        })
        .filter((item) => item.count);
      this.invoice = invoice;
    },
    fireConfigureInvoice(activated) {
      const invoice = JSON.parse(JSON.stringify(activated));
      const started_at = new Date(invoice.started_at);
      const finished_at = new Date(invoice.finished_at);
      invoice.min_at = formatToRFC3339(new Date(started_at.getFullYear(), started_at.getMonth() - 1, 1, 10, 10, 10));
      invoice.max_at = formatToRFC3339(finished_at);
      invoice.started_at = formatToRFC3339(started_at);
      invoice.finished_at = invoice.max_at;
      invoice.created_at = invoice.created_at.substr(0, 10);
      this.invoice = invoice;
    },
    fireDelete(item) {
      const text = 'Type "DELETE" to remove invoice ' + item.id;
      if (prompt(text, "D") !== "DELETE") {
        return;
      }
      this.invoiceDelete(item);
    },
    calculateFromSummary(summary, currency) {
      const result = { it: 0, service: 0 };
      let i, item;
      for (i in summary) {
        item = summary[i];
        if (!item.price) {
          continue;
        }
        result.it += Math.round((item.percent * item.price * item.count) / 100);
        result.service += Math.round(((100 - item.percent) * item.price * item.count) / 100);
      }
      result.vat = 0;
      if (new Date(this.filter_at) >= new Date("2021-10-01")) {
        result.vat = result.service * 0.2;
      }
      const currencyUpper = currency.toUpperCase();
      return `AllSports ${Math.floor(result.it / 100)}.${result.it % 100} ${currencyUpper}, Super ${Math.floor(
        result.service / 100
      )}.${result.service % 100} ${currencyUpper}, Super VAT ${Math.floor(result.vat / 100)}.${
        result.vat % 100
      } ${currencyUpper}`;
    },
    back() {
      this.$emit("close");
      this.invoice = null;
    },
    addLine() {
      this.invoice.summary.push({
        it: "",
        service: "",
        level: "",
        percent: Number(this.dictionaries.percent),
        price: 0,
        count: 0,
      });
    },
    deleteLine(index) {
      const result = this.invoice.summary.splice(index, 1);
      if (!result.length) {
        this.$set(this.invoice, "summary", this.invoice.summary);
      }
    },
    changeLine(index, key) {
      switch (key) {
        case "level":
          let totalCount = 0;
          const summary = this.invoice.summary;
          summary.map((item) => {
            totalCount += Number(item.count);
          });
          const line1 = this.invoice.summary[index];
          line1.price = this.priceCalculator.calculatePriceBy(line1.level, totalCount, line1.price);
          this.$set(this.invoice, "summary", this.invoice.summary);
          break;
      }
    },
    recalculatePrices() {
      let totalCount = 0;
      const summary = this.invoice.summary;
      summary.map((item) => {
        totalCount += Number(item.count);
      });
      summary.map((item) => {
        if (!item.level) {
          return;
        }
        item.price = this.priceCalculator.calculatePriceBy(item.level, totalCount, item.price);
      });
    },
    async fireUpdateInvoice(invoice) {
      if (invoice.id) {
        await this.invoiceUpdate({ claim: this.claim, invoice });
        await this.downloadInvoice({ invoice, ref: this.$refs.claim_invoice_download_ref });
      } else {
        const newInvoice = await this.invoiceCreate({ claim: this.claim, invoice });
        await this.downloadInvoice({ invoice: newInvoice, ref: this.$refs.claim_invoice_download_ref });
      }
      this.invoice = invoice;
    },
    ...mapActions({
      invoiceList: "company/invoice_list",
      invoiceCreate: "company/invoice_create",
      invoiceUpdate: "company/invoice_update",
      invoiceDelete: "company/invoice_delete",
      downloadInvoice: "company/invoice_download",
    }),
  },
};
</script>

<style scoped></style>
