<template>
  <small-layout>
    <div>
      <router-link
        v-if="company && company.id"
        :to="{ name: 'company_update', params: { company: company.id, country: $route.params.country } }"
      >
        Изменить {{ company.id }}:{{ company.name }}<br />
        Статус: {{ company.activated }} - {{ company.deactivation_cause }}
      </router-link>

      <div v-if="company.id">
        <form id="search">
          <label for="query">Local Search</label><input id="query" name="query" v-model="params.filter" />
          <button @click.prevent="params.allow_deleted = !params.allow_deleted">
            {{ params.allow_deleted ? "Allow DEL" : "Deny DEL" }}
          </button>
          <label>Activated Only <input type="checkbox" v-model="params.only_activated" /></label>
          <label for="filter_with_deleted">With Removed</label>
          <input id="filter_with_deleted" name="filter_with_deleted" type="checkbox" v-model="params.with_deleted" />
          <label for="filter_date">Date</label
          ><input id="filter_date" name="filter_date" type="month" v-model="params.filtered_at" />
        </form>
        <card-index :input="params"></card-index>
      </div>
    </div>
  </small-layout>
</template>

<script>
import { mapActions } from "vuex";
import CardIndex from "../cards/CardIndex";
export default {
  components: { CardIndex },
  props: ["company_id"],
  data() {
    let filterDate = new Date();
    filterDate.setMonth(filterDate.getMonth() + 1);
    filterDate.setDate(0);
    return {
      params: {
        company_id: 0,
        filter: "",
        filtered_at: filterDate.toJSON().substr(0, 7),
        allow_deleted: false,
        with_deleted: false,
        only_activated: false,
      },
      company: { id: 0 },
    };
  },
  methods: {
    ...mapActions({
      chooseCompany: "company/choose_company",
    }),
    async extractParamsAndRun(to) {
      this.params.company_id = 0;
      if (to.params.company) {
        this.params.company_id = Number(to.params.company);
      }
      this.company = await this.chooseCompany(this.params.company_id);
    },
  },
  watch: {
    $route(to) {
      this.extractParamsAndRun(to);
    },
  },
  async mounted() {
    await this.extractParamsAndRun(this.$route);
  },
};
</script>

<style scoped>
.active {
  background: #d2fced;
}
</style>
