<template>
  <small-layout>
    <div>
      <h1>Visit</h1>
      <input type="text" :value="$route.params.visit" @keyup.enter="newVisit" />
      <fieldset v-if="visitUpdateHolder">
        <legend>To update holder scans <button @click="visitUpdateHolder = false">Close form</button></legend>
        <form @submit.prevent="fireUpdateVisitCard">
          <div>
            <label for="up_holder_text">Enter FIO and choose options bellow:</label>
            <input type="text" id="up_holder_text" v-model="params.holder" v-on:keyup="holderSearchTimeout" />
            <select v-model="visit.new_card_id" id="up_holder">
              <option value="visit.card_id">
                Chosen
                {{
                  cards
                    .filter((card) => card.id === visit.card_id)
                    .reduce((acc, card) => (card ? card.holder : "..."), "...")
                }}
              </option>
              <option v-for="card in cards" :value="card.id">{{ card.holder }}</option>
            </select>
            <label for="up_created_at"
              >{{ visit.created_at }}-<input
                type="number"
                min="0"
                max="30"
                v-model.range="visit.new_timedelta"
                id="up_created_at"
              />
              DAYS</label
            >
            <button v-if="holderTimer" @click="holderSearchCancel">stop</button>
          </div>
          <div>
            <button :disabled="this.visit.new_card_id === this.visit.card_id">Confirm</button>
          </div>
        </form>
      </fieldset>
      <fieldset v-if="visitAppUpdateForm">
        <legend>To update app_holder_wait scans <button @click="visitAppUpdateForm = false">close form</button></legend>
        <form @submit.prevent="fireUpdateVisit">
          <div>
            <label for="up_status">Status:</label>
            <select v-model="visit.status" id="up_app_status">
              <option value="app_holder_wait">Choose</option>
              <option value="app_holder_passed">Accepted (with reason: Manually added broken scan)</option>
              <option value="app_holder_reject">Rejected (with reason: Manually reject broken scan)</option>
              <option value="app_holder_unknown">Unknown (with reason: Failed sync app)</option>
            </select>
          </div>
          <div>
            <label for="up_attraction">Attraction:</label>
            <select v-model="visit.attraction_id" id="up_app_attraction">
              <option value="">Choose</option>
              <option v-for="attraction in attractions" :value="attraction.id">
                {{ attraction.name }} {{ getformattedPriceWithCurrency(attraction.price) }}
              </option>
            </select>
          </div>
          <div>
            <button>Confirm</button>
          </div>
        </form>
      </fieldset>
      <fieldset v-if="visitUpdateForm">
        <legend>To update supplier_accepting scans <button @click="visitUpdateForm = false">close form</button></legend>
        <form @submit.prevent="fireUpdateVisit">
          <div>
            <label for="up_status">Status:</label>
            <select v-model="visit.status" id="up_status">
              <option value="supplier_accepting">Choose</option>
              <option value="accepted">Accepted (with reason: Manually added broken scan)</option>
              <option value="supplier_reject_wrong_id">Rejected (with reason: Manually reject broken scan)</option>
              <option value="unknown_barcode">Failed scanning (with reason: Manually update broken scan)</option>
            </select>
          </div>
          <div>
            <label for="up_attraction">Attraction:</label>
            <select v-model="visit.attraction_id" id="up_attraction">
              <option value="">Choose</option>
              <option v-for="attraction in attractions" :value="attraction.id">
                {{ attraction.name }} {{ getformattedPriceWithCurrency(attraction.price) }}
              </option>
            </select>
          </div>
          <div>
            <button>Confirm</button>
          </div>
        </form>
      </fieldset>
      <fieldset v-if="visit">
        <legend>Visit</legend>
        <div v-for="column in columns">
          <label :for="'view_' + column.key">{{ column.name }}:</label>
          &nbsp;&nbsp;&nbsp;
          <span v-if="'point' === column.name">
            {{ visit[column.key] }}
            <router-link v-if="visit[column.key]" :to="{ name: 'show_bounds', params: { visit_id: visit[0] } }">
              {{ $t("map.titleShowVisit") }}
            </router-link>
            <span v-if="visit[column.key][2]" class="fake_geo" title="Fake geo location"><b>×</b> fake</span>
          </span>
          <span v-else :id="'view_' + column.key">{{ visit[column.key] }}</span>
          <hr />
        </div>
        <button v-if="isSupportManager || isLocalManager" @click="fireUpdatingVisit(visit)">Visit &#9998;</button>
        <button
          v-if="isSupportManager && mixSupportCompanies.indexOf(Number(visit.company_id)) !== -1"
          @click="fireUpdatingVisitHolder(visit)"
        >
          Holder &#9998;
        </button>
      </fieldset>
      <fieldset v-if="supplier">
        <legend>Supplier</legend>
        <router-link
          v-if="supplier.id && country === 'by'"
          :to="{ name: 'by_supplier_update', params: { supplier: supplier.id } }"
          >More Details</router-link
        >
        <router-link
          v-else-if="supplier.id"
          :to="{ name: 'default_supplier_update', params: { supplier: supplier.id, country } }"
          >More Details</router-link
        >
        <div>
          <label>Название:</label>
          {{ supplier.name }}
        </div>
        <div>
          <label>City</label>
          {{ supplier.city }}
        </div>
        <div>
          <label>Адрес</label>
          {{ supplier.address }}
        </div>
        <div>
          <label>Телефоны для Посетителей</label>
          <div v-for="phone in supplier.contact_phone ? supplier.contact_phone.split(';') : []">
            <a :href="'tel:' + phone">{{ phone }}</a>
          </div>
        </div>
        <div>
          <label>О нас (для клиента)</label>
          {{ supplier.general_information }}
        </div>
        <div>
          <label>Rules [r]</label>
          {{ supplier.rules_r }}
        </div>
        <div>
          <label>Rules [s]</label>
          {{ supplier.rules_s }}
        </div>
        <div>
          <label>Rules [g]</label>
          {{ supplier.rules_g }}
        </div>
        <div>
          <label>Rules [p]</label>
          {{ supplier.rules_p }}
        </div>
      </fieldset>
    </div>
  </small-layout>
</template>

<style scoped lang="scss">
select {
  width: 100%;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import formatCurrency from "@/helpers/formatters/formatCurrency";
const mixSupportCompanies = String(process.env.VUE_APP_SUPPORT_COMPANIES).split(",").map(parseFloat);
export default {
  data: function () {
    const defaultColumns = [
      { key: "id", name: "Visit ID", order: 1 },
      { key: "holder", name: "Holder", order: 1 },
      { key: "sup_name", name: "Gym", order: 1 },
      { key: "attr_name", name: "Attraction", order: 1 },
      { key: "phone_number", name: "Phone", order: 1 },
      { key: "model", name: "Model", order: 1 },
      { key: "os", name: "OS", order: 1 },
      { key: "point", name: "Geo", order: 1 },
      { key: "registered", name: "Time", order: 1 },
      { key: "status", name: "Status", order: 1 },
      { key: "company_name", name: "Company", order: 1 },
      { key: "card_level", name: "Card Level", order: 1 },
      { key: "reason", name: "Reason", order: 1 },
      { key: "locale", name: "Lang", order: 1 },
      { key: "timezone", name: "Timezone", order: 1 },
      { key: "city", name: "City", order: 1 },
    ].filter((column) => {
      if (this.isSupportManager) {
        return ["card_price", "company_type", "company_size"].indexOf(column.key) === -1;
      }
      if (this.isSaleManager) {
        return ["price"].indexOf(column.key) === -1;
      }
      return true;
    });
    const d = new Date();
    return {
      mixSupportCompanies,
      params: {
        date: d.toJSON().substr(0, 10),
        holder: "",
      },
      visitUpdateForm: false,
      visitAppUpdateForm: false,
      visitUpdateHolder: false,
      visit: null,
      supplier: null,
      cards: [],
      attractions: [],
      orderKey: 0,
      order: 1,
      filterKey: "",
      columns: defaultColumns,
      holderTimer: null,
      holderTickCount: 3,
      holderTick: null,
    };
  },
  computed: {
    ...mapGetters({
      country: "auth/country",
      isAdmin: "auth/isAdmin",
      isSaleManager: "auth/isSaleManager",
      isSupportManager: "auth/isSupportManager",
      isLocalManager: "auth/isLocalManager",
    }),
  },
  methods: {
    getformattedPriceWithCurrency(price) {
      return formatCurrency(price);
    },
    holderSearchTimeout() {
      this.holderSearchCancel();
      this.holderTick = setInterval(() => {
        --this.holderTickCount;
      }, 500);
      this.holderTimer = setTimeout(() => {
        this.loadHoldersBy({ card_id: 0, holder: this.params.holder }).then((cards) => {
          this.holderSearchCancel();
          this.cards = cards;
        });
      }, 1000);
    },
    holderSearchCancel() {
      if (this.holderTimer) {
        clearTimeout(this.holderTimer);
        clearInterval(this.holderTick);
        this.holderTimer = null;
        this.holderTick = null;
        this.holderTickCount = 3;
      }
    },
    ...mapActions({
      isLocalManager: "auth/isLocalManager",
      updateVisit: "helpdesk/manually_update_visit",
      viewVisit: "helpdesk/view_visit",
      viewSupplier: "supplier/load_item",
      updateVisitCard: "helpdesk/manually_update_visit_card_mobile",
      loadAttractionByVisit: "helpdesk/load_attraction_by_visit",
      loadHoldersBy: "helpdesk/load_holder_by",
    }),
    async fireUpdatingVisit(visit) {
      if (this.isLocalManager) {
        this.attractions = await this.loadAttractionByVisit(visit);
        this.visit = Object.assign({ new_card_id: visit.card_id, new_timedelta: 0 }, visit);
        this.visitUpdateForm = !this.visitUpdateForm;
        return;
      } else if (
        visit.status === "aggregator_invalid_visit_status" ||
        visit.status === "app_holder_wait" ||
        visit.status === "app_holder_timeout" ||
        visit.price === 0
      ) {
        this.attractions = await this.loadAttractionByVisit(visit);
        this.visit = Object.assign({ new_card_id: visit.card_id, new_timedelta: 0 }, visit);
        this.visitAppUpdateForm = !this.visitAppUpdateForm;
      } else if (visit.status === "accepted" && visit.reason === "manually_added_broken_scan") {
        this.attractions = await this.loadAttractionByVisit(visit);
        this.visit = Object.assign({ new_card_id: visit.card_id, new_timedelta: 0 }, visit);
        this.visitAppUpdateForm = !this.visitAppUpdateForm;
      } else {
        alert("Not editable, please send details to developer.");
      }
    },
    async fireUpdatingVisitHolder(visit) {
      if (mixSupportCompanies.indexOf(Number(visit.company_id)) !== -1) {
        this.cards = await this.loadHoldersBy({ card_id: visit.card_id, holder: null });
        this.visit = Object.assign({ new_card_id: visit.card_id, new_timedelta: 0 }, visit);
        this.visitUpdateHolder = !this.visitUpdateHolder;
        this.params.holder = "";
      }
    },
    async fireUpdateVisitCard() {
      if (this.visit.new_card_id !== this.visit.card_id) {
        this.visit.card_id = this.visit.new_card_id;
        this.visit.timedelta = this.visit.new_timedelta;
        await this.updateVisitCard(this.visit);
        this.visitUpdateHolder = false;
        this.$router.back();
      }
    },
    async fireUpdateVisit() {
      switch (this.visit.status) {
        case "accepted":
        case "app_holder_passed":
          this.visit.reason = "manually_added_broken_scan";
          break;
        case "supplier_reject_wrong_id":
        case "app_holder_reject":
          this.visit.reason = "manually_rejected_broken_scan";
          break;
        case "app_holder_unknown":
          this.visit.reason = "manually_rejected_unknown";
          break;
        default:
          this.visit.reason = "manually_updated_broken_scan";
          break;
      }
      await this.updateVisit(this.visit);
      this.visitUpdateForm = false;
      this.visitAppUpdateForm = false;
    },
    newVisit(e) {
      this.$router.push({ name: "helpdesk_mobile_view", params: { visit: e.target.value } });
    },
  },
  watch: {
    $route(to) {
      this.viewVisit(this.$route.params.visit).then((visit) => {
        this.visit = visit;
      });
    },
  },
  mounted() {
    this.viewVisit(this.$route.params.visit).then((visit) => {
      this.visit = visit;

      if (visit.supplier_id) {
        this.viewSupplier(visit.supplier_id).then((supplier) => {
          this.supplier = supplier;
        });
      }
    });

    this.viewVisit(this.$route.params.visit).then((visit) => {
      this.visit = visit;
      if (visit.supplier_id) {
        this.viewSupplier(visit.supplier_id).then((supplier) => {
          this.supplier = supplier;
        });
      }
    });
  },
};
</script>
