<template>
  <jet-form-section @click_overlay="hide">
    <template #title> New Json File </template>

    <template #description>
      Please, prepare the file in editor, like
      <a href="https://code.visualstudio.com/">https://code.visualstudio.com/</a>. Be sure, it is support JSON format
      only. Then put the JSON file here and click "save".
    </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="date" value="Date of New Update of Affiliates" />
        <jet-input id="date" type="date" class="mt-1 block w-full" v-model="form.date" autofocus />
        <jet-input-error :message="form.errors.date" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="context" value="Context of file with update info" />
        <textarea id="context" class="mt-1 block w-full" rows="100" v-model="form.context" autofocus />
        <jet-input-error :message="form.errors.context" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <jet-button
        @click="createUpdate"
        class="ml-4"
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        Confirm
      </jet-button>
      <jet-action-message :on="form.recentlySuccessful" class="mr-3"> Saved. </jet-action-message>
    </template>
  </jet-form-section>
</template>

<script>
import JetButton from "../../Jetstream/Button";
import JetFormSection from "../../Jetstream/FormSection";
import JetInput from "../../Jetstream/Input";
import JetInputError from "../../Jetstream/InputError";
import JetLabel from "../../Jetstream/Label";
import JetActionMessage from "../../Jetstream/ActionMessage";
import JetSecondaryButton from "../../Jetstream/SecondaryButton";
import JetResponsiveNavLink from "../../Jetstream/ResponsiveNavLink";
import JetDangerButton from "../../Jetstream/DangerButton";
import { mapActions } from "vuex";

export default {
  components: {
    JetActionMessage,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetSecondaryButton,
    JetResponsiveNavLink,
    JetDangerButton,
  },

  data() {
    return {
      form: {
        date: new Date().toJSON().substring(0, 10),
        context: "",
        errors: [],
      },
    };
  },

  methods: {
    ...mapActions({
      store: "affiliates_update/create",
    }),
    async createUpdate() {
      await this.store(this.form);
      this.hide();
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>

<style type="text/css" scoped>
.w-full {
  width: 100%;
}
</style>
