<template>
  <ModalContent @close="close">
    <template slot="header">
      <h3 v-if="headerText">{{ $t(`${headerText}`) }}</h3>
    </template>
    <template slot="content">
      <ErrorBanner v-if="state.errorMessage" :message="state.errorMessage" />
      <span v-if="messageText">
        {{ $t(`${messageText}`) }}
      </span>
      <router-link
        v-if="additionalLinkText"
        :to="{
          name: additionalLinkRouteName,
          query: additionalLinkQuery,
        }"
        target="_blank"
      >
        {{ $t(`${additionalLinkText}`) }}
      </router-link>
      <slot></slot>
    </template>
    <template slot="controls">
      <Button @click.prevent="onAccept" auto-width :processing="state.processing">{{ $t(`${buttonText}`) }}</Button>
      <Button
        v-if="buttonDeclineText"
        button-type="light"
        :processing="state.declineProcessing"
        @click.prevent="onDecline"
        auto-width
        >{{ $t(`${buttonDeclineText}`) }}</Button
      >
    </template>
  </ModalContent>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import ModalContent from "./ModalContent.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";

import store from "@/store";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

export default {
  emits: ["close"],

  components: {
    Button,
    ModalContent,
    ErrorBanner,
  },

  setup(props, { emit }) {
    const state = reactive({
      processing: false,
      declineProcessing: false,

      errorMessage: "",
    });

    const messageText = computed(() => store.state.modal?.payload?.message);
    const headerText = computed(() => store.state.modal?.payload?.headerText);
    const buttonText = computed(() => store.state.modal?.payload?.buttonText || "OK");
    const buttonDeclineText = computed(() => store.state.modal?.payload?.buttonDeclineText || "");
    const additionalLinkText = computed(() => store.state.modal?.payload?.additionalLinkData?.text || "");
    const additionalLinkRouteName = computed(() => store.state.modal?.payload?.additionalLinkData?.routeName || "");
    const additionalLinkQuery = computed(() => store.state.modal?.payload?.additionalLinkData?.query || {});

    async function onAccept() {
      if (state.processing) {
        return;
      }

      const { payload } = store.state.modal;

      if (payload && payload.onAccept) {
        state.processing = true;
        state.errorMessage = "";

        try {
          await payload.onAccept();
        } catch (error) {
          const { errorMessage: text } = prepareAxiosErrors(error);

          state.errorMessage = text;
          state.processing = false;
          return;
        }

        state.processing = false;
      }

      close();
    }

    async function onDecline() {
      if (state.declineProcessing) {
        return;
      }

      const { payload } = store.state.modal;

      if (payload && payload.onDecline) {
        state.declineProcessing = true;
        await payload.onDecline();
        state.declineProcessing = false;
      }

      close();
    }

    function close() {
      emit("close");
    }

    return {
      state,

      messageText,
      headerText,
      buttonText,
      buttonDeclineText,
      additionalLinkText,
      additionalLinkRouteName,
      additionalLinkQuery,

      onAccept,
      onDecline,
      close,
    };
  },
};
</script>
