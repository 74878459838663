<template>
  <ModalContent tag="form" @close="close">
    <template #header>
      <h3>{{ $t("Add Coupons Group") }}</h3>
    </template>
    <template #content>
      <ErrorBanner v-if="state.errors.errorMessage" :message="state.errors.errorMessage" />
      <label class="row">
        <span>{{ $t("Coupons group name") }}</span>
        <BaseInput
          v-model="state.couponsGroupName"
          class="base_input"
          maxlength="180"
          :error="state.errors.name"
          autofocus
        />
      </label>
    </template>
    <template #controls>
      <Button button-type="light" @click.prevent="close" auto-width>{{ $t("Cancel") }}</Button>
      <Button :processing="state.processing" @click.prevent="onCreate" auto-width type="submit">
        {{ $t("Create") }}
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";

import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import ModalContent from "../ModalContent.vue";

import request from "@/helpers/request";
import store from "@/store";
import URLS from "@/config/urls";
import { VALIDATION_ERRORS } from "@/helpers/validators";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

export default {
  emits: ["close"],

  components: {
    BaseInput,
    Button,
    ErrorBanner,
    ModalContent,
  },

  setup(props, { emit }) {
    const state = reactive({
      errors: {},
      processing: false,

      couponsGroupName: "",
    });

    const isError = computed(() => Object.keys(state.errors).length > 0);

    function close() {
      emit("close");
    }

    function validate() {
      state.errors = {};

      if (state.couponsGroupName.length === 0) {
        state.errors = {
          name: VALIDATION_ERRORS.requiredField,
        };
      }
    }

    async function onCreate() {
      console.log(": onCreate");
      if (state.processing) {
        return;
      }

      validate();

      if (isError.value) {
        return;
      }

      state.errors = {};
      state.processing = true;

      const url = URLS.coupons.groups.list;
      const method = "POST";
      const data = {
        name: state.couponsGroupName,
      };

      try {
        const result = await request({ url, method, data });

        if (store.state.modal?.payload?.onSuccess) {
          store.state.modal?.payload?.onSuccess(result);
        }

        close();
      } catch (xhrError) {
        state.errors = prepareAxiosErrors(xhrError);
      }

      state.processing = false;
    }

    return {
      state,

      close,
      onCreate,
    };
  },
};
</script>

<style lang="scss" scoped>
label.row {
  display: flex;
  flex-direction: column;

  span {
    font-weight: 400;
    font-size: 18px;
    padding: 4px;
    line-height: 1.4em;
    padding: 0.5em 0;
  }

  .base_input {
    width: 280px;
  }
}
</style>
