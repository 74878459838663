<template>
  <ModalContent @close="close">
    <template slot="header">
      <h3>{{ companyName }}</h3>
    </template>
    <template slot="content">
      <p v-if="isCanceled">
        {{ $t(`The order has already been canceled`) }}
      </p>
      <p v-else>
        {{
          $t(
            `Clicking 'Continue,' the order for the next month will change status to 'Canceled.' Users will receive notifications about the application's suspension in the following month.`
          )
        }}
      </p>
    </template>
    <template slot="controls">
      <Button button-type="light" @click.prevent="close" auto-width>{{ $t("Cancel") }}</Button>
      <Button v-if="isCanceled === false" :processing="state.processing" @click.prevent="onCancel" auto-width>
        {{ $t("Continue") }}
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import ModalContent from "../ModalContent.vue";

import store from "@/store";

export default {
  emits: ["close"],

  components: {
    Button,
    ModalContent,
  },

  setup(props, { emit }) {
    const state = reactive({
      processing: false,
    });
    const isCanceled = computed(() => {
      const { orderStatus } = store.state.modal.payload;
      return orderStatus === 9;
    });
    const companyName = computed(() => store.state.modal.payload.companyName);

    async function onCancel() {
      if (state.processing) {
        return;
      }

      const { payload } = store.state.modal;

      if (payload && payload.onCancel) {
        state.processing = true;
        await payload.onCancel(payload.orderId);
        state.processing = false;
      }

      close();
    }

    function close() {
      emit("close");
    }

    return {
      state,
      isCanceled,
      companyName,

      onCancel,
      close,
    };
  },
};
</script>
