<template>
  <small-layout
    ><div>
      <h1>Receptionists/Users</h1>
      <fieldset v-if="userAdminUpdateForm">
        <legend>Обновить <button @click="userAdminUpdateForm = false">Cancel</button></legend>
        <form @submit.prevent="fireAdminUpdateUser">
          <div>
            <label for="up_admin_user_name">Name:</label>
            <input id="up_admin_user_name" type="text" v-model="user.name" />
          </div>
          <div>
            <label for="up_admin_user_position">Позиция:</label>
            <select id="up_admin_user_position" v-model="user.position">
              <option value="guest">-</option>
              <option value="salemanager">Local Sale Manager (linked to country)</option>
              <option value="supportmanager">Support Manager (access to multiple countries)</option>
              <option value="support2manager">Local Support Manager (linked to country)</option>
              <option value="accountantlocal">Local Accountant (linked to country)</option>
              <option value="accountant">Accountant (linked to country)</option>
              <option value="admin">Admin (linked to country)</option>
            </select>
            <div>
              <label for="up_admin_country">Country:</label>
              <input id="up_admin_country" type="text" v-model="user.country" />
            </div>
            <div>
              <label for="up_manager_phone">{{ $t("columns.phone") }}</label>
              <input id="up_manager_phone" type="tel" v-model="user.phone_number" />
            </div>
          </div>
          <div>
            <button>Update</button>
          </div>
        </form>
      </fieldset>
      <fieldset v-if="userUpdateForm">
        <legend>Обновить <button @click="userUpdateForm = false">Отмена</button></legend>
        <form @submit.prevent="fireUpdateUser">
          <div>
            <label for="up_user_name">Имя:</label>
            <input id="up_user_name" type="text" v-model="user.name" />
          </div>
          <div>
            <label for="up_user_email">Email:</label>
            <input id="up_user_email" type="email" autocomplete="off" v-model="user.email" />
          </div>
          <div>
            <label for="up_user_phone">{{ $t("columns.phone") }}</label>
            <input id="up_user_phone" type="tel" v-model="user.phone_number" />
          </div>
          <div>
            <label for="up_user_password_checked">Изменить:</label>
            <input id="up_user_password_checked" type="checkbox" v-model="user.resetPassword" />
            <label for="up_user_password">Пароль:</label>
            <input
              id="up_user_password"
              :type="up_user_password_shown"
              autocomplete="new-password"
              v-model="user.password"
            />
            <label for="up_user_password_shown">Показать:</label>
            <input
              id="up_user_password_shown"
              type="checkbox"
              v-model="up_user_password_shown"
              :true-value="'text'"
              :false-value="'password'"
            />
          </div>
          <div>
            <label for="up_user_position">Позиция:</label>
            <select id="up_user_position" v-model="user.position">
              <option value="finance">Финансы (доступ к нескольким поставщикам)</option>
              <option value="technical manager">Ресепшн (доступ только к одному поставщику)</option>
            </select>
          </div>
          <div>
            <span>{{ user.suppliers.length }} поставщиков</span>
            <label for="up_user_suppliers_checked">Изменить список поставщиков:</label>
            <input id="up_user_suppliers_checked" type="checkbox" v-model="user.resetSuppliers" />
            <ModelListSelect
              :list="searchableSuppliers"
              option-value="id"
              option-text="name"
              v-model="selectedSupplierOnUpdate"
              placeholder="выбрать поставщика"
              @searchchange="searchSupplier"
              :filterPredicate="startsWithPredicate"
            ></ModelListSelect>
            <button type="button" @click="addSelectedSupplier(user, searchableSuppliers, selectedSupplierOnUpdate)">
              Добавить
            </button>
            <ul>
              <li v-for="supplier in user.suppliers">
                {{ supplier.name
                }}<button type="button" @click="deleteSelectedSupplier(user, supplier)">Удалить</button>
              </li>
            </ul>
          </div>
          <div>
            <button>Обновить</button>
          </div>
        </form>
      </fieldset>
      <fieldset v-if="userCreateForm">
        <title>Добавление</title>
        <form @submit.prevent="fireCreateUser">
          <div>
            <label for="cr_user_name">Имя:</label>
            <input id="cr_user_name" type="text" v-model="newUser.name" />
          </div>
          <div>
            <label for="cr_user_email">Email:</label>
            <input id="cr_user_email" type="email" autocomplete="off" v-model="newUser.email" />
          </div>
          <div>
            <label for="cr_user_phone">{{ $t("columns.phone") }}</label>
            <input id="cr_user_phone" type="tel" v-model="newUser.phone_number" />
          </div>
          <div>
            <label for="cr_user_password">Пароль:</label>
            <input
              id="cr_user_password"
              :type="cr_user_password_shown"
              autocomplete="new-password"
              v-model="newUser.password"
            />
            <label for="cr_user_password_shown">Показать:</label>
            <input
              id="cr_user_password_shown"
              type="checkbox"
              v-model="cr_user_password_shown"
              :true-value="'text'"
              :false-value="'password'"
            />
          </div>
          <div>
            <label for="cr_user_position">Позиция:</label>
            <select id="cr_user_position" v-model="newUser.position">
              <option value="finance">Финансы (доступ к нескольким поставщикам)</option>
              <option value="technical manager">Ресепшн (доступ только к одному поставщику)</option>
            </select>
          </div>
          <div>
            <span for="cr_user_suppliers">{{ newUser.suppliers.length }} поставщиков</span>
            <ModelListSelect
              id="cr_user_suppliers"
              :list="searchableSuppliers"
              option-value="id"
              option-text="name"
              v-model="selectedSupplierOnUpdate"
              placeholder="выбрать поставщика"
              @searchchange="searchSupplier"
              :filterPredicate="startsWithPredicate"
            ></ModelListSelect>
            <button type="button" @click="addSelectedSupplier(newUser, searchableSuppliers, selectedSupplierOnUpdate)">
              Добавить
            </button>
            <ul>
              <li v-for="supplier in newUser.suppliers">
                {{ supplier.name
                }}<button type="button" @click="deleteSelectedSupplier(newUser, supplier)">Удалить</button>
              </li>
            </ul>
          </div>
          <div>
            <button>Создать</button>
          </div>
        </form>
      </fieldset>

      <form id="search" v-if="!$route.params.user_id">
        <button @click.prevent="userCreateForm = !userCreateForm">
          {{ userCreateForm ? "Отмена" : "Добавление" }}
        </button>
        Local search <input name="query" v-model="filterKey" />
        <button @click.prevent="resetUsers">Повторно Загрузить с сервера</button>
      </form>
      <table>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.name | capitalize }}
              <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredSource">
            <td>{{ entry.id }}</td>
            <td>{{ entry.name }}</td>
            <td>{{ entry.email }}</td>
            <td>{{ entry.plain_password }}</td>
            <td>{{ entry.position }}</td>
            <td>
              <ul v-if="entry.suppliers">
                <li v-for="supplier in entry.suppliers">
                  <router-link :key="supplier.id" :to="{ name: 'supplier_view', params: { supplier: supplier.id } }">{{
                    supplier.name
                  }}</router-link>
                </li>
              </ul>
            </td>
            <td>{{ entry.updated_at | parseDate }}</td>
            <td>{{ entry.phone_number }}</td>
            <td>
              <span v-if="entry.position != 'admin'">
                <button @click="updatingUser(entry)">Update</button>
                <button @click="fireDeleteUser(entry)">Delete</button>
              </span>
              <span v-if="entry.position == 'admin'">Админа нельзя удалить или обновить тут</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import { parseDate } from "../helpers";
import { mapActions, mapGetters } from "vuex";
import { ModelListSelect } from "vue-search-select";
import SortMixin from "../mixins/SortMixin";
export default {
  mixins: [SortMixin],
  components: {
    ModelListSelect,
  },
  data: function () {
    return {
      newUser: {
        position: "technical manager",
        suppliers: [],
        phone_number: "",
      },
      user: {},
      user_id: 0,
      up_user_password_shown: "password",
      cr_user_password_shown: "password",
      searchableSuppliers: [],
      selectedSupplierOnUpdate: undefined,
      searchSupplierSelected: true,
      updatingUserItem: null,
      userCreateForm: false,
      userUpdateForm: false,
      userAdminUpdateForm: false,
    };
  },
  filters: {
    parseDate,
  },
  computed: {
    ...mapGetters({
      source: "user/user_list",
    }),

    filteredSource() {
      const orderKey = this.orderKey;
      const index = this.columns.findIndex((column) => column.key === orderKey);
      let order = -1;
      if (index >= 0) {
        order = this.columns[index].order;
      }
      let data = this.source;
      if (Number(this.$route.params.user_id)) {
        const user_id = Number(this.$route.params.user_id);
        data = data.filter(function (row) {
          return row.id === user_id;
        });
      }
      if (this.filterKey) {
        const filterKey = this.filterKey && this.filterKey.toLowerCase();
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (orderKey && index !== -1) {
        this.columns[index].order = order;
        data = data.slice().sort(function (a, b) {
          a = a[orderKey];
          b = b[orderKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      if (index === -1) {
        console.log(`${index} cannot be -1. We are looking for ${orderKey} in our columns.`);
      }
      return data;
    },
  },
  methods: {
    createColumns() {
      return [
        { key: "id", name: "Id", order: 1 },
        { key: "name", name: "Name", order: 1 },
        { key: "email", name: "Email", order: 1 },
        { key: "plain_password", name: "Password", order: 1 },
        { key: "position", name: "Position", order: 1 },
        { key: "suppliers", name: "Suppliers", order: 1 },
        { key: "updated_at", name: "Updated", order: 1 },
        { key: "phone_number", name: "Phone", order: 1 },
      ];
    },
    ...mapActions({
      fetchUsers: "user/user_list",
      createUser: "user/add_user",
      updateUser: "user/update_user",
      deleteUser: "user/delete_user",
      supplierSearch: "user/supplier_search",
      updateAdminUser: "user/update_admin_user",
    }),
    async fireDeleteUser(user) {
      if (confirm("Are you sure?")) {
        await this.deleteUser(user);
      }
    },
    async fireUpdateUser() {
      await this.updateUser(this.user);
      this.userUpdateForm = false;
    },
    async fireAdminUpdateUser() {
      await this.updateAdminUser(this.user);
      this.userAdminUpdateForm = false;
    },
    async fireCreateUser() {
      await this.createUser(this.newUser);
      this.userCreateForm = false;
    },
    deleteSelectedSupplier(user, supplier) {
      user.suppliers.splice(user.suppliers.indexOf(supplier), 1);
    },
    async searchSupplier(searchText) {
      if (this.searchSupplierSelected) {
        this.searchSupplierSelected = false;
        try {
          if (!searchText && this.selectedSupplierOnUpdate) {
            this.searchSupplierSelected = true;
            return;
          }
          this.searchableSuppliers = await this.supplierSearch(searchText);
        } catch (e) {}
        this.searchSupplierSelected = true;
      }
    },
    startsWithPredicate(text, inputText) {
      return text.contains(inputText);
    },
    isSupplier(user) {
      return ["finance", "technical manager"].indexOf(user.position) !== -1;
    },
    updatingUser(user) {
      this.updatingUserItem = user;
      this.user = JSON.parse(JSON.stringify(user));
      if (this.isSupplier(user)) {
        this.userUpdateForm = true;
        return;
      }
      this.userAdminUpdateForm = true;
    },
    async resetUsers() {
      await this.fetchUsers();
    },
    addSelectedSupplier(user, searchableSuppliers, selected_id) {
      const suppliers = searchableSuppliers.filter((supplier) => supplier.id === selected_id);
      if (suppliers.length) {
        user.suppliers.push(suppliers.pop());
      }
    },
  },
  async mounted() {
    if (!this.source.length) {
      await this.resetUsers();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

@include table-hoverable;
</style>
