<template>
  <small-layout
    ><div>
      <h1>Update</h1>
      <form v-if="order" @submit.prevent="fireUpdateOrder">
        <fieldset>
          <div v-if="manager_list && manager_list.length > 1">
            <label for="params_manager_id">manager</label>
            <select id="params_manager_id" v-model="order.manager_id">
              <option v-for="manager in manager_list" :value="manager.id">{{ manager.name }}</option>
            </select>
          </div>
          <div>
            <label for="params_invoice_mode">{{ $t("Invoice Mode") }}</label>
            <select id="params_invoice_mode" v-model="order.invoice_mode">
              <option v-for="invoice_mode in invoice_modes" :value="invoice_mode" :key="`order-option-invoice_mode-${invoice_mode}`">
                {{ invoice_mode }}
              </option>
            </select>
          </div>
          <div>
            <button>Confirm</button>
          </div>
        </fieldset>
      </form>
    </div></small-layout
  >
</template>

<script>
import { mapActions } from "vuex";
import config from "../../config";

export default {
  data() {
    return {
      invoice_modes: config.invoice_modes,
      manager_list: [],
      order: null,
    };
  },
  async mounted() {
    this.order = await this.chooseOrder(this.$route.params.order);
    this.manager_list = await this.managers();
  },
  methods: {
    ...mapActions({
      updateOrder: "company/update_order_manager",
      chooseOrder: "company/load_claim_by_id",
      managers: "user/managers",
    }),
    async fireUpdateOrder() {
      await this.updateOrder(this.order);
    },
  },
};
</script>
