<template>
  <div id="supplier_audit" class="container">
    <div v-model="metadata">
      <div class="left-row">
        <strong>ID</strong>
      </div>
      <div class="right-row">audit_id</div>
      <div class="left-row">
        <strong>event</strong>
      </div>
      <div class="right-row">{{ metadata.audit_event }}</div>
      <div class="left-row">
        <strong>user</strong>
      </div>
      <div class="right-row">{{ metadata.user_name }}</div>
      <div class="left-row">
        <strong>ip_address</strong>
      </div>
      <div class="right-row">{{ metadata.audit_ip_address }}</div>
      <div class="left-row">
        <strong>user_agent</strong>
      </div>
      <div class="right-row">{{ metadata.audit_user_agent }}</div>
      <div class="left-row">
        <strong>tags</strong>
      </div>
      <div class="right-row">{{ metadata.audit_tags }}</div>
      <div class="left-row">
        <strong>url</strong>
      </div>
      <div class="right-row">{{ metadata.audit_url }}</div>
    </div>

    <hr />

    <table class="table table-striped">
      <thead>
        <tr>
          <th>attribute</th>
          <th>old</th>
          <th>new</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, attribute) in modified">
          <td>
            <strong>{{ attribute }}</strong>
          </td>
          <td class="danger">{{ value.old }}</td>
          <td class="success">{{ value.new }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    metadata: Object,
    modified: Object,
  },
  name: "AuditView",
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.left-row {
  grid-column: span 3;
}
.right-row {
  grid-column: span 9;
}
</style>
