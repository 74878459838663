import { EMAIL_COMMON_REGEXP } from "@/config/validators";
import { VALIDATION_ERRORS } from "../index";

const emailCommonRegexp = new RegExp(EMAIL_COMMON_REGEXP);

export default (email) => {
  if (email.length === 0) {
    return false;
  }

  if (!emailCommonRegexp.test(email)) {
    return VALIDATION_ERRORS.invalidFormat;
  }

  return false;
};
