import camelCase from "lodash/camelCase";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import snakeCase from "lodash/snakeCase";

export function camelCaseObjectKeys(obj) {
  if (!obj) {
    return obj;
  }

  const camelCasedObject = Object.keys(obj).reduce((result, key) => {
    const newKey = camelCase(key);
    const value = obj[key];
    const isObjectValue = isObject(value) && !isArray(value);

    return Object.assign(result, {
      [newKey]: isObjectValue ? camelCaseObjectKeys(value) : value,
    });
  }, {});

  return camelCasedObject;
}

export function snakeCaseObjectKeys(obj) {
  if (!obj) {
    return obj;
  }

  const snakeCasedObject = Object.keys(obj).reduce((result, key) => {
    const newKey = snakeCase(key);
    const value = obj[key];
    const isObjectValue = isObject(value) && !isArray(value);

    return Object.assign(result, {
      [newKey]: isObjectValue ? snakeCaseObjectKeys(value) : value,
    });
  }, {});

  return snakeCasedObject;
}
