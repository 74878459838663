<template>
  <jet-form-section @click_overlay="hide">
    <template #title> New </template>

    <template #description>
      After you create message, you can send it to all holders with active subscription. You cannot send one message two
      times, sending will be ignored.
    </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="title" value="Title" />
        <jet-input id="title" type="text" class="mt-1 block w-full" v-model="form.title" autofocus />
        <jet-input-error :message="form.errors.title" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="link" value="Open Screen in APP" />
        <jet-input id="link" type="text" class="mt-1 block w-full" v-model="form.link" autofocus />
        <jet-input-error :message="form.errors.link" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="url" value="URL TO ANOTHER SOURCE" />
        <jet-input id="url" type="url" class="mt-1 block w-full" v-model="form.url" autofocus />
        <jet-input-error :message="form.errors.url" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="body" value="Body" />
        <textarea id="body" rows="6" class="mt-1 block w-full" v-model="form.body" autofocus />
        <jet-input-error :message="form.errors.body" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="message_status" value="Supplier with Tickets Only" />
        <select id="message_status" class="mt-1 block w-full" v-model="form.message_status" autofocus>
          <option value=""></option>
          <option
            v-for="message_status in [
              'company',
              'support',
              'rating',
              'objects',
              'change_status',
              'hard_upgrade',
              'soft_upgrade',
            ]"
            :value="message_status"
          >
            {{ message_status }}
          </option>
        </select>
        <jet-input-error :message="form.errors.message_status" class="mt-2" />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <jet-label for="phone" value="Phone if we need to send only 1 user" />
        <jet-input id="phone" type="number" class="mt-1 block w-full" v-model="form.phone" autofocus />
        <jet-input-error :message="form.errors.phone" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <jet-button
        @click="createMessage"
        class="ml-4"
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        Confirm
      </jet-button>
      <jet-action-message :on="form.recentlySuccessful" class="mr-3"> Saved. </jet-action-message>
    </template>
  </jet-form-section>
</template>

<script>
import JetButton from "../../Jetstream/Button";
import JetFormSection from "../../Jetstream/FormSection";
import JetInput from "../../Jetstream/Input";
import JetInputError from "../../Jetstream/InputError";
import JetLabel from "../../Jetstream/Label";
import JetActionMessage from "../../Jetstream/ActionMessage";
import JetSecondaryButton from "../../Jetstream/SecondaryButton";
import JetResponsiveNavLink from "../../Jetstream/ResponsiveNavLink";
import JetDangerButton from "../../Jetstream/DangerButton";
import { mapActions } from "vuex";

export default {
  components: {
    JetActionMessage,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetSecondaryButton,
    JetResponsiveNavLink,
    JetDangerButton,
  },

  data() {
    return {
      form: {
        title: "",
        url: "",
        link: "",
        body: "",
        message_status: "",
        phone: "",
        errors: [],
      },
    };
  },

  methods: {
    ...mapActions({
      store: "message/store",
    }),
    async createMessage() {
      await this.store(this.form);
      this.hide();
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
